// Redux
import { Dispatch } from 'redux';
import { invoiceActionTypes } from '../constants/invoice_constants';
import { API } from '../api/invoices-api';
import * as types from '../types/invoices-types';

// Models
import { NewInvoice, InvoiceInfo } from '../models/invoice/invoice_model';
import { ActivityInvoice } from "../models/invoice/activityInvoice_model";

// -------------------------------------INVOICES FETCH ----------------------------------------

const invoiceFetchBegin = (): types.IInvoicesFetchBegin => ({
    type: invoiceActionTypes.INVOICE_FETCH_BEGIN,
});

const invoiceFetchError = (e: any, errorType: any): types.IInvoicesFetchError => ({
    type: invoiceActionTypes.INVOICE_FETCH_ERROR,
    error: e,
    errorType: errorType
});

const invoiceFetchSuccess = (data: Array<InvoiceInfo>): types.IInvoicesFetchSuccess => ({
    type: invoiceActionTypes.INVOICE_FETCH_SUCCESS,
    data,
});

export const invoiceFetch = (activePaid: boolean, activeArchive: boolean) => (dispatch: Dispatch) => {
    dispatch(invoiceFetchBegin());
    API.fetchInvoices(activePaid, activeArchive).then((response: any) => {
        dispatch(invoiceFetchSuccess(response.data));
    }).catch((error: any) => {
        dispatch(invoiceFetchError("Error", error.response.request.status));
    });
};

// -------------------------------------INVOICE LINK ----------------------------------------

const invoiceLinkBegin = (): types.IInvoiceLinkBegin => ({
    type: invoiceActionTypes.INVOICE_LINK_BEGIN,
});

const invoiceLinkError = (e: any, errorType: any): types.IInvoiceLinkError => ({
    type: invoiceActionTypes.INVOICE_LINK_ERROR,
    error: e,
    errorType: errorType
});

const invoiceLinkSuccess = (data: Array<InvoiceInfo>): types.IInvoiceLinkSuccess => ({
    type: invoiceActionTypes.INVOICE_LINK_SUCCESS,
    data,
});

export const invoiceLink = (idInvoice: string) => (dispatch: Dispatch) => {
    dispatch(invoiceLinkBegin());
    API.invoiceLink(idInvoice).then((response: any) => {
        dispatch(invoiceLinkSuccess(response.data));
    }).catch((error: any) => {
        dispatch(invoiceLinkError("Error", error.response.request.status));
    });
};

// -------------------------------------LAST INVOICE ----------------------------------------

const lastInvoiceBegin = (): types.ILastInvoiceBegin => ({
    type: invoiceActionTypes.LAST_INVOICE_BEGIN,
});

const lastInvoiceError = (e: any, errorType: any): types.ILastInvoiceError => ({
    type: invoiceActionTypes.LAST_INVOICE_ERROR,
    error: e,
    errorType: errorType
});

const lastInvoiceSuccess = (data: any): types.ILastInvoiceSuccess => ({
    type: invoiceActionTypes.LAST_INVOICE_SUCCESS,
    data,
});

export const lastInvoice = () => (dispatch: Dispatch) => {
    dispatch(lastInvoiceBegin());
    API.lastInvoice().then((response: any) => {
        dispatch(lastInvoiceSuccess(response.data));
    }).catch((error: any) => {
        dispatch(lastInvoiceError("Error", error.response.request.status));
    });
};

// -------------------------------------FETCH ACTIVITY INVOICE ----------------------------------------

const invoiceActivityFetchBegin = (): types.IActivityInvoiceFetchBegin => ({
    type: invoiceActionTypes.ACTIVITY_INVOICE_FETCH_BEGIN,
});

const invoiceActivityFetchError = (e: any, errorType: any): types.IActivityInvoiceFetchError => ({
    type: invoiceActionTypes.ACTIVITY_INVOICE_FETCH_ERROR,
    error: e,
    errorType: errorType
});

const invoiceActivityFetchSuccess = (data: Array<ActivityInvoice>): types.IActivityInvoiceFetchSuccess => ({
    type: invoiceActionTypes.ACTIVITY_INVOICE_FETCH_SUCCESS,
    data,
});

export const invoiceActivityFetch = (id: string) => (dispatch: Dispatch) => {
    dispatch(invoiceActivityFetchBegin());
    API.invoiceActivity(id).then((response: any) => {
        dispatch(invoiceActivityFetchSuccess(response.data));
    }).catch((error: any) => {
        dispatch(invoiceActivityFetchError("Error", error.response.request.status));
    });
};




// -------------------------------------NEW INVOICE ----------------------------------------

const newInvoiceBegin = (): types.INewInvoiceBegin => ({
    type: invoiceActionTypes.NEW_INVOICE_BEGIN,
});

const newInvoiceError = (e: any, errorType: any): types.INewInvoiceError => ({
    type: invoiceActionTypes.NEW_INVOICE_ERROR,
    error: e,
    errorType: errorType
});

const newInvoiceSuccess = (data: any): types.INewInvoiceSuccess => ({
    type: invoiceActionTypes.NEW_INVOICE_SUCCESS,
    data,
});

export const newInvoice = (data: any) => (dispatch: Dispatch) => {
    dispatch(newInvoiceBegin());
    API.createInvoice(data).then((response: any) => {
        dispatch(newInvoiceSuccess(response.data));
    }).catch((error: any) => {
        dispatch(newInvoiceError(error.response.data.message, error.response.request.status));
    });
};

// -------------------------------------UPDATE INVOICE ----------------------------------------

const updateInvoiceBegin = (data: any): types.IUpdateInvoiceBegin => ({
    type: invoiceActionTypes.UPDATE_INVOICE_BEGIN,
    data
});

const updateInvoiceError = (e: any, errorType: any): types.IUpdateInvoiceError => ({
    type: invoiceActionTypes.UPDATE_INVOICE_ERROR,
    error: e,
    errorType: errorType
});

const updateInvoiceSuccess = (): types.IUpdateInvoiceSuccess => ({
    type: invoiceActionTypes.UPDATE_INVOICE_SUCCESS,
});

export const updateInvoice = (data: NewInvoice, id: string) => (dispatch: Dispatch) => {
    dispatch(updateInvoiceBegin(data));
    API.updateInvoice(data, id).then((response: any) => {
        dispatch(updateInvoiceSuccess());
    }).catch((error: any) => {
        dispatch(updateInvoiceError(error.response.data.message, error.response.request.status));
    });
};

//--------------------------------------statusApprovedByAM------------------------------------------------
const statusApprovedAMBegin = (): types.IStatusApprovedAMBegin => ({
    type: invoiceActionTypes.STATUS_APPROVED_AM_BEGIN,
});

const statusApprovedAMError = (e: any, errorType: any): types.IStatusApprovedAMError => ({
    type: invoiceActionTypes.STATUS_APPROVED_AM_ERROR,
    error: e,
    errorType: errorType
});

const statusApprovedAMSuccess = (): types.IStatusApprovedAMSuccess => ({
    type: invoiceActionTypes.STATUS_APPROVED_AM_SUCCESS,

});

export const statusApprovedAM = (data: any) => (dispatch: Dispatch) => {
    dispatch(statusApprovedAMBegin());
    API.statusAprovedByAM(data).then((response: any) => {
        dispatch(statusApprovedAMSuccess());
    }).catch((error: any) => {
        dispatch(statusApprovedAMError(error, error.response.request.status));
    });
};

//--------------------------------------statusApprovedByAM------------------------------------------------
const statusApprovedAMGroupBegin = (): types.IStatusApprovedAMGroupBegin => ({
    type: invoiceActionTypes.STATUS_APPROVED_AM_GROUP_BEGIN,
});

const statusApprovedAMGroupError = (e: any, errorType: any): types.IStatusApprovedAMGroupError => ({
    type: invoiceActionTypes.STATUS_APPROVED_AM_GROUP_ERROR,
    error: e,
    errorType: errorType
});

const statusApprovedAMGroupSuccess = (): types.IStatusApprovedAMGroupSuccess => ({
    type: invoiceActionTypes.STATUS_APPROVED_AM_GROUP_SUCCESS,

});

export const statusApprovedAMGroup = (data: any) => (dispatch: Dispatch) => {
    dispatch(statusApprovedAMGroupBegin());
    API.statusAprovedByAM(data).then((response: any) => {
        dispatch(statusApprovedAMGroupSuccess());
    }).catch((error: any) => {
        dispatch(statusApprovedAMGroupError(error, error.response.request.status));
    });
};

//--------------------------------------statusApprovedByFinance------------------------------------------------
const statusApprovedFinanceBegin = (): types.IStatusApprovedFinanceBegin => ({
    type: invoiceActionTypes.STATUS_APPROVED_FINANCE_BEGIN,
});

const statusApprovedFinanceError = (e: any, errorType: any): types.IStatusApprovedFinanceError => ({
    type: invoiceActionTypes.STATUS_APPROVED_FINANCE_ERROR,
    error: e,
    errorType: errorType
});

const statusApprovedFinanceSuccess = (): types.IStatusApprovedFinanceSuccess => ({
    type: invoiceActionTypes.STATUS_APPROVED_FINANCE_SUCCESS,

});

export const statusApprovedFinance = (data: any) => (dispatch: Dispatch) => {
    dispatch(statusApprovedFinanceBegin());
    API.statusAprovedByFinance(data).then((response: any) => {
        dispatch(statusApprovedFinanceSuccess());
    }).catch((error: any) => {
        dispatch(statusApprovedFinanceError(error, error.response.request.status));
    });
};

//--------------------------------------statusApprovedByFinanceGroup------------------------------------------------
const statusApprovedFinanceGroupBegin = (): types.IStatusApprovedFinanceGroupBegin => ({
    type: invoiceActionTypes.STATUS_APPROVED_FINANCE_GROUP_BEGIN,
});

const statusApprovedFinanceGroupError = (e: any, errorType: any): types.IStatusApprovedFinanceGroupError => ({
    type: invoiceActionTypes.STATUS_APPROVED_FINANCE_GROUP_ERROR,
    error: e,
    errorType: errorType
});

const statusApprovedFinanceGroupSuccess = (): types.IStatusApprovedFinanceGroupSuccess => ({
    type: invoiceActionTypes.STATUS_APPROVED_FINANCE_GROUP_SUCCESS,

});

export const statusApprovedFinanceGroup = (data: any) => (dispatch: Dispatch) => {
    dispatch(statusApprovedFinanceGroupBegin());
    API.statusAprovedByFinance(data).then((response: any) => {
        dispatch(statusApprovedFinanceGroupSuccess());
    }).catch((error: any) => {
        dispatch(statusApprovedFinanceGroupError(error, error.response.request.status));
    });
};

//--------------------------------------statusPaidByFinance------------------------------------------------
const statusPaidFinanceBegin = (): types.IStatusPaidFinanceBegin => ({
    type: invoiceActionTypes.STATUS_PAID_FINANCE_BEGIN,
});

const statusPaidFinanceError = (e: any, errorType: any): types.IStatusPaidFinanceError => ({
    type: invoiceActionTypes.STATUS_PAID_FINANCE_ERROR,
    error: e,
    errorType: errorType
});

const statusPaidFinanceSuccess = (): types.IStatusPaidFinanceSuccess => ({
    type: invoiceActionTypes.STATUS_PAID_FINANCE_SUCCESS,

});

export const statusPaidFinance = (data: any) => (dispatch: Dispatch) => {
    dispatch(statusPaidFinanceBegin());
    API.statusPAIDByFinance(data).then((response: any) => {
        dispatch(statusPaidFinanceSuccess());
    }).catch((error: any) => {
        dispatch(statusPaidFinanceError(error, error.response.request.status));
    });
};

//--------------------------------------statusRejectedByAM------------------------------------------------------
const statusRejectedAMBegin = (): types.IStatusRejectedAMBegin => ({
    type: invoiceActionTypes.STATUS_REJECTED_AM_BEGIN,
});

const statusRejectedAMError = (e: any, errorType: any): types.IStatusRejectedAMError => ({
    type: invoiceActionTypes.STATUS_REJECTED_AM_ERROR,
    error: e,
    errorType: errorType
});

const statusRejectedAMSuccess = (): types.IStatusRejectedAMSuccess => ({
    type: invoiceActionTypes.STATUS_REJECTED_AM_SUCCESS,

});

export const statusRejectedAM = (data: any) => (dispatch: Dispatch) => {
    dispatch(statusRejectedAMBegin());
    API.statusRejectedAM(data).then((response: any) => {
        dispatch(statusRejectedAMSuccess());
    }).catch((error: any) => {
        dispatch(statusRejectedAMError(error, error.response.request.status));
    });
};

//--------------------------------------statusRejectedByAM------------------------------------------------------
const statusRejectedFinanceBegin = (): types.IStatusRejectedFinanceBegin => ({
    type: invoiceActionTypes.STATUS_REJECTED_FINANCE_BEGIN,
});

const statusRejectedFinanceError = (e: any, errorType: any): types.IStatusRejectedFinanceError => ({
    type: invoiceActionTypes.STATUS_REJECTED_FINANCE_ERROR,
    error: e,
    errorType: errorType
});

const statusRejectedFinanceSuccess = (): types.IStatusRejectedFinanceSuccess => ({
    type: invoiceActionTypes.STATUS_REJECTED_FINANCE_SUCCESS,

});

export const statusRejectedFinance = (data: any) => (dispatch: Dispatch) => {
    dispatch(statusRejectedFinanceBegin());
    API.statusRejectedByFinance(data).then((response: any) => {
        dispatch(statusRejectedFinanceSuccess());
    }).catch((error: any) => {
        dispatch(statusRejectedFinanceError(error, error.response.request.status));
    });
};

// -------------------------------------ARCHIVE INVOICE ----------------------------------------

const archiveInvoiceBegin = (): types.IArchiveInvoiceBegin => ({
    type: invoiceActionTypes.ARCHIVE_INVOICE_BEGIN,
});

const archiveInvoiceError = (e: any, errorType: any): types.IArchiveInvoiceError => ({
    type: invoiceActionTypes.ARCHIVE_INVOICE_ERROR,
    error: e,
    errorType: errorType
});

const archiveInvoiceSuccess = (): types.IArchiveInvoiceSuccess => ({
    type: invoiceActionTypes.ARCHIVE_INVOICE_SUCCESS,
});

export const archiveInvoice = (id: string) => (dispatch: Dispatch) => {
    dispatch(archiveInvoiceBegin());
    API.archiveInvoice(id).then((response: any) => {
        dispatch(archiveInvoiceSuccess());
    }).catch((error: any) => {
        dispatch(archiveInvoiceError("Error", error.response));
    });
};

// -------------------------------------UNARCHIVE INVOICE ----------------------------------------

const unarchiveInvoiceBegin = (): types.IUnarchiveInvoiceBegin => ({
    type: invoiceActionTypes.UNARCHIVE_INVOICE_BEGIN,
});

const unarchiveInvoiceError = (e: any, errorType: any): types.IUnarchiveInvoiceError => ({
    type: invoiceActionTypes.UNARCHIVE_INVOICE_ERROR,
    error: e,
    errorType: errorType
});

const unarchiveInvoiceSuccess = (): types.IUnarchiveInvoiceSuccess => ({
    type: invoiceActionTypes.UNARCHIVE_INVOICE_SUCCESS,
});

export const unarchiveInvoice = (id: string) => (dispatch: Dispatch) => {
    dispatch(unarchiveInvoiceBegin());
    API.unarchiveInvoice(id).then((response: any) => {
        dispatch(unarchiveInvoiceSuccess());
    }).catch((error: any) => {
        dispatch(unarchiveInvoiceError("Error", error.response));
    });
};

//--------------------------------------statusPaidByFinanceGroup------------------------------------------------
const statusPaidGroupFinanceBegin = (): types.IStatusPaidGroupFinanceBegin => ({
    type: invoiceActionTypes.STATUS_PAID_GROUP_FINANCE_BEGIN,
});

const statusPaidGroupFinanceError = (e: any, errorType: any): types.IStatusPaidGroupFinanceError => ({
    type: invoiceActionTypes.STATUS_PAID_GROUP_FINANCE_ERROR,
    error: e,
    errorType: errorType
});

const statusPaidGroupFinanceSuccess = (): types.IStatusPaidGroupFinanceSuccess => ({
    type: invoiceActionTypes.STATUS_PAID_GROUP_FINANCE_SUCCESS,

});

export const statusPaidGroupFinance = (data: any) => (dispatch: Dispatch) => {
    dispatch(statusPaidGroupFinanceBegin());
    API.statusPAIDByFinance(data).then((response: any) => {
        dispatch(statusPaidGroupFinanceSuccess());
    }).catch((error: any) => {
        dispatch(statusPaidGroupFinanceError(error, error.response.request.status));
    });
};

// -------------------------------------ARCHIVE INVOICE GROUP----------------------------------------

const archiveInvoiceGroupBegin = (): types.IArchiveInvoiceGroupBegin => ({
    type: invoiceActionTypes.ARCHIVE_INVOICE_GROUP_BEGIN,
});

const archiveInvoiceGroupError = (e: any, errorType: any): types.IArchiveInvoiceGroupError => ({
    type: invoiceActionTypes.ARCHIVE_INVOICE_GROUP_ERROR,
    error: e,
    errorType: errorType
});

const archiveInvoiceGroupSuccess = (): types.IArchiveInvoiceGroupSuccess => ({
    type: invoiceActionTypes.ARCHIVE_INVOICE_GROUP_SUCCESS,
});

export const archiveInvoiceGroup = (id: string) => (dispatch: Dispatch) => {
    dispatch(archiveInvoiceGroupBegin());
    API.archiveInvoice(id).then((response: any) => {
        dispatch(archiveInvoiceGroupSuccess());
    }).catch((error: any) => {
        dispatch(archiveInvoiceGroupError("Error", error.response));
    });
};

// -------------------------------------UNARCHIVE INVOICE GROUP----------------------------------------

const unarchiveInvoiceGroupBegin = (): types.IUnarchiveInvoiceGroupBegin => ({
    type: invoiceActionTypes.UNARCHIVE_INVOICE_GROUP_BEGIN,
});

const unarchiveInvoiceGroupError = (e: any, errorType: any): types.IUnarchiveInvoiceGroupError => ({
    type: invoiceActionTypes.UNARCHIVE_INVOICE_GROUP_ERROR,
    error: e,
    errorType: errorType
});

const unarchiveInvoiceGroupSuccess = (): types.IUnarchiveInvoiceGroupSuccess => ({
    type: invoiceActionTypes.UNARCHIVE_INVOICE_GROUP_SUCCESS,
});

export const unarchiveInvoiceGroup = (id: string) => (dispatch: Dispatch) => {
    dispatch(unarchiveInvoiceGroupBegin());
    API.unarchiveInvoice(id).then((response: any) => {
        dispatch(unarchiveInvoiceGroupSuccess());
    }).catch((error: any) => {
        dispatch(unarchiveInvoiceGroupError("Error", error.response));
    });
};

//--------------------------------------Revert invoice------------------------------------------------------
const revertInvoiceBegin = (): types.IRevertInvoiceBegin => ({
    type: invoiceActionTypes.REVERT_INVOICE_BEGIN,
});

const revertInvoiceError = (e: any, errorType: any): types.IRevertInvoiceError => ({
    type: invoiceActionTypes.REVERT_INVOICE_ERROR,
    error: e,
    errorType: errorType
});

const revertInvoiceSuccess = (): types.IRevertInvoiceSuccess => ({
    type: invoiceActionTypes.REVERT_INVOICE_SUCCESS,

});

export const revertInvoice = (id: any) => (dispatch: Dispatch) => {
    dispatch(revertInvoiceBegin());
    API.revertStatusInvoice(id).then((response: any) => {
        dispatch(revertInvoiceSuccess());
    }).catch((error: any) => {
        dispatch(revertInvoiceError(error, error.response.request.status));
    });
};