import React, { Component } from 'react';

//Styles
import style from '../../styles/basicComponents/ToastAlert.module.css';

//Icons
import { FaCheck } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

interface Props {
  label?: string
  showToast: boolean
  timeActive: number
  disableFunction: () => void
  error?: boolean
}

interface State { }

export default class ToastAlert extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {};
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.showToast != prevProps.showToast) {
      if (this.props.showToast) {
        setTimeout(() => { this.props.disableFunction() }, this.props.timeActive)
      }
    }
  }

  render() {
    return (
      <>
        <div className={[style.__mainStructure, this.props.showToast ? style.__visibleToast : style.__disabledToast].join(' ')}>
          {!this.props.error ?
            <div className={style.__baseIcon}>
              <div className={style.__IconBack}><FaCheck className={style.__iconSuccesfully} /></div>
            </div>
            :
            <div className={style.__baseIcon}>
              <div className={style.__IconBackError}>
                <div className={style.__IconBackErrorWhite}>
                  <MdCancel className={style.__iconError} /></div>
              </div>
            </div>
          }
          {!this.props.error ?
            <div className={style.__baseText}><div className={style.__textCenter}>{this.props.label ? this.props.label : <>Your information has been saved succesfully</>}</div></div>
            :
            <div className={style.__baseText}><div className={style.__textCenter}>{this.props.label ? this.props.label : <>Something went wrong. Please try again later</>}</div></div>
          }
        </div>
      </>
    );
  }
}
