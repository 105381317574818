import React, { Component } from 'react';

//components internal
import TextField from '../../components/basicComponents/TextField';
import TextFieldArea from '../../components/basicComponents/TextFieldArea';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import ToastAlert from '../../components/basicComponents/ToastAlert';
import Loader from '../../components/basicComponents/Loader';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';
import PopUp from '../../components/basicComponents/PopUp';

//components external
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//styles
import styles from '../../styles/screens/myProfile.module.css';
import stylesPop from '../../styles/screens/updatePassword.module.css';
import titleStyle from '../../styles/titles.module.css';

//Modelos
import { MyUserModel } from '../../models/user/user_model';

//Redux
import { connect } from 'react-redux';
import {
  updateUser,
  requestPasswordChangeEmail,
  cancelEmailUserVerified
} from '../../actions/auth-actions';

interface Props {
  //inherited
  myUser: MyUserModel,
  viewUser: () => void,
  updateInfoUser: () => void,

  //Redux and Reducers
  updateSuccess: boolean,
  updateUser: (data: any) => void,
  requestPasswordChangeEmail: (id: any) => void,
  messageError: string,
  cancelEmailUserVerified: () => void
}

interface State {
  data: any;
  name: string;
  email: string;
  phone: string;
  address: string;
  role: string;
  saveButtonActive: boolean
  noSpam: boolean

  valueTitle: string

  emailRepeat: boolean

  //Toast
  saveSuccess: boolean;
  saveError: boolean;
  emailSent: boolean;

  //loader
  screenLoad: boolean;

  //Pop Up
  popUpTriggerChangeEmail: boolean
  popUpTriggerCancelChangeEmail: boolean
  popUpTriggerChangePassword: boolean

  //errors
  nameError: boolean;
  emailError: boolean;
}

class AMProfile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {
      data: {
        name: this.props.myUser.name,
        email: this.props.myUser.email,
        phone: this.props.myUser.phone,
        address: this.props.myUser.address,
      },

      saveSuccess: false,
      saveError: false,
      emailSent: false,

      name: this.props.myUser.name,
      email: this.props.myUser.email,
      phone: this.props.myUser.phone,
      address: this.props.myUser.address,
      role: this.props.myUser.role.name,
      nameError: false,
      emailError: false,
      valueTitle: "",
      popUpTriggerChangeEmail: false,
      popUpTriggerCancelChangeEmail: false,
      popUpTriggerChangePassword: false,
      screenLoad: false,
      noSpam: false,
      saveButtonActive: false,
      emailRepeat: false
    };
    this.state = initialState;
  }

  /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
  componentDidMount() {
    this.props.updateInfoUser()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.myUser != prevProps.myUser) {
      this.setState({
        data: {
          name: this.props.myUser.name,
          email: this.props.myUser.email,
          phone: this.props.myUser.phone,
          address: this.props.myUser.address,
        },
        name: this.props.myUser.name,
        email: this.props.myUser.email,
        phone: this.props.myUser.phone,
        address: this.props.myUser.address,
        role: this.props.myUser.role.name,
      })
    }
    if (this.props.updateSuccess == false && prevProps.updateSuccess == true) {
      this.props.viewUser();
    }

    if (this.props.messageError != prevProps.messageError && prevProps.messageError == "" &&
      this.props.messageError == "email repeat") {
      this.setState({ emailRepeat: true, saveSuccess: false })
      document.getElementById('emailFocusOnError')!.focus()
    }
  }






  //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
  onChangeName = (value: any) => {
    this.setState({
      name: value,
      nameError: false,
    });
    setTimeout(() => this.activeDisableSaveButton(), 40)
  };

  onChangeEmail = (value: any) => {
    const emailValue = value.replace(/\s/g, "")
    if (this.props.myUser.email == this.props.myUser.user || !this.state.noSpam || this.props.myUser.email_verify) {
      this.setState({
        email: emailValue,
        emailError: false,
        emailRepeat: false
      });
      setTimeout(() => this.activeDisableSaveButton(), 40)
    }
  };

  onChangePhone = (value: any) => {
    this.setState({
      phone: value,
    });
    setTimeout(() => this.activeDisableSaveButton(), 40)
  };

  onChangeAddress = (value: any) => {
    this.setState({
      address: value,
    });
    setTimeout(() => this.activeDisableSaveButton(), 40)
  };
  changePassword = () => {
    if (!this.state.noSpam || this.props.myUser.email_verify) {
      this.props.requestPasswordChangeEmail(this.props.myUser.id)
      this.setState({ emailSent: true, noSpam: true })
    }
  };








  //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
  activeDisableSaveButton = () => {
    const { name, email, phone, address } = this.state;
    const { myUser } = this.props;
    if (name == myUser.name && email == myUser.email && phone == myUser.phone && address == myUser.address) {
      this.setState({ saveButtonActive: false })
    } else {
      this.setState({ saveButtonActive: true })
    }
  }







  //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////
  onCancel = () => {
    this.setState({
      name: this.props.myUser.name,
      email: this.props.myUser.email,
      phone: this.props.myUser.phone,
      address: this.props.myUser.address,

      nameError: false,
      emailError: false,
      screenLoad: true,
    });
  };
  onSubmit = () => {
    const email_regex = new RegExp(/^[A-Z0-9._%+-]+\@[A-Z0-9.]+$/i);
    const { name, phone, email, address } = this.state;
    const { data } = this.state;
    data.name = name;
    data.email = email;
    data.phone = phone;
    data.address = address;

    let nameError = false,
      emailError = false;
    if (email == "" || !email_regex.test(email)) {
      emailError = true;
      document.getElementById("emailFocusOnError")!.focus();
    }
    if (name == "") {
      nameError = true;
      document.getElementById("nameFocusOnError")!.focus();
    }

    if (nameError || emailError) {
      this.setState({ nameError, emailError, saveError: true });
      const windowScroll = document.getElementById("windowScrollAnimation");
      windowScroll!.scrollTop = 0;
      return;
    }

    if (email != this.props.myUser.email) {
      this.setState({ popUpTriggerChangeEmail: true })
      return
    }

    this.props.updateUser(data);
    this.setState({ saveSuccess: true });
  };

  onSubmitConfirmChangeEmail = () => {
    const { name, phone, email, address } = this.state;
    const { data } = this.state;
    data.name = name;
    data.email = email;
    data.phone = phone;
    data.address = address;
    this.props.updateUser(data);
    this.setState({ saveSuccess: true, popUpTriggerChangeEmail: false });
  }







  /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
  render() {
    const {
      nameError,
      emailError,
      saveSuccess,
      saveError,
      emailRepeat,
      emailSent,
      screenLoad,
      saveButtonActive,
      popUpTriggerChangeEmail,
      popUpTriggerCancelChangeEmail,
      popUpTriggerChangePassword
    } = this.state;
    return (
      <Container className={styles.__MainContent}>
        <Row className={titleStyle.__MainRowTitle}>
          <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
            <label className={[titleStyle.__titlePart1, titleStyle.__noLink].join(' ')}>MY USER</label>
            <label className={titleStyle.__titlePart2}>{'>'}</label>
            <label className={titleStyle.__titlePart3}>
              {this.state.role == "Manager" ? "ACCOUNT MANAGER" : this.state.role == "Finance" ? "FINANCE" : "HUMAN CAPITAL"}
            </label>
            <label className={titleStyle.__titlePart4}>{this.state.valueTitle != "" && '>'}</label>
            <label className={titleStyle.__titlePart5}>{this.state.valueTitle}</label>
          </Col>
        </Row>
        <ReduxLoader />
        <div className={stylesPop.__centerAll}>
          <PopUp passwordTrigger={popUpTriggerChangeEmail} title="Update email process"
            subTitle=""
            text="You are about to start an email process that requires you to access the new email address you wish to register.
            If you widh to proceed, please confirm. Otherwise, you may cancel the process"
            buttonTxt="Continue"
            extraButton={<ButtonForm label="CANCEL" extraClass={styles.__buttonCancel} onClick={() => {
              this.setState({ popUpTriggerChangeEmail: false, email: this.props.myUser.email })
              setTimeout(() => this.activeDisableSaveButton(), 40)
            }} />}
            onClick={() => {
              this.onSubmitConfirmChangeEmail()
            }} />

          <PopUp passwordTrigger={popUpTriggerChangePassword} title="Update password process"
            subTitle=""
            text={"We'll send an email to <strong>" + this.props.myUser.user + "</strong> with a link to update your password"}
            buttonTxt="Continue"
            extraButton={<ButtonForm label="CANCEL" extraClass={styles.__buttonCancel} onClick={() => {
              this.setState({ popUpTriggerChangePassword: false })
            }} />}
            onClick={() => {
              this.setState({ popUpTriggerChangePassword: false })
              this.changePassword();
              this.setState({ valueTitle: "CHANGE PASSWORD" })
              const windowScroll = document.getElementById("windowScrollAnimation");
              windowScroll!.scrollTop = 0;
            }} />


          <PopUp passwordTrigger={popUpTriggerCancelChangeEmail} title="Canceling update process"
            subTitle=""
            text="By clicking 'Continue', you will cancel the email update process that you started earlier. This will not have any 
            further consequences, and you can log in with your credentials as usual"
            buttonTxt="Continue"
            extraButton={<ButtonForm label="CANCEL" extraClass={styles.__buttonCancel} onClick={() => {
              this.setState({ popUpTriggerCancelChangeEmail: false })
            }} />}
            onClick={() => {
              this.setState({ popUpTriggerCancelChangeEmail: false })
              this.props.cancelEmailUserVerified();
            }} />
        </div>
        <Loader
          showLoader={screenLoad}
          timeActive={100}
          disableFunction={() => { this.setState({ screenLoad: false }) }}
        />
        <ToastAlert
          showToast={saveSuccess}
          timeActive={3000}
          disableFunction={() => {
            this.setState({ saveSuccess: false });
          }}
        />
        <ToastAlert
          error
          label="Required data is missing"
          showToast={saveError}
          timeActive={3000}
          disableFunction={() => {
            this.setState({ saveError: false });
          }}
        />
        <ToastAlert
          label="Email has been sent, please check your inbox to reset your password."
          showToast={emailSent}
          timeActive={3000}
          disableFunction={() => {
            this.setState({ emailSent: false });
          }}
        />

        <Row>
          <Col lg="12" md="12" sm="12">
            <h5 className={styles.__subTitle}>GENERAL INFORMATION</h5>
          </Col>
        </Row>
        <Row onClick={() => { this.setState({ valueTitle: "GENERAL INFORMATION" }) }}>
          <Col lg="6" md="6" sm="6">
            <label className={styles.__boldText}>Name</label>
            <TextField
              maxLenght={50}
              value={this.state.name}
              id="nameFocusOnError"
              error={this.state.nameError}
              placeholder="Write your full name"
              onChange={(value: any) => {
                this.onChangeName(value);
              }}
            />
            {nameError && (
              <span
                className={["text-danger", styles.alert].join(" ")}
                id="alert-user">
                This field is required.
              </span>
            )}
          </Col>
          <Col lg="6" md="6" sm="6">
            <label className={styles.__boldText}>Email address</label>
            <TextField
              maxLenght={50}
              value={this.state.email}
              id="emailFocusOnError"
              disabled={this.state.noSpam ||
                !this.props.myUser.email_verify ||
                this.props.myUser.email != this.props.myUser.user}
              error={this.state.emailError || emailRepeat}
              placeholder="Write your email address"
              onChange={(value: any) => {
                this.onChangeEmail(value);
              }}
            />
            {this.props.myUser.email != this.props.myUser.user &&
              <span
                className={[styles.alertEmail].join(" ")}
                id="alert-user">
                Verify your email or <span onClick={() => { this.setState({ popUpTriggerCancelChangeEmail: true }) }} className={[styles.alertEmailLink].join(" ")}>cancel</span> the current update process.
              </span>
            }
            {emailRepeat &&
              <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This email is already registered.</span>
            }
            {emailError && (
              <span
                className={["text-danger", styles.alert].join(" ")}
                id="alert-user">
                This field is required.
              </span>
            )}
          </Col>
        </Row>
        <Row onClick={() => { this.setState({ valueTitle: "GENERAL INFORMATION" }) }}>
          <Col lg="6" md="6" sm="6">
            <label className={styles.__boldText}>Phone number</label>
            <TextField
              maxLenght={50}
              value={this.state.phone}
              id="phoneFocusOnError"
              placeholder="+52 0000-0000"
              onChange={(value: any) => {
                this.onChangePhone(value);
              }}
            />
          </Col>
        </Row>
        <Row onClick={() => { this.setState({ valueTitle: "GENERAL INFORMATION" }) }}>
          <Col lg="12" md="12" sm="12" className="mt-2">
            <label className={styles.__boldText}>Full address</label>
            <TextFieldArea
              maxLenght={100}
              value={this.state.address}
              id="addressFocusOnError"
              placeholder="Write your full address, incluiding Zip Code and Country"
              onChange={(value: any) => {
                this.onChangeAddress(value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" className={styles.textChangePassword}>
            Account Management
          </Col>
          <Col lg="12" md="12" sm="12" className={styles.paddingSection}>
            <ButtonForm
              disabled={
                this.state.noSpam ||
                !this.props.myUser.email_verify ||
                this.props.myUser.email != this.props.myUser.user
              }
              onClick={() => {
                this.setState({ popUpTriggerChangePassword: true })
              }}
              label="Change password"
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className={styles.__buttonsSaveCancel}>
              <ButtonForm
                onClick={() => {
                  this.onCancel();
                }}
                extraClass={styles.__marginButtonsCancel}
                label="Cancel"
              />
              <ButtonForm
                onClick={() => {
                  this.onSubmit();
                }}
                disabled={!saveButtonActive}
                extraClass={styles.__marginButtonsSave}
                colorFill
                label="Save"
              />
            </div>
          </Col>
        </Row>
      </Container >
    );
  }
}

const mapStateToProps = (state: any) => ({
  messageError: state.authReducer.messageError,
  updateSuccess: state.authReducer.updateSuccess
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUser: (data: any) => dispatch(updateUser(data)),
  requestPasswordChangeEmail: (id: any) => dispatch(requestPasswordChangeEmail(id)),
  cancelEmailUserVerified: () => dispatch(cancelEmailUserVerified())
});

export default connect(mapStateToProps, mapDispatchToProps)(AMProfile);
