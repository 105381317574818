// React
import  { Component } from 'react';
import { Provider } from 'react-redux';

// Components
import { store } from './persist/store';

// Routing base
import Main from './screens/main'

//styles
import './styles/index.module.css';

class App extends Component<any> {

  render() {
    return (
      <Provider store={store}>
            <Main />
      </Provider>
    );
  }
}

export default App;
