// Redux
import { Reducer } from 'redux';
import { ReportAction } from '../types/report-types';
import { reportActionTypes } from "../constants/report-constants";

interface ReportState {
    loadingDataReport: boolean,
    messageErrorReport: string,
    dataCards: Array<any>
    dataTable: Array<any>
    loadCardsReport: boolean
    loadTableReport: boolean
}

const initialState: ReportState = {
    loadingDataReport: false,
    messageErrorReport: "",
    dataCards: [],
    dataTable: [],
    loadCardsReport: false,
    loadTableReport: false
};

export const reportReducer: Reducer<ReportState, ReportAction> = (state = initialState, action: ReportAction) => {
    switch (action.type) {
        // ------------------ CARDS -----------------------------
        case reportActionTypes.GET_CARDS_INFO_BEGIN:
            return {
                ...state,
                messageErrorReport: '',
                loadingDataReport: true,
                loadCardsReport: false
            };

        case reportActionTypes.GET_CARDS_INFO_ERROR:
            return {
                ...state,
                loadingDataReport: false,
                messageErrorReport: action.error,
                loadCardsReport: false
            };

        case reportActionTypes.GET_CARDS_INFO_SUCCESS:
            return {
                ...state,
                messageErrorReport: '',
                loadingDataReport: false,
                loadCardsReport: true,
                dataCards: {
                    ...action.data,
                },
            };
        // ------------------ TABLE -----------------------------
        case reportActionTypes.GET_TABLE_INFO_BEGIN:
            return {
                ...state,
                messageErrorReport: '',
                loadingDataReport: true,
                loadTableReport: false,
                dataTable: [],
            };

        case reportActionTypes.GET_TABLE_INFO_ERROR:
            return {
                ...state,
                loadingDataReport: false,
                messageErrorReport: action.error,
                loadTableReport: false,
                dataTable: [],
            };

        case reportActionTypes.GET_TABLE_INFO_SUCCESS:
            return {
                ...state,
                messageErrorReport: '',
                loadingDataReport: false,
                loadTableReport: true,
                dataTable: {
                    ...action.data,
                },
            };
        default:
            return state;
    }
};
