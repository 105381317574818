// Redux
import { Reducer } from 'redux';
import { InvoiceAction } from '../types/invoices-types';
import { invoiceActionTypes } from '../constants/invoice_constants';

// Models
import { NewInvoice, InvoiceInfo } from '../models/invoice/invoice_model';
import { ActivityInvoice } from "../models/invoice/activityInvoice_model";

interface InvoiceState {
    loading: boolean,
    newInvoice: NewInvoice,
    messageError: string,
    paymentsFetch: Array<InvoiceInfo>,
    activityInvoice: Array<ActivityInvoice>
    create_invoice: string
    updateStatusLoad: boolean
    lastInvoiceData: string
    invoiceLinkR: Array<InvoiceInfo>
    revertStatus: number
    updateInvoicesStatus: boolean
}

const initialState: InvoiceState = {
    loading: false,
    newInvoice: {} as NewInvoice,
    messageError: "",
    paymentsFetch: [],
    activityInvoice: [],
    create_invoice: "",
    updateStatusLoad: false,
    lastInvoiceData: "",
    invoiceLinkR: [],
    revertStatus: 0,
    updateInvoicesStatus: false
};

export const invoiceReducer: Reducer<InvoiceState, InvoiceAction> = (state = initialState, action: InvoiceAction) => {
    switch (action.type) {
        // ------------------ FETCH INVOICES -----------------------------
        case invoiceActionTypes.INVOICE_FETCH_BEGIN:
            return {
                ...state,
                messageError: '',
                loading: true,
            };

        case invoiceActionTypes.INVOICE_FETCH_ERROR:
            return {
                ...state,
                loading: false,
                messageError: action.error
            };

        case invoiceActionTypes.INVOICE_FETCH_SUCCESS:
            return {
                ...state,
                messageError: '',
                loading: false,
                paymentsFetch: {
                    ...action.data,
                },
            };

        // ------------------ INVOICE LINK -----------------------------
        case invoiceActionTypes.INVOICE_LINK_BEGIN:
            return {
                ...state,
                messageError: '',
                loading: true,
                invoiceLinkR: []
            };

        case invoiceActionTypes.INVOICE_LINK_ERROR:
            return {
                ...state,
                loading: false,
                messageError: action.error,
                invoiceLinkR: []
            };

        case invoiceActionTypes.INVOICE_LINK_SUCCESS:
            return {
                ...state,
                messageError: '',
                loading: false,
                invoiceLinkR: {
                    ...action.data,
                },
            };

        // ------------------ STATUS UPDATE GROUP -----------------------------

        case invoiceActionTypes.STATUS_PAID_GROUP_FINANCE_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case invoiceActionTypes.STATUS_APPROVED_AM_GROUP_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case invoiceActionTypes.STATUS_APPROVED_FINANCE_GROUP_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case invoiceActionTypes.ARCHIVE_INVOICE_GROUP_BEGIN:
            return {
                ...state,
                loading: true,
            };

        // ------------------ FETCH ACTIVITY INVOICE -----------------------------
        case invoiceActionTypes.ACTIVITY_INVOICE_FETCH_BEGIN:
            return {
                ...state,
                messageError: '',
                loading: true,
            };

        case invoiceActionTypes.ACTIVITY_INVOICE_FETCH_ERROR:
            return {
                ...state,
                loading: false,
                messageError: action.error
            };

        case invoiceActionTypes.ACTIVITY_INVOICE_FETCH_SUCCESS:
            return {
                ...state,
                messageError: '',
                loading: false,
                activityInvoice: {
                    ...action.data,
                },
            };

        // ------------------ LAST INVOICE -----------------------------
        case invoiceActionTypes.LAST_INVOICE_BEGIN:
            return {
                ...state,
                messageError: '',
                loading: true,
                lastInvoiceData: ""
            };

        case invoiceActionTypes.LAST_INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                lastInvoiceData: "",
                messageError: action.error
            };

        case invoiceActionTypes.LAST_INVOICE_SUCCESS:
            return {
                ...state,
                messageError: '',
                loading: false,
                lastInvoiceData: action.data,
            };

        // ------------------ UPDATE Invoice -----------------------------
        case invoiceActionTypes.UPDATE_INVOICE_BEGIN:
            return {
                ...state,
                messageError: '',
                loading: true,
                create_invoice: '',
                updateInvoicesStatus: false
            };

        case invoiceActionTypes.UPDATE_INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                messageError: action.error,
                create_invoice: action.error,
                updateInvoicesStatus: true
            };

        case invoiceActionTypes.UPDATE_INVOICE_SUCCESS:
            return {
                ...state,
                messageError: '',
                loading: false,
                create_invoice: 'invoice updateded successfully'
            };
        // ------------------ Archive Invoice -----------------------------
        case invoiceActionTypes.ARCHIVE_INVOICE_BEGIN:
            return {
                ...state,
                messageError: '',
                loading: true,
                create_invoice: '',
                updateStatusLoad: true
            };

        case invoiceActionTypes.ARCHIVE_INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                messageError: action.error,
                create_invoice: 'Error',
                updateStatusLoad: false
            };

        case invoiceActionTypes.ARCHIVE_INVOICE_SUCCESS:
            return {
                ...state,
                messageError: '',
                loading: false,
                create_invoice: 'invoice archived successfully',
                updateStatusLoad: false
            };

        // ------------------Unarchive Invoice -----------------------------
        case invoiceActionTypes.UNARCHIVE_INVOICE_BEGIN:
            return {
                ...state,
                messageError: '',
                loading: true,
                create_invoice: '',
                updateStatusLoad: true
            };

        case invoiceActionTypes.UNARCHIVE_INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                messageError: action.error,
                create_invoice: 'Error',
                updateStatusLoad: false
            };

        case invoiceActionTypes.UNARCHIVE_INVOICE_SUCCESS:
            return {
                ...state,
                messageError: '',
                loading: false,
                create_invoice: 'invoice unarchived successfully',
                updateStatusLoad: false
            };
        // ------------------ NEW Invoice -----------------------------
        case invoiceActionTypes.NEW_INVOICE_BEGIN:
            return {
                ...state,
                messageError: '',
                loading: true,
                create_invoice: ''
            };

        case invoiceActionTypes.NEW_INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                messageError: action.error,
                create_invoice: action.error
            };

        case invoiceActionTypes.NEW_INVOICE_SUCCESS:
            return {
                ...state,
                messageError: '',
                loading: false,
                myInvoice: {
                    ...action.data,
                },
                create_invoice: 'invoice created successfully||' + action.data.invoice.id
            };
        // ------------------ APROVED AM -----------------------------
        case invoiceActionTypes.STATUS_APPROVED_AM_BEGIN:
            return {
                ...state,
                updateStatusLoad: true,
                updateInvoicesStatus: false
            };

        case invoiceActionTypes.STATUS_APPROVED_AM_ERROR:
            return {
                ...state,
                updateStatusLoad: false,
                updateInvoicesStatus: true
            };

        case invoiceActionTypes.STATUS_APPROVED_AM_SUCCESS:
            return {
                ...state,
                updateStatusLoad: false
            };
        // ------------------ APROVED FINANCE -----------------------------
        case invoiceActionTypes.STATUS_APPROVED_FINANCE_BEGIN:
            return {
                ...state,
                updateStatusLoad: true,
                updateInvoicesStatus: false
            };

        case invoiceActionTypes.STATUS_APPROVED_FINANCE_ERROR:
            return {
                ...state,
                updateStatusLoad: false,
                updateInvoicesStatus: true
            };

        case invoiceActionTypes.STATUS_APPROVED_FINANCE_SUCCESS:
            return {
                ...state,
                updateStatusLoad: false,
            };
        // ------------------ PAID -----------------------------
        case invoiceActionTypes.STATUS_PAID_FINANCE_BEGIN:
            return {
                ...state,
                updateStatusLoad: true,
                updateInvoicesStatus: false
            };

        case invoiceActionTypes.STATUS_PAID_FINANCE_ERROR:
            return {
                ...state,
                updateStatusLoad: false,
                updateInvoicesStatus: true
            };

        case invoiceActionTypes.STATUS_PAID_FINANCE_SUCCESS:
            return {
                ...state,
                updateStatusLoad: false
            };
        // ------------------ REJECTED AM -----------------------------
        case invoiceActionTypes.STATUS_REJECTED_AM_BEGIN:
            return {
                ...state,
                updateStatusLoad: true,
                updateInvoicesStatus: false
            };

        case invoiceActionTypes.STATUS_REJECTED_AM_ERROR:
            return {
                ...state,
                updateStatusLoad: false,
                updateInvoicesStatus: true
            };

        case invoiceActionTypes.STATUS_REJECTED_AM_SUCCESS:
            return {
                ...state,
                updateStatusLoad: false
            };
        // ------------------ REJECTED FINANCE -----------------------------
        case invoiceActionTypes.STATUS_REJECTED_FINANCE_BEGIN:
            return {
                ...state,
                updateStatusLoad: true,
                updateInvoicesStatus: false
            };

        case invoiceActionTypes.STATUS_REJECTED_FINANCE_ERROR:
            return {
                ...state,
                updateStatusLoad: false,
                updateInvoicesStatus: true
            };

        case invoiceActionTypes.STATUS_REJECTED_FINANCE_SUCCESS:
            return {
                ...state,
                updateStatusLoad: false
            };

        // ------------------ revert invoice -----------------------------
        case invoiceActionTypes.REVERT_INVOICE_BEGIN:
            return {
                ...state,
                revertStatus: 0
            };

        case invoiceActionTypes.REVERT_INVOICE_ERROR:
            return {
                ...state,
                revertStatus: 1
            };

        case invoiceActionTypes.REVERT_INVOICE_SUCCESS:
            return {
                ...state,
                revertStatus: 2
            };
        default:
            return state;

    }
};
