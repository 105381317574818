// Axios
import axios from 'axios';

const base_api_url = process.env.REACT_APP_BASE_URL;

const activityLogFetch = (page: number) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}activityLog/` + page, headers);
}

const activityLogCount = () => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}activityLog/countRecord`, headers);
}

export const API = {
    activityLogFetch: activityLogFetch,
    activityLogCount: activityLogCount
}