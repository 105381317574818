import React, { Component } from 'react';

//Styles
import style from '../../styles/basicComponents/TextField.module.css';

interface Props {
  id?: string
  value: string
  type?: string
  error?: boolean
  extraClass?: any
  placeholder?: string
  onBlur?: () => void
  onChange: (value: any) => void;
  disabled?: boolean
  onKeyDown?: any
  maxValue?: any
  minValue?: any
  onlyNumbers?: boolean
  maxLenght: number
}

interface State { }

export default class TextField extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {};
  }

  handleKeyPress = (event: any) => {
    if (this.props.onlyNumbers) {
      const keyCode = event.keyCode || event.which;
      const tecla = String.fromCharCode(keyCode);
      if ((tecla !== "." && !/^\d$/.test(tecla)) || (tecla === "." && event.target.value.includes("."))) {
        event.preventDefault();
      }
    }
    if (this.props.type == "date") {
      event.preventDefault();
    }
  }

  render() {
    return (
      <>
        <input className={[style.__baseStructure, this.props.disabled ? style.__disabled : style.__baseStructureCorrect, this.props.error ? style.__baseStructureIncorrect : this.props.disabled ? style.__disabledInput : style.__baseStructureCorrect].join(' ')}
          placeholder={this.props.placeholder}
          type={this.props.type}
          id={this.props.id}
          readOnly={this.props.disabled}
          value={this.props.value}

          max={this.props.maxValue}
          min={this.props.minValue}
          onChange={(value: any) => {
            this.props.onChange(value.target.value.substring(0, this.props.maxLenght))
          }}
          onBlur={() => { this.props.onBlur != undefined && this.props.onBlur() }}
          onKeyDown={this.props.onKeyDown}
          onKeyPress={this.handleKeyPress}
        />
      </>
    );
  }
}
