import React, { Component } from 'react';

//React icons
import { RxReload } from 'react-icons/rx'

//components internal
import TableInfo from '../../components/advancedComponents/tableInfo';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import Switch from '../../components/basicComponents/Switch';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';

//components external
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Tooltip } from 'react-tooltip';
import Col from 'react-bootstrap/Col';

//styles
import styles from '../../styles/screens/invoices.module.css';
import titleStyle from '../../styles/titles.module.css';

//Modelos
import { InvoiceInfo } from '../../models/invoice/invoice_model';
import { MyUserModel } from '../../models/user/user_model';

//Redux
import { connect } from 'react-redux';
import { invoiceFetch, invoiceLink } from '../../actions/invoices-actions';

//Screens
import EditViewInvoiceDetails from './editViewInvoiceDetails';

interface Props {
    //inherited
    myUser: MyUserModel,

    //Redux and Reducers
    invoiceFetch: (activePaid: boolean, activeArchive: boolean) => void,
    invoices: Array<InvoiceInfo>
    invoiceLink: (id: string) => void,
    invoiceLinkR: Array<InvoiceInfo>,
}

interface State {
    invoicesTable: Array<any>
    rejected: number
    submitted: number
    approved: number
    all: number
    statusActive: number
    activeInvoicePaid: boolean
    activeInvoiceArchive: boolean

    showInvoiceDetails: boolean
    invoiceUrlId: string,
    idSelected: string,
    folioSelected: string,
    statusSelected: string,
    contractorSelected: string,
    monthly_feeSelected: string,
    hourly_rateSelected: string,
    AMSelected: string,
    payment_methodSelected: string,
    payment_detailsSelected: string,
    proyectSelected: any,
    billable_hoursSelected: any,
    totalSelected: any,
    periodStartSelected: any,
    periodEndSelected: any,
    periodSelected: any,
    noteSelected: string
    createdSelected: any,
    invoiceDateSelected: any
}


class MyInvoicesContractor extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            invoicesTable: [],
            showInvoiceDetails: false,
            idSelected: "",
            folioSelected: "",
            statusSelected: "",
            contractorSelected: "",
            monthly_feeSelected: "",
            hourly_rateSelected: "",
            AMSelected: "",
            payment_methodSelected: "",
            payment_detailsSelected: "",
            proyectSelected: "",
            billable_hoursSelected: "",
            totalSelected: "",
            periodStartSelected: "",
            periodEndSelected: "",
            periodSelected: "",
            noteSelected: "",
            createdSelected: "",
            rejected: 0,
            submitted: 0,
            approved: 0,
            all: 0,
            statusActive: 0,
            invoiceDateSelected: '',
            activeInvoicePaid: false,
            activeInvoiceArchive: false,
            invoiceUrlId: window.location.search,
        };
        this.state = initialState;
    }
    /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
    componentDidMount() {
        const { invoiceFetch, invoiceLink } = this.props;
        invoiceFetch(false, false);
        let linkInvoiceSelected = false, idSelected = ""
        const { invoiceUrlId } = this.state;
        if (invoiceUrlId != undefined && invoiceUrlId != null && invoiceUrlId != '') {
            const invoiceUrlIdValueArray = invoiceUrlId.split('=')
            if (invoiceUrlIdValueArray[0].substring(1) == "invoice") {
                linkInvoiceSelected = true
                idSelected = invoiceUrlIdValueArray[1]
                invoiceLink(idSelected)
            }
        }
    }
    componentDidUpdate(prevProps: Props) {
        if (this.props.invoices != prevProps.invoices) {
            this.onClickFilter(this.state.statusActive)
        }

        //loadInvoiceLink
        if (this.props.invoiceLinkR != null && this.props.invoiceLinkR != undefined &&
            prevProps.invoiceLinkR != null && prevProps.invoiceLinkR != undefined) {
            if (this.props.invoiceLinkR != prevProps.invoiceLinkR &&
                prevProps.invoiceLinkR.length == 0 && this.props.invoiceLinkR.length != 0) {
                if (Array.isArray(Object.values(this.props.invoiceLinkR)) && !this.state.showInvoiceDetails) {
                    const valuesTable = Object.values(this.props.invoiceLinkR);
                    valuesTable.map((invoice: InvoiceInfo) => {
                        this.setState({
                            showInvoiceDetails: true,
                            idSelected: invoice.id,
                            folioSelected: invoice.folio,
                            statusSelected: this.renderStatusName(invoice.status)!,
                            contractorSelected: invoice.contractor,
                            monthly_feeSelected: this.currencyFormatter("USD", invoice.monthlyFee),
                            hourly_rateSelected: this.currencyFormatter("USD", invoice.hourly_rate),
                            AMSelected: invoice.AM,
                            payment_methodSelected: invoice.payment,
                            payment_detailsSelected: invoice.payment_details,
                            proyectSelected: invoice.proyect,
                            billable_hoursSelected: invoice.billable_hours,
                            totalSelected: this.currencyFormatter("USD", invoice.total),
                            periodStartSelected: invoice.periodStart,
                            periodEndSelected: invoice.periodEnd,
                            periodSelected: this.periodFormat(invoice.periodStart, invoice.periodEnd),
                            noteSelected: invoice.note,
                            createdSelected: invoice.invoice_date,
                            invoiceDateSelected: invoice.invoice_date
                        })
                    })
                }

            }
        }
    }







    //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////
    selectInvoice = (selectedInvoice: InvoiceInfo) => {
        this.setState({
            showInvoiceDetails: true,
            idSelected: selectedInvoice.id,
            folioSelected: selectedInvoice.folio,
            statusSelected: selectedInvoice.status,
            contractorSelected: selectedInvoice.contractor,
            monthly_feeSelected: selectedInvoice.monthy_fee,
            hourly_rateSelected: selectedInvoice.hourly_rate,
            AMSelected: selectedInvoice.AM,
            payment_methodSelected: selectedInvoice.payment,
            payment_detailsSelected: selectedInvoice.payment_details,
            proyectSelected: selectedInvoice.proyect,
            billable_hoursSelected: selectedInvoice.billable_hours,
            totalSelected: selectedInvoice.total,
            periodStartSelected: selectedInvoice.periodStart,
            periodEndSelected: selectedInvoice.periodEnd,
            periodSelected: selectedInvoice.period,
            noteSelected: selectedInvoice.note,
            createdSelected: selectedInvoice.created,
            invoiceDateSelected: selectedInvoice.invoice_date
        })
    }
    onClickFilter = (statusFilter: any) => {
        let linkInvoiceSelected = false, idSelected = ""
        const { invoiceUrlId } = this.state;
        this.setState({ statusActive: statusFilter })
        const valuesTable = Object.values(this.props.invoices);
        if (Array.isArray(valuesTable)) {
            if (invoiceUrlId != undefined && invoiceUrlId != null && invoiceUrlId != '') {
                const invoiceUrlIdValueArray = invoiceUrlId.split('=')
                if (invoiceUrlIdValueArray[0].substring(1) == "invoice") {
                    linkInvoiceSelected = true
                    idSelected = invoiceUrlIdValueArray[1]
                }
            }
            let invoicesValues: any[] = []
            let submitted = 0, approved = 0, rejected = 0, paid = 0, archived = 0;
            valuesTable.map((invoice: InvoiceInfo) => {
                let acceptFilter = false;
                if (invoice.status == "6") {
                    archived++;
                }
                if (invoice.status == "0") {
                    submitted++;
                }
                if (invoice.status == "3") {
                    paid++;
                }
                if (invoice.status == "1" || invoice.status == "2") {
                    approved++;
                }
                if (invoice.status == "4" || invoice.status == "5") {
                    rejected++;
                }
                if (invoice.status == "0" && (statusFilter == 0 || statusFilter == 1)) {
                    acceptFilter = true;
                }
                if (invoice.status == "3" && statusFilter == 0) {
                    acceptFilter = true
                }
                if ((invoice.status == "1" || invoice.status == "2") && (statusFilter == 0 || statusFilter == 2)) {
                    acceptFilter = true;
                }
                if ((invoice.status == "4" || invoice.status == "5") && (statusFilter == 0 || statusFilter == 3)) {
                    acceptFilter = true;
                }
                if (invoice.status == "6" && statusFilter == 0) {
                    acceptFilter = true
                }
                if (acceptFilter) {
                    invoicesValues.push({
                        id: invoice.id,
                        folio: invoice.folio,
                        status: this.renderStatusName(invoice.status),
                        contractor: invoice.contractor,
                        monthy_fee: this.currencyFormatter("USD", invoice.amount),
                        hourly_rate: this.currencyFormatter("USD", invoice.hourly_rate),
                        AM: invoice.AM,
                        payment: invoice.payment,
                        payment_details: invoice.payment_details,
                        proyect: invoice.proyect,
                        billable_hours: invoice.billable_hours,
                        total: this.currencyFormatter("USD", invoice.total),
                        periodStart: invoice.periodStart,
                        periodEnd: invoice.periodEnd,
                        period: this.periodFormat(invoice.periodStart, invoice.periodEnd),
                        note: invoice.note,
                        created: invoice.created,
                        amount: this.currencyFormatter("USD", invoice.amount),
                        invoice_date: invoice.invoice_date
                    })
                }
                if (linkInvoiceSelected) {
                    if (idSelected == invoice.id) {
                        this.setState({
                            showInvoiceDetails: true,
                            idSelected: invoice.id,
                            folioSelected: invoice.folio,
                            statusSelected: this.renderStatusName(invoice.status)!,
                            contractorSelected: invoice.contractor,
                            monthly_feeSelected: this.currencyFormatter("USD", invoice.amount),
                            hourly_rateSelected: this.currencyFormatter("USD", invoice.hourly_rate),
                            AMSelected: invoice.AM,
                            payment_methodSelected: invoice.payment,
                            payment_detailsSelected: invoice.payment_details,
                            proyectSelected: invoice.proyect,
                            billable_hoursSelected: invoice.billable_hours,
                            totalSelected: this.currencyFormatter("USD", invoice.total),
                            periodStartSelected: invoice.periodStart,
                            periodEndSelected: invoice.periodEnd,
                            periodSelected: this.periodFormat(invoice.periodStart, invoice.periodEnd),
                            noteSelected: invoice.note,
                            createdSelected: invoice.created,
                            invoiceDateSelected: invoice.invoice_date
                        })
                    }
                }
            })
            this.setState({ invoicesTable: invoicesValues, submitted: submitted, rejected: rejected, approved: approved, all: (submitted + rejected + approved + paid + archived) })
        }
    }







    ///////////////////FORMATS FUNCTIONS//////////////////////////////////////
    renderStatusName = (valueStatus: any) => {
        if (valueStatus == 0) {
            return "Submitted"
        }
        if (valueStatus == 1) {
            return "Approved - AM"
        }
        if (valueStatus == 2) {
            return "Approved - FIN"
        }
        if (valueStatus == 3) {
            return "Paid"
        }
        if (valueStatus == 4) {
            return "Rejected - AM"
        }
        if (valueStatus == 5) {
            return "Rejected - FIN"
        }
        if (valueStatus == 6) {
            return "Archived"
        }
    }
    currencyFormatter = (currency: any, value: any) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            minimumFractionDigits: 2,
            currency
        })
        return formatter.format(value)
    }

    periodFormat = (startDate: any, endDate: any) => {
        const startFormat = startDate.substring(5, 7) + "/" + startDate.substring(8, 10) + "/" + startDate.substring(0, 4);
        const endFormat = endDate.substring(5, 7) + "/" + endDate.substring(8, 10) + "/" + endDate.substring(0, 4);
        return startFormat + " - " + endFormat
    }

    dateFormat = (date: any) => {
        const dateValue = date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4);
        return dateValue
    }






    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const { idSelected, folioSelected, noteSelected, totalSelected, periodSelected, createdSelected,
            proyectSelected, statusSelected, contractorSelected, hourly_rateSelected,
            monthly_feeSelected, payment_detailsSelected, payment_methodSelected, billable_hoursSelected, AMSelected,
            activeInvoiceArchive, activeInvoicePaid, invoicesTable, periodEndSelected, periodStartSelected,
            invoiceDateSelected, statusActive, all, approved, rejected, submitted
        } = this.state
        const { myUser } = this.props
        const columns = [
            {
                name: 'DATE',
                center: true,
                cell: (row: InvoiceInfo) => (
                    <div className={styles.__divCellTable}>
                        <label className={styles.spanValueTable}>{this.dateFormat(row.invoice_date)}</label>
                    </div>
                ),
                selector: (row: any, i: any) => row.invoice_date,
                sortable: true,

            },
            {
                name: 'INVOICE NO.',
                center: true,
                cell: (row: InvoiceInfo) => (
                    <div onClick={() => {
                        this.selectInvoice({
                            id: row.id,
                            status: row.status,
                            contractor: row.contractor,
                            folio: row.folio,
                            AM: row.AM,
                            amount: row.amount,
                            periodStart: row.periodStart,
                            periodEnd: row.periodEnd,
                            period: row.period,
                            payment: row.payment,
                            payment_details: row.payment_details,
                            created: row.created,
                            billable_hours: row.billable_hours,
                            note: row.note,
                            hourly_rate: row.hourly_rate,
                            monthy_fee: row.monthy_fee,
                            proyect: row.proyect,
                            total: row.total,
                            invoice_date: row.invoice_date
                        })

                    }} className={styles.__divCellTable}>
                        <label className={styles.spanValueTableFolio}>{row.folio}</label>
                    </div>
                ),
                selector: (row: any, i: any) => row.folio,
                sortable: true,
            },
            {
                name: 'BILLABLE HOURS',
                center: true,
                cell: (row: InvoiceInfo) => (
                    <div className={styles.__divCellTable}>
                        <label className={styles.spanValueTable}>{row.billable_hours}</label>
                    </div>
                ),
                selector: (row: any, i: any) => row.billable_hours,
                sortable: true,
            },
            {
                name: 'AMOUNT',
                center: true,
                cell: (row: InvoiceInfo) => (
                    <div className={styles.__divCellTable}>
                        <label className={styles.spanValueTable}>
                            {row.amount}
                        </label>
                    </div>
                ),
                selector: (row: any, i: any) => row.amount,
                sortable: true,
            },
            {
                name: 'PERIOD',
                center: true,
                cell: (row: InvoiceInfo) => (
                    <div className={styles.__divCellTable}>
                        <label className={styles.spanValueTable}>
                            {this.periodFormat(row.periodStart, row.periodEnd)}
                        </label>
                    </div>
                ),
                selector: (row: any, i: any) => row.periodStart,
                sortable: true,
            },
            {
                name: 'CREATED',
                center: true,
                cell: (row: InvoiceInfo) => (
                    <div className={styles.__divCellTable}>
                        <label className={styles.spanValueTable}>{this.dateFormat(row.created)}</label>
                    </div>
                ),
                selector: (row: any, i: any) => row.created,
                sortable: true,

            },
            {
                name: 'STATUS',
                center: true,
                cell: (row: InvoiceInfo) => (
                    <div className={styles.__divCellTable}>
                        <label className={[styles.spanValueTable, styles.spanValueTableStatus,
                        row.status == "Submitted" ? styles.__spanSubmitted :
                            row.status == "Approved - AM" ? styles.__spanApprovedAM :
                                row.status == "Approved - FIN" ? styles.__spanApprovedFinance :
                                    row.status == "Paid" ? styles.__spanPaid :
                                        row.status == "Rejected - AM" ? styles.__spanRejectedAM :
                                            row.status == "Rejected - FIN" ? styles.__spanRejectedFinance :
                                                row.status == "Archived" ? styles.__spanArchived : ""
                        ].join(' ')}>
                            {row.status}
                        </label>
                    </div>
                ),
                selector: (row: any, i: any) => row.status,
                sortable: true,
            }
        ];
        return (
            this.state.showInvoiceDetails ?
                <EditViewInvoiceDetails
                    back={() => { this.setState({ showInvoiceDetails: false }) }}
                    myUser={myUser}
                    id={idSelected}
                    status={statusSelected}
                    contractor={contractorSelected}
                    hourly_rate={hourly_rateSelected}
                    monthly_fee={monthly_feeSelected}
                    payment_method={payment_methodSelected}
                    payment_details={payment_detailsSelected}
                    folio={folioSelected}
                    AM={AMSelected}
                    period={periodSelected}
                    periodStart={periodStartSelected}
                    periodEnd={periodEndSelected}
                    proyect={proyectSelected}
                    billable_hours={billable_hoursSelected}
                    total={totalSelected}
                    created={createdSelected}
                    note={noteSelected}
                    invoice_date={invoiceDateSelected}
                /> :
                <Container className={styles.__MainContent}>
                    <Row className={titleStyle.__MainRowTitle}>
                        <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
                            <label className={titleStyle.__titlePart1}></label>
                            <label className={titleStyle.__titlePart2}></label>
                            <label className={titleStyle.__titlePart3}>INVOICES</label>
                            <label className={titleStyle.__titlePart4}></label>
                            <label className={titleStyle.__titlePart5}></label>
                        </Col>
                    </Row>
                    <ReduxLoader />
                    <Row className="">
                        <Col lg="12" md="12" sm="12"><h4 className={styles.__MainTitle}></h4></Col>
                        {this.props.myUser.payment_method.details != '' ?
                            <Col lg="12" md="12" sm="12" className={styles.__buttonHeadTable}>
                                <ButtonForm
                                    onClick={() => {
                                        window.location.href = "/newInvoice"
                                    }}
                                    extraClass={styles.__buttonNew}
                                    colorFill
                                    label="New Invoice"
                                />
                                <Tooltip id={"Reload"} />
                                <ButtonForm id={"Reload"}
                                    toltipText={"Reload"}
                                    extraClass={styles.__buttonReloadContractor} colorFill
                                    onClick={async () => {
                                        this.props.invoiceFetch(activeInvoicePaid, activeInvoiceArchive)
                                    }}
                                    icon={<RxReload />}
                                />
                                <Col lg="12" md="12" sm="12" className={styles.__SwitchHeadTableButtons}>
                                    <div>
                                        <span className={styles.__filterSpan}>Archived</span> <Switch
                                            onChange={() => {
                                                this.props.invoiceFetch(activeInvoicePaid, !activeInvoiceArchive);
                                                this.setState({ activeInvoiceArchive: !activeInvoiceArchive })
                                            }}
                                            checked={activeInvoiceArchive} />
                                    </div>
                                    <div>
                                        <span className={styles.__filterSpan}>Paid</span> <Switch
                                            onChange={() => {
                                                this.props.invoiceFetch(!activeInvoicePaid, activeInvoiceArchive);
                                                this.setState({ activeInvoicePaid: !activeInvoicePaid })
                                            }}
                                            checked={activeInvoicePaid} />
                                    </div>
                                </Col>
                            </Col>
                            : <>
                                <Col lg="12" md="12" sm="12" className={styles.__buttonHeadTablenoInvoice}>
                                    <span>You must choose a payment method before creating a new invoice{"\n"} </span>
                                </Col>
                                <Col lg="12" md="12" sm="12" className={styles.__buttonHeadTable}>
                                    <span
                                        onClick={() => {
                                            window.location.href = "/myProfile"
                                        }}
                                        className={styles.__rediectMyProfileButton}>Add payment method</span>
                                </Col>
                            </>}
                        <Col lg="12" md="12" sm="12" className={styles.__statusHeadTable}>
                            <span
                                onClick={() => { this.onClickFilter(0) }}
                                className={[styles.__countStatusLabel, statusActive == 0 ? styles.__activeCountStatusLabel : ''].join(' ')}
                            >All({all})</span>

                            <span
                                onClick={() => { this.onClickFilter(1) }}
                                className={[styles.__countStatusLabel, statusActive == 1 ? styles.__activeCountStatusLabel : ''].join(' ')}
                            >Submitted({submitted})</span>

                            <span
                                onClick={() => { this.onClickFilter(2) }}
                                className={[styles.__countStatusLabel, statusActive == 2 ? styles.__activeCountStatusLabel : ''].join(' ')}
                            >Approved({approved})</span>

                            <span
                                onClick={() => { this.onClickFilter(3) }}
                                className={[styles.__countStatusLabel, statusActive == 3 ? styles.__activeCountStatusLabel : ''].join(' ')}
                            >Rejected({rejected})</span>
                        </Col>
                        <TableInfo paginationPerPage={12} columns={columns} data={invoicesTable} />
                    </Row>
                </Container>
        );
    }
}

const mapStateToProps = (state: any) => ({
    invoices: state.invoiceReducer.paymentsFetch,
    invoiceLinkR: state.invoiceReducer.invoiceLinkR,
});

const mapDispatchToProps = (dispatch: any) => ({
    invoiceFetch: (activePaid: boolean, activeArchive: boolean) => dispatch(invoiceFetch(activePaid, activeArchive)),
    invoiceLink: (id: string) => dispatch(invoiceLink(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyInvoicesContractor);