import { Component } from 'react';
// Redux
import { connect } from "react-redux";
import { userAuth, logout } from '../actions/auth-actions';

// Routing
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

//Screens AUTH
import MyProfile from './myProfile/AMProfile'
import ContactorProfile from './myProfile/ContractorProfile'
import Login from './login/login';
//Screens Users
import NewUser from './users/NewUser';
import UserView from './users/userView';
import UserViewFinance from './users/userViewFinance';

//Screens Reports
import ViewReport from './report/reports';

//Screens Invoices
import InvoicesTableAM from './invoices/invoicesTableAM';
import InvoicesTableHC from './invoices/invoicesTableHC';
import NewInvoice from './invoices/NewInvoice';
import UpdatePassword from './confirmEmails/UpdatePassword';
import EmailVerified from './confirmEmails/emailVerified';
import ActivityLog from './activity/activityLog';
import MyInvoicesContractor from './invoices/myInvoicesContractor'
import InvoicesTableFinance from './invoices/invoicesTableFinance'

//models
import { MyUserModel } from '../models/user/user_model';

//Components
import SideBar from '../components/mainComponents/sideBar';

//styles 
import style from '../styles/screens/home.module.css';
import ContractorDetailsInvoice from './invoices/contractorDetailsInvoice';





interface Props {
  myUser: MyUserModel
  userAuth: () => void
  logout: () => void
}

interface State {
  sidebarStatus: boolean
  textIconsSideBar: boolean
  login: boolean
}


class Main extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {
      sidebarStatus: true,
      textIconsSideBar: true,
      login: true
    }
    this.state = initialState;
  }

  componentDidMount() {
    if (localStorage.getItem('tokenInvoicing') != undefined &&
      localStorage.getItem('tokenInvoicing') != null &&
      localStorage.getItem('tokenInvoicing') != "") {
      this.props.userAuth();
    } else if (window.location.pathname != "/"
      && window.location.pathname != "/updatePassword"
      && window.location.pathname != "/emailVerified") {
      this.props.logout()
    }
  }

  updateMyUserAuth = () => {
    this.props.userAuth();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.myUser != prevProps.myUser) {

    }
  }

  validateUser = () => {
    this.props.userAuth();
  }

  hideShowSideBar = (value: boolean) => {

    if (value) {
      this.setState({ sidebarStatus: value })
      setTimeout(() => this.setState({ textIconsSideBar: value }), 200)
    } else {
      this.setState({ sidebarStatus: value, textIconsSideBar: value })
    }
  }

  renderMainView = (view: any) => {
    return (
      <>
        <SideBar myUser={this.props.myUser} role={this.props.myUser.role.name} logout={() => { this.props.logout() }} textIcons={this.state.textIconsSideBar} completeScreen={(value: boolean) => this.hideShowSideBar(value)} sidebarStatus={this.state.sidebarStatus} />
        <div id='windowScrollAnimation' className={[style.__animationMain, this.state.sidebarStatus ? style.__mainView : style.__mainViewComplete].join(' ')}>{view}</div>
      </>
    )
  }

  routesManager = () => {
    const actualLink = window.location.pathname
    let redirect = false;
    if (actualLink != "/activityLog" && actualLink != "/invoices" && actualLink != "/Invoice" && actualLink != "/myProfile"
      && actualLink != "/user" && actualLink != "/newUser" && actualLink != "/userView" && actualLink != "/updatePassword"
      && actualLink != "/emailVerified" && actualLink != "/reports"
    ) {
      redirect = true
    }
    return (
      <>
        <Route path="/emailVerified" element={<EmailVerified />} />
        <Route path="/invoices" element={this.renderMainView(<InvoicesTableAM myUser={this.props.myUser} />)} />
        <Route path="/newUser" element={this.renderMainView(<NewUser />)} />
        <Route path="/myProfile" element={this.renderMainView(<MyProfile updateInfoUser={() => { this.updateMyUserAuth() }} myUser={this.props.myUser} viewUser={() => { this.validateUser() }} />)} />
        <Route path="/userView" element={this.renderMainView(<UserView myUser={this.props.myUser} />)} />
        <Route path="/activityLog" element={this.renderMainView(<ActivityLog user={this.props.myUser} />)} />
        <Route path="/reports" element={this.renderMainView(<ViewReport />)} />
        <Route path="/updatePassword" element={<UpdatePassword />} />
        <Route path="*" element={<>{redirect && <>{this.redirect("/invoices")}</>}</>} />
      </>)
  }

  routesContactor = () => {
    const actualLink = window.location.pathname
    let redirect = false, redirecTo = "/invoices";
    if (actualLink != "/invoices" && actualLink != "/newInvoice" && actualLink != "/contractorDetailsInvoices"
      && actualLink != "/myProfile" && actualLink != "/updatePassword" && actualLink != "/emailVerified") {
      redirect = true
    }
    if (this.props.myUser.payment_method.name == "" && actualLink == "/newInvoice") {
      redirect = true
      redirecTo = "/myProfile";
    }
    return (
      <>
        <Route path="/emailVerified" element={<EmailVerified />} />
        <Route path="/updatePassword" element={<UpdatePassword />} />
        <Route path="/myProfile" element={this.renderMainView(<ContactorProfile updateInfoUser={() => { this.updateMyUserAuth() }} myUser={this.props.myUser} />)} />
        <Route path="/newInvoice" element={this.renderMainView(<NewInvoice myUser={this.props.myUser} />)} />
        <Route path="/invoices" element={this.renderMainView(<MyInvoicesContractor myUser={this.props.myUser} />)} />
        <Route path="/contractorDetailsInvoices" element={this.renderMainView(<ContractorDetailsInvoice />)} />

        <Route path="*" element={<>{redirect && <>{this.redirect(redirecTo)}</>}</>} />
      </>
    )
  }

  routesFinance = () => {
    const actualLink = window.location.pathname
    let redirect = false;
    if (actualLink != "/activityLog" && actualLink != "/invoices" && actualLink != "/Invoice" && actualLink != "/myProfile"
      && actualLink != "/user" && actualLink != "/newUser" && actualLink != "/userView" && actualLink != "/updatePassword"
      && actualLink != "/emailVerified"
    ) {
      redirect = true
    }
    return (
      <>
        <Route path="/emailVerified" element={<EmailVerified />} />
        <Route path="/myProfile" element={this.renderMainView(<MyProfile updateInfoUser={() => { this.updateMyUserAuth() }} myUser={this.props.myUser} viewUser={() => { this.validateUser() }} />)} />
        <Route path="/invoices" element={this.renderMainView(<InvoicesTableFinance myUser={this.props.myUser} />)} />
        <Route path="/activityLog" element={this.renderMainView(<ActivityLog user={this.props.myUser} />)} />
        <Route path="/userView" element={this.renderMainView(<UserViewFinance myUser={this.props.myUser} />)} />
        <Route path="/updatePassword" element={<UpdatePassword />} />
        <Route path="*" element={<>{redirect && <>{this.redirect("/invoices")}</>}</>} />
      </>
    )
  }

  routesHC = () => {
    const actualLink = window.location.pathname
    let redirect = false;
    if (actualLink != "/activityLog" && actualLink != "/invoices" && actualLink != "/Invoice" && actualLink != "/myProfile"
      && actualLink != "/user" && actualLink != "/newUser" && actualLink != "/userView" && actualLink != "/updatePassword"
      && actualLink != "/emailVerified"
    ) {
      redirect = true
    }
    return (
      <>
        <Route path="/emailVerified" element={<EmailVerified />} />
        <Route path="/newUser" element={this.renderMainView(<NewUser />)} />
        <Route path="/myProfile" element={this.renderMainView(<MyProfile updateInfoUser={() => { this.updateMyUserAuth() }} myUser={this.props.myUser} viewUser={() => { this.validateUser() }} />)} />
        <Route path="/userView" element={this.renderMainView(<UserView myUser={this.props.myUser} />)} />
        <Route path="/invoices" element={this.renderMainView(<InvoicesTableHC myUser={this.props.myUser} />)} />
        <Route path="/activityLog" element={this.renderMainView(<ActivityLog user={this.props.myUser} />)} />
        <Route path="/updatePassword" element={<UpdatePassword />} />
        <Route path="*" element={<>{redirect && <>{this.redirect("/userView")}</>}</>} />
      </>
    )
  }

  notUser = () => {
    const actualLink = window.location.pathname
    let redirect = false;
    if (actualLink != "/" && actualLink != "/updatePassword" && actualLink != "/emailVerified"
    ) {
      redirect = true
    }
    return (
      <>
        <Route path="/emailVerified" element={<EmailVerified />} />
        <Route path="/updatePassword" element={<UpdatePassword />} />
        <Route path="/" element={<Login myUser={this.props.myUser} validateUser={() => { this.validateUser() }} />} />
        <Route path="*" element={<>{redirect && <>{this.redirect("/")}</>}</>} />
      </>
    )
  }

  redirect = (directTo: any) => {
    window.location.href = directTo
  }

  render() {
    return (

      <>
        <Router>
          <Routes>
            {this.props.myUser != undefined && this.props.myUser.role != undefined && this.props.myUser.role.name == "Finance" &&
              this.routesFinance()
            }
            {this.props.myUser != undefined && this.props.myUser.role != undefined && this.props.myUser.role.name == "Contractor" &&
              this.routesContactor()
            }
            {this.props.myUser != undefined && this.props.myUser.role != undefined && this.props.myUser.role.name == "Human Capital" &&
              this.routesHC()
            }
            {this.props.myUser != undefined && this.props.myUser.role != undefined && this.props.myUser.role.name == "Manager" &&
              this.routesManager()
            }

            {(!this.props.myUser || this.props.myUser.role == undefined) &&
              this.notUser()
            }
          </Routes>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  myUser: state.authReducer.myUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  userAuth: () => dispatch(userAuth()),
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)(Main);