import React, { Component } from 'react';

//Styles
import styles from '../../styles/basicComponents/Loader.module.css';


interface Props {
  showLoader?: boolean
  timeActive?: number
  disableFunction: () => void
}

interface State { }

class Loader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {};
    this.state = initialState;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.showLoader != prevProps.showLoader) {
      if (this.props.showLoader) {
        setTimeout(() => { this.props.disableFunction() }, this.props.timeActive)
      }
    }
  }

  render() {
    return (this.props.showLoader) ? (
      <>
        <div className={styles.__loaderContainer}>
          <div className={styles.dot_spinner}>
            <div className={styles.dot_spinner__dot}></div>
            <div className={styles.dot_spinner__dot}></div>
            <div className={styles.dot_spinner__dot}></div>
            <div className={styles.dot_spinner__dot}></div>
            <div className={styles.dot_spinner__dot}></div>
            <div className={styles.dot_spinner__dot}></div>
            <div className={styles.dot_spinner__dot}></div>
            <div className={styles.dot_spinner__dot}></div>
          </div>
        </div>
      </>)
      : ''
  }
}

export default Loader;