// Axios
import axios from 'axios';

const base_api_url = process.env.REACT_APP_BASE_URL;

const paymentMethods = () => {
    const headers = {
        headers:{
            Authorization : "Bearer "+ localStorage.getItem('tokenInvoicing')
        }
      };
    return axios.get(`${base_api_url}paymentMethods/`, headers);
}



export const API = {
    paymentMethods: paymentMethods,
}