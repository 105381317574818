// Axios
import axios from 'axios';
import { NewInvoice } from "../models/invoice/invoice_model";

const base_api_url = process.env.REACT_APP_BASE_URL;


const createInvoice = (data: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.post(`${base_api_url}invoice/createInvoice`, data, headers);

}

const fetchInvoices = (activePaid: boolean, activeArchive: boolean) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    const data = { activePaid: activePaid, activeArchive: activeArchive }
    return axios.post(`${base_api_url}invoice/allInvoices`, data, headers);
}

const invoiceLink = (idInvoice: string) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    const data = { idInvoice: idInvoice }
    return axios.post(`${base_api_url}invoice/invoiceLink`, data, headers);
}

const lastInvoice = () => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}invoice/lastInvoice`, headers);

}

const invoiceActivity = (id: string) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}invoice/history/` + id, headers);

}

const updateInvoice = (data: NewInvoice, id: string) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}invoice/update/` + id, data, headers);

}

//-------------------------------------------statusAprovedByAM-------------------------------------------------------
const statusAprovedByAM = (data: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}invoice/statusAprovedByAM`, data, headers);

}
//---------------------------------------statusAprovedByFinance-----------------------------------------------------
const statusAprovedByFinance = (data: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}invoice/statusAprovedByFinance`, data, headers);

}
//------------------------------------statusPAIDByFinance---------------------------------------------------------
const statusPAIDByFinance = (data: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}invoice/statusPAIDByFinance`, data, headers);

}

//----------------------------------Status Rejected By AM -----------------------------------------------------------
const statusRejectedAM = (data: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}invoice/statusRejectedByAM`, data, headers);

}
//--------------------------------------statusRejectedByFinance---------------------------------------------------------
const statusRejectedByFinance = (data: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}invoice/statusRejectedByFinance`, data, headers);

}

//--------------------------------------archive Invoice---------------------------------------------------------
const archiveInvoice = (id: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}invoice/archive/` + id, {}, headers);
}

//--------------------------------------unarchive Invoice---------------------------------------------------------
const unarchiveInvoice = (id: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}invoice/unarchive/` + id, {}, headers);
}


//--------------------------------------revert invoice status---------------------------------------------------------
const revertStatusInvoice = (id: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}invoice/revert/` + id, {}, headers);
}

export const API = {
    createInvoice: createInvoice,
    fetchInvoices: fetchInvoices,
    lastInvoice: lastInvoice,
    updateInvoice: updateInvoice,
    invoiceActivity: invoiceActivity,
    statusAprovedByAM: statusAprovedByAM,
    statusAprovedByFinance: statusAprovedByFinance,
    statusPAIDByFinance: statusPAIDByFinance,
    statusRejectedAM: statusRejectedAM,
    statusRejectedByFinance: statusRejectedByFinance,
    archiveInvoice: archiveInvoice,
    unarchiveInvoice: unarchiveInvoice,
    invoiceLink: invoiceLink,
    revertStatusInvoice: revertStatusInvoice
}