import React, { Component } from 'react';

//External components
import DataTable from 'react-data-table-component';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//Styles
import styles from '../../styles/advancedComponents/tableInfo.module.css';

//Icons
import { FcPrevious, FcNext } from 'react-icons/fc';


interface Props {
  data: any
  columns: any
  paginationPerPage: number
  pageLoader?: number
  showPaginationLoaderPrevious?: boolean
  showPaginationLoaderNext?: boolean
  actionPaginationLoaderPrevious?: () => void
  actionPaginationLoaderNext?: () => void
}

interface State {
  page: any
}

export default class TableInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {
      page: 1
    };
    this.state = initialState
  }

  componentDidUpdate(prevProps: Props) {
    try {
      const navBarPagination = document.getElementsByClassName("rdt_Pagination")[0]
      if (navBarPagination) {
        navBarPagination.id = "hiddenTableNav";
        if (document.getElementById("hiddenTableNav")) {
          document.getElementById("hiddenTableNav")!.style.display = "none";
        }
      }
      if (this.props.data.length != prevProps.data.length) {
        this.firstPage();
      }
    } catch (e: any) {
    }
  }

  firstPage = () => {
    this.setState({ page: 1 })
  }

  componentDidMount() {
    try {
      const navBarPagination = document.getElementsByClassName("rdt_Pagination")[0]
      if (navBarPagination) {
        navBarPagination.id = "hiddenTableNav";
        if (document.getElementById("hiddenTableNav")) {
          document.getElementById("hiddenTableNav")!.style.display = "none";
        }
      }
    } catch (e: any) {
    }
  }


  renderSpan = (pageValue: number) => {
    const pageLoader = this.props.pageLoader != undefined ? this.props.pageLoader : 0
    return <span
      key={"page" + pageValue}
      onClick={() => { this.setState({ page: pageValue }) }}
      className={this.state.page == pageValue ? styles.__pagesListSelected : styles.__pagesList}
    >{pageValue + (pageLoader * 5)}
    </span>
  }

  renderPages = (totalPages: number) => {
    let arrayTags = []
    for (let i = 0; totalPages > i; i++) {
      arrayTags.push(this.renderSpan(i + 1));
    }
    return arrayTags
  }

  render() {
    const pageTable = this.state.page;
    const totalPages = Math.ceil(this.props.data.length / 10)
    return (
      <>
        <DataTable
          columns={this.props.columns}
          data={this.props.data}
          pagination
          paginationIconNext="Next"
          paginationIconPrevious="Previous"
          paginationDefaultPage={pageTable}
          className={styles.__tableBase}
          paginationPerPage={this.props.paginationPerPage}
          paginationComponentOptions={{
            noRowsPerPage: true,
            selectAllRowsItem: false,
          }}
        />
        <Row className={styles.__paginationRow}>
          <Col lg="9" md="7" sm="12">
            {this.props.showPaginationLoaderPrevious &&
              <span className={styles.__nextPreviousButtonsLoad} onClick={() => { this.setState({ page: 1 }); this.props.actionPaginationLoaderPrevious!(); }}>{'<'}</span>
            }
            {this.renderPages(totalPages)}
            {this.props.showPaginationLoaderNext &&
              <span className={styles.__nextPreviousButtonsLoad} onClick={() => { this.setState({ page: 1 }); this.props.actionPaginationLoaderNext!() }}>{'>'}</span>
            }
          </Col>
          <Col lg="3" md="5" sm="12">
            <div className={styles.__buttonsNextPrevious}>
              <div className={pageTable > 1 ? styles.__buttonPreviousActive : styles.__buttonPrevious} onClick={() => { if (pageTable > 1) { this.setState({ page: pageTable - 1 }) } }}>
                <FcPrevious className={styles.__buttonIconPrevious} /> Previous
              </div>
              <div className={pageTable < totalPages ? styles.__buttonNextActive : styles.__buttonNext} onClick={() => { if (pageTable < totalPages) { this.setState({ page: pageTable + 1 }) } }}>
                Next  <FcNext className={styles.__buttonIconNext} />
              </div>
            </div>
          </Col>
        </Row>

      </>
    );
  }
}
