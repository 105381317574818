import React, { Component } from 'react';

//Styles
import style from '../../styles/basicComponents/checkBox.module.css';

interface Props {
    id?: string
    checked: boolean
    onclick: (value: any) => void
    value: string
    extraClass?: any
}

interface State { }

export default class CheckBox extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initialState = {};
    }


    render() {
        return (
            <>

                <label
                    className={[style.container, this.props.extraClass ? this.props.extraClass : ""].join(' ')}>
                    <input type="checkbox"
                        value={this.props.value}
                        id={this.props.id ? this.props.id : ""}
                        onChange={(value: any) => { this.props.onclick(value.target.value) }}
                        checked={this.props.checked} />
                    <span className={this.props.checked ? style.checkmark : style.nocheckmark}></span>
                </label>
            </>
        );
    }
}
