import React, { Component } from 'react';

//Styles
import styles from '../../styles/screens/emailVerified.module.css';

//Components
import ButtonForm from '../../components/basicComponents/ButtonForm';

//Logo
import newLogo from '../../assets/img/Logo-Invoiscio-gris.png';

//Icons
import { ReactComponent as ConfirmEmail } from '../../assets/svg/confirmEmail.svg';

//Redux
import { connect } from 'react-redux';
import { emailUserVerified } from '../../actions/auth-actions';

interface Props {
    //Redux and Reducers
    emailUserVerified: (token: string) => void,
    verifyEmail: boolean,
    message: any
}

interface State {
    //URL Values
    token: string
}

class EmailVerified extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            //URL Values
            token: window.location.hash.slice(1),
        };
        this.state = initialState;
    }

    //////////////////BASIC FUNCTIONS////////////////////////////////////////////
    componentDidMount() {
        const { token } = this.state
        if (token != undefined && token != null) {
            this.props.emailUserVerified(token)
        } else {
            window.location.href = "./";
        }

    }





    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const { message } = this.props
        return (
            <>{this.props.verifyEmail &&
                <div className={styles.__bgWhite}>
                    <div className={styles.__centerAll}>
                        <img className={styles.__invocieLogo} src={newLogo} alt="Scio logo" />
                        <div className={styles.__bodyMainDiv}>
                            <ConfirmEmail />
                            <h3 className={styles.__textTitle}>YOUR EMAIL</h3>
                            <h3 className={styles.__textTitleEmail}>{message.message.split("||")[1]}</h3>
                            <h3 className={styles.__textTitle}>WAS SUCCESSFULLY VERIFIED</h3>
                            <span className={styles.__textSpan}>NOW YOU CAN ACCESS WITH YOUR NEW REGISTERED EMAIL AS USUAL.</span>
                        </div>

                        <div >
                            <ButtonForm onClick={() => { window.location.href = "/" }} colorFill label="GO TO LOGIN" />
                        </div>
                    </div>
                </div>
            }
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    verifyEmail: state.authReducer.verifyEmail,
    message: state.authReducer.message
});

const mapDispatchToProps = (dispatch: any) => ({
    emailUserVerified: (token: string) => dispatch(emailUserVerified(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerified);