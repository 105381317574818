// Redux
import { Dispatch } from 'redux';
import { filesBucketActionTypes } from "../constants/filesBucket-contants";
import { API } from '../api/filesBucket-api';
import * as types from '../types/filesBucket-types';
//models
import { FilesInvoices } from "../models/invoice/filesInvoice_model"
import { FilesUser } from "../models/user/filesUser_model"



// -------------------------------------FETCH INVOICE FILES----------------------------------------
const fetchBucketInvoiceFilesBegin = (): types.IFetchBucketInvoiceFilesBegin => ({
    type: filesBucketActionTypes.GET_FILES_INVOICE_BEGIN,
});

const fetchBucketInvoiceFilesError = (e: any): types.IFetchBucketInvoiceFilesError => ({
    type: filesBucketActionTypes.GET_FILES_INVOICE_ERROR,
    error: e
});

const fetchBucketInvoiceFilesSuccess = (filesInvoices: Array<FilesInvoices>): types.IFetchBucketInvoiceFilesSuccess => ({
    type: filesBucketActionTypes.GET_FILES_INVOICE_SUCCESS,
    filesInvoices: filesInvoices
});

export const fetchBucketInvoiceFiles = (invoice: any) => (dispatch: Dispatch) => {
    dispatch(fetchBucketInvoiceFilesBegin());
    API.getFilesInvoice(invoice).then((response: any) => {
        dispatch(fetchBucketInvoiceFilesSuccess(response.data));
    }).catch((error: any) => {
        dispatch(fetchBucketInvoiceFilesError(error.response.data.message));
    });
};

// -------------------------------------UPLOAD INVOICE FILES----------------------------------------
const filesBucketInvoiceUploadBegin = (): types.IFilesBucketInvoiceUploadBegin => ({
    type: filesBucketActionTypes.UPLOAD_INVOICE_BEGIN,
});

const filesBucketInvoiceUploadError = (e: any): types.IFilesBucketInvoiceUploadError => ({
    type: filesBucketActionTypes.UPLOAD_INVOICE_ERROR,
    error: e
});

const filesBucketInvoiceUploadSuccess = (message: any): types.IFilesBucketInvoiceUploadSuccess => ({
    type: filesBucketActionTypes.UPLOAD_INVOICE_SUCCESS,
    message: message
});

export const filesBucketInvoiceUpload = (files: any) => (dispatch: Dispatch) => {
    dispatch(filesBucketInvoiceUploadBegin());
    API.uploadFilesInvoice(files).then((response: any) => {
        dispatch(filesBucketInvoiceUploadSuccess(response.data));
    }).catch((error: any) => {
        dispatch(filesBucketInvoiceUploadError(error.response.data.message));
    });
};

// -------------------------------------FETCH USER FILES----------------------------------------
const fetchBucketUserFilesBegin = (): types.IFetchBucketUserFilesBegin => ({
    type: filesBucketActionTypes.GET_FILES_USER_BEGIN,
});

const fetchBucketUserFilesError = (e: any): types.IFetchBucketUserFilesError => ({
    type: filesBucketActionTypes.GET_FILES_USER_ERROR,
    error: e
});

const fetchBucketUserFilesSuccess = (filesUser: Array<FilesUser>): types.IFetchBucketUserFilesSuccess => ({
    type: filesBucketActionTypes.GET_FILES_USER_SUCCESS,
    filesUser: filesUser
});

export const fetchBucketUserFiles = (user: any) => (dispatch: Dispatch) => {
    dispatch(fetchBucketUserFilesBegin());
    API.getFilesUser(user).then((response: any) => {
        dispatch(fetchBucketUserFilesSuccess(response.data));
    }).catch((error: any) => {
        dispatch(fetchBucketUserFilesError(error.response.data.message));
    });
};

// -------------------------------------UPLOAD USER FILES----------------------------------------
const filesBucketUserUploadBegin = (): types.IFilesBucketUserUploadBegin => ({
    type: filesBucketActionTypes.UPLOAD_USER_BEGIN,
});

const filesBucketUserUploadError = (e: any): types.IFilesBucketUserUploadError => ({
    type: filesBucketActionTypes.UPLOAD_USER_ERROR,
    error: e
});

const filesBucketUserUploadSuccess = (message: any): types.IFilesBucketUserUploadSuccess => ({
    type: filesBucketActionTypes.UPLOAD_USER_SUCCESS,
    message: message
});

export const filesBucketUserUpload = (files: any) => (dispatch: Dispatch) => {
    dispatch(filesBucketUserUploadBegin());
    API.uploadFilesUser(files).then((response: any) => {
        dispatch(filesBucketUserUploadSuccess(response.data));
    }).catch((error: any) => {
        dispatch(filesBucketUserUploadError(error.response.data.message));
    });
};



