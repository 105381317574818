// Redux
import { Dispatch } from 'redux';
import { newUserActionTypes } from '../constants/newUser-constants';
import { API_CREATE_USER } from '../api/newUser-api';
import * as types from '../types/newUser-types';

// Models
import { NewUser } from '../models/user/user_model';


// -------------------------------------NEW USER ----------------------------------------

const newUserBegin = (): types.INewUserBegin => ({
  type: newUserActionTypes.SAVE_BEGIN,
});

const newUserError = (e: any, errorType: any): types.INewUserError => ({
  type: newUserActionTypes.SAVE_ERROR,
  error: e,
  errorType: errorType
});

const newUserSuccess = (data: any): types.INewUserSuccess => ({
  type: newUserActionTypes.SAVE_SUCCESS,
  data,
});

export const newUser = (data: any) => (dispatch: Dispatch) => {
  dispatch(newUserBegin());
  API_CREATE_USER.createUser(data).then((response: any) => {
    dispatch(newUserSuccess(response.data));
  }).catch((error) => {
    if (error.response.data.message == "email repeat") {
      return dispatch(newUserError(`email repeat`, error.response.request.status));
    }
    dispatch(newUserError("Error", error.response.request.status));
  });
};


// -------------------------------------DATA CLEAN ----------------------------------------

const dataClean = (): types.ICleanDataNewUser => ({
  type: newUserActionTypes.CLEAN_DATA_NEW_USER
});

export const clean = () => (dispatch: Dispatch) => {
  dispatch(dataClean());
};

