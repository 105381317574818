import React, { Component } from 'react';
import axios from 'axios';

//components internal
import TextField from '../../components/basicComponents/TextField';
import TextFieldArea from '../../components/basicComponents/TextFieldArea';
import ButtonForm from '../../components/basicComponents/ButtonForm';

//components external
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ViewIcon from '../../assets/svg/view.svg';
import DownloadIcon from '../../assets/svg/download.svg';

//styles
import styles from '../../styles/screens/myProfile.module.css';

//Redux
import { connect } from 'react-redux';
import { fetchBucketInvoiceFiles } from '../../actions/filesBucket-actions';

//Models
import { FilesInvoices } from '../../models/invoice/filesInvoice_model';



interface Props {
    //Redux and Reducers
    filesInvoice: Array<FilesInvoices>,
    loadingGetSuccess: boolean,
    fetchBucketInvoiceFiles: (id: string) => void
}

interface State {
    //files
    filesNamesUploaded: any[],
    filesUploaded: any[]
}

class ContractorDetailsInvoice extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            //files
            filesNamesUploaded: [],
            filesUploaded: [],

        };
        this.state = initialState;
    }

    //////////////////BASIC FUNCTIONS////////////////////////////////////////////
    componentDidMount = async () => {
        if (localStorage.getItem('idNew') != null && localStorage.getItem('idNew') != undefined) {
            this.props.fetchBucketInvoiceFiles(localStorage.getItem('idNew')!)
        }
    }
    componentDidUpdate(prevProps: Props) {
        if (this.props.loadingGetSuccess != prevProps.loadingGetSuccess &&
            prevProps.loadingGetSuccess && !this.props.loadingGetSuccess
        ) {
            this.updateFilesUploaded()
        }
    }






    ///////////////////FORMATS FUNCTIONS//////////////////////////////////////
    renderStatusName = (valueStatus: any) => {
        if (valueStatus == 0) {
            return "Submitted"
        }
        if (valueStatus == 1) {
            return "Approved - AM"
        }
        if (valueStatus == 2) {
            return "Approved - FIN"
        }
        if (valueStatus == 3) {
            return "Paid"
        }
        if (valueStatus == 4) {
            return "Rejected - AM"
        }
        if (valueStatus == 5) {
            return "Rejected - FIN"
        }
    }
    dateFormat = (date: any) => {
        const dateValue = date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4);
        return dateValue
    }
    currencyFormatter = (currency: any, value: any) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            minimumFractionDigits: 2,
            currency
        })
        return formatter.format(value)
    }







    //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
    updateFilesUploaded = () => {
        let filesInvoicesV: Array<any> = [];
        if (this.props.filesInvoice != null && this.props.filesInvoice != undefined) {
            filesInvoicesV = Object.values(this.props.filesInvoice);
            let filesValuesData: any[] = []
            let filesValuesName: any[] = []
            if (Array.isArray(filesInvoicesV)) {
                filesInvoicesV.map((file: any) => {
                    filesValuesName.push(file.name)
                    filesValuesData.push(file.data)
                })

                this.setState({ filesNamesUploaded: filesValuesName, filesUploaded: filesValuesData })
            }
        }
    }






    /////////////////////////////////ONCLICK FUNCTIONS//////////////////////////////////////////
    onCancel = () => {
        window.location.href = "/invoices"
    }
    handleView = (fileName: any) => {
        const base_api_url = process.env.REACT_APP_BASE_URL;
        const headers = {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        };

        axios.get(`${base_api_url}uploadFiles/viewInvoice/${fileName}`, {
            headers,
            responseType: 'arraybuffer'
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const fileBlob = new Blob([response.data], { type: contentType });
                const fileUrl = URL.createObjectURL(fileBlob);
                window.open(fileUrl);
            })
            .catch(error => {
            });
    };
    handleDownload = (fileName: any) => {
        const base_api_url = process.env.REACT_APP_BASE_URL;
        const headers = {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        };

        axios.get(`${base_api_url}uploadFiles/viewInvoice/${fileName}`, {
            headers,
            responseType: 'arraybuffer'
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const fileBlob = new Blob([response.data], { type: contentType });
                const fileUrl = URL.createObjectURL(fileBlob);

                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = fileName;
                link.click();

                URL.revokeObjectURL(fileUrl);
            })
            .catch(error => {
            });
    };







    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const { filesNamesUploaded, filesUploaded } = this.state
        const { filesInvoice } = this.props
        return (
            <Container>

                <Row>
                    <Col lg="10" md="10" sm="10"><h4 className={styles.__MainTitle}>Invoice no. {localStorage.getItem("folio")} </h4></Col>

                    <Col lg="2" md="2" sm="2"><div className={styles.__subbmitedTag}>{localStorage.getItem("status")} </div></Col>
                </Row>
                <Row>
                    <Row>
                        <Col lg="12" md="12" sm="12"><h5 className={styles.__subTitle}>Invoice details</h5></Col>
                    </Row>
                    <Row className={styles.__contractorInfo}>
                        <Row>
                            <Col lg="4" md="4" sm="4" >
                                <label className={styles.__boldText}>Contractor: </label> {localStorage.getItem("contractor")}
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Monthly fee: </label> {localStorage.getItem("monthly_fee")}
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Hourly rate: </label> {localStorage.getItem("hourly_rate")}
                            </Col>

                        </Row>
                        <Row>
                            <Col lg="4" md="4" sm="4" >
                                <label className={styles.__boldText}>Assigned Account Manager: </label> {localStorage.getItem("AM")}
                            </Col>
                            <Col lg="8" md="8" sm="8">
                                <label className={styles.__boldText}>Payment method: </label> {localStorage.getItem("payment_method")}
                            </Col>
                        </Row>
                    </Row>
                </Row>

                <Row>
                    <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Invoice no.</label>
                        <TextField maxLenght={99} disabled value={localStorage.getItem("folio")!} onChange={(value: any) => { }} />
                    </Col>
                    <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Invoice date</label>
                        <TextField maxLenght={100} disabled type="date" value={localStorage.getItem("invoice_date")!} onChange={(value: any) => { }} />
                    </Col>
                    <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Period start</label>
                        <TextField maxLenght={100} disabled type="date" value={localStorage.getItem("start_date")!} onChange={(value: any) => { }} />
                    </Col>
                    <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Period end</label>
                        <TextField maxLenght={100} disabled type="date" value={localStorage.getItem("end_date")!} onChange={(value: any) => { }} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Billable hours</label>
                        <TextField maxLenght={50} disabled value={localStorage.getItem("billable_hours")!} onChange={(value: any) => { }} />
                    </Col>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Total amount</label>
                        <TextField maxLenght={50} disabled value={localStorage.getItem("total")!} onChange={(value: any) => { }} />
                    </Col>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Assigned project</label>
                        <TextField maxLenght={35} disabled value={localStorage.getItem("proyect")!} onChange={(value: any) => { }} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Notes</label>
                        <TextFieldArea maxLenght={300} disabled value={localStorage.getItem("note")!} placeholder="Send us a note" onChange={(value: any) => { }} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" className={styles.__AttachmentsRowsDivMain}>
                        <label className={styles.__boldText}>Attachments</label>
                    </Col>
                </Row>

                <Row className={styles.__AttachmentsRowsDivMain}>

                    {(filesInvoice != undefined && filesInvoice != null) &&
                        filesNamesUploaded.length > 0 ?
                        filesNamesUploaded.map((value: any, index: number) => (

                            <Col key={"filesTableCol" + index} lg="12" md="12" sm="12" className={styles.__AttachmentsRows}>
                                {value}
                                <div className={styles.__rightIcons}>
                                    {value.split(".")[1] == "pdf" ?
                                        <><img onClick={() => { this.handleDownload(filesUploaded[index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} />
                                            <img onClick={() => { this.handleView(filesUploaded[index]) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></>
                                        :
                                        <><img onClick={() => { this.handleDownload(filesUploaded[index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></>
                                    }

                                </div>
                            </Col>
                        ))
                        :
                        <>
                            <Col lg="12" md="12" sm="12" className={styles.__AttachmentsRows}>
                                <h3 className={styles.__noFilesText}>No files</h3>
                            </Col>
                        </>
                    }
                </Row>

                <Col lg="12" md="12" sm="12">
                    <div className={styles.__buttonsSaveCancel}>
                        <ButtonForm onClick={() => { this.onCancel() }} extraClass={styles.__marginButtonsCancel} label="Close" />
                    </div>
                </Col>

            </Container>
        );
    }
}

const mapStateToProps = (state: any) => ({
    filesInvoice: state.filesBucketReducer.filesInvoice,
    loadingGetSuccess: state.filesBucketReducer.loadingGetSuccess,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchBucketInvoiceFiles: (id: string) => dispatch(fetchBucketInvoiceFiles(id)),
});

export default connect(
    mapStateToProps, mapDispatchToProps)(ContractorDetailsInvoice);