import React, { Component } from 'react';

//Icons
import { TbFileExport } from 'react-icons/tb';

//Components internal
import ReduxLoader from '../../components/basicComponents/ReduxLoader';
import PopUp from '../../components/basicComponents/PopUp';
import ToastAlert from '../../components/basicComponents/ToastAlert';
import TableInfo from '../../components/advancedComponents/tableInfo';

//Components external
import { CSVLink } from 'react-csv';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

//Styles
import style from '../../styles/screens/userView.module.css';
import titleStyle from '../../styles/titles.module.css';

//Redux
import { connect } from 'react-redux';
import { usersFetch } from '../../actions/users-action';
import { userDetailsModal } from '../../actions/users-action';
import { fetchBucketUserFiles } from '../../actions/filesBucket-actions';

//Models
import { MyUserModel, usersModel } from '../../models/user/user_model';
import { FilesUser } from '../../models/user/filesUser_model';

//Screens
import ViewUser from './ViewUser';

interface Props {
    //inherited
    myUser: MyUserModel,

    //Redux and Reducers
    fetchBucketUserFiles: (user: string) => void,
    filesUser: Array<FilesUser>,
    loadingGetSuccess: boolean,
    usersFetch: () => void,
    users: Array<usersModel>,
    userDetails: MyUserModel,
    getUserStatus: boolean,
    userDetailsModal: (id: string) => Array<MyUserModel>,
    statusUpdate: boolean
}

interface State {
    popUpTrigger: boolean;
    currentUser: any;
    active: boolean;
    usersTable: Array<any>
    anchorEl: any
    sucessRequestForgotPassword: boolean
    userDetailsView: boolean;
    forceLoadFiles: boolean
    filesNamesUploaded: any[],
    filesUploaded: any[]
}

class userViewFinance extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initialState = {
            popUpTrigger: false,
            usersTable: [],
            anchorEl: null,
            currentUser: {
                name: "",
                phone: "",
                email: "",
                monthly_fee: "",
                hourly_rate: "",
                address: "",
                verify: "",
                payment_method: "",
                AM: "",
            },
            active: false,
            sucessRequestForgotPassword: false,
            userDetailsView: false,
            forceLoadFiles: true,
            filesNamesUploaded: [],
            filesUploaded: [],
        };
        this.state = initialState;
    }


    /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
    componentDidMount = async () => {
        this.props.usersFetch();
        const invoiceUrlId = window.location.search;
        if (invoiceUrlId != undefined && invoiceUrlId != null && invoiceUrlId != '') {
            const invoiceUrlIdValueArray = invoiceUrlId.split('=')
            if (invoiceUrlIdValueArray[0].substring(1) == "user") {
                const idSelected = invoiceUrlIdValueArray[1];
                this.getIdFromChild(idSelected, true)
            }
        }
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.getUserStatus != prevProps.getUserStatus &&
            this.props.getUserStatus &&
            !prevProps.getUserStatus
        ) {
            this.setState({ popUpTrigger: false })
        }
        if (this.props.users != prevProps.users) {
            this.renderUserValues()
        }
        if (this.props.statusUpdate != prevProps.statusUpdate &&
            prevProps.statusUpdate &&
            !this.props.statusUpdate
        ) {
            this.props.usersFetch();
        }
        if (this.props.loadingGetSuccess != prevProps.loadingGetSuccess &&
            prevProps.loadingGetSuccess && !this.props.loadingGetSuccess
        ) {
            this.updateFilesUploaded()
        }
    }






    //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
    renderUserValues = () => {
        const valuesTable = Object.values(this.props.users);
        if (Array.isArray(valuesTable)) {
            let usersValues: any[] = []
            valuesTable.map((user: usersModel) => {
                usersValues.push({
                    id: user.id,
                    name: user.name,
                    createdDate: this.periodFormat(user.createdDate),
                    type: user.type,
                    role: user.role,
                    AM: user.AM,
                    monthly_fee: this.currencyFormatter("USD", user.monthly_fee),
                    status: user.status ? "Inactive" : "Active",
                })
            })
            this.setState({ usersTable: usersValues })
        }
    }

    updateFilesUploaded = () => {
        let filesInvoicesV: Array<any> = [];
        if (this.props.filesUser != null && this.props.filesUser != undefined) {
            filesInvoicesV = Object.values(this.props.filesUser);
            let filesValuesData: any[] = []
            let filesValuesName: any[] = []
            if (Array.isArray(filesInvoicesV)) {
                filesInvoicesV.map((file: any) => {
                    filesValuesName.push(file.name)
                    filesValuesData.push(file.data)
                })

                this.setState({ filesNamesUploaded: filesValuesName, filesUploaded: filesValuesData })
            }
        }
    }







    ///////////////////FORMATS FUNCTIONS//////////////////////////////////////
    periodFormat = (date: any) => {
        const startFormat = date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4);
        return startFormat
    }

    currencyFormatter = (currency: any, value: any) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            minimumFractionDigits: 2,
            currency
        })
        return formatter.format(value)
    }






    //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////

    getIdFromChild = (currentId: any, isPopped: any) => {
        this.setState({ popUpTrigger: isPopped, active: true });
        this.props.userDetailsModal(currentId);
        this.props.fetchBucketUserFiles(currentId)
    };

    showUser = async () => {
        this.setState({ userDetailsView: true });
    };

    closePop = async () => {
        this.setState({ popUpTrigger: false, userDetailsView: false });
    };







    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const columns = [
            {
                name: 'NAME',
                center: true,
                cell: (row: usersModel) => (
                    <div className={style.__divCellTableName}>
                        <label onClick={() => { this.getIdFromChild(row.id, true) }} className={style.spanValueTableName}>{row.name}</label>
                    </div>
                ),
                sortable: true,
                selector: (row: any, i: any) => row.name
            },
            {
                name: 'START DATE',
                center: true,
                cell: (row: usersModel) => (
                    <div className={style.__divCellTable}>
                        <label className={style.spanValueTable}>{row.createdDate}</label>
                    </div>
                ),
                sortable: true,
                selector: (row: any, i: any) => row.createdDate
            },
            {
                name: 'TYPE OF USER',
                center: true,
                cell: (row: usersModel) => (
                    <div className={style.__divCellTable}>
                        <label className={style.spanValueTable}>{row.type}</label>
                    </div>
                ),
                sortable: true,
                selector: (row: any, i: any) => row.type
            },
            {
                name: 'ROLE',
                center: true,
                cell: (row: usersModel) => (
                    <div className={style.__divCellTable}>
                        <label className={style.spanValueTable}>
                            {row.role}
                        </label>
                    </div>
                ),
                sortable: true,
                selector: (row: any, i: any) => row.role
            },
            {
                name: 'AM ASSIGNED',
                center: true,
                cell: (row: usersModel) => (
                    <div className={style.__divCellTableName}>
                        <label className={style.spanValueTable}>
                            {row.role == "Contractor" ? row.AM : "---"}
                        </label>
                    </div>
                ),
                sortable: true,
                selector: (row: any, i: any) => row.AM
            },
            {
                name: 'MONTHLY FEE',
                center: true,
                cell: (row: usersModel) => (
                    <div className={style.__divCellTable}>
                        <label >
                            {row.role == "Contractor" ? row.monthly_fee : "---"}
                        </label>
                    </div>
                ),
                selector: (row: any, i: any) => row.monthly_fee,
                sortable: true,
            },
            {
                name: 'STATUS',
                center: true,
                cell: (row: any) => (
                    <div className={style.__divCellTable}>
                        <label className={row.status == "Active" ? style.__activeUser : style.__inactiveUser} >
                            {row.status}
                        </label>
                    </div>
                ),
                selector: (row: any, i: any) => row.status,
                sortable: true,
            }
        ];
        const headers = [
            { label: "NAME", key: "name" },
            { label: "START DATE", key: "createdDate" },
            { label: "TYPE OF USER", key: "type" },
            { label: "ROLE", key: "role" },
            { label: "AM ASSIGNED", key: "AM" },
            { label: "MONTHLY FEE", key: "monthly_fee" },
            { label: "STATUS", key: "status" }
        ];
        return !this.state.userDetailsView ? (
            <>
                <ReduxLoader Active={this.state.active} />
                <Container className={style.__MainContent}>
                    <ToastAlert timeActive={3000}
                        showToast={this.state.sucessRequestForgotPassword}
                        disableFunction={() => { this.setState({ sucessRequestForgotPassword: false }) }}
                        label="The password reset email was sent"
                    />
                    <Row className={titleStyle.__MainRowTitle}>
                        <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
                            <label className={titleStyle.__titlePart1}></label>
                            <label className={titleStyle.__titlePart2}></label>
                            <label className={titleStyle.__titlePart3}>USERS</label>
                            <label className={titleStyle.__titlePart4}></label>
                            <label className={titleStyle.__titlePart5}></label>
                        </Col>
                    </Row>
                    <div className={[style.__divHeaderButtons, " mb-4 float-right flex"].join(' ')}>
                        <CSVLink className={style.__buttonExport} filename="Users" data={this.state.usersTable} headers={headers}>
                            <TbFileExport />
                        </CSVLink>
                    </div>
                    <TableInfo paginationPerPage={10} columns={columns} data={this.state.usersTable} />
                    <PopUp
                        filesNamesUploaded={this.state.filesNamesUploaded}
                        filesUploaded={this.state.filesUploaded}
                        userDetailsTrigger={this.state.popUpTrigger}
                        buttonTxt="EXIT"
                        myUser={this.props.myUser}
                        showUserDetails={this.showUser}
                        onClick={this.closePop}
                        data={this.props.userDetails}
                    />
                </Container>
            </>
        ) : (
            <ViewUser
                filesNamesUploaded={this.state.filesNamesUploaded}
                filesUploaded={this.state.filesUploaded}
                filesUser={this.props.filesUser}
                data={this.props.userDetails}
                onClick={this.closePop} />
        );

    }
}
const mapStateToProps = (state: any) => ({
    users: state.usersReducer.users,
    userDetails: state.usersReducer.userDetails,
    filesUser: state.filesBucketReducer.filesUser,
    loadingGetSuccess: state.filesBucketReducer.loadingGetSuccess,
    statusUpdate: state.usersReducer.statusUpdate,
    getUserStatus: state.usersReducer.getUserStatus
});

const mapDispatchToProps = (dispatch: any) => ({
    usersFetch: () => dispatch(usersFetch()),
    userDetailsModal: (id: any) => dispatch(userDetailsModal(id)),
    fetchBucketUserFiles: (user: string) => dispatch(fetchBucketUserFiles(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(userViewFinance);
