import React, { Component } from 'react';

//Components
import TableInfo from '../../components/advancedComponents/tableInfo';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

//Styles
import style from '../../styles/screens/userView.module.css';
import styles from '../../styles/screens/invoices.module.css';
import titleStyle from '../../styles/titles.module.css';
import 'react-tooltip/dist/react-tooltip.css';

//Models
import { ActivityLogModel } from '../../models/activity/activityLogModel';
import { MyUserModel } from '../../models/user/user_model';

//Redux
import { connect } from 'react-redux';
import { activityLogFetch, activityLogCount } from '../../actions/activityLog-actions';


interface Props {
    //Redux and Reducers
    activityLogFetch: (page: number) => void,
    log: Array<ActivityLogModel>,
    activityLogCount: () => void,
    countRecords: any,
    user: MyUserModel
    loading: boolean
}

interface State {
    pageLoading: number
    maxPagesLoader: number

}
class ActivityLog extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initialState = {
            pageLoading: 0,
            maxPagesLoader: this.props.countRecords.totalRecordLog != undefined ? Math.ceil(this.props.countRecords.totalRecordLog / 50) : 1
        }
        this.state = initialState;
    }

    //////////////////BASIC FUNCTIONS////////////////////////////////////////////
    componentDidUpdate(prevProps: Props) {
        const { countRecords } = this.props
        if (countRecords != prevProps.countRecords) {
            this.setState({ maxPagesLoader: countRecords.totalRecordLog != undefined ? Math.ceil(countRecords.totalRecordLog / 50) : 1 })
        }
    }

    componentDidMount = () => {
        this.props.activityLogCount();
        this.props.activityLogFetch(this.state.pageLoading);
    };






    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const columns = [
            {
                name: 'DATE',
                selector: (row: any) => row.date,
                sortable: true,
                reorder: true,
                width: '180px',
                center: true,
                cell: (row: any) => (
                    <div className={styles.__divCellTable}>
                        <label className={styles.spanValueTable}>
                            {row.date.substring(0, 10).replace(/-/g, '/')} - {row.date.substring(11, 16)}
                        </label>
                    </div>
                )
            },
            {
                name: 'USER',
                selector: (row: any) => row.name,
                sortable: true,
                reorder: true,
                width: '240px',
                center: true,
                cell: (row: any) => (
                    <div className={styles.__divCellTable}>
                        <label className={styles.spanValueTable}>{row.name}</label>
                    </div>
                ),
            },
            {
                name: 'CHANGE',
                center: true,
                selector: (row: any) => row.details,
                sortable: true,
                reorder: true,
                cell: (row: any) => (
                    <>
                        <div className={styles.__divCellTableLeft}>
                            {this.props.user.role.name == "Finance" ?
                                <>
                                    {row.details.substring(0, 11) == "Has created" &&
                                        <label className={styles.spanValueTable}>
                                            Has created a new <a>user {row.details.split(">user")[1].slice(0, -4)}</a>
                                        </label>
                                    }
                                    {row.details.substring(0, 10) == "Has edited" &&
                                        <label className={styles.spanValueTable}>
                                            Has edited a <a>user´s {row.details.split(">user´s")[1].slice(0, -16)}</a> information
                                        </label>
                                    }
                                    {row.details.substring(0, 13) == "Has activated" &&
                                        <label className={styles.spanValueTable}>
                                            Has activated a <a>user {row.details.split(">user")[1].slice(0, -4)}</a>
                                        </label>
                                    }
                                    {row.details.substring(0, 16) == "Has desactivated" &&
                                        <label className={styles.spanValueTable}>
                                            Has desactivated a <a>user {row.details.split(">user")[1].slice(0, -4)}</a>
                                        </label>
                                    }
                                    {(row.details.substring(0, 10) != "Has edited" && row.details.substring(0, 11) != "Has created"
                                        && row.details.substring(0, 13) != "Has activated" && row.details.substring(0, 16) != "Has desactivated"
                                    )
                                        &&
                                        <label dangerouslySetInnerHTML={{ __html: row.details }} className={styles.spanValueTable}>
                                        </label>
                                    }

                                </>

                                :
                                <label dangerouslySetInnerHTML={{ __html: row.details }} className={styles.spanValueTable}>
                                </label>
                            }

                        </div>
                    </>

                )
            },
        ];
        const { maxPagesLoader, pageLoading } = this.state
        const { log, activityLogFetch } = this.props
        return (
            <>
                <Container className={style.__MainContent}>
                    <ReduxLoader />
                    <Row className={titleStyle.__MainRowTitle}>
                        <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
                            <label className={titleStyle.__titlePart1}></label>
                            <label className={titleStyle.__titlePart2}></label>
                            <label className={titleStyle.__titlePart3}>ACTIVITY LOG</label>
                            <label className={titleStyle.__titlePart4}></label>
                            <label className={titleStyle.__titlePart5}></label>
                        </Col>
                    </Row>
                    <div className={style.__tableActivityLog}>
                        <TableInfo
                            showPaginationLoaderPrevious={pageLoading != 0}
                            showPaginationLoaderNext={pageLoading < (maxPagesLoader - 1)}
                            pageLoader={pageLoading}
                            paginationPerPage={10}
                            columns={columns}
                            actionPaginationLoaderNext={() => { activityLogFetch(pageLoading + 1); this.setState({ pageLoading: pageLoading + 1 }) }}
                            actionPaginationLoaderPrevious={() => { activityLogFetch(pageLoading - 1); this.setState({ pageLoading: pageLoading - 1 }) }}
                            data={Object.values(log)} />
                    </div>
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    log: state.activityLogReducer.log,
    countRecords: state.activityLogReducer.countRecords,
    loading: state.activityLogReducer.loading
});

const mapDispatchToProps = (dispatch: any) => ({
    activityLogFetch: (page: number) => dispatch(activityLogFetch(page)),
    activityLogCount: () => dispatch(activityLogCount())
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);

