import React, { Component } from 'react';
import axios from 'axios';

//Icons
import ViewIcon from '../../assets/svg/view.svg';
import DownloadIcon from '../../assets/svg/download.svg';

//Styles
import styles from '../../styles/screens/editUser.module.css';
import titleStyle from '../../styles/titles.module.css';

//Internal components
import TextField from '../../components/basicComponents/TextField';
import TextFieldArea from '../../components/basicComponents/TextFieldArea';
import ButtonForm from '../../components/basicComponents/ButtonForm';

//External components
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//Redux
import { connect } from 'react-redux';
import { filesBucketUserUpload } from '../../actions/filesBucket-actions';


interface Props {
    //inherited
    onClick?: () => void,
    data?: any,
    filesNamesUploaded?: any[],
    filesUploaded?: any[],
    filesUser?: any[],

    //Redux and Reducers
    filesBucketUserUpload: (files: any) => void,
    UpdateUserDetailsModal?: (data: any) => void,
    userAuth?: () => void
}

interface State {
    filesNamesUploaded?: any[],
    filesUploaded?: any[]

    id: any
    name: string
    email: string
    phone: string
    address: string

    hourRate: any
    monthFee: any
    month: any

    AM: any
    AMValue: any
}

class ViewUser extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            filesNamesUploaded: this.props.filesNamesUploaded,
            filesUploaded: this.props.filesUploaded,

            id: this.props.data.id,
            name: this.props.data.name,
            email: this.props.data.email,
            phone: this.props.data.phone,
            address: this.props.data.address,

            hourRate: this.props.data.hourly_rate.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            monthFee: this.props.data.monthly_fee.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
            month: this.props.data.monthly_fee.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),

            AM: this.props.data.AM,
            AMValue: "",
        }

        this.state = initialState;
    }


    /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
    componentDidMount = async () => {
        const windowScroll = document.getElementById("windowScrollAnimation");
        windowScroll!.scrollTop = 0;
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.data != prevProps.data) {
            this.setState({
                name: this.props.data.name,
                email: this.props.data.email,
                phone: this.props.data.phone,
                address: this.props.data.address,

                hourRate: this.props.data.hourly_rate,
                monthFee: this.props.data.monthly_fee,

                AM: this.props.data.AM,
                filesNamesUploaded: this.props.filesNamesUploaded,
                filesUploaded: this.props.filesUploaded
            })
        }
    }








    //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////

    handleView = (fileName: any) => {
        const base_api_url = process.env.REACT_APP_BASE_URL;
        const headers = {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        };

        axios.get(`${base_api_url}uploadFiles/viewUser/${fileName}`, {
            headers,
            responseType: 'arraybuffer'
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const fileBlob = new Blob([response.data], { type: contentType });
                const fileUrl = URL.createObjectURL(fileBlob);
                window.open(fileUrl);
            })
            .catch((error: any) => {
            });
    };

    handleDownload = (fileName: any) => {
        const base_api_url = process.env.REACT_APP_BASE_URL;
        const headers = {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        };

        axios.get(`${base_api_url}uploadFiles/viewUser/${fileName}`, {
            headers,
            responseType: 'arraybuffer'
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const fileBlob = new Blob([response.data], { type: contentType });
                const fileUrl = URL.createObjectURL(fileBlob);

                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = fileName;
                link.click();

                URL.revokeObjectURL(fileUrl);
            })
            .catch((error: any) => {
            });
    };


    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////

    render() {
        const { filesNamesUploaded, name, email, phone, address, hourRate, monthFee, AM } = this.state
        return (
            <>
                <Row className={titleStyle.__MainRowTitle}>
                    <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
                        <label onClick={() => { window.location.href = "/userView" }} className={titleStyle.__titlePart1}>USERS</label>
                        <label className={titleStyle.__titlePart2}>{">"}</label>
                        <label className={titleStyle.__titlePart3}>USER DETAILS</label>
                        <label className={titleStyle.__titlePart4}></label>
                        <label className={titleStyle.__titlePart5}></label>
                    </Col>
                </Row>
                <div>
                    <h5 className={styles.__generalInfoTitle}>GENERAL INFORMATION</h5>
                    <Row>
                        <Col lg="6" md="6" sm="6">
                            <label className='form-label fw-bold'>Name</label>
                            <TextField maxLenght={50} disabled id='nameErrorFocus' value={name} onChange={() => { }} />
                        </Col>

                        <Col lg="6" md="6" sm="6">
                            <label className='form-label fw-bold'>Email address</label>
                            <TextField maxLenght={50} disabled id='emailErrorFocus' value={email} onChange={() => { }} />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6" md="6" sm="6">
                            <label htmlFor='phonenumber' className='form-label fw-bold'>Phone number</label>
                            <TextField maxLenght={17} id='phoneErrorFocus' value={phone} disabled onChange={() => { }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor='address' className='form-label fw-bold'>Full address</label>
                            <TextFieldArea maxLenght={100} id='addressErrorFocus' value={address} disabled onChange={() => { }} />
                        </Col>
                    </Row>
                    {
                        AM &&
                        <>
                            <div className='mt-4'>
                                <Row>
                                    <Col lg="4" md="4" sm="4">
                                        <label className='form-label fw-bold' >AM Assigned</label>
                                        <TextField maxLenght={50} placeholder='AM Assigned'
                                            onChange={() => { }} disabled value={AM} />
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                        <label className='form-label fw-bold' >Monthly fee</label>
                                        <TextField maxLenght={50} onlyNumbers id='monthlyFeeErrorFocus' placeholder='$4,000.00'
                                            onChange={() => { }} disabled value={monthFee} />
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                        <label className='form-label fw-bold' >Hourly rate</label>
                                        <TextField maxLenght={50} placeholder='$25.00' onChange={() => { }} value={hourRate} disabled />
                                    </Col>

                                </Row>
                                <Row className='mt-3'>
                                    <Col lg="12" md="12" sm="12">
                                        <label className='form-label fw-bold' >Attachments</label>
                                    </Col>
                                </Row>
                                <Table responsive>
                                    <tbody>
                                        {filesNamesUploaded != undefined &&
                                            filesNamesUploaded.length > 0 &&
                                            filesNamesUploaded.map((value: any, index: number) => (
                                                <tr key={"filesUploaded" + index}>
                                                    <td className={styles.tableItem}>
                                                        <span id={index.toString()}>{value}</span>
                                                    </td>
                                                    {value.split(".")[1] == "pdf" ?
                                                        <>
                                                            <td><img onClick={() => { this.handleDownload(this.state.filesUploaded![index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                            <td><img onClick={() => { this.handleView(this.state.filesUploaded![index]) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td>
                                                        </>
                                                        :
                                                        <>
                                                            <td></td>
                                                            <td><img onClick={() => { this.handleDownload(this.state.filesUploaded![index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                        </>
                                                    }

                                                </tr>
                                            ))}
                                        {filesNamesUploaded != undefined &&
                                            filesNamesUploaded.length == 0 &&
                                            <tr>
                                                <td style={{ textAlign: "center" }}>
                                                    No files
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    }
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <div className={styles.__buttonsSaveCancel}>
                                <ButtonForm onClick={() => { this.props.onClick!(); }} extraClass={styles.__marginButtonsCancel} label="Back" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}


const mapStateToProps = (state: any) => ({
    messageError: state.usersReducer.messageError
});

const mapDispatchToProps = (dispatch: any) => ({
    filesBucketUserUpload: (files: any) => dispatch(filesBucketUserUpload(files))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);
