import React, { Component } from 'react';

//Redux
import { connect } from 'react-redux';
import { requestForgotPassword } from '../../actions/auth-actions';

//Components
import TextField from '../basicComponents/TextField';
import ButtonForm from '../basicComponents/ButtonForm';

//styles
import styles from '../../styles/advancedComponents/recoverPasswordModal.module.css';

interface Props {
    activeModal: boolean
    onClose: () => void
    requestForgotPassword: (email: string, option: number, userRequest: any) => void
    successRequest: () => void
}

interface State {
    step: Number
    email: string
    errorEmailFormat: boolean
    errorEmailEmpty: boolean
}

class RecoverPasswordModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initialState = {
            step: 0,
            email: "",
            errorEmailFormat: false,
            errorEmailEmpty: false
        };
        this.state = initialState
    }

    componentDidUpdate(prevProps: Props) {

    }

    onChangeEmail = (value: any) => {
        this.setState({ email: value, errorEmailEmpty: false, errorEmailFormat: false })
    }

    componentDidMount() {

    }

    nextStep = () => {
        const email_regex = new RegExp(/^[A-Z0-9._%+-]+\@[A-Z0-9.]+$/i);
        const { email } = this.state;
        if (email.trim() == "") {
            this.setState({ errorEmailEmpty: true })
            return
        }
        if (!email_regex.test(email)) {
            this.setState({ errorEmailFormat: true })
            return
        }
        this.setState({ step: 1 })
    }

    onSubmit = () => {
        const email = this.state.email
        this.props.requestForgotPassword(email, 0, "")
        this.setState({ step: 0, email: "" })
        this.props.onClose()
        this.props.successRequest();
    }

    render() {
        const { step, email, errorEmailEmpty, errorEmailFormat } = this.state
        return (
            <>
                {this.props.activeModal &&
                    <div className={styles.__boundComponnet}>
                        <div className={styles.__modalContainer}>
                            {step == 0 ?
                                <>
                                    <div>
                                        <h3 className={styles.__title}>Forgot your password?</h3>
                                    </div>
                                    <div className={styles.__textDiv}>
                                        <span className={styles.__text}>Please enter your registered email to receive a password reset link</span>
                                    </div>
                                    <div className={styles.__textDiv}>
                                        <TextField maxLenght={50} error={errorEmailEmpty || errorEmailFormat} extraClass={styles.__textFieldEmail} placeholder="Email" value={email} onChange={(value: string) => { this.onChangeEmail(value) }} />
                                        {errorEmailEmpty &&
                                            <span className={styles.err} >This field is required</span>
                                        }
                                        {errorEmailFormat &&
                                            <span className={styles.err} >Enter a valid email</span>
                                        }
                                    </div>

                                    <div>
                                        <ButtonForm label="Cancel" onClick={() => { this.props.onClose() }} />
                                        <ButtonForm extraClass={styles.__buttonMargin} colorFill label="Next"
                                            onClick={() => { this.nextStep() }} />
                                    </div>
                                </>
                                :
                                <>
                                    <div>
                                        <h3 className={styles.__title}>Confirm your email</h3>
                                    </div>
                                    <div className={styles.__textDivComplete}>
                                        <span className={styles.__textLeft}>A reset password link will be sent to: </span>
                                    </div>
                                    <div className={styles.__textDivComplete}>
                                        <strong className={styles.__text}>{email} </strong>
                                    </div>
                                    <div className={styles.__textDivCompleteLast}>
                                        <span className={styles.__textLeftLast}>Please confirm that this is the correct email address. If not, please use the 'Back' button to make changes</span>
                                    </div>

                                    <div>
                                        <ButtonForm label="Back" onClick={() => { this.setState({ step: 0 }) }} />
                                        <ButtonForm extraClass={styles.__buttonMargin} colorFill label="Confirm"
                                            onClick={() => { this.onSubmit() }} />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
});
const mapDispatchToProps = (dispatch: any) => ({
    requestForgotPassword: (email: string, option: number, userRequest: any) => dispatch(requestForgotPassword(email, option, userRequest)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordModal);
