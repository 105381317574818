// Axios
import axios from 'axios';

const base_api_url = process.env.REACT_APP_BASE_URL;

const getInfoCards = (year: string) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}report/cards/` + year, headers);
}

const getInfoTable = (year: string) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}report/table/` + year, headers);
}


export const API = {
    getInfoCards: getInfoCards,
    getInfoTable: getInfoTable
}