// Redux
import { Reducer } from 'redux';
import { NewUserAction } from '../types/newUser-types';
import { newUserActionTypes } from '../constants/newUser-constants';

// Models
import { NewUser } from '../models/user/user_model';

interface NewUserState {
  loading: boolean,
  loginError: boolean,
  myUser: NewUser,
  messageError: string,
}

const initialState: NewUserState = {
  loading: false,
  loginError: false,
  myUser: {} as NewUser,
  messageError: ""
};

export const newUserReducer: Reducer<NewUserState, NewUserAction> = (state = initialState, action: NewUserAction) => {
  switch (action.type) {
    // ------------------ NEW USER -----------------------------
    case newUserActionTypes.SAVE_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
      };

    case newUserActionTypes.SAVE_ERROR:
      return {
        ...state,
        loading: false,
        messageError: action.error
      };

    case newUserActionTypes.SAVE_SUCCESS:
      return {
        ...state,
        messageError: 'no Error||' + action.data.user.id,
        loading: false,
        myUser: {
          ...action.data,
        },
      };

    //--------------------CLEAN DATA ----------------------
    case newUserActionTypes.CLEAN_DATA_NEW_USER:
      return {
        ...state,
        messageError: ''
      };

    default:
      return state;
  }
};
