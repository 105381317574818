import React, { Component } from 'react';

//Styles
import styles from '../../styles/basicComponents/UploadFile.module.css';

interface Props {
    id?: string
    error?: boolean
    placeholder: string
    disabled?: boolean
    onChange: (value: any) => void
    onClick?: () => void
    needPDF?: boolean
}
interface State {
}

class UploadFile extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                <input
                    className={[styles.all, this.props.disabled ? styles.__disabled : styles.all].join(' ')}
                    type='file'
                    id='fileDropdownGeneral'
                    disabled={this.props.disabled}
                    accept="application/pdf, application/vnd.ms-excel, application/msword, .xlsx,.xls,"
                    onChange={(value: any) => { this.props.onChange(value) }}
                    style={{ display: "none" }}
                />

                <p className={[styles.__pMaxSize, this.props.error ? styles.__pMaxSizeError : ""].join(' ')}>
                    {this.props.needPDF &&
                        <span className={styles.__needPDFLabel}>
                            It is mandatory to attach your invoice as a PDF file. Additionally, you may include any additional files that are needed for support.
                        </span>
                    }

                    Max. file size: 10 Mb.</p>
                <label onClick={() => { if (this.props.onClick != undefined) { this.props.onClick() } }} className={[styles.all,
                this.props.disabled ? styles.__disabled : styles.all,
                styles.__textInput,
                this.props.error ? styles.__inputError : ""].join(' ')}
                    htmlFor='fileDropdownGeneral'>
                    <label htmlFor='fileDropdownGeneral' >{this.props.placeholder}</label>
                    <button id='upload' className={styles.btnUpload}
                        onClick={() => {
                            if (this.props.onClick != undefined) { this.props.onClick() }
                            document.getElementById("fileDropdownGeneral")?.click();
                        }}
                    >Upload</button>
                </label>
            </>
        )
    }
}
export default UploadFile