export enum filesBucketActionTypes {
    GET_FILES_INVOICE_BEGIN = "GET_FILES_INVOICE_BEGIN",
    GET_FILES_INVOICE_ERROR = "GET_FILES_INVOICE_ERROR",
    GET_FILES_INVOICE_SUCCESS = "GET_FILES_INVOICE_SUCCESS",

    UPLOAD_INVOICE_BEGIN = "UPLOAD_INVOICE_BEGIN",
    UPLOAD_INVOICE_ERROR = "UPLOAD_INVOICE_ERROR",
    UPLOAD_INVOICE_SUCCESS = "UPLOAD_INVOICE_SUCCESS",

    GET_FILES_USER_BEGIN = "GET_FILES_USER_BEGIN",
    GET_FILES_USER_ERROR = "GET_FILES_USER_ERROR",
    GET_FILES_USER_SUCCESS = "GET_FILES_USER_SUCCESS",

    UPLOAD_USER_BEGIN = "UPLOAD_USER_BEGIN",
    UPLOAD_USER_ERROR = "UPLOAD_USER_ERROR",
    UPLOAD_USER_SUCCESS = "UPLOAD_USER_SUCCESS",
}
