// Redux
import { Dispatch } from 'redux';
import { reportActionTypes } from "../constants/report-constants";
import { API } from '../api/report-api';
import * as types from '../types/report-types';



// -------------------------------------GET CARDS INFO ----------------------------------------

const getInfoCardsBegin = (): types.IGetCardsInfoBegin => ({
    type: reportActionTypes.GET_CARDS_INFO_BEGIN,
});

const getInfoCardsError = (e: any, errorType: any): types.IGetCardsInfoError => ({
    type: reportActionTypes.GET_CARDS_INFO_ERROR,
    error: e,
    errorType: errorType
});

const getInfoCardsSuccess = (data: Array<any>): types.IGetCardsInfoSuccess => ({
    type: reportActionTypes.GET_CARDS_INFO_SUCCESS,
    data,
});

export const getInfoCards = (year: string) => (dispatch: Dispatch) => {
    dispatch(getInfoCardsBegin());
    API.getInfoCards(year).then((response: any) => {
        dispatch(getInfoCardsSuccess(response.data));
    }).catch((error: any) => {
        dispatch(getInfoCardsError(error.response.data.message, error.response.request.status));
    });
};

// -------------------------------------GET TABLES INFO ----------------------------------------

const getInfoTableBegin = (): types.IGetTableInfoBegin => ({
    type: reportActionTypes.GET_TABLE_INFO_BEGIN,
});

const getInfoTableError = (e: any, errorType: any): types.IGetTableInfoError => ({
    type: reportActionTypes.GET_TABLE_INFO_ERROR,
    error: e,
    errorType: errorType
});

const getInfoTableSuccess = (data: Array<any>): types.IGetTableInfoSuccess => ({
    type: reportActionTypes.GET_TABLE_INFO_SUCCESS,
    data,
});

export const getInfoTable = (year: string) => (dispatch: Dispatch) => {
    dispatch(getInfoTableBegin());
    API.getInfoTable(year).then((response: any) => {
        dispatch(getInfoTableSuccess(response.data));
    }).catch((error: any) => {
        dispatch(getInfoTableError(error.response.data.message, error.response.request.status));
    });
};