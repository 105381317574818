/* eslint-disable no-shadow */
// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';

//Icons
import { ReactComponent as LogosideBarHide } from '../../assets/svg/logosideBarHide.svg';
import { ReactComponent as MyUser } from '../../assets/svg/myUser.svg';
import LogOut from '../../assets/svg/logOut.svg';
import Collapse from '../../assets/svg/collapse.svg';
import { FaBars } from 'react-icons/fa';
import { IoStatsChartOutline } from 'react-icons/io5';
import { FiUsers } from 'react-icons/fi';
import { BsGear } from 'react-icons/bs';
import { CgFileDocument } from 'react-icons/cg';

//Styles
import styles from '../../styles/mainComponents/sideBar.module.css';

//External components
import { NavLink } from 'react-router-dom';

//models
import { MyUserModel } from '../../models/user/user_model';

interface Props {
    sidebarStatus: boolean
    textIcons: boolean
    completeScreen: (value: boolean) => void
    logout: () => void
    role: string
    myUser: MyUserModel
}

interface State {
    location: any
}

class Sidebar extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            location: window.location.pathname
        }
        this.state = initialState;
    }

    componentDidUpdate = (prevProps: Props) => {
        if (window.location.pathname != this.state.location) {
            this.setState({ location: window.location.pathname })
        }
    }

    renderListGroupItem = (classNameItem: any, label: string, srcImg: any, href: any, selected: boolean) => {

        return (
            <NavLink
                to={"/" + href}
                onClick={() => {
                    if (window.location.pathname.substring(1) == href) {
                        window.location.href = href
                    }
                }}
                className={[classNameItem, selected ? styles.__selectedLink : "", !this.props.sidebarStatus ? styles.cutLink : ""].join(' ')}
            >
                {srcImg}{this.props.textIcons && <span className={[selected ? styles.__spanIconsSideBarSelected : styles.__spanIconsSideBarNoSelected].join(' ')}>{label}</span>}
            </NavLink>
        )
    }

    renderListGroupItemLogout = (classNameItem: any, label: string, srcImg: any, selected: boolean) => {

        return (
            <a
                onClick={() => { this.props.logout() }}
                className={classNameItem}
            >
                <img className={styles.__iconSideBar} src={srcImg} />{this.props.textIcons && <span className={[selected ? styles.__spanIconsSideBarSelected : styles.__spanIconsSideBarNoSelected].join(' ')}>{label}</span>}
            </a>
        )
    }

    render() {
        const { role } = this.props
        const showSidebar = this.props.sidebarStatus;
        const baseStylesItems = showSidebar ? "list-group-item list-group-item-action py-2 ripple" : "list-group-item-action py-2 ripple";
        let activeLinkSideBar = 0
        if (this.state.location == "/myProfile") {
            activeLinkSideBar = 1
        }
        if (this.state.location == "/user" || this.state.location == "/newUser" || this.state.location == "/userView") {
            activeLinkSideBar = 2
        }
        if (this.state.location == "/invoices" || this.state.location == "/Invoice" || this.state.location == "/newInvoice" ||
            this.state.location == "/contractorDetailsInvoices") {
            activeLinkSideBar = 3
        }
        if (this.state.location == "/activityLog") {
            activeLinkSideBar = 4
        }
        if (this.state.location == "/reports") {
            activeLinkSideBar = 5
        }
        return (
            <>
                <nav id="sidebarMenu" className={["d-lg-block bg-white", styles.animationSidebar, showSidebar ? styles.sidebar : styles.sidebarHide].join(' ')}>
                    <div className={styles.__logoMainDiv}>
                        {this.props.sidebarStatus ?
                            <h3 className={styles.__titleLogo}>InvoiScio</h3>
                            :
                            <div className={styles.__logoLogo}><LogosideBarHide /></div>
                        }
                        <div className={styles.__divBorderLogo}> </div>
                    </div>
                    <div className={[styles.__userInfoDataMainDiv, showSidebar ? "" : styles.__userInfoDataMainDivCut].join(' ')}>
                        <div className={this.props.sidebarStatus ? styles.__logoDivMyUser : styles.__logosideBarHide}>
                            <MyUser />
                        </div>
                        {this.props.sidebarStatus &&
                            <div className={styles.__infoDivMyUser}>
                                <div className={styles.__infoNameDivMyUser}>
                                    <label className={styles.__nameTextMyUser}>{this.props.myUser.name}</label>
                                </div>
                                <div className={styles.__infoRoleDivMyUser}>
                                    <label className={styles.__roleTextMyUser}>
                                        {this.props.myUser.role.name == "Manager" ? "ACCOUNT MANAGER" : this.props.myUser.role.name}
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                    <div onClick={() => this.props.completeScreen(!showSidebar)} className={[styles.__openSidebarDivPosition, !showSidebar ? styles.__leftPosition : ""].join(' ')}>
                        <div className={["list-group list-group-flush", styles.animationSidebar].join(' ')}>
                            <a>
                                <img src={Collapse} />
                            </a>
                        </div>
                    </div>
                    <div className={["position-sticky", styles.__linksNav].join(' ')}>
                        <div className={["list-group list-group-flush", styles.linksMainDiv].join(' ')}>
                            {this.renderListGroupItem([baseStylesItems, styles.listGroupItem, !showSidebar ? styles.__marginLeftIcons : styles.__marginLeftIconsShow].join(' '), "Profile", <BsGear className={[styles.__iconSideBarRoutes, activeLinkSideBar == 1 ? styles.__iconSelected : ""].join(' ')} />, "myProfile", activeLinkSideBar == 1)}
                            {role != "Contractor" && this.renderListGroupItem([baseStylesItems, styles.listGroupItem, !showSidebar ? styles.__marginLeftIcons : styles.__marginLeftIconsShow].join(' '), "Users", <FiUsers className={[styles.__iconSideBarRoutes, activeLinkSideBar == 2 ? styles.__iconSelected : ""].join(' ')} />, "userView", activeLinkSideBar == 2)}
                            {this.renderListGroupItem([baseStylesItems, styles.listGroupItem, !showSidebar ? styles.__marginLeftIcons : styles.__marginLeftIconsShow].join(' '), "Invoices", <CgFileDocument className={[styles.__iconSideBarRoutes, activeLinkSideBar == 3 ? styles.__iconSelected : ""].join(' ')} />, "invoices", activeLinkSideBar == 3)}
                            {role != "Contractor" && this.renderListGroupItem([baseStylesItems, styles.listGroupItem, !showSidebar ? styles.__marginLeftIcons : styles.__marginLeftIconsShow].join(' '), "Activity log", <FaBars className={[styles.__iconSideBarRoutes, activeLinkSideBar == 4 ? styles.__iconSelected : ""].join(' ')} />, "activityLog", activeLinkSideBar == 4)}
                            {role == "Manager" && this.renderListGroupItem([baseStylesItems, styles.listGroupItem, !showSidebar ? styles.__marginLeftIcons : styles.__marginLeftIconsShow].join(' '), "Report", <IoStatsChartOutline className={[styles.__iconSideBarRoutes, activeLinkSideBar == 5 ? styles.__iconSelected : ""].join(' ')} />, "reports", activeLinkSideBar == 5)}
                        </div>
                    </div>
                    <div className={styles.__logOutDivPosition}>
                        <div className="list-group list-group-flush mt-4">
                            {this.renderListGroupItemLogout([baseStylesItems, styles.listGroupItemLogOut, !showSidebar ? styles.__marginLeftIcons : styles.__marginLeftIconsShow].join(' '), "Logout", LogOut, false)}
                        </div>
                    </div>
                </nav>
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({

});

const mapDispatchToProps = (dispatch: any) => ({
});

export default Sidebar