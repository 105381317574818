import React, { Component } from 'react';

//Styles
import style from '../../styles/basicComponents/DropDown.module.css';

//External components
import Select from 'react-select';

interface Props {
  value?: any
  defaultValue?: any
  error?: boolean
  extraClass?: any
  placeholder?: string
  onChange: (value: any) => void;
  disabled?: boolean
  options?: any
  id?: string
  isSearchable?: boolean
}

interface State { }

export default class DropDown extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {};
  }

  render() {
    const customStyles = {
      control: (base: any, state: any) => ({
        ...base,
        background: "transparent",
        borderColor: "transparent",
        boxShadow: null,
        "&:hover": {
          borderColor: "transparent"
        }
      }),
    };
    return (
      <>
        <Select
          maxMenuHeight={250}
          placeholder={this.props.placeholder}
          id={this.props.id || ''}
          styles={customStyles}
          onChange={(value: any) => { this.props.onChange(value) }}
          className={[style.__baseStructure, this.props.error ? style.__baseStructureIncorrect : style.__baseStructureCorrect].join(' ')}
          isSearchable={false}
          defaultValue={this.props.defaultValue}
          options={this.props.options}
          value={this.props.value}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary25: '#f1f4fc',
              primary: '#005979'
            },
          })}
        />
      </>
    );
  }
}
