import React, { Component } from 'react';
import axios from 'axios';

//Redux
import { connect } from 'react-redux';
import { fetchPaymentMethods } from '../../actions/paymentMethods-actions';
import { updateUser, requestPasswordChangeEmail, cancelEmailUserVerified } from '../../actions/auth-actions';
import { fetchBucketUserFiles } from '../../actions/filesBucket-actions';

//components internal
import TextField from '../../components/basicComponents/TextField';
import DropDown from '../../components/basicComponents/DropDown';
import TextFieldArea from '../../components/basicComponents/TextFieldArea';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import ToastAlert from '../../components/basicComponents/ToastAlert';
import Loader from '../../components/basicComponents/Loader';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';
import PopUp from '../../components/basicComponents/PopUp';


//components external
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


//Icons
import ViewIcon from '../../assets/svg/view.svg';
import DownloadIcon from '../../assets/svg/download.svg';

//styles
import styles from '../../styles/screens/myProfile.module.css';
import stylesPop from '../../styles/screens/updatePassword.module.css';
import titleStyle from '../../styles/titles.module.css';

//models
import { MyUserModel } from '../../models/user/user_model';
import { PaymentMethodModel } from '../../models/payments/paymentsMethod-model';
import { FilesUser } from '../../models/user/filesUser_model';

interface Props {
  //inherited
  myUser: MyUserModel,
  updateUser: (data: any) => void,

  //Redux and Reducers
  fetchBucketUserFiles: (user: string) => void,
  filesUser: Array<FilesUser>,
  loadingGetSuccess: boolean,
  messageError: any,
  updateSuccess: boolean,
  paymentsFetch: Array<PaymentMethodModel>,
  fetchPaymentMethods: () => void,
  updateInfoUser: () => void,
  requestPasswordChangeEmail: (id: any) => void,
  cancelEmailUserVerified: () => void
}

interface State {
  name: string
  email: string
  phone: string
  address: string
  role: string
  paymentMethodId: string
  paymentMethodLabel: string
  paymentsOptions: Array<any>
  paymentDetails: string
  saveButtonActive: boolean
  noSpam: boolean

  valueTitle: string

  emailRepeat: boolean

  filesNamesUploaded: any[],
  filesUploaded: any[]

  //Pop Up
  popUpTriggerChangeEmail: boolean
  popUpTriggerCancelChangeEmail: boolean
  popUpTriggerChangePassword: boolean

  //Toast
  saveSuccess: boolean
  saveError: boolean
  emailSent: boolean

  //Loader
  screenLoad: boolean

  //errors
  nameError: boolean
  emailError: boolean
  paymentError: boolean
  paymentDetailsError: boolean
}

class ContactorProfile extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const initialState = {
      name: this.props.myUser.name,
      email: this.props.myUser.email,
      phone: this.props.myUser.phone == null ? "" : this.props.myUser.phone,
      address: this.props.myUser.address == null ? "" : this.props.myUser.address,
      role: this.props.myUser.role.name,
      paymentMethodId: this.props.myUser.payment_method.type,
      paymentMethodLabel: this.props.myUser.payment_method.name == "" ? "Select your preferred type of payment method" : this.props.myUser.payment_method.name,
      paymentDetails: this.props.myUser.payment_method.details,
      saveButtonActive: false,
      valueTitle: "",

      saveSuccess: false,
      saveError: false,
      emailSent: false,

      filesNamesUploaded: [],
      filesUploaded: [],
      //Pop Up
      popUpTriggerChangeEmail: false,
      popUpTriggerCancelChangeEmail: false,
      popUpTriggerChangePassword: false,

      nameError: false,

      emailError: false,
      paymentError: false,
      paymentDetailsError: false,
      paymentsOptions: [],
      noSpam: false,
      screenLoad: false,
      emailRepeat: false
    };
    this.state = initialState;
  }


  /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
  componentDidMount = async () => {
    await this.props.fetchPaymentMethods()
    await this.loadPaymentOptions()
    await this.props.updateInfoUser()
    await this.props.fetchBucketUserFiles(this.props.myUser.id)
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.paymentsFetch != prevProps.paymentsFetch) {
      this.loadPaymentOptions()
    }
    if (this.props.myUser != prevProps.myUser) {
      this.setState({
        name: this.props.myUser.name,
        email: this.props.myUser.email,
        phone: this.props.myUser.phone == null ? "" : this.props.myUser.phone,
        address: this.props.myUser.address == null ? "" : this.props.myUser.address,
        paymentMethodId: this.props.myUser.payment_method.type,
        paymentMethodLabel: this.props.myUser.payment_method.name == "" ? "Select your preferred type of payment method" : this.props.myUser.payment_method.name,
        paymentDetails: this.props.myUser.payment_method.details,
      })
    }
    if (this.props.updateSuccess == false && prevProps.updateSuccess == true) {
      this.props.updateInfoUser();
    }
    if (this.props.messageError != prevProps.messageError && prevProps.messageError == "" &&
      this.props.messageError == "email repeat") {
      this.setState({ emailRepeat: true, saveSuccess: false })
      document.getElementById('emailFocusOnError')!.focus()
    }
    if (this.props.loadingGetSuccess != prevProps.loadingGetSuccess &&
      prevProps.loadingGetSuccess && !this.props.loadingGetSuccess
    ) {
      this.updateFilesUploaded()
    }
  }






  //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
  updateFilesUploaded = () => {
    let filesInvoicesV: Array<any> = [];
    if (this.props.filesUser != null && this.props.filesUser != undefined) {
      filesInvoicesV = Object.values(this.props.filesUser);
      let filesValuesData: any[] = []
      let filesValuesName: any[] = []
      if (Array.isArray(filesInvoicesV)) {
        filesInvoicesV.map((file: any) => {
          filesValuesName.push(file.name)
          filesValuesData.push(file.data)
        })

        this.setState({ filesNamesUploaded: filesValuesName, filesUploaded: filesValuesData })
      }
    }
  }
  activeDisableSaveButton = () => {
    const { name, email, phone, address, paymentMethodLabel, paymentDetails } = this.state;
    const { myUser } = this.props;
    if (name == myUser.name && email == myUser.email && phone == myUser.phone && address == myUser.address &&
      paymentMethodLabel == myUser.payment_method.name && paymentDetails == myUser.payment_method.details) {
      this.setState({ saveButtonActive: false })
    } else {
      this.setState({ saveButtonActive: true })
    }
  }
  loadPaymentOptions = () => {
    const paymentsArray = Object.values(this.props.paymentsFetch);
    if (Array.isArray(paymentsArray)) {
      let paymentsMethods: any[] = []
      paymentsArray.map((payments: PaymentMethodModel) => {
        paymentsMethods.push({ label: payments.name, value: payments.id })
      })
      this.setState({ paymentsOptions: paymentsMethods })
    }
  }






  //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
  onChangeName = (value: any) => {
    this.setState({
      name: value,
      nameError: false
    })
    setTimeout(() => this.activeDisableSaveButton(), 40)
  }

  onChangeEmail = (value: any) => {
    const emailValue = value.replace(/\s/g, "")
    if (this.props.myUser.email == this.props.myUser.user || !this.state.noSpam || this.props.myUser.email_verify) {
      this.setState({
        email: emailValue,
        emailError: false,
        emailRepeat: false
      });
      setTimeout(() => this.activeDisableSaveButton(), 40)
    }
  }

  onChangePhone = (value: any) => {
    this.setState({
      phone: value,
    })
    setTimeout(() => this.activeDisableSaveButton(), 40)
  }

  onChangeAddress = (value: any) => {
    this.setState({
      address: value,
    })
    setTimeout(() => this.activeDisableSaveButton(), 40)
  }

  onChangePaymentDetails = (value: any) => {
    this.setState({
      paymentDetails: value,
      paymentDetailsError: false
    })
    setTimeout(() => this.activeDisableSaveButton(), 40)
  }

  onChangePayment = (value: any) => {
    this.setState({
      paymentMethodId: value.value,
      paymentMethodLabel: value.label,
      paymentError: false
    })
    setTimeout(() => this.activeDisableSaveButton(), 40)
  }

  changePassword = () => {
    if (!this.state.noSpam || this.props.myUser.email_verify) {
      this.props.requestPasswordChangeEmail(this.props.myUser.id)
      this.setState({ emailSent: true, noSpam: true })
    }
  }






  //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////
  onSubmit = () => {
    const { name, phone, email, address, paymentMethodId, paymentDetails } = this.state
    let nameError = false, emailError = false, paymentError = false, paymentDetailsError = false;
    if (email.trim() == "") {
      emailError = true;
      document.getElementById('emailFocusOnError')!.focus()
    }
    if (name.trim() == "") {
      nameError = true;
      document.getElementById('nameFocusOnError')!.focus()
    }
    if (paymentMethodId == "") {
      paymentError = true
    }
    if (paymentDetails == "") {
      paymentDetailsError = true
    }
    if (nameError || emailError || paymentError || paymentDetailsError) {
      this.setState({ nameError, emailError, paymentDetailsError, paymentError, saveError: true })
      const windowScroll = document.getElementById('windowScrollAnimation');
      windowScroll!.scrollTop = 0;
      return
    }

    if (email != this.props.myUser.email) {
      this.setState({ popUpTriggerChangeEmail: true })
      return
    }

    const dataInfoUpdate = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      type_payment: paymentMethodId,
      payment_details: paymentDetails
    }
    this.props.updateUser(dataInfoUpdate)
    this.setState({ saveSuccess: true, saveButtonActive: false })
    const windowScroll = document.getElementById('windowScrollAnimation');
    windowScroll!.scrollTop = 0;
  }

  onSubmitConfirmChangeEmail = () => {
    const { name, phone, email, address, paymentMethodId, paymentDetails } = this.state
    const dataInfoUpdate = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      type_payment: paymentMethodId,
      payment_details: paymentDetails
    }
    this.props.updateUser(dataInfoUpdate)
    this.setState({ saveSuccess: true, saveButtonActive: false, popUpTriggerChangeEmail: false })
    const windowScroll = document.getElementById("windowScrollAnimation");
    windowScroll!.scrollTop = 0;
  }

  onCancel = () => {
    this.setState({
      name: this.props.myUser.name,
      email: this.props.myUser.email,
      phone: this.props.myUser.phone,
      address: this.props.myUser.address,
      paymentMethodId: this.props.myUser.payment_method.type,
      paymentMethodLabel: this.props.myUser.payment_method.name == "" ? "Select your preferred type of payment method" : this.props.myUser.payment_method.name,
      paymentDetails: this.props.myUser.payment_method.details,

      nameError: false,
      emailError: false,
      paymentError: false,
      paymentDetailsError: false,
      screenLoad: true,
    });
    const windowScroll = document.getElementById('windowScrollAnimation');
    windowScroll!.scrollTop = 0;
  }

  handleView = (fileName: any) => {
    const base_api_url = process.env.REACT_APP_BASE_URL;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    };

    axios.get(`${base_api_url}uploadFiles/viewUser/${fileName}`, {
      headers,
      responseType: 'arraybuffer'
    })
      .then(response => {
        const contentType = response.headers['content-type'];
        const fileBlob = new Blob([response.data], { type: contentType });
        const fileUrl = URL.createObjectURL(fileBlob);
        window.open(fileUrl);
      })
      .catch((error: any) => {
      });
  };

  handleDownload = (fileName: any) => {
    const base_api_url = process.env.REACT_APP_BASE_URL;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    };

    axios.get(`${base_api_url}uploadFiles/viewUser/${fileName}`, {
      headers,
      responseType: 'arraybuffer'
    })
      .then(response => {
        const contentType = response.headers['content-type'];
        const fileBlob = new Blob([response.data], { type: contentType });
        const fileUrl = URL.createObjectURL(fileBlob);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(fileUrl);
      })
      .catch((error: any) => {
      });
  };






  ///////////////////FORMATS FUNCTIONS//////////////////////////////////////
  currencyFormatter = (currency: any, value: any) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency
    })
    return formatter.format(value)
  }






  /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
  render() {
    const monthly_fee_Value = this.currencyFormatter(
      "USD",
      this.props.myUser.monthly_fee
    )
    const hourly_rate_Value = this.currencyFormatter(
      "USD",
      this.props.myUser.hourly_rate
    )
    const { name, email, phone, address, paymentMethodId, paymentMethodLabel, paymentsOptions, paymentDetails, emailSent,
      nameError, emailError, emailRepeat, paymentError, paymentDetailsError, saveSuccess, saveError, screenLoad,
      saveButtonActive, popUpTriggerChangeEmail, popUpTriggerCancelChangeEmail, popUpTriggerChangePassword } = this.state
    return (
      <Container>
        <Row className={titleStyle.__MainRowTitle}>
          <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
            <label className={[titleStyle.__titlePart1, titleStyle.__noLink].join(' ')}>MY USER</label>
            <label className={titleStyle.__titlePart2}>{'>'}</label>
            <label className={titleStyle.__titlePart3}>CONTRACTOR</label>
            <label className={titleStyle.__titlePart4}>{this.state.valueTitle != "" && '>'}</label>
            <label className={titleStyle.__titlePart5}>{this.state.valueTitle}</label>
          </Col>
        </Row>
        <div className={stylesPop.__centerAll}>
          <PopUp passwordTrigger={popUpTriggerChangeEmail} title="Update email process"
            subTitle=""
            text="You are about to start an email process that requires you to access the new email address you wish to register.
            If you widh to proceed, please confirm. Otherwise, you may cancel the process"
            buttonTxt="Continue"
            extraButton={<ButtonForm label="CANCEL" extraClass={styles.__buttonCancel} onClick={() => {
              this.setState({ popUpTriggerChangeEmail: false, email: this.props.myUser.email })
              setTimeout(() => this.activeDisableSaveButton(), 40)
            }} />}
            onClick={() => {
              this.onSubmitConfirmChangeEmail()
            }} />

          <PopUp passwordTrigger={popUpTriggerChangePassword} title="Update password process"
            subTitle=""
            text={"We'll send an email to <strong>" + this.props.myUser.user + "</strong> with a link to update your password"}
            buttonTxt="Continue"
            extraButton={<ButtonForm label="CANCEL" extraClass={styles.__buttonCancel} onClick={() => {
              this.setState({ popUpTriggerChangePassword: false })
            }} />}
            onClick={() => {
              this.setState({ popUpTriggerChangePassword: false })
              this.changePassword();
              this.setState({ valueTitle: "CHANGE PASSWORD" })
              const windowScroll = document.getElementById("windowScrollAnimation");
              windowScroll!.scrollTop = 0;
            }} />


          <PopUp passwordTrigger={popUpTriggerCancelChangeEmail} title="Canceling update process"
            subTitle=""
            text="By clicking 'Continue', you will cancel the email update process that you started earlier. This will not have any 
            further consequences, and you can log in with your credentials as usual"
            buttonTxt="Continue"
            extraButton={<ButtonForm label="CANCEL" extraClass={styles.__buttonCancel} onClick={() => {
              this.setState({ popUpTriggerCancelChangeEmail: false })
            }} />}
            onClick={() => {
              this.setState({ popUpTriggerCancelChangeEmail: false })
              this.props.cancelEmailUserVerified();
            }} />
        </div>
        {/* 👇======================Loaders===========================👇 */}
        <ReduxLoader />
        <Loader
          showLoader={screenLoad}
          timeActive={100}
          disableFunction={() => { this.setState({ screenLoad: false }) }}
        />
        {/* 👆======================Loaders===========================👆 */}
        <ToastAlert
          label="Email has been sent, please check to reset your password."
          showToast={emailSent}
          timeActive={3000}
          disableFunction={() => { this.setState({ emailSent: false }) }}
        />
        <ToastAlert
          showToast={saveSuccess}
          timeActive={3000}
          disableFunction={() => { this.setState({ saveSuccess: false }) }}
        />
        <ToastAlert
          error
          label="Required data is missing"
          showToast={saveError}
          timeActive={3000}
          disableFunction={() => { this.setState({ saveError: false }) }}
        />
        <Row>
          <Col lg="12" md="12" sm="12"><h5 className={styles.__subTitle}>GENERAL INFORMATION</h5></Col>
        </Row>
        <Row onClick={() => { this.setState({ valueTitle: "GENERAL INFORMATION" }) }}>
          <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
            <label className={styles.__boldText}>Name</label>
            <TextField maxLenght={50} value={name} id="nameFocusOnError" error={nameError} placeholder="Write your full name" onChange={(value: any) => { this.onChangeName(value) }} />
            {nameError &&
              <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This field is required.</span>
            }
          </Col>
          <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
            <label className={styles.__boldText}>Email address</label>
            <TextField maxLenght={50} value={email} id="emailFocusOnError" error={emailError || emailRepeat} placeholder="Write your email address" onChange={(value: any) => { this.onChangeEmail(value) }} />
            {this.props.myUser.email != this.props.myUser.user &&
              <span
                className={[styles.alertEmail].join(" ")}
                id="alert-user">
                Verify your email or <span onClick={() => { this.setState({ popUpTriggerCancelChangeEmail: true }) }} className={[styles.alertEmailLink].join(" ")}>cancel</span> the current update process.
              </span>
            }
            {emailError &&
              <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This field is required.</span>
            }
            {emailRepeat &&
              <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This email is already registered.</span>
            }
          </Col>
        </Row>
        <Row onClick={() => { this.setState({ valueTitle: "GENERAL INFORMATION" }) }}>
          <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
            <label className={styles.__boldText}>Phone number</label>
            <TextField maxLenght={18} value={phone} id="phoneFocusOnError" placeholder="+52 0000-0000" onChange={(value: any) => { this.onChangePhone(value) }} />
          </Col>
        </Row>
        <Row onClick={() => { this.setState({ valueTitle: "GENERAL INFORMATION" }) }}>
          <Col lg="12" md="12" sm="12" className={styles.colMyProfileInput}>
            <label className={styles.__boldText}>Full address</label>
            <TextFieldArea maxLenght={100} value={address} id="addressFocusOnError" placeholder="Write your full address, incluiding Zip Code and Country" onChange={(value: any) => { this.onChangeAddress(value) }} />
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12"><h5 className={styles.__subTitle}>PAYMENT METHODS</h5></Col>
        </Row>
        <Row onClick={() => { this.setState({ valueTitle: "PAYMENT METHODS" }) }}>
          <Col lg="12" md="12" sm="12" className={styles.colMyProfileInput}>
            <label className={styles.__boldText}>Type of payment method</label>
            <DropDown value={{ value: paymentMethodId, label: paymentMethodLabel }} options={paymentsOptions} error={paymentError} placeholder="Select your preferred type of payment method" onChange={(value: any) => { this.onChangePayment(value) }} />
            {paymentError &&
              <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This field is required.</span>
            }
          </Col>
        </Row>
        {paymentMethodId != "" &&
          <Row onClick={() => { this.setState({ valueTitle: "PAYMENT METHODS" }) }} >
            <Col lg="12" md="12" sm="12" >
              <label className={styles.__boldText}>Enter the details of your type of payment</label>
              <TextFieldArea
                maxLenght={250}
                value={paymentDetails} error={paymentDetailsError} placeholder="Enter the details for us to send your money overseas" onChange={(value: any) => { this.onChangePaymentDetails(value) }} />
              {paymentDetailsError &&
                <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This field is required.</span>
              }
            </Col>
          </Row>
        }
        <Row className={styles.colMyProfileInput}>
          <Col lg="12" md="12" sm="12" className={styles.textChangePassword}>
            Account Management
          </Col>
          <Col lg="12" md="12" sm="12" className={styles.paddingSection}>
            <ButtonForm disabled={this.state.noSpam || !this.props.myUser.email_verify}
              extraClass={styles.__buttonPassword}
              onClick={() => { this.setState({ popUpTriggerChangePassword: true }) }} label="Change password" />
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12"><h5 className={styles.__subTitle}>CONTRACT INFORMATION</h5></Col>
        </Row>
        <Row className={styles.__contractInfo}>
          <Col lg="5" md="5" sm="5" >
            <label className={styles.__boldText}>Assigned Account Manager:</label> {this.props.myUser.AM}
          </Col>
          <Col lg="3" md="3" sm="3">
            <label className={styles.__boldText}>Hourly rate:</label> {hourly_rate_Value}
          </Col>
          <Col lg="4" md="4" sm="4">
            <label className={styles.__boldText}>Monthly fee:</label> {monthly_fee_Value}
          </Col>
        </Row>
        <Row className={styles.__AttachmentsRowsDivMain}>
          <Col className={styles.__AttachmentsRowsMainTop} lg="12" md="12" sm="12"><label className={styles.__boldText}>Attachments</label></Col>
        </Row>
        <Row className={styles.__AttachmentsRowsDivMain}>
          {this.state.filesNamesUploaded != undefined &&
            this.state.filesNamesUploaded.length > 0 ?
            this.state.filesNamesUploaded.map((value: any, index: number) => (
              <Col lg="12" md="12" sm="12" className={styles.__AttachmentsRows}>
                {value}
                <div className={styles.__rightIcons}>
                  {value.split(".")[1] == "pdf" ?
                    <> <img onClick={() => { this.handleDownload(this.state.filesUploaded![index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} />
                      <img onClick={() => { this.handleView(this.state.filesUploaded![index]) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></>
                    :
                    <> <img onClick={() => { this.handleDownload(this.state.filesUploaded![index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></>}

                </div>
              </Col>
            )) :
            <>
              <Col lg="12" md="12" sm="12" className={styles.__AttachmentsRows}>
                <h3 className={styles.__noFilesText}>No files</h3>
              </Col>
            </>
          }
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12">
            <div className={styles.__buttonsSaveCancel}>
              <ButtonForm onClick={() => { this.onCancel() }} extraClass={styles.__marginButtonsCancel} label="Cancel" />
              <ButtonForm onClick={() => { this.onSubmit() }} disabled={!saveButtonActive} extraClass={styles.__marginButtonsSave} colorFill label="Save" />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  paymentsFetch: state.paymentMethodsReducer.paymentsFetch,
  updateSuccess: state.authReducer.updateSuccess,
  messageError: state.authReducer.messageError,
  filesUser: state.filesBucketReducer.filesUser,
  loadingGetSuccess: state.filesBucketReducer.loadingGetSuccess,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchPaymentMethods: () => dispatch(fetchPaymentMethods()),
  updateUser: (data: any) => dispatch(updateUser(data)),
  requestPasswordChangeEmail: (id: any) => dispatch(requestPasswordChangeEmail(id)),
  cancelEmailUserVerified: () => dispatch(cancelEmailUserVerified()),
  fetchBucketUserFiles: (user: string) => dispatch(fetchBucketUserFiles(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)(ContactorProfile);