// Redux
import { Dispatch } from 'redux';
import { activityLogActionTypes } from '../constants/activityLog-constants';
import { API } from '../api/activityLog-api';
import * as types from '../types/activityLog-types';

// Models
import { ActivityLogModel } from '../models/activity/activityLogModel';


// -------------------------------------ACTIVITY lOG FETCH----------------------------------------
const activityLogFetchBegin = (): types.IActivityLogFetchBegin => ({
  type: activityLogActionTypes.LOG_BEGIN,
});

const activityLogFetchError = (e: any, errorType: any): types.IActivityLogFetchError => ({
  type: activityLogActionTypes.LOG_ERROR,
  error: e,
  errorType: errorType
});

const activityLogFetchSuccess = (data: Array<ActivityLogModel>): types.IActivityLogFetchSuccess => ({
  type: activityLogActionTypes.LOG_SUCCESS,
  data,
});

export const activityLogFetch = (page: number) => (dispatch: Dispatch) => {
  dispatch(activityLogFetchBegin());
  API.activityLogFetch(page).then((response: any) => {
    dispatch(activityLogFetchSuccess(response.data));
  }).catch((error: any) => {
    dispatch(activityLogFetchError(error.response.data.message, error.response.request.status));
  });
};

// -------------------------------------ACTIVITY lOG COUNT----------------------------------------
const activityLogCountBegin = (): types.IActivityLogCountBegin => ({
  type: activityLogActionTypes.COUNT_RECORDS_BEGIN,
});

const activityLogCountError = (e: any, errorType: any): types.IActivityLogCountError => ({
  type: activityLogActionTypes.COUNT_RECORDS_ERROR,
  error: e,
  errorType: errorType
});

const activityLogCountSuccess = (data: any): types.IActivityLogCountSuccess => ({
  type: activityLogActionTypes.COUNT_RECORDS_SUCCESS,
  data,
});

export const activityLogCount = () => (dispatch: Dispatch) => {
  dispatch(activityLogCountBegin());
  API.activityLogCount().then((response: any) => {
    dispatch(activityLogCountSuccess(response.data));
  }).catch((error: any) => {
    dispatch(activityLogCountError(error.response.data.message, error.response.request.status));
  });
};


