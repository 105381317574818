import { Component } from 'react';

//redux
import { connect } from 'react-redux';
import { login, clean } from '../../actions/auth-actions';

//Components
import ButtonForm from '../../components/basicComponents/ButtonForm';
import TextField from '../../components/basicComponents/TextField';
import RecoverPasswordModal from '../../components/advancedComponents/recoverPasswordModal';
import ToastAlert from '../../components/basicComponents/ToastAlert';

//Icons
import NewLogoReduced from '../../assets/img/logoLogin.png';

//Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../../styles/basicComponents/login.module.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

//Models
import { LoginModel } from '../../models/auth/login-model';

interface Props {
    //inherited
    myUser: any,
    validateUser: () => void,

    //Redux and Reducers
    login: (data: LoginModel) => void,
    clean: () => void,
    messageError: any
}

interface State {
    data: LoginModel
    errorUser: boolean,
    errorPassword: boolean
    showPassword: boolean
    messageError: string
    userIncorrect: boolean
    sucessRequestForgotPassword: boolean
    recoverPasswordModal: boolean
}

class Login extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: {
                user: '',
                password: '',
            },
            errorUser: false,
            errorPassword: false,
            showPassword: false,
            messageError: "",
            userIncorrect: false,
            sucessRequestForgotPassword: false,
            recoverPasswordModal: false
        }
    }


    /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
    componentDidMount = () => {
        this.props.clean();
    }

    componentDidUpdate = (prevProps: Props) => {
        if (this.props.messageError != prevProps.messageError) {
            if (this.props.messageError == "User not found" && prevProps.messageError == "") {
                this.setState({ userIncorrect: true })
            }
            if (this.props.messageError == "accessSuccess" && prevProps.messageError == "") {
                this.props.validateUser();
            }
        }
    }








    //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
    onChangeUserName = (value: any) => {
        const { data } = this.state;
        data.user = value;
        this.setState({ data, errorUser: false, userIncorrect: false });
    }

    onChangeUserPassword = (value: any) => {
        const { data } = this.state;
        data.password = value;
        this.setState({ data, errorPassword: false, userIncorrect: false });
    }







    //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////
    handleSubmit = async (event?: any) => {
        const { data } = this.state
        let errorUser = false, errorPassword = false;
        if (data.user == "") { errorUser = true }
        if (data.password == "") { errorPassword = true }

        if (errorUser || errorPassword) {
            this.setState({ errorUser, errorPassword })
            return
        }
        try {
            await this.props.login(data)
        } catch (e: any) {
        }

    }






    //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
    onKeyDown = async (event?: React.KeyboardEvent) => {
        const { data } = this.state
        let errorUser = false, errorPassword = false;
        if (event?.key === 'Enter') {
            if (data.user == "") { errorUser = true }
            if (data.password == "") { errorPassword = true }

            if (errorUser || errorPassword) {
                this.setState({ errorUser, errorPassword })
                return
            }
            try {
                await this.props.login(data)
            } catch (e: any) {
            }
        }

    }






    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const { data, errorPassword, errorUser, userIncorrect, recoverPasswordModal, sucessRequestForgotPassword } = this.state
        return (
            <div className={styles.__App}>
                <ToastAlert timeActive={3000}
                    showToast={sucessRequestForgotPassword}
                    disableFunction={() => { this.setState({ sucessRequestForgotPassword: false }) }}
                    label="The password reset email was sent"
                />
                <RecoverPasswordModal successRequest={() => { this.setState({ sucessRequestForgotPassword: true }) }} activeModal={recoverPasswordModal} onClose={() => { this.setState({ recoverPasswordModal: false }) }} />
                <div className={['container', styles.container].join(' ')}>
                    <div className='row align-items-center justify-content-center vh-100 '>
                        <div className={['h-30 py-3 card', styles.card, styles.form].join(' ')}>
                            <div className={['pt-3 fw-bold pb-3 fs-5 w-100', styles.logo_spacing].join(' ')}>
                                <img src={NewLogoReduced} alt='Scio logo' className='img-fluid'></img>
                            </div>
                            <div className={['mb-1 text-start', errorUser ? styles.text_start_alert : styles.text_start].join(' ')}>
                                <label htmlFor='user' className='form-label fw-bold' id='user-label'>Email</label>
                                <TextField maxLenght={50} value={data.user} error={errorUser || userIncorrect} placeholder='email@example.com' onChange={(value: any) => { this.onChangeUserName(value) }} onKeyDown={this.onKeyDown} />
                                {errorUser && <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This field is required.</span>}
                            </div>
                            <div className={['mb-1 text-start', (errorPassword || userIncorrect) ? styles.text_start_alert : styles.text_start].join(' ')}>
                                <label htmlFor='password' className='form-label fw-bold' id='password-label'>Password</label>
                                <TextField maxLenght={50} type={this.state.showPassword ? "text" : "password"} value={data.password} error={errorPassword || userIncorrect} placeholder='●●●●●●●' onChange={(value: any) => { this.onChangeUserPassword(value) }} onKeyDown={this.onKeyDown} />
                                <div onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }} className={['input-group-append', styles.eyeDivBase].join(' ')}>
                                    <span className={['input-group-text', (errorPassword || userIncorrect) ? styles.eyeError : styles.eye].join(' ')} id="basic-addon2">
                                        {this.state.showPassword &&
                                            <i className={['bi bi-eye', styles.eye_icon].join(' ')} id='eye-icon' style={{ cursor: "pointer" }}></i>
                                        }
                                        {!this.state.showPassword &&
                                            <i className={['bi bi-eye-slash', styles.eye_icon].join(' ')} id='eye-icon' style={{ cursor: "pointer" }}></i>
                                        }
                                    </span>
                                </div>
                                {errorPassword &&
                                    <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This field is required.</span>
                                }
                            </div>
                            {userIncorrect &&
                                <div>
                                    <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>Invalid credentials.</span>
                                </div>
                            }
                            <div className={['mb-3 mt-3', styles.div_btn].join(' ')}>
                                <ButtonForm onClick={() => { this.handleSubmit() }} label='Log in' extraClass={['text-white rounded-pill p-1 fw-bold w-100', styles.btn_submit].join(' ')} />
                            </div>
                            <div className='mb-3'>
                                <p><a onClick={() => { this.setState({ recoverPasswordModal: true }) }} className={['text-dark', styles.forgot_pass].join(' ')}>Forgot your password?</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    messageError: state.authReducer.messageError,
});
const mapDispatchToProps = (dispatch: any) => ({
    login: (data: LoginModel) => dispatch(login(data)),
    clean: () => dispatch(clean()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

