// Axios
import axios from 'axios';

const base_api_url = process.env.REACT_APP_BASE_URL;


export const usersFetch = () => {
  const headers = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    }
  };
  return axios.get<any>(`${base_api_url}users/`, headers);
}

const updateUserStatus = (data: any) => {
  const headers = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    }
  };
  return axios.put(`${base_api_url}users/userStatus`, data, headers);
}

const usersManagersFetch = () => {
  const headers = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    }
  };
  return axios.get(`${base_api_url}users/accountMannager`, headers);
}

const userDetailsModal = (id: string) => {
  const headers = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    }
  };
  const currentId = {id: id}
  return axios.post(`${base_api_url}users/userdetails`, currentId, headers);
}

const updateUserDetailsModal = (data: any) => {
  const headers = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    }
  };
  return axios.put(`${base_api_url}users/userModalUpdate`, data, headers);
}


export const API = {
  usersFetch: usersFetch,
  updateUserStatus: updateUserStatus,
  usersManagersFetch: usersManagersFetch,
  userDetailsModal: userDetailsModal,
  updateUserDetailsModal: updateUserDetailsModal,
}