import React, { Component } from 'react';
import axios from 'axios';

//Icons
import ViewIcon from '../../assets/svg/view.svg';
import DownloadIcon from '../../assets/svg/download.svg';
import { Close } from '@mui/icons-material';

//Styles
import styles from '../../styles/basicComponents/PopUp.module.css';
import tableStyle from '../../styles/basicComponents/table.module.css';

//Internal Components
import ButtonForm from './ButtonForm';
import TextField from './TextField';
import TextFieldArea from './TextFieldArea';

//External components
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

//models
import { MyUserModel } from '../../models/user/user_model';

//Redux
import { connect } from 'react-redux';
import { userAuth } from '../../actions/auth-actions';



interface Props {
  title?: string
  subTitle?: string
  text?: string
  buttonTxt?: string
  onClick: () => void;
  passwordTrigger?: boolean
  userDetailsTrigger?: boolean
  extraButton?: any
  showUserDetails?: () => void;
  data?: any
  myUser?: MyUserModel
  userAuth?: () => void
  filesNamesUploaded?: any[],
  filesUploaded?: any[]
}

interface State {
  isInternal: boolean
}
class PopUp extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const initialState = {
      isInternal: false
    }

    this.state = initialState;
  }

  handleView = (fileName: any) => {
    const base_api_url = process.env.REACT_APP_BASE_URL;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    };

    axios.get(`${base_api_url}uploadFiles/viewUser/${fileName}`, {
      headers,
      responseType: 'arraybuffer'
    })
      .then(response => {
        const contentType = response.headers['content-type'];
        const fileBlob = new Blob([response.data], { type: contentType });
        const fileUrl = URL.createObjectURL(fileBlob);
        window.open(fileUrl);
      })
      .catch(error => {
      });
  };

  handleDownload = (fileName: any) => {
    const base_api_url = process.env.REACT_APP_BASE_URL;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    };

    axios.get(`${base_api_url}uploadFiles/viewUser/${fileName}`, {
      headers,
      responseType: 'arraybuffer'
    })
      .then(response => {
        const contentType = response.headers['content-type'];
        const fileBlob = new Blob([response.data], { type: contentType });
        const fileUrl = URL.createObjectURL(fileBlob);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(fileUrl);
      })
      .catch(error => {
      });
  };


  render() {
    const name = (this.props.data != '' && this.props.data != undefined) ? this.props.data.name : '';
    const role = (this.props.data != '' && this.props.data != undefined) ? this.props.data.role.name : '';;
    const email = (this.props.data != '' && this.props.data != undefined) ? this.props.data.email : '';
    const phone = (this.props.data != '' && this.props.data != undefined) ? this.props.data.phone : '';
    const address = (this.props.data != '' && this.props.data != undefined) ? this.props.data.address : '';
    const paymentMethodName = (this.props.data != '' && this.props.data != undefined) ? this.props.data.payment_method.name : '';
    const paymentMethodDetails = (this.props.data != '' && this.props.data != undefined) ? this.props.data.payment_method.details : '';
    const am = (this.props.data != '' && this.props.data != undefined) ? this.props.data.AM : '';
    const hourlyRate = (this.props.data != '' && this.props.data != undefined) ? this.props.data.hourly_rate.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';
    const monthlyFee = (this.props.data != '' && this.props.data != undefined) ? this.props.data.monthly_fee.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';
    return (this.props.passwordTrigger) ? (
      <>
        <div className={styles.__popupContainer}>
          <div className={styles.__popupInner}>
            <h1 className={styles.__title}>{this.props.title}</h1>
            <h4 className={styles.__subTitle}>{this.props.subTitle}</h4>
            <label dangerouslySetInnerHTML={this.props.text ? { __html: this.props.text } : { __html: "" }} className={styles.__text}></label>
            {this.props.extraButton &&
              this.props.extraButton
            }

            <ButtonForm label={this.props.buttonTxt} colorFill extraClass={this.props.extraButton ? "" : styles.__buttonSize} onClick={() => { this.props.onClick() }} />
          </div>
        </div>
      </>
    )
      : this.props.userDetailsTrigger ? (
        <>
          <div className={styles.modal_container}>
            <div className={styles.__popupInnerUsers} id='modal'>
              <div className={styles.__divExitBtn}>
                <Close className={styles.__exitBtn} onClick={() => { this.props.onClick() }} />
              </div>
              <h1 className={styles.__titleRoleUserDetails}><strong>
                {role == "Manager" && "ACCOUNT MANAGER "}
                {role == "Finance" && "FINANCE "}
                {role == "Human Capital" && "HUMAN CAPITAL "}
                {role == "Contractor" && "CONTRACTOR "}
                DETAILS</strong></h1>
              <div className={[styles.container, (am) ? styles.containerContractor : ''].join(' ')}>
                <strong>GENERAL INFORMATION</strong>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <label htmlFor='Name' className='form-label fw-bold'>Name</label>
                    <TextField maxLenght={50} value={name} disabled={true} onChange={function (value: any): void {
                      throw new Error('Function not implemented.');
                    }} />
                  </Col>
                  <Col lg="6" md="6" sm="6">
                    <label htmlFor='email' className='form-label fw-bold'>Email address</label>
                    <TextField maxLenght={50} value={email} disabled={true} onChange={function (value: any): void {
                      throw new Error('Function not implemented.');
                    }} />
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" sm="6">
                    <label htmlFor='phonenumber' className='form-label fw-bold'>Phone number</label>
                    <TextField maxLenght={18} value={phone} disabled={true} onChange={function (value: any): void {
                      throw new Error('Function not implemented.');
                    }} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label htmlFor='address' className='form-label fw-bold'>Full address</label>
                    <TextFieldArea noShowLenght maxLenght={100} value={address} disabled={true} onChange={function (value: any): void {
                      throw new Error('Function not implemented.')
                    }} />
                  </Col>
                </Row>
                {paymentMethodName && paymentMethodDetails && <>
                  <strong>PAYMENT METHODS</strong>
                  <Row>
                    <Col lg="6" md="6" sm="6">
                      <label htmlFor='typeOfPayment' className='form-label fw-bold'>Type of payment method</label>
                      <TextField maxLenght={20} value={paymentMethodName} disabled={true} onChange={() => { }} />
                    </Col>
                    <Col lg="6" md="6" sm="6">
                      <label htmlFor='peymentDetails' className='form-label fw-bold'>Payment details</label>
                      <TextFieldArea noShowLenght disabled maxLenght={250} value={paymentMethodDetails} onChange={() => { }} />
                    </Col>
                  </Row>
                </>
                }
                {am &&
                  <>
                    <strong>CONTRACT INFORMATION</strong>
                    <div className={styles.contract_info}>
                      <Row>
                        <Col lg="4" md="4" sm="4">
                          <strong>Assigned Account Manager:</strong>
                          <br />
                          {am}
                        </Col>
                        <Col lg="4" md="4" sm="4">
                          <strong>Hourly rate:</strong>
                          <br />
                          {hourlyRate}
                        </Col>
                        <Col lg="4" md="4" sm="4">
                          <strong>Monthly fee:</strong>
                          <br />
                          {monthlyFee}
                        </Col>
                      </Row>
                    </div>
                  </>
                }
                {am &&
                  <>
                    <strong>Attachments</strong>
                    <Col className={styles.attachments}>
                      <Table responsive>
                        <tbody>
                          {this.props.filesNamesUploaded != undefined &&
                            this.props.filesNamesUploaded.length > 0 ?
                            this.props.filesNamesUploaded.map((value: any, index: number) => (
                              <tr key={index}>
                                <td className={tableStyle.tableItem}>
                                  <span id={index.toString()}>{value} </span>
                                </td>
                                {value.split(".")[1] == "pdf" ?
                                  <> <td><img onClick={() => { this.handleDownload(this.props.filesUploaded![index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                    <td><img onClick={() => { this.handleView(this.props.filesUploaded![index]) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td></>
                                  :
                                  <>
                                    <td></td>
                                    <td><img onClick={() => { this.handleDownload(this.props.filesUploaded![index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                  </>
                                }

                              </tr>
                            )) :
                            <>
                              <hr />
                              <Col lg="12" md="12" sm="12" className={styles.__AttachmentsRows}>
                                <h3 className={styles.__noFilesText}>No files</h3>
                              </Col>
                            </>
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </>
                }
              </div>
              <div className={[styles.div_btn].join(' ')}>
                <ButtonForm onClick={() => { this.props.showUserDetails!() }} label={this.props.myUser != undefined ? this.props.myUser.role.name == "Finance" ? "See details" : 'Edit' : 'Edit'} extraClass={['text-white rounded-pill p-1 fw-bold w-100', styles.btn_submit].join(' ')} />
              </div>
            </div >
          </div>
        </>
      )
        : ""
  }
}

const mapStateToProps = (state: any) => ({
  users: state.usersReducer.users,
});

const mapDispatchToProps = (dispatch: any) => ({
  usersFetch: () => dispatch(userAuth()),

});

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
