export enum usersActionTypes {
  USERS_FETCH_BEGIN = "USERS_FETCH_BEGIN",
  USERS_FETCH_ERROR = "USERS_FETCH_ERROR",
  USERS_FETCH_SUCCESS = "USERS_FETCH_SUCCESS",

  USERS_MANAGERS_FETCH_BEGIN = "USERS_MANAGERS_FETCH_BEGIN",
  USERS_MANAGERS_FETCH_ERROR = "USERS_MANAGERS_FETCH_ERROR",
  USERS_MANAGERS_FETCH_SUCCESS = "USERS_MANAGERS_FETCH_SUCCESS",

  USER_STATUS_BEGIN = "USER_STATUS_BEGIN",
  USER_STATUS_ERROR = "USER_STATUS_ERROR",
  USER_STATUS_SUCCESS = "USER_STATUS_SUCCESS",

  USER_DETAILS_STATUS_BEGIN = "USER_DETAILS_STATUS_BEGIN",
  USER_DETAILS_STATUS_ERROR = "USER_DETAILS_STATUS_ERROR",
  USER_DETAILS_STATUS_SUCCESS = "USER_DETAILS_STATUS_SUCCESS",

  UPDATE_USER_DETAILS_STATUS_BEGIN = "UPDATE_USER_DETAILS_STATUS_BEGIN",
  UPDATE_USER_DETAILS_STATUS_ERROR = "UPDATE_USER_DETAILS_STATUS_ERROR",
  UPDATE_USER_DETAILS_STATUS_SUCCESS = "UPDATE_USER_DETAILS_STATUS_SUCCESS",
}