// Axios
import axios from 'axios';

const base_api_url = process.env.REACT_APP_BASE_URL;

const uploadFilesInvoice = (filesData: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.post(`${base_api_url}uploadFiles/uploadInvoice`, filesData, headers);
}

const getFilesInvoice = (invoice: string) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}invoice/files/` + invoice, headers);
}

const uploadFilesUser = (filesData: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.post(`${base_api_url}uploadFiles/uploadUser`, filesData, headers);
}

const getFilesUser = (user: string) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}uploadFiles/filesUser/` + user, headers);
}

export const API = {
    uploadFilesInvoice: uploadFilesInvoice,
    getFilesInvoice: getFilesInvoice,
    uploadFilesUser: uploadFilesUser,
    getFilesUser: getFilesUser,
}