import React, { Component } from 'react';

//Styles
import style from '../../styles/basicComponents/Switch.module.css';

interface Props {
    checked: boolean
    id?: string
    extraClassInput?: any
    extraClassComponet?: any
    onChange: () => void
}

interface State { }

export default class Switch extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const initialState = {};
    }


    render() {
        const { id, extraClassComponet, extraClassInput, checked, onChange } = this.props
        return (
            <>
                <label
                    className={[style.switch, extraClassComponet != undefined ? extraClassComponet : ""].join(' ')}>
                    <input
                        onChange={() => { onChange() }}
                        checked={checked}
                        className={[style.inputSwitch, extraClassInput != undefined ? extraClassInput : ""].join(' ')}
                        type="checkbox" />
                    <span className={[style.slider, style.round].join(' ')}></span>
                </label>
            </>
        );
    }
}
