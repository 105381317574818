// Axios
import axios from 'axios';

//Models
import { sendInitialUserByToken } from "../models/auth/newUserAuthPassword-model";

const base_api_url = process.env.REACT_APP_BASE_URL;

const login = (data: any) => {
    return axios.post(`${base_api_url}auth/login`, data);
}

const userAuth = () => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}auth/myUser`, headers);
}

const updateUserAuth = (data: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.put(`${base_api_url}auth/myUser`, data, headers);
}

const getUserNewPassword = (data: sendInitialUserByToken) => {
    return axios.post(`${base_api_url}auth/userUpdatePasswordToken`, data);
}

const updatePassword = (password: string) => {
    const token = window.location.hash.slice(1)
    const userValue = window.location.search.split('=')
    const updatePasswordValues = { password: password, token: token, user: userValue[1] }
    return axios.put(`${base_api_url}auth/updatePassword`, updatePasswordValues);
}

const requestPasswordChangeEmail = (id: any) => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    const idJson = { id: id }
    return axios.post(`${base_api_url}auth/passwordEmailRequest`, idJson, headers);
}

const forgotPasswordRequest = (email: any, option: number, userRequest: any) => {
    const emailJson = { email: email, option: option, userRequest: userRequest }
    return axios.post(`${base_api_url}auth/forgotPasswordRequest`, emailJson);
}

const emailUserVerified = (token: string) => {
    const Json = { token: token }
    return axios.put(`${base_api_url}auth/updateUserEmail`, Json);
}

const cancelEmailUserVerified = () => {
    const headers = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        }
    };
    return axios.get(`${base_api_url}auth/cancelEmailUpdate`, headers);
}

export const API = {
    login: login,
    userAuth: userAuth,
    updateUserAuth: updateUserAuth,
    updatePassword: updatePassword,
    forgotPasswordRequest: forgotPasswordRequest,
    requestPasswordChangeEmail: requestPasswordChangeEmail,
    getUserNewPassword: getUserNewPassword,
    emailUserVerified: emailUserVerified,
    cancelEmailUserVerified: cancelEmailUserVerified
}