import React, { Component } from 'react';

//Styles
import styles from '../../styles/basicComponents/Loader.module.css';

//Redux
import { connect } from 'react-redux';



interface Props {
  loadingAuthReducer: boolean
  loadingUsersReducer: boolean
  loadingPaymentMethodsReducer: boolean
  loadingNewUserReducer: boolean
  loadingInvoiceReducer: boolean
  loadingActivityLogReducer: boolean
  loadingFilesUpload: boolean
  activeForce?: boolean
  Active?: boolean
}

interface State { }

class ReduxLoader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {};
    this.state = initialState;
  }

  componentDidUpdate(prevProps: Props) {
  }

  render() {
    const { loadingAuthReducer, loadingUsersReducer, loadingPaymentMethodsReducer,
      loadingNewUserReducer, loadingInvoiceReducer, loadingActivityLogReducer, loadingFilesUpload, Active, activeForce } = this.props;
    const loaderView = (
      <>
        {!Active &&
          <div className={styles.__loaderContainer}>
            <div className={styles.dot_spinner}>
              <div className={styles.dot_spinner__dot}></div>
              <div className={styles.dot_spinner__dot}></div>
              <div className={styles.dot_spinner__dot}></div>
              <div className={styles.dot_spinner__dot}></div>
              <div className={styles.dot_spinner__dot}></div>
              <div className={styles.dot_spinner__dot}></div>
              <div className={styles.dot_spinner__dot}></div>
              <div className={styles.dot_spinner__dot}></div>
            </div>
          </div>
        }

      </>
    )

    return (
      loadingAuthReducer ? (loaderView) : loadingUsersReducer ? (loaderView) :
        loadingPaymentMethodsReducer ? (loaderView) : loadingNewUserReducer ? (loaderView) :
          loadingInvoiceReducer ? (loaderView) : loadingActivityLogReducer ? (loaderView) : activeForce ? (loaderView) : null
    )
  }
}

const mapStateToProps = (state: any) => ({
  loadingAuthReducer: state.authReducer.loading,
  loadingUsersReducer: state.usersReducer.loading,
  loadingPaymentMethodsReducer: state.paymentMethodsReducer.loadingPayments,
  loadingNewUserReducer: state.newUserReducer.loading,
  loadingInvoiceReducer: state.invoiceReducer.loading,
  loadingActivityLogReducer: state.activityLogReducer.loading,
  loadingFilesUpload: state.filesBucketReducer.loadingUpload,
});

export default connect(mapStateToProps)(ReduxLoader);