export enum invoiceActionTypes {
    INVOICE_FETCH_BEGIN = "INVOICE_FETCH_BEGIN",
    INVOICE_FETCH_ERROR = "INVOICE_FETCH_ERROR",
    INVOICE_FETCH_SUCCESS = "INVOICE_FETCH_SUCCESS",

    ACTIVITY_INVOICE_FETCH_BEGIN = "ACTIVITY_INVOICE_FETCH_BEGIN",
    ACTIVITY_INVOICE_FETCH_ERROR = "ACTIVITY_INVOICE_FETCH_ERROR",
    ACTIVITY_INVOICE_FETCH_SUCCESS = "ACTIVITY_INVOICE_FETCH_SUCCESS",

    INVOICE_LINK_BEGIN = "INVOICE_LINK_BEGIN",
    INVOICE_LINK_ERROR = "INVOICE_LINK_ERROR",
    INVOICE_LINK_SUCCESS = "INVOICE_LINK_SUCCESS",

    NEW_INVOICE_BEGIN = "NEW_INVOICE_BEGIN",
    NEW_INVOICE_ERROR = "NEW_INVOICE_ERROR",
    NEW_INVOICE_SUCCESS = "NEW_INVOICE_SUCCESS",

    UPDATE_INVOICE_BEGIN = "UPDATE_INVOICE_BEGIN",
    UPDATE_INVOICE_ERROR = "UPDATE_INVOICE_ERROR",
    UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS",

    STATUS_SUBMITTED_BEGIN = "STATUS_SUBMITTED_BEGIN",
    STATUS_SUBMITTED_ERROR = "STATUS_SUBMITTED_ERROR",
    STATUS_SUBMITTED_SUCCESS = "STATUS_SUBMITTED_SUCCESS",

    STATUS_APPROVED_AM_BEGIN = "STATUS_APPROVED_AM_BEGIN",
    STATUS_APPROVED_AM_ERROR = "STATUS_APPROVED_AM_ERROR",
    STATUS_APPROVED_AM_SUCCESS = "STATUS_APPROVED_AM_SUCCESS",

    STATUS_APPROVED_AM_GROUP_BEGIN = "STATUS_APPROVED_AM_GROUP_BEGIN",
    STATUS_APPROVED_AM_GROUP_ERROR = "STATUS_APPROVED_AM_GROUP_ERROR",
    STATUS_APPROVED_AM_GROUP_SUCCESS = "STATUS_APPROVED_AM_GROUP_SUCCESS",

    STATUS_APPROVED_FINANCE_BEGIN = "STATUS_APPROVED_FINANCE_BEGIN",
    STATUS_APPROVED_FINANCE_ERROR = "STATUS_APPROVED_FINANCE_ERROR",
    STATUS_APPROVED_FINANCE_SUCCESS = "STATUS_APPROVED_FINANCE_SUCCESS",

    STATUS_APPROVED_FINANCE_GROUP_BEGIN = "STATUS_APPROVED_FINANCE_GROUP_BEGIN",
    STATUS_APPROVED_FINANCE_GROUP_ERROR = "STATUS_APPROVED_FINANCE_GROUP_ERROR",
    STATUS_APPROVED_FINANCE_GROUP_SUCCESS = "STATUS_APPROVED_FINANCE_GROUP_SUCCESS",

    STATUS_PAID_FINANCE_BEGIN = "STATUS_PAID_FINANCE_BEGIN",
    STATUS_PAID_FINANCE_ERROR = "STATUS_PAID_FINANCE_ERROR",
    STATUS_PAID_FINANCE_SUCCESS = "STATUS_PAID_FINANCE_SUCCESS",

    STATUS_REJECTED_AM_BEGIN = "STATUS_REJECTED_AM_BEGIN",
    STATUS_REJECTED_AM_ERROR = "STATUS_REJECTED_AM_ERROR",
    STATUS_REJECTED_AM_SUCCESS = "STATUS_REJECTED_AM_SUCCESS",

    STATUS_REJECTED_FINANCE_BEGIN = "STATUS_REJECTED_FINANCE_BEGIN",
    STATUS_REJECTED_FINANCE_ERROR = "STATUS_REJECTED_FINANCE_ERROR",
    STATUS_REJECTED_FINANCE_SUCCESS = "STATUS_REJECTED_FINANCE_SUCCESS",

    ARCHIVE_INVOICE_BEGIN = "ARCHIVE_INVOICE_BEGIN",
    ARCHIVE_INVOICE_ERROR = "ARCHIVE_INVOICE_ERROR",
    ARCHIVE_INVOICE_SUCCESS = "ARCHIVE_INVOICE_SUCCESS",

    LAST_INVOICE_BEGIN = "LAST_INVOICE_BEGIN",
    LAST_INVOICE_ERROR = "LAST_INVOICE_ERROR",
    LAST_INVOICE_SUCCESS = "LAST_INVOICE_SUCCESS",

    UNARCHIVE_INVOICE_BEGIN = "UNARCHIVE_INVOICE_BEGIN",
    UNARCHIVE_INVOICE_ERROR = "UNARCHIVE_INVOICE_ERROR",
    UNARCHIVE_INVOICE_SUCCESS = "UNARCHIVE_INVOICE_SUCCESS",

    ARCHIVE_INVOICE_GROUP_BEGIN = "ARCHIVE_INVOICE_GROUP_BEGIN",
    ARCHIVE_INVOICE_GROUP_ERROR = "ARCHIVE_INVOICE_GROUP_ERROR",
    ARCHIVE_INVOICE_GROUP_SUCCESS = "ARCHIVE_INVOICE_GROUP_SUCCESS",

    STATUS_PAID_GROUP_FINANCE_BEGIN = "STATUS_PAID_GROUP_FINANCE_BEGIN",
    STATUS_PAID_GROUP_FINANCE_ERROR = "STATUS_PAID_GROUP_FINANCE_ERROR",
    STATUS_PAID_GROUP_FINANCE_SUCCESS = "STATUS_PAID_GROUP_FINANCE_SUCCESS",

    UNARCHIVE_INVOICE_GROUP_BEGIN = "UNARCHIVE_INVOICE_GROUP_BEGIN",
    UNARCHIVE_INVOICE_GROUP_ERROR = "UNARCHIVE_INVOICE_GROUP_ERROR",
    UNARCHIVE_INVOICE_GROUP_SUCCESS = "UNARCHIVE_INVOICE_GROUP_SUCCESS",

    REVERT_INVOICE_BEGIN = "REVERT_INVOICE_BEGIN",
    REVERT_INVOICE_ERROR = "REVERT_INVOICE_ERROR",
    REVERT_INVOICE_SUCCESS = "REVERT_INVOICE_SUCCESS",

}