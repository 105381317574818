import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

//Icons
import ViewIcon from '../../assets/svg/view.svg';
import CancelIcon from '../../assets/svg/cancel.svg';
import DownloadIcon from '../../assets/svg/download.svg';

//components internal
import TextField from '../../components/basicComponents/TextField';
import TextFieldArea from '../../components/basicComponents/TextFieldArea';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import ToastAlert from '../../components/basicComponents/ToastAlert';
import PopUp from '../../components/basicComponents/PopUp';
import UploadFile from '../../components/basicComponents/UploadFile';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';

//components external
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';


//styles
import styles from '../../styles/screens/myProfile.module.css';
import stylesPop from '../../styles/screens/updatePassword.module.css';
import titleStyle from '../../styles/titles.module.css';
import tableStyle from '../../styles/basicComponents/table.module.css';

//models
import { MyUserModel } from '../../models/user/user_model';
import { NewInvoice } from '../../models/invoice/invoice_model'
import { ActivityInvoice } from '../../models/invoice/activityInvoice_model';
import { FilesInvoices } from '../../models/invoice/filesInvoice_model';

//redux
import { connect } from 'react-redux';
import { statusApprovedAM, statusApprovedFinance, statusPaidFinance, statusRejectedAM, statusRejectedFinance, updateInvoice, invoiceActivityFetch } from '../../actions/invoices-actions';
import { fetchBucketInvoiceFiles, filesBucketInvoiceUpload } from '../../actions/filesBucket-actions';




interface Props {
    //inherited
    id: string,
    folio: string,
    status: string,
    contractor: string,
    monthly_fee: string,
    hourly_rate: string,
    AM: string,
    payment_method: string,
    payment_details: string,
    proyect: any,
    billable_hours: any,
    total: any,
    period: any,
    periodStart: any,
    periodEnd: any,
    invoice_date: any,
    note: string,
    created: any,
    myUser: MyUserModel,
    back: () => void,

    //Redux and Reducers
    activityInvoice: Array<ActivityInvoice>,
    invoiceActivityFetch: (id: string) => void,
    updateInvoice: (data: NewInvoice, id: string) => void,
    statusApprovedAM: (data: any) => void,
    statusApprovedFinance: (data: any) => void,
    statusPaidFinance: (data: any) => void,
    statusRejectedAM: (data: any) => void,
    statusRejectedFinance: (data: any) => void,
    filesInvoice: Array<FilesInvoices>,
    fetchBucketInvoiceFiles: (id: string) => void,
    loadingGetSuccess: boolean,
    updateInvoicesStatus: boolean,
    create_invoice: string,
    filesBucketInvoiceUpload: (files: any) => void


}
interface State {

    //Errors
    notesError: boolean
    folioError: boolean
    totalHoursError: boolean
    totalError: boolean
    errorFile: boolean
    projectError: boolean
    folioRepeat: boolean
    filesErrornoPDF: boolean

    //allow edit
    allowEditValues: boolean
    finishEdit: boolean

    //files
    placeholderInputUpload: string
    fileInputUpload: any
    filesNames: any[],
    files: any[],
    filesNamesUploaded: any[],
    filesUploaded: any[]

    //Values
    folio: string,
    start_date: any,
    end_date: any,
    total_hours: string,
    total_hours_format: any
    total: string,
    totalFormat: string
    project: string,
    notes: string
    periodFormat: any
    saveButtonActive: boolean
    invoice_date: any

    //Toast and modal
    saveSuccess: boolean
    saveError: boolean
    modalConfirm: boolean
    modalReload: boolean
    activeForceLoader: boolean
}

class EditViewInvoiceDetails extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            //Errors
            notesError: false,
            folioError: false,
            totalHoursError: false,
            totalError: false,
            errorFile: false,
            projectError: false,
            folioRepeat: false,
            filesErrornoPDF: false,

            //allow edit
            allowEditValues: (this.props.status == "Rejected - FIN" || this.props.status == "Rejected - AM") ? true : false,
            finishEdit: false,

            //files
            placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)',
            fileInputUpload: [],
            filesNames: [],
            files: [],
            filesNamesUploaded: [],
            filesUploaded: [],


            //Values
            folio: this.props.folio,
            start_date: this.props.periodStart.substring(0, 10),
            end_date: this.props.periodEnd.substring(0, 10),
            total_hours: this.props.billable_hours,
            total_hours_format: Number.parseFloat(this.props.billable_hours).toFixed(2),
            total: this.props.total,
            totalFormat: this.props.total,
            project: this.props.proyect,
            periodFormat: this.props.period,
            notes: (this.props.status == "Rejected - FIN" || this.props.status == "Rejected - AM") ? "" : this.props.note,
            saveButtonActive: false,
            invoice_date: this.props.invoice_date.substring(0, 10),

            //Toast and modal
            saveSuccess: false,
            saveError: false,
            modalReload: false,
            modalConfirm: false,
            activeForceLoader: false,
        };
        this.state = initialState;
    }





    //////////////////BASIC FUNCTIONS////////////////////////////////////////////
    componentDidMount = async () => {
        this.props.invoiceActivityFetch(this.props.id)
        this.props.fetchBucketInvoiceFiles(this.props.id)
    }

    async componentDidUpdate(prevProps: Props) {

        if (this.props.folio != prevProps.folio) {
            this.setState({
                allowEditValues: (this.props.status == "Rejected - FIN" || this.props.status == "Rejected - AM") ? true : false,
                folio: this.props.folio,
                start_date: this.props.periodStart.substring(0, 10),
                end_date: this.props.periodEnd.substring(0, 10),
                total_hours: this.props.billable_hours,
                total: this.props.total,
                project: this.props.proyect,
                notes: (this.props.status == "Rejected - FIN" || this.props.status == "Rejected - AM") ? "" : this.props.note,
            })
        }
        if (prevProps.id != this.props.id) {
            this.props.invoiceActivityFetch(this.props.id)
        }
        if (this.props.loadingGetSuccess != prevProps.loadingGetSuccess &&
            prevProps.loadingGetSuccess && !this.props.loadingGetSuccess
        ) {
            this.updateFilesUploaded()
        }
        const { create_invoice } = this.props
        if (create_invoice != prevProps.create_invoice &&
            create_invoice == "invoice updateded successfully" &&
            prevProps.create_invoice == ""
        ) {
            this.setState({ activeForceLoader: true })
            await this.uploadNewFiles()
            await this.updateFiles()
            const windowScroll = document.getElementById("windowScrollAnimation");
            windowScroll!.scrollTop = 0;
            this.setState({ saveSuccess: true, activeForceLoader: false, finishEdit: true, })
        }
        if (this.props.updateInvoicesStatus != prevProps.updateInvoicesStatus &&
            !prevProps.updateInvoicesStatus && this.props.updateInvoicesStatus
        ) {
            this.setState({ modalReload: true })
        }
        if (create_invoice != prevProps.create_invoice &&
            create_invoice == "folio repeat" &&
            prevProps.create_invoice == ""
        ) {
            this.setState({ folioRepeat: true })
        }
    }






    //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
    uploadNewFiles = async () => {
        const { files } = this.state
        this.setState({ activeForceLoader: true })
        for (var i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append('file', files[i])
            formData.append('invoice', this.props.id)
            this.props.filesBucketInvoiceUpload(formData)
        }
    }

    updateFiles = async () => {
        if (this.props.filesInvoice != undefined && this.props.filesInvoice != null) {
            const filesInvoicesV = Object.values(this.props.filesInvoice);
            if (filesInvoicesV.length > 0) {
                filesInvoicesV.map((file: any) => {
                    if (!this.state.filesUploaded.includes(file.data)) {
                        const headers = {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
                            }
                        };
                        const base_api_url = process.env.REACT_APP_BASE_URL;
                        axios.delete(`${base_api_url}uploadFiles/deleteFile/` + file.id, headers);
                    }
                })
            }
        }
    }

    updateFilesUploaded = () => {
        let filesInvoicesV: Array<any> = [];
        if (this.props.filesInvoice != null && this.props.filesInvoice != undefined) {
            filesInvoicesV = Object.values(this.props.filesInvoice);
            let filesValuesData: any[] = []
            let filesValuesName: any[] = []
            if (Array.isArray(filesInvoicesV)) {
                filesInvoicesV.map((file: any) => {
                    filesValuesName.push(file.name)
                    filesValuesData.push(file.data)
                })

                this.setState({ filesNamesUploaded: filesValuesName, filesUploaded: filesValuesData })
            }
        }
    }






    //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
    onclickUpload = (fileV: any, fileNameV: any) => {
        if (fileV != "") {
            const file: any[] = this.state.files
            const filesName: any[] = this.state.filesNames
            file.unshift(fileV)
            filesName.unshift(fileNameV)
            this.setState({ files: file, filesNames: filesName, fileInputUpload: '', saveButtonActive: true, placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)' })
            const inputElement = document.getElementById('fileDropdownGeneral') as HTMLInputElement
            inputElement.value = ""
        }
    }

    onChangeUpload = (file: any) => {
        if (file.target.files[0] != undefined) {
            if (file.target.files[0].size < 10485750 &&
                file.target.files[0].name.split(".").length == 2
            ) {
                const extencion = file.target.files[0].name.split(".")
                if (extencion[1] == "pdf" || extencion[1] == "doc" || extencion[1] == "docx" || extencion[1] == "xlsx" || extencion[1] == "csv") {
                    this.onclickUpload(file.target.files[0], file.target.files[0].name)
                }
                else {
                    this.setState({ fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)', errorFile: true })
                }
            } else {
                this.setState({ fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)', errorFile: true })
            }

        }
    }

    onChangeNote = (value: any) => {
        if (this.state.allowEditValues && !this.state.finishEdit) {
            this.setState({ notes: value, notesError: false })
            setTimeout(() => this.activeDisableSaveButton(), 40)
        }
    }

    onChangeFolio = (value: any) => {
        if (this.state.allowEditValues && !this.state.finishEdit) {
            this.setState({ folio: value, folioError: false, folioRepeat: false })
            setTimeout(() => this.activeDisableSaveButton(), 40)
        }
    }
    onChangeInvoiceDate = (value: any) => {
        if (this.state.allowEditValues && !this.state.finishEdit && value != "") {
            this.setState({ invoice_date: value })
            setTimeout(() => this.activeDisableSaveButton(), 40)
        }
    }
    onChangeStartDate = (value: any) => {
        if (this.state.allowEditValues && !this.state.finishEdit && value != "") {
            if (value < this.state.end_date) {
                this.setState({ start_date: value })
                this.periodFormat(value, this.state.end_date)
                setTimeout(() => this.activeDisableSaveButton(), 40)
            }

        }
    }
    onChangeEndDate = (value: any) => {
        if (this.state.allowEditValues && !this.state.finishEdit && value != "") {
            if (this.state.start_date < value) {
                this.setState({ end_date: value })
                this.periodFormat(this.state.start_date, value)
                setTimeout(() => this.activeDisableSaveButton(), 40)
            }
        }
    }
    onChangeTotalHours = (value: any) => {
        if (this.state.allowEditValues && !this.state.finishEdit) {
            this.setState({ total_hours: value, totalHoursError: false, total_hours_format: Number.parseFloat(value).toFixed(2) })
            setTimeout(() => this.activeDisableSaveButton(), 40)
        }
    }
    onChangeTotal = (value: any) => {
        const formatValue = value.replace("$", "").replace(/,/g, "")
        if (this.state.allowEditValues && !this.state.finishEdit) {
            this.setState({ totalFormat: value == "" ? "" : this.currencyFormatter("USD", formatValue), total: value, totalError: false })
            setTimeout(() => this.activeDisableSaveButton(), 40)
        }
    }
    onChangeProject = (value: any) => {
        if (this.state.allowEditValues && !this.state.finishEdit) {
            this.setState({ project: value, projectError: false })
            setTimeout(() => this.activeDisableSaveButton(), 40)
        }
    }

    activeDisableSaveButton = () => {
        const { folio, start_date, end_date, total_hours, total, project } = this.state;
        if (folio == this.props.folio, start_date == this.props.periodStart, end_date == this.props.periodEnd &&
            total_hours == this.props.billable_hours && total == this.props.total && project == this.props.proyect) {
            this.setState({ saveButtonActive: false })
        } else {
            this.setState({ saveButtonActive: true })
        }
    }








    //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////
    onCancel = () => {
        if (this.state.finishEdit) {
            window.location.reload()
        } else {
            this.props.back()
        }
    }
    onApproved = () => {
        if (this.props.myUser.role.name == "Manager") {
            this.props.statusApprovedAM({ id: this.props.id })

        } else if (this.props.myUser.role.name == "Finance") {
            this.props.statusApprovedFinance({ id: this.props.id })
        } else {
            alert('you cant do that')
        } window.location.reload()
    }

    onRejected = () => {
        if (this.props.myUser.role.name == "Manager") {
            this.props.statusRejectedAM({ id: this.props.id })
        } else if (this.props.myUser.role.name == "Finance") {
            this.props.statusRejectedFinance({ id: this.props.id })
        } else {
            alert('you cant do that')
        } window.location.reload()
    }

    onPaid = () => {
        if (this.props.myUser.role.name == "Finance" && this.props.status == "Approved - FIN") {
            this.props.statusPaidFinance({ id: this.props.id })
        } else {
            alert("You cannot change the status")
        } window.location.reload()
    }

    onSubmit = () => {
        const { folio, total_hours, total, project, filesNames, filesNamesUploaded } = this.state
        let folioError = false, totalError = false, projectError = false, totalHoursError = false, filesErrornoPDF = true;
        if (folio == "") {
            folioError = true
        }
        if (total_hours == "") {
            totalHoursError = true
        }
        if (total == "") {
            totalError = true
        }
        if (project == "") {
            projectError = true
        }
        for (var i = 0; i < filesNames.length; i++) {
            const nameComplete = filesNames[i].split(".")
            const lastValue = nameComplete.length
            if (nameComplete[lastValue - 1] == "pdf") {
                filesErrornoPDF = false
            }
        }
        for (var i = 0; i < filesNamesUploaded.length; i++) {
            const nameComplete = filesNamesUploaded[i].split(".")
            const lastValue = nameComplete.length
            if (nameComplete[lastValue - 1] == "pdf") {
                filesErrornoPDF = false
            }
        }
        if (folioError || totalError || projectError || totalHoursError || filesErrornoPDF) {
            this.setState({ projectError, totalError, totalHoursError, folioError, saveError: true, filesErrornoPDF: filesErrornoPDF })
            return
        }
        this.setState({ modalConfirm: true })
    }

    handleView = (fileName: any) => {
        const base_api_url = process.env.REACT_APP_BASE_URL;
        const headers = {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        };

        axios.get(`${base_api_url}uploadFiles/viewInvoice/${fileName}`, {
            headers,
            responseType: 'arraybuffer'
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const fileBlob = new Blob([response.data], { type: contentType });
                const fileUrl = URL.createObjectURL(fileBlob);
                window.open(fileUrl);
            })
            .catch(error => {
            });
    };

    handleDownload = (fileName: any) => {
        const base_api_url = process.env.REACT_APP_BASE_URL;
        const headers = {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        };

        axios.get(`${base_api_url}uploadFiles/viewInvoice/${fileName}`, {
            headers,
            responseType: 'arraybuffer'
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const fileBlob = new Blob([response.data], { type: contentType });
                const fileUrl = URL.createObjectURL(fileBlob);

                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = fileName;
                link.click();

                URL.revokeObjectURL(fileUrl);
            })
            .catch(error => {
            });
    };

    handleDelete = (value: any) => {
        const files = this.state.filesUploaded.slice();
        const filesNames = this.state.filesNamesUploaded.slice();

        if (value !== -1) {
            files.splice(value, 1);
            filesNames.splice(value, 1);
            this.setState({ filesUploaded: files, filesNamesUploaded: filesNames, saveButtonActive: true });
        }
    };

    deleteFile = (value: any) => {
        const files = this.state.files.slice();
        const filesNames = this.state.filesNames.slice();

        if (value !== -1) {
            files.splice(value, 1);
            filesNames.splice(value, 1);
            this.setState({ files, filesNames, saveButtonActive: true });
        }
    }

    downLoadFile = (value: any, name: any) => {
        const { files } = this.state
        var urlArchivo = URL.createObjectURL(files[value]);

        var linkDescarga = document.createElement('a');
        linkDescarga.href = urlArchivo;
        linkDescarga.download = name;
        linkDescarga.click();
        URL.revokeObjectURL(urlArchivo);
    }

    previewFile = (value: any, name: any) => {
        const { files } = this.state
        var urlArchivo = URL.createObjectURL(files[value]);
        window.open(urlArchivo, '_blank');
        URL.revokeObjectURL(urlArchivo);
    }









    ///////////////////FORMATS FUNCTIONS//////////////////////////////////////
    periodFormat = (startDate: any, endDate: any) => {
        const startFormat = startDate.substring(5, 7) + "/" + startDate.substring(8, 10) + "/" + startDate.substring(0, 4);
        const endFormat = endDate.substring(5, 7) + "/" + endDate.substring(8, 10) + "/" + endDate.substring(0, 4);
        this.setState({ periodFormat: startFormat + " - " + endFormat })
    }

    renderStatusName = (valueStatus: any) => {
        if (valueStatus == 0) {
            return "Submitted"
        }
        if (valueStatus == 1) {
            return "Approved - AM"
        }
        if (valueStatus == 2) {
            return "Approved - FIN"
        }
        if (valueStatus == 3) {
            return "Paid"
        }
        if (valueStatus == 4) {
            return "Rejected - AM"
        }
        if (valueStatus == 5) {
            return "Rejected - FIN"
        }
    }

    currencyFormatter = (currency: any, value: any) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            minimumFractionDigits: 2,
            currency
        })
        return formatter.format(value)
    }






    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const currentDate = new Date();
        const minDate = new Date(currentDate.getFullYear() - 2, 0, 1).toISOString().split('T')[0];
        const maxDate = new Date(currentDate.getFullYear() + 2, 11, 31).toISOString().split('T')[0];
        const { folioError, folioRepeat, totalHoursError, totalError, notesError, projectError,
            folio, end_date, start_date, total, total_hours, project, notes, periodFormat, invoice_date,
            allowEditValues, saveSuccess, saveError, modalConfirm, finishEdit, saveButtonActive, activeForceLoader,
            filesNames, filesErrornoPDF, errorFile, totalFormat, total_hours_format, placeholderInputUpload,
            filesNamesUploaded, filesUploaded, modalReload } = this.state;
        const { activityInvoice, status, filesInvoice, myUser, contractor } = this.props
        const valuesTable = Object.values(activityInvoice);
        let invoiceActivityValues: any[] = []
        if (Array.isArray(valuesTable)) {
            valuesTable.map((activity: ActivityInvoice) => {
                invoiceActivityValues.push({
                    user: activity.user,
                    note: activity.note,
                    created_at: activity.created_at
                })
            })
        }
        const tableActivity = invoiceActivityValues
        return (
            <Container className={styles.__MainContent}>
                <div className={stylesPop.__centerAll}>
                    <PopUp passwordTrigger={modalReload} title=""
                        subTitle="STATUS ERROR"
                        text={`The status of this invoice has changed, your edit can't be applied`}
                        buttonTxt="Accept"
                        onClick={() => {
                            window.location.href = "./invoices?invoice=" + this.props.id
                        }} />
                </div>
                <ToastAlert
                    showToast={saveSuccess}
                    timeActive={3000}
                    disableFunction={() => { this.setState({ saveSuccess: false }) }}
                />
                <ToastAlert
                    error
                    label="Required data is missing"
                    showToast={saveError}
                    timeActive={3000}
                    disableFunction={() => { this.setState({ saveError: false }) }}
                />
                <ReduxLoader activeForce={activeForceLoader} />
                <Row className={titleStyle.__MainRowTitle}>
                    <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
                        <label onClick={() => { window.location.href = "/invoices" }} className={titleStyle.__titlePart1}>INVOICES</label>
                        <label className={titleStyle.__titlePart2}>{">"}</label>
                        <label className={titleStyle.__titlePart3}>REVIEW INVOICE</label>
                        <label className={titleStyle.__titlePart4}>{">"}</label>
                        <label className={titleStyle.__titlePart5}>{this.props.status}</label>
                    </Col>
                </Row>
                <div className={stylesPop.__centerAll}>
                    <PopUp passwordTrigger={modalConfirm} title="CONFIRMATION"
                        subTitle=""
                        text={`Once submitted, your invoice cannot be edited until reviewed and rejected. <br> Do you want to proceed?<br>
                        <div style="margin-left: .7em ;width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Invoice no.:</strong> `+ folio + `</div>
                        <div style="width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Invoice date:</strong> `+ invoice_date + `</div>
                        <div style="margin-left: .7em ;width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Period start:</strong> `+ start_date + `</div>
                        <div style="width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Period end:</strong> `+ end_date + `</div>
                        <div style="margin-left: .7em ;width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Billable hours:</strong> `+ total_hours + `</div>
                        <div style="width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Total amount:</strong> `+ total + `</div>
                        <div style="margin-left: .7em ;width: calc(99% - 0.7em); display: inline-block; text-align: left;"><strong>Assigned project:</strong> `+ project + `</div>
                        <div style="margin-left: .7em ;width: calc(99% - 0.7em); display: inline-block; text-align: left;"><strong>Notes:</strong> `+ notes + `</div>
                        `}
                        buttonTxt="YES"
                        extraButton={<ButtonForm label="CANCEL" colorFill extraClass={styles.__buttonCancel} onClick={() => { this.setState({ modalConfirm: false }) }} />}
                        onClick={() => {
                            const data = {
                                folio: folio,
                                start_date: start_date,
                                end_date: end_date,
                                total_hours: total_hours,
                                total: total.replace("$", "").replace(/,/g, ""),
                                proyect: project,
                                note: notes == "" ? " " : notes,
                                invoice_date: invoice_date
                            }
                            this.props.updateInvoice(data, this.props.id)
                            this.setState({ modalConfirm: false })
                        }} />
                </div>
                <Row>
                    <Col lg="10" md="10" sm="10"><h4 className={styles.__MainTitle}>Invoice no. {this.props.folio} </h4></Col>
                    <Col lg="2" md="2" sm="2"><div className={[
                        (status == "Submitted" || finishEdit) ? styles.__subbmitedTag :
                            status == "Approved - AM" ? styles.__approvedAMTag :
                                status == "Approved - FIN" ? styles.__approvedFinanceTag :
                                    status == "Paid" ? styles.__paidTag :
                                        status == "Rejected - AM" ? styles.__rejectedTag :
                                            status == "Rejected - FIN" ? styles.__rejectedTag :
                                                status == "Archived" ? styles.__archivedTag : ""
                    ].join('')}>{finishEdit ? "Submitted" : status} </div></Col>
                </Row>
                <Row>
                    <Row className={styles.__contractorInfo}>
                        <Row>
                            <Col lg="4" md="4" sm="4" >
                                <label className={styles.__boldText}>Contractor: </label> {contractor}
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Monthly fee: </label> {this.currencyFormatter("USD", myUser.monthly_fee)}
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Hourly rate: </label> {this.currencyFormatter("USD", myUser.hourly_rate)}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="8" md="8" sm="8" >
                                <label className={styles.__boldText}>Assigned Account Manager: </label> {this.props.AM}
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Payment method: </label> {this.props.payment_method}
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col lg="12" md="12" sm="12"><h5 className={styles.__subTitle}>Invoice details</h5></Col>
                    </Row>
                </Row>
                <Row>
                    <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Invoice no.</label>
                        <TextField maxLenght={99} error={folioError || folioRepeat} disabled={!allowEditValues || finishEdit} value={folio} onChange={(value: any) => { this.onChangeFolio(value.substring(0, 100)) }} />
                        {folioError &&
                            <span className={styles.err} >This field is required</span>
                        }
                        {folioRepeat &&
                            <span className={styles.err} >This folio is already registered</span>
                        }
                    </Col>
                    <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Invoice date</label>
                        <TextField maxLenght={100} type="date" maxValue={!allowEditValues || finishEdit ? "" : maxDate} minValue={!allowEditValues || finishEdit ? "" : minDate} disabled={!allowEditValues || finishEdit} value={invoice_date} onChange={(value: any) => { this.onChangeInvoiceDate(value) }} />
                    </Col>
                    {(!allowEditValues || finishEdit) ?
                        <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
                            <label className={styles.__boldText}>Period</label>
                            <TextField maxLenght={100} disabled value={periodFormat} onChange={(value: any) => { }} />
                        </Col> :
                        <>
                            <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                                <label className={styles.__boldText}>Period start</label>
                                <TextField maxLenght={100} type="date" minValue={minDate} maxValue={end_date} value={start_date} onChange={(value: any) => { this.onChangeStartDate(value) }} />
                            </Col>
                            <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                                <label className={styles.__boldText}>Period end</label>
                                <TextField maxLenght={100} type="date" maxValue={maxDate} minValue={start_date} value={end_date} onChange={(value: any) => { this.onChangeEndDate(value) }} />
                            </Col>
                        </>
                    }

                </Row>
                <Row>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Billable hours</label>
                        <TextField onlyNumbers maxLenght={50}
                            onBlur={() => {
                                this.setState({ total_hours: total_hours_format })
                            }} error={totalHoursError} disabled={!allowEditValues || finishEdit} value={(total_hours)} onChange={(value: any) => { this.onChangeTotalHours(value) }} />
                        {totalHoursError &&
                            <span className={styles.err} >This field is required</span>
                        }
                    </Col>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Total amount</label>
                        <TextField maxLenght={50} onBlur={() => { this.setState({ total: totalFormat }) }} onlyNumbers error={totalError} disabled={!allowEditValues || finishEdit} value={total} onChange={(value: any) => { this.onChangeTotal(value) }} />
                        {totalError &&
                            <span className={styles.err} >This field is required</span>
                        }
                    </Col>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Project</label>
                        <TextField maxLenght={35} error={projectError} disabled={!allowEditValues || finishEdit} value={project} onChange={(value: any) => { this.onChangeProject(value) }} />
                        {projectError &&
                            <span className={styles.err} >This field is required</span>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <label className={styles.__boldText}>Attachments</label>
                        {(myUser.role.name == "Contractor" && !finishEdit && (status == "Rejected - AM" || status == "Rejected - FIN")) ?
                            <>
                                <UploadFile needPDF onClick={() => { this.setState({ errorFile: false, filesErrornoPDF: false }) }} error={errorFile || filesErrornoPDF} disabled={!allowEditValues || finishEdit}
                                    placeholder={placeholderInputUpload} onChange={(value: any) => { this.onChangeUpload(value); }} />
                                <Col>
                                    <Table responsive>
                                        <tbody>
                                            {filesNames.length > 0 && filesNames.map((value: any, index: number) => (

                                                <tr key={"newFiles" + index}>
                                                    <td className={tableStyle.tableItem}>
                                                        <span key={"newFilesSpan" + index} id={index.toString()}>{value}</span>
                                                    </td>
                                                    {value.split(".")[1] == "pdf" ?
                                                        <>
                                                            <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                            <td><img onClick={() => { this.previewFile(index, value) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td>
                                                            <td><img onClick={() => { this.deleteFile(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                                                        </>
                                                        :
                                                        <>
                                                            <td></td>
                                                            <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                            <td><img onClick={() => { this.deleteFile(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                                                        </>
                                                    }
                                                </tr>
                                            ))}
                                            {(filesInvoice != undefined && filesInvoice != null) &&
                                                filesNamesUploaded.length > 0 &&
                                                filesNamesUploaded.map((value: any, index: number) => (

                                                    <tr key={"uploadedFiles" + index} id={"uploadedFiles" + index}>
                                                        <td className={tableStyle.tableItem}>
                                                            <span key={"uploadedFilesSpan" + index} id={"uploadedFilesSpan" + index}>{value}</span>
                                                        </td>
                                                        {value.split(".")[1] == "pdf" ?
                                                            <>
                                                                <td><img onClick={() => { this.handleDownload(filesUploaded[index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                                <td><img onClick={() => { this.handleView(filesUploaded[index]) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td>
                                                                <td><img onClick={() => { this.handleDelete(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                                                            </>
                                                            :
                                                            <>
                                                                <td></td>
                                                                <td><img onClick={() => { this.handleDownload(this.state.filesUploaded[index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                                <td><img onClick={() => { this.handleDelete(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                                                            </>
                                                        }
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </>

                            :
                            <>
                                <Col>
                                    <Table responsive>
                                        <tbody>
                                            {filesNames.length > 0 && filesNames.map((value: any, index: number) => (
                                                <tr key={"newFiles" + index}>
                                                    <td className={tableStyle.tableItem}>
                                                        <span key={"newFilesSpan" + index} id={index.toString()}>{value}</span>
                                                    </td>
                                                    {value.split(".")[1] == "pdf" ?
                                                        <>
                                                            <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                            <td><img onClick={() => { this.previewFile(index, value) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td>
                                                        </>
                                                        :
                                                        <>
                                                            <td></td>
                                                            <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                        </>
                                                    }
                                                </tr>
                                            ))}
                                            {(filesInvoice != undefined && this.props.filesInvoice != null) &&
                                                filesNamesUploaded.length > 0 ?
                                                filesNamesUploaded.map((value: any, index: number) => (

                                                    <tr key={"uploadedFiles" + index}>
                                                        <td className={tableStyle.tableItem}>
                                                            <span key={"uploadedFilesspan" + index} id={index.toString()}>{value}</span>
                                                        </td>
                                                        {value.split(".")[1] == "pdf" ?
                                                            <>
                                                                <td><img onClick={() => { this.handleDownload(this.state.filesUploaded[index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                                <td><img onClick={() => { this.handleView(this.state.filesUploaded[index]) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td>
                                                            </>

                                                            :
                                                            <>
                                                                <td></td>
                                                                <td><img onClick={() => { this.handleDownload(this.state.filesUploaded[index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                            </>
                                                        }
                                                    </tr>
                                                ))
                                                :
                                                <>
                                                    {this.state.filesNames.length == 0 &&
                                                        <tr>
                                                            <td>
                                                                <Col lg="12" md="12" sm="12" className={styles.__AttachmentsRows}>
                                                                    <h3 className={styles.__noFilesText}>No files</h3>
                                                                </Col>
                                                            </td>
                                                        </tr>

                                                    }

                                                </>
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </>
                        }
                    </Col>
                </Row>
                {allowEditValues && !finishEdit &&
                    <Row>
                        <Col lg="12" md="12" sm="12" className={styles.colMyProfileInput}>
                            <label className={styles.__boldText}>Notes</label>
                            <TextFieldArea
                                maxLenght={300}
                                error={notesError} disabled={!allowEditValues || finishEdit} value={notes} placeholder="Send us a note" onChange={(value: any) => { this.onChangeNote(value) }} />
                            {notesError &&
                                <span className={styles.err} >This field is required</span>
                            }
                        </Col>
                    </Row>
                }

                <Row >
                    <Col lg="12" md="12" sm="12" className={styles.colAcitivyInvoice}>
                        <label className={styles.__boldText}>ACTIVITY</label>
                        <Table responsive className={styles.__tableBase}>
                            <thead>
                                <tr key={"thead"}>
                                    <td className={[styles.__theadTable, styles.__nameDateStatusColumns, styles.__statusColumn].join(' ')}><span>USER </span></td>
                                    <td className={[styles.__theadTable, styles.__nameDateStatusColumns, styles.__statusColumn].join(' ')}><span>DATE </span></td>
                                    <td className={[styles.__theadTable, styles.__nameDateStatusColumns, styles.__statusColumn].join(' ')}><span>STATUS </span></td>
                                    <td className={[styles.__theadTable, styles.__notesColumn].join(' ')}><span>NOTES </span></td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableActivity != undefined && tableActivity.length > 0 &&
                                    tableActivity.map((value: ActivityInvoice, index: number) => (
                                        <tr className={styles.__trClass} key={index}>
                                            <td className={[styles.__tbodyTable, styles.__nameColumn].join(' ')}><p className={styles.overflowEllipsis}>{value.user}</p></td>
                                            <td className={[styles.__tbodyTable, styles.__dateColumn].join(' ')}><p className={styles.overflowEllipsis}>{value.created_at.substring(0, 10).replace(/-/g, '/')} - ({value.created_at.substring(11, 16)})</p></td>
                                            <td className={[styles.__tbodyTable, styles.__statusColumn].join(' ')}><p className={styles.overflowEllipsis}>{value.note.split('||')[0]}</p></td>
                                            <td className={[styles.__tbodyTablenoCenter, styles.__notesColumn].join(' ')}>
                                                <p className={styles.overflowEllipsis} lang="es">{value.note.split('||')[1]}</p>
                                            </td>
                                        </tr>
                                    ))}
                                {finishEdit &&
                                    <tr className={styles.__trClass} key={"001"}>
                                        <td className={[styles.__tbodyTable, styles.__nameColumn].join(' ')}><p className={styles.overflowEllipsis}>{this.props.myUser.name} </p></td>
                                        <td className={[styles.__tbodyTable, styles.__dateColumn].join(' ')}><p className={styles.overflowEllipsis}>
                                            {moment().subtract(6, 'hours').format('YYYY/MM/DD - (HH:mm)')}
                                        </p></td>
                                        <td className={[styles.__tbodyTable, styles.__statusColumn].join(' ')}><p className={styles.overflowEllipsis}>Submitted </p></td>
                                        <td className={[styles.__tbodyTablenoCenter, styles.__notesColumn].join(' ')}><p className={styles.overflowEllipsis}>{notes} </p></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <div className={styles.__buttonsSaveCancel}>

                            {(this.props.myUser.role.name == "Contractor" && !finishEdit && (this.props.status == "Rejected - AM" || this.props.status == "Rejected - FIN")) ?
                                <>
                                    <ButtonForm onClick={() => { this.onCancel() }} statusBtn extraClass={styles.__marginButtonsCancel} label="Cancel" />
                                    <ButtonForm onClick={() => { this.onSubmit() }} disabled={!saveButtonActive} statusBtn extraClass={styles.__marginButtonsSave} colorFill label="Save" />
                                </>

                                :
                                <>
                                    <ButtonForm onClick={() => { this.onCancel() }} statusBtn extraClass={styles.__marginButtonsCancel} label="Back" />
                                </>
                            }

                        </div>
                    </Col>
                </Row>

            </Container>
        );
    }
}

const mapStateToProps = (state: any) => ({
    activityInvoice: state.invoiceReducer.activityInvoice,
    filesInvoice: state.filesBucketReducer.filesInvoice,
    loadingGetSuccess: state.filesBucketReducer.loadingGetSuccess,
    create_invoice: state.invoiceReducer.create_invoice,
    updateInvoicesStatus: state.invoiceReducer.updateInvoicesStatus
});
const mapDispatchToProps = (dispatch: any) => ({
    statusApprovedAM: (data: any) => dispatch(statusApprovedAM(data)),
    invoiceActivityFetch: (id: string) => dispatch(invoiceActivityFetch(id)),
    statusApprovedFinance: (data: any) => dispatch(statusApprovedFinance(data)),
    statusPaidFinance: (data: any) => dispatch(statusPaidFinance(data)),
    statusRejectedAM: (data: any) => dispatch(statusRejectedAM(data)),
    statusRejectedFinance: (data: any) => dispatch(statusRejectedFinance(data)),
    updateInvoice: (data: NewInvoice, id: string) => dispatch(updateInvoice(data, id)),
    fetchBucketInvoiceFiles: (id: string) => dispatch(fetchBucketInvoiceFiles(id)),
    filesBucketInvoiceUpload: (files: Array<any>) => dispatch(filesBucketInvoiceUpload(files)),
});
export default connect(
    mapStateToProps, mapDispatchToProps)(EditViewInvoiceDetails);