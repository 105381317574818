import React, { Component } from 'react';

//React icons
import { SlOptions } from 'react-icons/sl';
import { TbFileExport } from 'react-icons/tb';
import ArchiveBoxBold from '../../assets/svg/archive.svg';
import UnArchiveBoxBold from '../../assets/svg/unarchive.svg';
import ArchiveAll from '../../assets/svg/archiveAll.svg';
import UnArchiveAll from '../../assets/svg/unarchiveAll.svg';
import { GrRevert } from 'react-icons/gr';
import { RxReload } from 'react-icons/rx'
import GoChecklist from '../../assets/svg/checkListApprove.svg'


//components internal
import TableInfo from '../../components/advancedComponents/tableInfo';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import DropDown from '../../components/basicComponents/DropDown';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';
import CheckBox from '../../components/basicComponents/Checkbox';
import Switch from '../../components/basicComponents/Switch';

//components external
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Tooltip } from 'react-tooltip';
import { CSVLink } from 'react-csv';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal
} from '@chakra-ui/react';

//styles
import styles from '../../styles/screens/invoices.module.css';
import style from '../../styles/screens/userView.module.css';
import titleStyle from '../../styles/titles.module.css';

//Models
import { usersManagersModel } from '../../models/user/user_model';
import { MyUserModel } from '../../models/user/user_model';

//Redux
import { connect } from 'react-redux';
import { InvoiceInfo } from '../../models/invoice/invoice_model';
import { invoiceFetch, archiveInvoice, archiveInvoiceGroup, unarchiveInvoice, unarchiveInvoiceGroup, invoiceLink, revertInvoice, statusApprovedAMGroup } from '../../actions/invoices-actions';
import { usersManagerFetch } from '../../actions/users-action';

//Screens
import InternalDetailsInvoice from './internalDetailsInvoices';

interface Props {
  //inherited
  myUser: MyUserModel,

  //Redux and Reducers
  invoiceFetch: (activePaid: boolean, activeArchive: boolean) => void,
  invoiceLink: (id: string) => void,
  revertInvoice: (id: string) => void,
  revertStatus: number,
  invoiceLinkR: Array<InvoiceInfo>,
  invoices: Array<InvoiceInfo>,
  usersManagers: Array<usersManagersModel>,
  usersManagerFetch: () => void,
  statusApprovedAMGroup: (data: any) => void,
  archiveInvoice: (id: string) => void,
  unarchiveInvoice: (id: string) => void,
  create_invoice: any,
  archiveInvoiceGroup: (id: string) => void,
  unarchiveInvoiceGroup: (id: string) => void,
  updateStatusLoad: boolean
}

interface State {
  invoicesTable: Array<any>
  valuesDropdownManager: any
  valuesDropdownStatus: any
  filterManager: string
  filterStatus: string
  activeInvoicePaid: boolean
  activeInvoiceArchive: boolean
  activeInvoicePaidBefore: boolean
  activeInvoiceArchiveBefore: boolean
  forceLoader: boolean,
  selectAll: boolean,
  countStatusSubmittedApprovedAMRejectedPaid: number,
  countApprovedFin: number,
  countArchived: number,

  showInvoiceDetails: boolean,
  idSelected: string,
  folioSelected: string,
  invoiceUrlId: string,
  statusSelected: string,
  contractorSelected: string,
  monthly_feeSelected: string,
  hourly_rateSelected: string,
  AMSelected: string,
  payment_methodSelected: string,
  payment_detailsSelected: string,
  proyectSelected: any,
  billable_hoursSelected: any,
  totalSelected: any,
  periodStartSelected: any,
  periodEndSelected: any,
  periodSelected: any,
  noteSelected: string
  createdSelected: any,
  amountSelected: any,
  invoice_dateSelected: any,
  invoicesGroup: Array<any>
  invoicesGroupVerification: Array<any>
  loadInvoiceFisrtTime: boolean
}


class InvoicesTableAM extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const initialState = {
      invoicesTable: [],
      filterManager: "All managers",
      filterStatus: "10",
      valuesDropdownManager: [],
      valuesDropdownStatus: [
        { value: "10", label: "All status" },
        { value: "0", label: "Submitted" },
        { value: "1", label: "Approved - AM" },
        { value: "2", label: "Approved - FIN" },
        { value: "3", label: "Paid" },
        { value: "4", label: "Rejected - AM" },
        { value: "5", label: "Rejected - FIN" },
        { value: "6", label: "Archived" }
      ],
      showInvoiceDetails: false,
      activeInvoicePaid: false,
      activeInvoiceArchive: false,
      activeInvoicePaidBefore: false,
      activeInvoiceArchiveBefore: false,
      idSelected: "",
      folioSelected: "",
      statusSelected: "",
      forceLoader: false,
      contractorSelected: "",
      monthly_feeSelected: "",
      hourly_rateSelected: "",
      AMSelected: "",
      payment_methodSelected: "",
      payment_detailsSelected: "",
      countStatusSubmittedApprovedAMRejectedPaid: 0,
      countApprovedFin: 0,
      countArchived: 0,
      proyectSelected: "",
      selectAll: false,
      invoiceUrlId: window.location.search,
      billable_hoursSelected: "",
      totalSelected: "",
      periodStartSelected: "",
      periodEndSelected: "",
      periodSelected: "",
      noteSelected: "",
      createdSelected: "",
      amountSelected: "",
      invoice_dateSelected: "",
      invoicesGroup: [],
      invoicesGroupVerification: [],
      loadInvoiceFisrtTime: true
    };
    this.state = initialState;
  }



  //////////////////BASIC FUNCTIONS////////////////////////////////////////////
  componentDidMount() {
    const { invoiceFetch, usersManagerFetch, invoiceLink } = this.props;
    invoiceFetch(false, false);
    usersManagerFetch()
    let linkInvoiceSelected = false, idSelected = ""
    const { invoiceUrlId } = this.state;
    if (invoiceUrlId != undefined && invoiceUrlId != null && invoiceUrlId != '') {
      const invoiceUrlIdValueArray = invoiceUrlId.split('=')
      if (invoiceUrlIdValueArray[0].substring(1) == "invoice") {
        linkInvoiceSelected = true
        idSelected = invoiceUrlIdValueArray[1]
        invoiceLink(idSelected)
      }
    }
  }
  componentDidUpdate(prevProps: Props) {
    //Invoices
    this.loadInvoices(prevProps)
    //Managers
    this.loadManagers(prevProps)
    //Archive

    ///reload invoices
    if (this.props.updateStatusLoad != prevProps.updateStatusLoad &&
      prevProps.updateStatusLoad && !this.props.updateStatusLoad) {
      this.props.invoiceFetch(this.state.activeInvoicePaid, this.state.activeInvoiceArchive)
    }

    //loadInvoiceLink
    if (this.props.invoiceLinkR != null && this.props.invoiceLinkR != undefined &&
      prevProps.invoiceLinkR != null && prevProps.invoiceLinkR != undefined) {
      if (this.props.invoiceLinkR != prevProps.invoiceLinkR &&
        prevProps.invoiceLinkR.length == 0 && this.props.invoiceLinkR.length != 0) {
        if (Array.isArray(Object.values(this.props.invoiceLinkR)) && !this.state.showInvoiceDetails) {
          const valuesTable = Object.values(this.props.invoiceLinkR);
          valuesTable.map((invoice: InvoiceInfo) => {
            this.setState({
              loadInvoiceFisrtTime: false,
              showInvoiceDetails: true,
              idSelected: invoice.id,
              folioSelected: invoice.folio,
              statusSelected: this.renderStatusName(invoice.status)!,
              contractorSelected: invoice.contractor,
              monthly_feeSelected: this.currencyFormatter("USD", invoice.monthlyFee),
              hourly_rateSelected: this.currencyFormatter("USD", invoice.hourly_rate),
              AMSelected: invoice.AM,
              payment_methodSelected: invoice.payment,
              payment_detailsSelected: invoice.payment_details,
              proyectSelected: invoice.proyect,
              billable_hoursSelected: invoice.billable_hours,
              totalSelected: this.currencyFormatter("USD", invoice.total),
              periodStartSelected: invoice.periodStart,
              periodEndSelected: invoice.periodEnd,
              periodSelected: this.periodFormat(invoice.periodStart, invoice.periodEnd),
              noteSelected: invoice.note,
              createdSelected: invoice.invoice_date,
              invoice_dateSelected: invoice.invoice_date
            })
          })
        }
      }
    }

    if (this.props.revertStatus != prevProps.revertStatus &&
      prevProps.revertStatus == 0 && this.props.revertStatus == 2) {
      this.props.invoiceFetch(this.state.activeInvoicePaid, this.state.activeInvoiceArchive)
    }

  }








  /////////////////////////////////ONCLICK FUNCTIONS//////////////////////////////////////////
  selectInvoice = (selectedInvoice: InvoiceInfo) => {
    this.setState({
      showInvoiceDetails: true,
      idSelected: selectedInvoice.id,
      folioSelected: selectedInvoice.folio,
      statusSelected: selectedInvoice.status,
      contractorSelected: selectedInvoice.contractor,
      monthly_feeSelected: selectedInvoice.monthy_fee,
      hourly_rateSelected: selectedInvoice.hourly_rate,
      AMSelected: selectedInvoice.AM,
      payment_methodSelected: selectedInvoice.payment,
      payment_detailsSelected: selectedInvoice.payment_details,
      proyectSelected: selectedInvoice.proyect,
      billable_hoursSelected: selectedInvoice.billable_hours,
      totalSelected: selectedInvoice.total,
      periodStartSelected: selectedInvoice.periodStart,
      periodEndSelected: selectedInvoice.periodEnd,
      periodSelected: selectedInvoice.period,
      noteSelected: selectedInvoice.note,
      createdSelected: selectedInvoice.created,
      invoice_dateSelected: selectedInvoice.invoice_date
    })
  }
  archivedOrApprovedInvoicesSelected = async (value: boolean) => {
    const { invoicesGroup } = this.state
    if (value) {
      for (let i = 0; i < invoicesGroup.length; i++) {
        await this.markAsApproved(invoicesGroup[i])
      }
    } else {
      for (let i = 0; i < invoicesGroup.length; i++) {
        await this.markAsArchived(invoicesGroup[i])
      }
    }
  }

  markAsArchived = async (id: string) => {
    const { archiveInvoiceGroup } = this.props
    archiveInvoiceGroup(id)
    return
  }

  markAsApproved = async (idValue: string) => {
    const { statusApprovedAMGroup } = this.props
    statusApprovedAMGroup({ id: idValue, note: "" })
    return
  }
  unarchivedSelected = async () => {
    const { invoicesGroup } = this.state
    for (let i = 0; i < invoicesGroup.length; i++) {
      await this.markAsUnarchived(invoicesGroup[i])
    }
  }

  markAsUnarchived = async (id: string) => {
    const { unarchiveInvoiceGroup } = this.props
    unarchiveInvoiceGroup(id)
    return
  }
  markAll = () => {
    const valuesTable = Object.values(this.state.invoicesTable);
    const { invoicesGroupVerification } = this.state
    const verificatiosnValues: Array<any> = []
    const idValues: Array<any> = []
    if (Array.isArray(valuesTable)) {
      valuesTable.map((invoice: InvoiceInfo) => {
        if (invoice.status == "Archived" && invoicesGroupVerification.includes("2")) {
          idValues.push(invoice.id)
          verificatiosnValues.push("2")
        } else if (invoice.status != "Archived" && !(invoicesGroupVerification.includes("2"))) {
          idValues.push(invoice.id)
          if (invoice.status == "Submitted") {
            verificatiosnValues.push("3")
          } else {
            verificatiosnValues.push("0")
          }
        }

        else if (invoice.status == "Archived" && verificatiosnValues.length == 0) {
          idValues.push(invoice.id)
          verificatiosnValues.push("2")
        }
      })
      this.setState({ invoicesGroup: idValues, invoicesGroupVerification: verificatiosnValues })
    }
  }

  desmarkAll = () => {
    this.setState({ invoicesGroup: [], invoicesGroupVerification: [] })
  }








  ///////////////////FORMATS FUNCTIONS//////////////////////////////////////
  renderStatusName = (valueStatus: any) => {
    if (valueStatus == 0) {
      return "Submitted"
    }
    if (valueStatus == 1) {
      return "Approved - AM"
    }
    if (valueStatus == 2) {
      return "Approved - FIN"
    }
    if (valueStatus == 3) {
      return "Paid"
    }
    if (valueStatus == 4) {
      return "Rejected - AM"
    }
    if (valueStatus == 5) {
      return "Rejected - FIN"
    }
    if (valueStatus == 6) {
      return "Archived"
    }
  }
  currencyFormatter = (currency: any, value: any) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency
    })
    return formatter.format(value)
  }
  dateFormat = (date: any) => {
    const dateValue = date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4);
    return dateValue
  }
  periodFormat = (startDate: any, endDate: any) => {
    const startFormat = startDate.substring(5, 7) + "/" + startDate.substring(8, 10) + "/" + startDate.substring(0, 4);
    const endFormat = endDate.substring(5, 7) + "/" + endDate.substring(8, 10) + "/" + endDate.substring(0, 4);
    return startFormat + " - " + endFormat
  }







  //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
  loadManagers = (prevProps: Props) => {
    if (this.props.usersManagers != prevProps.usersManagers) {
      const managers = Object.values(this.props.usersManagers);
      if (Array.isArray(managers)) {
        let managersValues: any[] = [{ value: "0", label: "All managers" }]
        managers.map((managerFilter: usersManagersModel) => {
          managersValues.push({ value: managerFilter.id, label: managerFilter.name })
        })
        this.setState({ valuesDropdownManager: managersValues })
      }
    }
  }

  loadInvoices = (prevProps: Props) => {
    if (this.props.invoices != prevProps.invoices) {
      this.filterInvoices(this.state.filterManager, this.state.filterStatus, false)
    }
  }


  firstColumSelect = () => {
    return <>
      <div>
        <CheckBox extraClass={styles.__checkboxTableSelectALL}
          checked={
            this.state.selectAll
          }
          onclick={() => {
            if (this.state.selectAll) {
              this.desmarkAll();
            } else {
              this.markAll()
            }
            this.setState({ selectAll: !this.state.selectAll })
          }}
          value={"selectedAll"} />
        <span>DATE</span>
      </div>
    </>
  }








  //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
  filterInvoices = (accountManager: any, invoiceStatus: any, DropDown: boolean) => {
    const { filterManager, filterStatus } = this.state;
    const filterAMV = accountManager != null ? DropDown ? accountManager.label : accountManager : filterManager
    const filterStatusV = invoiceStatus != null ? DropDown ? invoiceStatus.value : invoiceStatus : filterStatus
    let linkInvoiceSelected = false, idSelected = ""
    const { invoiceUrlId } = this.state;
    this.setState({ filterManager: filterAMV, filterStatus: filterStatusV })

    if (!DropDown) {
      this.setState({})
    }

    const valuesTable = Object.values(this.props.invoices);
    if (Array.isArray(valuesTable)) {
      if (invoiceUrlId != undefined && invoiceUrlId != null && invoiceUrlId != '') {
        const invoiceUrlIdValueArray = invoiceUrlId.split('=')
        if (invoiceUrlIdValueArray[0].substring(1) == "invoice") {
          linkInvoiceSelected = true
          idSelected = invoiceUrlIdValueArray[1]
        }
      }
      let countGen = 0, countApprovedFin = 0, countArchived = 0
      let invoicesValues: any[] = []
      let newselectId: Array<any> = []
      let newselectVerify: Array<any> = []
      valuesTable.map((invoice: InvoiceInfo) => {
        if (((filterAMV == "All managers" || filterAMV == "Account Manager") || filterAMV == invoice.AM) && (filterStatusV == "10" || filterStatusV == invoice.status)) {
          if (invoice.status == "6") {
            countArchived++;
          } else if (invoice.status == "2") {
            countApprovedFin++;
          } else {
            countGen++;
          }
          if (this.state.invoicesGroup.includes(invoice.id)) {
            newselectId.push(invoice.id)
            if (invoice.status == "2") {
              newselectVerify.push("1")

            } else if (invoice.status == "6") {
              newselectVerify.push("2")

            } else {
              newselectVerify.push("0")
            }
          }
          invoicesValues.push({
            id: invoice.id,
            folio: invoice.folio,
            status: this.renderStatusName(invoice.status),
            contractor: invoice.contractor,
            monthy_fee: this.currencyFormatter("USD", invoice.monthlyFee),
            hourly_rate: this.currencyFormatter("USD", invoice.hourly_rate),
            AM: invoice.AM,
            payment: invoice.payment,
            payment_details: invoice.payment_details,
            proyect: invoice.proyect,
            billable_hours: invoice.billable_hours,
            period: this.periodFormat(invoice.periodStart, invoice.periodEnd),
            total: this.currencyFormatter("USD", invoice.total),
            note: invoice.note,
            amount: this.currencyFormatter("USD", invoice.amount),
            invoice_date: invoice.invoice_date
          })
          if (linkInvoiceSelected && this.state.loadInvoiceFisrtTime) {
            if (idSelected == invoice.id) {
              this.setState({
                loadInvoiceFisrtTime: false,
                showInvoiceDetails: true,
                idSelected: invoice.id,
                folioSelected: invoice.folio,
                statusSelected: this.renderStatusName(invoice.status)!,
                contractorSelected: invoice.contractor,
                monthly_feeSelected: this.currencyFormatter("USD", invoice.monthlyFee),
                hourly_rateSelected: this.currencyFormatter("USD", invoice.hourly_rate),
                AMSelected: invoice.AM,
                payment_methodSelected: invoice.payment,
                payment_detailsSelected: invoice.payment_details,
                proyectSelected: invoice.proyect,
                billable_hoursSelected: invoice.billable_hours,
                totalSelected: this.currencyFormatter("USD", invoice.total),
                periodStartSelected: invoice.periodStart,
                periodEndSelected: invoice.periodEnd,
                periodSelected: this.periodFormat(invoice.periodStart, invoice.periodEnd),
                noteSelected: invoice.note,
                createdSelected: invoice.invoice_date,
                invoice_dateSelected: invoice.invoice_date
              })
            }
          }
        }
      })
      if (newselectId.length > 0) {
        if (this.state.invoicesGroupVerification.includes("2")) {
          if (newselectId.length >= (countArchived)) {
            this.setState({ selectAll: true })
          } else {
            this.setState({ selectAll: false })
          }
        } else {
          if (newselectId.length >= (countGen + countApprovedFin)) {
            this.setState({ selectAll: true })
          } else {
            this.setState({ selectAll: false })
          }
        }
      } else {
        this.setState({ selectAll: false })
      }
      this.setState({
        invoicesTable: invoicesValues, countStatusSubmittedApprovedAMRejectedPaid: countGen,
        countApprovedFin: countApprovedFin, countArchived: countArchived, invoicesGroup: newselectId, invoicesGroupVerification: newselectVerify
      })
    }
  }









  /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
  render() {
    const { invoicesGroupVerification, invoicesGroup,
      idSelected, folioSelected, noteSelected, totalSelected, periodSelected, createdSelected,
      proyectSelected, statusSelected, contractorSelected, hourly_rateSelected,
      monthly_feeSelected, payment_detailsSelected, payment_methodSelected, billable_hoursSelected, invoice_dateSelected,
      AMSelected, forceLoader, valuesDropdownManager, valuesDropdownStatus,
      activeInvoiceArchive, activeInvoicePaid, activeInvoiceArchiveBefore, activeInvoicePaidBefore, invoicesTable
    } = this.state
    const { myUser } = this.props
    const columns = [
      {
        name: this.firstColumSelect(),
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={[styles.__divCellTable].join(' ')}>
            {((row.status == "Archived" && !invoicesGroupVerification.includes("0") && !invoicesGroupVerification.includes("1")) ||
              (row.status != "Archived" && !invoicesGroupVerification.includes("2"))
            ) ?
              <>
                <CheckBox extraClass={styles.__checkboxTableColumn}
                  checked={
                    invoicesGroup.includes(row.id)
                  }
                  onclick={(value: any) => {
                    const { invoicesGroup, invoicesGroupVerification, countStatusSubmittedApprovedAMRejectedPaid,
                      countApprovedFin, countArchived } = this.state
                    const findElement = invoicesGroup.includes(value)
                    if (!findElement) {
                      invoicesGroup.push(value)
                      if (row.status == "Archived") {
                        invoicesGroupVerification.push("2")
                        if (invoicesGroup.length == (countArchived)) {
                          this.setState({ selectAll: true })
                        }
                      } else if (row.status == "Submitted") {
                        invoicesGroupVerification.push("3")
                        if (invoicesGroup.length == (countStatusSubmittedApprovedAMRejectedPaid + countApprovedFin)) {
                          this.setState({ selectAll: true })
                        }
                      }
                      else {
                        invoicesGroupVerification.push("0")
                        if (invoicesGroup.length == (countStatusSubmittedApprovedAMRejectedPaid + countApprovedFin)) {
                          this.setState({ selectAll: true })
                        }
                      }

                      this.setState({ invoicesGroup: invoicesGroup, invoicesGroupVerification: invoicesGroupVerification })
                    } else {
                      const indice = invoicesGroup.indexOf(value);
                      invoicesGroup.splice(indice, 1);
                      if (row.status == "Approved - FIN") {
                        const indice = invoicesGroupVerification.indexOf("1");
                        invoicesGroupVerification.splice(indice, 1);
                      } else if (row.status == "Archived") {
                        const indice = invoicesGroupVerification.indexOf("2");
                        invoicesGroupVerification.splice(indice, 1);
                      } else if (row.status == "Submitted") {
                        const indice = invoicesGroupVerification.indexOf("3");
                        invoicesGroupVerification.splice(indice, 1);
                      } else {
                        const indice = invoicesGroupVerification.indexOf("0");
                        invoicesGroupVerification.splice(indice, 1);
                      }
                      this.setState({ invoicesGroup: invoicesGroup, invoicesGroupVerification: invoicesGroupVerification, selectAll: false })
                    }
                  }}
                  value={row.id} />
                <label className={styles.spanValueTable}>{this.dateFormat(row.invoice_date)}</label>
              </>
              :
              <label className={styles.spanValueTableDateArchived}>{this.dateFormat(row.invoice_date)}</label>
            }

          </div>
        ),
        selector: (row: any, i: any) => row.invoice_date,

      },
      {
        name: 'CONTRACTOR',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label className={styles.spanValueTable}>{row.contractor}</label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.contractor
      },
      {
        name: 'INVOICE NO.',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label onClick={() => {
              this.selectInvoice({
                id: row.id,
                folio: row.folio,
                status: row.status,
                contractor: row.contractor,
                monthy_fee: row.monthy_fee,
                hourly_rate: row.hourly_rate,
                AM: row.AM,
                payment: row.payment,
                payment_details: row.payment_details,
                proyect: row.proyect,
                billable_hours: row.billable_hours,
                total: row.total,
                periodStart: row.periodStart,
                periodEnd: row.periodEnd,
                period: row.period,
                note: row.note,
                created: row.created,
                amount: row.amount,
                invoice_date: row.invoice_date,
              })

            }} className={styles.spanValueTableFolio}>{row.folio}</label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.folio
      },
      {
        name: 'AM ASSIGNED',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label className={styles.spanValueTable}>{row.AM}</label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.AM
      },
      {
        name: 'AMOUNT',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label className={styles.spanValueTable}>
              {row.amount}
            </label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.amount
      },
      {
        name: 'PERIOD',
        center: true,
        cell: (row: any) => (
          <div className={styles.__divCellTable}>
            <label className={styles.spanValueTable}>
              {row.period}
            </label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.period
      },
      {
        name: 'STATUS',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label className={[styles.spanValueTable, styles.spanValueTableStatus,
            row.status == "Submitted" ? styles.__spanSubmitted :
              row.status == "Approved - AM" ? styles.__spanApprovedAM :
                row.status == "Approved - FIN" ? styles.__spanApprovedFinance :
                  row.status == "Paid" ? styles.__spanPaid :
                    row.status == "Rejected - AM" ? styles.__spanRejectedAM :
                      row.status == "Rejected - FIN" ? styles.__spanRejectedFinance :
                        row.status == "Archived" ? styles.__spanArchived
                          : ""
            ].join(' ')}>
              {row.status}
            </label>
          </div>
        ),
        selector: (row: any, i: any) => row.status,
        sortable: true,
      },
      {
        name: '',
        center: true,
        cell: (row: any) => (
          invoicesGroup.length == 0 &&
          <Menu>
            <MenuButton onClick={() => {
              this.setState({
                idSelected: row.id,
                folioSelected: row.folio,
                statusSelected: row.status,
                contractorSelected: row.contractor,
                monthly_feeSelected: row.monthy_fee,
                hourly_rateSelected: row.hourly_rate,
                AMSelected: row.AM,
                payment_methodSelected: row.payment,
                payment_detailsSelected: row.payment_details,
                proyectSelected: row.proyect,
                billable_hoursSelected: row.billable_hours,
                totalSelected: row.total,
                periodSelected: row.period,
                noteSelected: row.note,
                amountSelected: row.amount
              });
            }} className={style.__buttonActionsTable}><SlOptions /></MenuButton>
            <Portal>
              <MenuList className={style.__menuListModal} zIndex={1000000}>
                {row.status == "Archived" &&
                  <MenuItem onClick={() => {
                    this.props.unarchiveInvoice(row.id);
                  }} className={styles.__menuListItemModal}>
                    <img className={styles.__actionMenuIcons} src={UnArchiveBoxBold} alt="ArchiveBoxBold" />
                    Unarchive
                  </MenuItem>
                }
                {row.status != "Archived" &&
                  <MenuItem onClick={() => {
                    this.props.archiveInvoice(row.id);
                  }} className={styles.__menuListItemModal}>
                    <img className={styles.__actionMenuIcons} src={ArchiveBoxBold} alt="ArchiveBoxBold" />
                    Archive
                  </MenuItem>
                }
                {(row.status == "Approved - AM" || row.status == "Rejected - AM") &&
                  <MenuItem onClick={() => {
                    this.props.revertInvoice(row.id);
                  }} className={styles.__menuListItemModal}>
                    <GrRevert className={styles.__actionMenuIcons} />
                    Revert status
                  </MenuItem>
                }
              </MenuList>
            </Portal>
          </Menu>
        ),
        width: '30px',
        sortable: true,
      },
    ];
    const headers = [
      { label: "Invoice date", key: "invoice_date" },
      { label: "Contractor", key: "contractor" },
      { label: "Invoice no.", key: "folio" },
      { label: "AM ASSIGNED", key: "AM" },
      { label: "period", key: "period" },
      { label: "Amount", key: "amount" },
      { label: "Status", key: "status" }
    ];
    return (
      this.state.showInvoiceDetails ?
        <InternalDetailsInvoice
          myUser={myUser}
          id={idSelected}
          folio={folioSelected}
          status={statusSelected}
          contractor={contractorSelected}
          monthly_fee={monthly_feeSelected}
          hourly_rate={hourly_rateSelected}
          AM={AMSelected}
          back={() => { this.setState({ showInvoiceDetails: false }) }}
          payment_method={payment_methodSelected}
          payment_details={payment_detailsSelected}
          proyect={proyectSelected}
          billable_hours={billable_hoursSelected}
          total={totalSelected}
          period={periodSelected}
          note={noteSelected}
          created={createdSelected}
          invoice_date={invoice_dateSelected}
        /> :
        <Container className={styles.__MainContent}>
          <Row className={titleStyle.__MainRowTitle}>
            <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
              <label className={titleStyle.__titlePart1}></label>
              <label className={titleStyle.__titlePart2}></label>
              <label className={titleStyle.__titlePart3}>INVOICES</label>
              <label className={titleStyle.__titlePart4}></label>
              <label className={titleStyle.__titlePart5}></label>
            </Col>
          </Row>
          <ReduxLoader activeForce={forceLoader} />
          <Row className={[styles.marginBottom].join(' ')}>
            <Col lg="12" md="12" sm="12"><h4 className={styles.__MainTitle}></h4></Col>
            <Row className={[styles.__rowHeadTable, styles.marginBottom].join(' ')}>
              <Col lg="3" md="6" sm="12" className={styles.__buttonHeadTable}>
                <DropDown defaultValue={{ label: "Account Manager", value: "0" }}
                  options={valuesDropdownManager} onChange={async (value: any) => { this.filterInvoices(value, null, true) }} />
              </Col>
              <Col lg="3" md="6" sm="12" className={styles.__buttonHeadTable}>
                <DropDown defaultValue={{ label: "Invoice status", value: "10" }}
                  options={valuesDropdownStatus} onChange={(value: any) => {
                    if (value.label == "Paid") {
                      const paidValue = activeInvoicePaid ? activeInvoicePaid : !activeInvoicePaid
                      this.props.invoiceFetch(paidValue, activeInvoiceArchiveBefore);
                      this.setState({ activeInvoicePaid: paidValue, activeInvoiceArchive: activeInvoiceArchiveBefore })
                      this.filterInvoices(null, value, true)
                    } else if (value.label == "Archived") {
                      const archivedValue = activeInvoiceArchive ? activeInvoiceArchive : !activeInvoiceArchive
                      this.props.invoiceFetch(activeInvoicePaidBefore, archivedValue);
                      this.setState({ activeInvoiceArchive: archivedValue, activeInvoicePaid: activeInvoicePaidBefore })
                      this.filterInvoices(null, value, true)
                    } else {
                      if (activeInvoiceArchive != activeInvoiceArchiveBefore || activeInvoicePaid != activeInvoicePaidBefore) {
                        this.props.invoiceFetch(activeInvoicePaidBefore, activeInvoiceArchiveBefore);
                        this.setState({ activeInvoiceArchive: activeInvoiceArchiveBefore, activeInvoicePaid: activeInvoicePaidBefore })
                      }
                      this.filterInvoices(null, value, true)
                    }

                  }} />
              </Col>
              <Col lg="6" md="6" sm="12" className={styles.__buttonHeadTableButtons}>
                <>
                  <Tooltip id={"approveSelected"} />
                  <ButtonForm id={"approveSelected"}
                    toltipText={invoicesGroup.length === 0 ? "Select invoices to approve" : (invoicesGroupVerification.includes("0") || invoicesGroupVerification.includes("1") || invoicesGroupVerification.includes("2")) ? "Only invoices submitted can be approved" : "Approve selected"}
                    extraClass={[styles.__buttonFilter, styles.__buttonApprovedGroup].join(' ')} colorFill
                    onClick={async () => {
                      if (!invoicesGroupVerification.includes("0")
                        && !invoicesGroupVerification.includes("1") && !invoicesGroupVerification.includes("2")) {
                        await this.archivedOrApprovedInvoicesSelected(true)
                        setTimeout(() => {
                          this.props.invoiceFetch(activeInvoicePaid, activeInvoiceArchive)
                          this.setState({ invoicesGroup: [], invoicesGroupVerification: [] })
                        }, 1500)
                      }
                    }}
                    disabled={invoicesGroupVerification.includes("0") || invoicesGroupVerification.includes("1") || invoicesGroupVerification.includes("2") || invoicesGroup.length === 0}
                    icon={<img
                      src={GoChecklist}
                    />}
                  />
                </>
                <>
                  <Tooltip id={"archiveSelected"} />
                  <ButtonForm id={"archiveSelected"}
                    toltipText={invoicesGroup.length === 0 ? "Select invoices to mark as archived" : invoicesGroupVerification.includes("2") ? "Unarchived" : "Mark as archived"}
                    extraClass={styles.__buttonFilter} colorFill
                    onClick={async () => {
                      if (invoicesGroupVerification.includes("2")) {
                        this.setState({ forceLoader: true })
                        await this.unarchivedSelected()
                        setTimeout(() => {
                          this.props.invoiceFetch(activeInvoicePaid, activeInvoiceArchive)
                          this.setState({ invoicesGroup: [], invoicesGroupVerification: [], forceLoader: false })
                        }, 1500)
                      } else {
                        await this.archivedOrApprovedInvoicesSelected(false)
                        setTimeout(() => {
                          this.props.invoiceFetch(activeInvoicePaid, activeInvoiceArchive)
                          this.setState({ invoicesGroup: [], invoicesGroupVerification: [] })
                        }, 1500)
                      }
                    }}
                    disabled={invoicesGroup.length === 0}
                    icon={<img
                      src={invoicesGroupVerification.includes("2") ? UnArchiveAll : ArchiveAll}
                      alt="Mark as archived" />}
                  />
                </>
                <Tooltip id={"exportButton"} />
                <CSVLink data-tooltip-id="exportButton" data-tooltip-content="Export invoices"
                  className={styles.__buttonExport}
                  filename={"Invoices (" + new Date().toISOString().replace(/-/g, '_').substring(0, 10) + " - " + new Date().toISOString().substring(11, 16).replace(/:/g, ' ') + ")"}
                  data={invoicesTable} headers={headers}>
                  <TbFileExport />
                </CSVLink>
                <Tooltip id={"Reload"} />
                <ButtonForm id={"Reload"}
                  toltipText={"Reload"}
                  extraClass={styles.__buttonReload} colorFill
                  onClick={async () => {
                    this.setState({ invoicesGroup: [], invoicesGroupVerification: [] })
                    this.props.invoiceFetch(activeInvoicePaid, activeInvoiceArchive)
                  }}
                  icon={<RxReload />}
                />
                <Col lg="12" md="12" sm="12" className={styles.__SwitchHeadTableButtons}>
                  <div>
                    <span className={styles.__filterSpan}>Archived</span> <Switch
                      onChange={() => {
                        this.props.invoiceFetch(activeInvoicePaid, !activeInvoiceArchive);
                        this.setState({ activeInvoiceArchive: !activeInvoiceArchive, activeInvoiceArchiveBefore: !activeInvoiceArchive })
                      }}
                      checked={activeInvoiceArchive} />
                  </div>
                  <div>
                    <span className={styles.__filterSpan}>Paid</span> <Switch
                      onChange={() => {
                        this.props.invoiceFetch(!activeInvoicePaid, activeInvoiceArchive);
                        this.setState({ activeInvoicePaid: !activeInvoicePaid, activeInvoicePaidBefore: !activeInvoicePaid })
                      }}
                      checked={activeInvoicePaid} />
                  </div>
                </Col>
              </Col>
            </Row>
            {invoicesGroup.length > 0 &&
              <Row className={styles.__rowInvoicesSelected}>
                <span className={styles.__invoicesSelected}>{invoicesGroup.length} selected invoice
                  {invoicesGroup.length > 1 &&
                    <>s</>
                  }
                </span>
              </Row>
            }
            <TableInfo paginationPerPage={10} columns={columns} data={invoicesTable} />
          </Row>
        </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  invoices: state.invoiceReducer.paymentsFetch,
  invoiceLinkR: state.invoiceReducer.invoiceLinkR,
  usersManagers: state.usersReducer.usersManagers,
  updateStatusLoad: state.invoiceReducer.updateStatusLoad,
  create_invoice: state.invoiceReducer.create_invoice,
  revertStatus: state.invoiceReducer.revertStatus
});

const mapDispatchToProps = (dispatch: any) => ({
  invoiceFetch: (activePaid: boolean, activeArchive: boolean) => dispatch(invoiceFetch(activePaid, activeArchive)),
  invoiceLink: (id: string) => dispatch(invoiceLink(id)),
  usersManagerFetch: () => dispatch(usersManagerFetch()),
  archiveInvoice: (id: string) => dispatch(archiveInvoice(id)),
  unarchiveInvoice: (id: string) => dispatch(unarchiveInvoice(id)),
  archiveInvoiceGroup: (id: string) => dispatch(archiveInvoiceGroup(id)),
  statusApprovedAMGroup: (data: any) => dispatch(statusApprovedAMGroup(data)),
  unarchiveInvoiceGroup: (id: string) => dispatch(unarchiveInvoiceGroup(id)),
  revertInvoice: (id: string) => dispatch(revertInvoice(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTableAM);