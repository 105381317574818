// Redux
import { Reducer } from 'redux';
import { AuthAction } from '../types/auth-types';
import { authActionTypes } from '../constants/auth-constants';

// Models
import { MyUserModel } from '../models/user/user_model';
import { getInitialUserByToken } from "../models/auth/newUserAuthPassword-model";

interface AuthState {
  loading: boolean,
  loginError: boolean,
  myUser: MyUserModel,
  messageError: string,
  authMessageError: string,
  userInitialUpdatePassword: getInitialUserByToken
  verifyEmail: boolean
  updateSuccess: boolean,
  message: any
}

const initialState: AuthState = {
  loading: false,
  loginError: false,
  myUser: {} as MyUserModel,
  messageError: "",
  authMessageError: "",
  userInitialUpdatePassword: {} as getInitialUserByToken,
  verifyEmail: false,
  updateSuccess: false,
  message: ""
};

export const authReducer: Reducer<AuthState, AuthAction> = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    // ------------------ LOGIN -----------------------------
    case authActionTypes.LOGIN_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
        loginError: false,
      };

    case authActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginError: true,
        loading: false,
        messageError: action.error
      };

    case authActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        messageError: 'accessSuccess',
        loginError: false,
        loading: false,
      };

    // ------------------ LOGOUT -----------------------------
    case authActionTypes.LOGOUT_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
        loginError: false,
      };

    case authActionTypes.LOGOUT_ERROR:
      return {
        ...state,
        loginError: true,
        loading: false,
        messageError: action.error
      };

    case authActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        loginError: false,
        loading: false,
        myUser: {} as MyUserModel,
      };


    // ------------------ UPDATE USER AUTH -----------------------------
    case authActionTypes.USER_AUTH_UPDATE_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
        loginError: false,
        updateSuccess: true
      };

    case authActionTypes.USER_AUTH_UPDATE_ERROR:
      return {
        ...state,
        loginError: true,
        loading: false,
        messageError: action.error,
        updateSuccess: true
      };

    case authActionTypes.USER_AUTH_UPDATE_SUCCESS:
      return {
        ...state,
        messageError: '',
        loginError: false,
        loading: false,
        updateSuccess: false
      };


    // ------------------REQUEST PASSWORD CHANGE EMAIL-----------------------------
    case authActionTypes.USER_AUTH_REQUEST_PASSWORD_EMAIL_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
        loginError: false,
      };

    case authActionTypes.USER_AUTH_REQUEST_PASSWORD_EMAIL_ERROR:
      return {
        ...state,
        loginError: true,
        loading: false,
        messageError: action.error
      };

    case authActionTypes.USER_AUTH_REQUEST_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        messageError: '',
        loginError: false,
        loading: false,
      };

    //--------------------CLEAN DATA ----------------------
    case authActionTypes.CLEAN_DATA_LOGIN:
      return {
        ...state,
        messageError: ''
      };

    //--------------------------USER AUTH-----------------------------

    case authActionTypes.USER_AUTH_BEGIN:
      return {
        ...state,
        authMessageError: '',
        loading: true,
      };

    case authActionTypes.USER_AUTH_ERROR:
      return {
        ...state,
        loading: false,
        authMessageError: action.error
      };

    case authActionTypes.USER_AUTH_SUCCESS:
      return {
        ...state,
        authMessageError: '',
        loading: false,
        myUser: {
          ...action.data,
        },
      };

    //--------------------------GET INITIAL DATA (UPDATE USER PASSWORD)-----------------------------

    case authActionTypes.GET_INITIAL_DATA_UPDATE_PASSWORD_BEGIN:
      return {
        ...state,
        authMessageError: '',
        loading: true,
      };

    case authActionTypes.GET_INITIAL_DATA_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        authMessageError: action.error
      };

    case authActionTypes.GET_INITIAL_DATA_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        authMessageError: '',
        loading: false,
        userInitialUpdatePassword: {
          ...action.data,
        },
      };

    //---------------------------UPDATE EMAIL USER-----------------------------

    case authActionTypes.UPDATE_EMAIL_USER_BEGIN:
      return {
        ...state,
        verifyEmail: false
      };

    case authActionTypes.UPDATE_EMAIL_USER_ERROR:
      return {
        ...state,
        verifyEmail: false
      };

    case authActionTypes.UPDATE_EMAIL_USER_SUCCESS:
      return {
        ...state,
        verifyEmail: true,
        message: {
          ...action.message
        }
      };

    default:
      return state;
  }
};
