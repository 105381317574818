// Redux
import { Reducer } from 'redux';
import { PaymentMethodsAction } from '../types/paymentMethods-types';
import { paymentMethodsActionTypes } from "../constants/paymentMethod-constants";

// Models
import { PaymentMethodModel } from '../models/payments/paymentsMethod-model';

interface AuthState {
    loadingPayments: boolean,
    paymentsFetch: Array<PaymentMethodModel>,
    messageErrorPayment: string,
}

const initialState: AuthState = {
  loadingPayments: false,
  paymentsFetch: [],
  messageErrorPayment: "",
};

export const paymentMethodsReducer: Reducer<AuthState, PaymentMethodsAction> = (state = initialState, action: PaymentMethodsAction) => {
  switch (action.type) {
    // ------------------ LOGIN -----------------------------
    case paymentMethodsActionTypes.FETCH_PAYMENT_METHODS_BEGIN:
      return {
        ...state,
        messageErrorPayment: '',
        loadingPayments: true,
      };

    case paymentMethodsActionTypes.FETCH_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        loadingPayments: false,
        messageErrorPayment: action.error
      };

    case paymentMethodsActionTypes.FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        messageErrorPayment: '',
        loginError: false,
        loadingPayments: false,
        paymentsFetch: {
            ...action.data,
          },
      };

        

    default:
      return state;
  }
};
