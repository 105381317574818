import React, { Component } from 'react';

//React icons
import { TbFileExport } from 'react-icons/tb';
import { RxReload } from 'react-icons/rx'


//components internal
import TableInfo from '../../components/advancedComponents/tableInfo';
import DropDown from '../../components/basicComponents/DropDown';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';
import Switch from '../../components/basicComponents/Switch';

//components external
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Tooltip } from 'react-tooltip';
import Col from 'react-bootstrap/Col';
import { CSVLink } from 'react-csv';

//styles
import styles from '../../styles/screens/invoices.module.css';
import titleStyle from '../../styles/titles.module.css';

//Models
import { usersManagersModel } from '../../models/user/user_model';
import { MyUserModel } from '../../models/user/user_model';

//Redux
import { connect } from 'react-redux';
import { InvoiceInfo } from '../../models/invoice/invoice_model';
import { invoiceFetch, archiveInvoice, invoiceLink } from '../../actions/invoices-actions';
import { usersManagerFetch } from '../../actions/users-action';

//Screens
import InternalDetailsInvoice from './internalDetailsInvoices';

interface Props {
  //inherited
  myUser: MyUserModel,

  //Redux and Reducers
  invoiceFetch: (activePaid: boolean, activeArchive: boolean) => void,
  invoices: Array<InvoiceInfo>,
  invoiceLink: (id: string) => void,
  invoiceLinkR: Array<InvoiceInfo>,
  usersManagers: Array<usersManagersModel>,
  usersManagerFetch: () => void,
  archiveInvoice: (id: string) => void,
  create_invoice: any
}

interface State {
  invoicesTable: Array<any>
  valuesDropdownManager: any
  valuesDropdownStatus: any
  filterManager: string
  filterStatus: string
  activeInvoicePaid: boolean
  activeInvoiceArchive: boolean
  activeInvoicePaidBefore: boolean
  activeInvoiceArchiveBefore: boolean

  showInvoiceDetails: boolean,
  idSelected: string,
  folioSelected: string,
  invoiceUrlId: string,
  statusSelected: string,
  contractorSelected: string,
  monthly_feeSelected: string,
  hourly_rateSelected: string,
  AMSelected: string,
  payment_methodSelected: string,
  payment_detailsSelected: string,
  proyectSelected: any,
  billable_hoursSelected: any,
  totalSelected: any,
  periodStartSelected: any,
  periodEndSelected: any,
  periodSelected: any,
  noteSelected: string
  createdSelected: any,
  amountSelected: any,
  invoice_dateSelected: any
}


class InvoicesTableHC extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const initialState = {
      invoicesTable: [],
      filterManager: "All managers",
      filterStatus: "10",
      valuesDropdownManager: [],
      valuesDropdownStatus: [
        { value: "10", label: "All status" },
        { value: "0", label: "Submitted" },
        { value: "1", label: "Approved - AM" },
        { value: "2", label: "Approved - FIN" },
        { value: "3", label: "Paid" },
        { value: "4", label: "Rejected - AM" },
        { value: "5", label: "Rejected - FIN" },
        { value: "6", label: "Archived" }
      ],
      activeInvoicePaid: false,
      activeInvoiceArchive: false,
      activeInvoicePaidBefore: false,
      activeInvoiceArchiveBefore: false,
      showInvoiceDetails: false,
      idSelected: "",
      folioSelected: "",
      statusSelected: "",
      contractorSelected: "",
      monthly_feeSelected: "",
      hourly_rateSelected: "",
      AMSelected: "",
      payment_methodSelected: "",
      payment_detailsSelected: "",
      proyectSelected: "",
      invoiceUrlId: window.location.search,
      billable_hoursSelected: "",
      totalSelected: "",
      periodStartSelected: "",
      periodEndSelected: "",
      periodSelected: "",
      noteSelected: "",
      createdSelected: "",
      amountSelected: "",
      invoice_dateSelected: ""
    };
    this.state = initialState;
  }
  /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
  componentDidMount() {
    const { invoiceFetch, usersManagerFetch, invoiceLink } = this.props;
    invoiceFetch(false, false);
    usersManagerFetch()
    let linkInvoiceSelected = false, idSelected = ""
    const { invoiceUrlId } = this.state;
    if (invoiceUrlId != undefined && invoiceUrlId != null && invoiceUrlId != '') {
      const invoiceUrlIdValueArray = invoiceUrlId.split('=')
      if (invoiceUrlIdValueArray[0].substring(1) == "invoice") {
        linkInvoiceSelected = true
        idSelected = invoiceUrlIdValueArray[1]
        invoiceLink(idSelected)
      }
    }
  }
  componentDidUpdate(prevProps: Props) {
    //Invoices
    this.loadInvoices(prevProps)
    //Managers
    this.loadManagers(prevProps)

    //loadInvoiceLink
    if (this.props.invoiceLinkR != null && this.props.invoiceLinkR != undefined &&
      prevProps.invoiceLinkR != null && prevProps.invoiceLinkR != undefined) {
      if (this.props.invoiceLinkR != prevProps.invoiceLinkR &&
        prevProps.invoiceLinkR.length == 0 && this.props.invoiceLinkR.length != 0) {
        if (Array.isArray(Object.values(this.props.invoiceLinkR)) && !this.state.showInvoiceDetails) {
          const valuesTable = Object.values(this.props.invoiceLinkR);
          valuesTable.map((invoice: InvoiceInfo) => {
            this.setState({
              showInvoiceDetails: true,
              idSelected: invoice.id,
              folioSelected: invoice.folio,
              statusSelected: this.renderStatusName(invoice.status)!,
              contractorSelected: invoice.contractor,
              monthly_feeSelected: this.currencyFormatter("USD", invoice.monthlyFee),
              hourly_rateSelected: this.currencyFormatter("USD", invoice.hourly_rate),
              AMSelected: invoice.AM,
              payment_methodSelected: invoice.payment,
              payment_detailsSelected: invoice.payment_details,
              proyectSelected: invoice.proyect,
              billable_hoursSelected: invoice.billable_hours,
              totalSelected: this.currencyFormatter("USD", invoice.total),
              periodStartSelected: invoice.periodStart,
              periodEndSelected: invoice.periodEnd,
              periodSelected: this.periodFormat(invoice.periodStart, invoice.periodEnd),
              noteSelected: invoice.note,
              createdSelected: invoice.invoice_date,
              invoice_dateSelected: invoice.invoice_date
            })
          })
        }

      }
    }
  }








  //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
  loadManagers = (prevProps: Props) => {
    if (this.props.usersManagers != prevProps.usersManagers) {
      const managers = Object.values(this.props.usersManagers);
      if (Array.isArray(managers)) {
        let managersValues: any[] = [{ value: "0", label: "All managers" }]
        managers.map((managerFilter: usersManagersModel) => {
          managersValues.push({ value: managerFilter.id, label: managerFilter.name })
        })
        this.setState({ valuesDropdownManager: managersValues })
      }
    }
  }


  loadInvoices = (prevProps: Props) => {
    if (this.props.invoices != prevProps.invoices) {
      this.filterInvoices(this.state.filterManager, this.state.filterStatus, false)
    }
  }









  //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////
  selectInvoice = (selectedInvoice: InvoiceInfo) => {
    this.setState({
      showInvoiceDetails: true,
      idSelected: selectedInvoice.id,
      folioSelected: selectedInvoice.folio,
      statusSelected: selectedInvoice.status,
      contractorSelected: selectedInvoice.contractor,
      monthly_feeSelected: selectedInvoice.monthy_fee,
      hourly_rateSelected: selectedInvoice.hourly_rate,
      AMSelected: selectedInvoice.AM,
      payment_methodSelected: selectedInvoice.payment,
      payment_detailsSelected: selectedInvoice.payment_details,
      proyectSelected: selectedInvoice.proyect,
      billable_hoursSelected: selectedInvoice.billable_hours,
      totalSelected: selectedInvoice.total,
      periodStartSelected: selectedInvoice.periodStart,
      periodEndSelected: selectedInvoice.periodEnd,
      periodSelected: selectedInvoice.period,
      noteSelected: selectedInvoice.note,
      createdSelected: selectedInvoice.created,
      invoice_dateSelected: selectedInvoice.invoice_date
    })
  }








  ///////////////////FORMATS FUNCTIONS//////////////////////////////////////
  renderStatusName = (valueStatus: any) => {
    if (valueStatus == 0) {
      return "Submitted"
    }
    if (valueStatus == 1) {
      return "Approved - AM"
    }
    if (valueStatus == 2) {
      return "Approved - FIN"
    }
    if (valueStatus == 3) {
      return "Paid"
    }
    if (valueStatus == 4) {
      return "Rejected - AM"
    }
    if (valueStatus == 5) {
      return "Rejected - FIN"
    }
    if (valueStatus == 6) {
      return "Archived"
    }
  }
  currencyFormatter = (currency: any, value: any) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency
    })
    return formatter.format(value)
  }
  periodFormat = (startDate: any, endDate: any) => {
    const startFormat = startDate.substring(5, 7) + "/" + startDate.substring(8, 10) + "/" + startDate.substring(0, 4);
    const endFormat = endDate.substring(5, 7) + "/" + endDate.substring(8, 10) + "/" + endDate.substring(0, 4);
    return startFormat + " - " + endFormat
  }

  dateFormat = (date: any) => {
    const dateValue = date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4);
    return dateValue
  }







  //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
  filterInvoices = (accountManager: any, invoiceStatus: any, DropDown: boolean) => {
    const { filterManager, filterStatus } = this.state;
    const filterAMV = accountManager != null ? DropDown ? accountManager.label : accountManager : filterManager
    const filterStatusV = invoiceStatus != null ? DropDown ? invoiceStatus.value : invoiceStatus : filterStatus
    this.setState({ filterManager: filterAMV, filterStatus: filterStatusV })
    const valuesTable = Object.values(this.props.invoices);
    if (Array.isArray(valuesTable)) {
      let invoicesValues: any[] = []
      valuesTable.map((invoice: InvoiceInfo) => {
        if (((filterAMV == "All managers" || filterAMV == "Account Manager") || filterAMV == invoice.AM) && (filterStatusV == "10" || filterStatusV == invoice.status)) {
          invoicesValues.push({
            id: invoice.id,
            folio: invoice.folio,
            status: this.renderStatusName(invoice.status),
            contractor: invoice.contractor,
            monthy_fee: this.currencyFormatter("USD", invoice.monthlyFee),
            hourly_rate: this.currencyFormatter("USD", invoice.hourly_rate),
            AM: invoice.AM,
            payment: invoice.payment,
            payment_details: invoice.payment_details,
            proyect: invoice.proyect,
            billable_hours: invoice.billable_hours,
            period: this.periodFormat(invoice.periodStart, invoice.periodEnd),
            total: this.currencyFormatter("USD", invoice.total),
            note: invoice.note,
            amount: this.currencyFormatter("USD", invoice.amount),
            invoice_date: invoice.invoice_date
          })

        }
      })
      this.setState({ invoicesTable: invoicesValues })
    }
  }








  /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
  render() {
    const { idSelected, folioSelected, noteSelected, totalSelected, periodSelected, createdSelected,
      proyectSelected, statusSelected, contractorSelected, hourly_rateSelected,
      monthly_feeSelected, payment_detailsSelected, payment_methodSelected, billable_hoursSelected, invoice_dateSelected,
      AMSelected, valuesDropdownManager, valuesDropdownStatus,
      activeInvoiceArchive, activeInvoicePaid, activeInvoiceArchiveBefore, activeInvoicePaidBefore, invoicesTable
    } = this.state
    const { myUser } = this.props
    const columns = [
      {
        name: 'DATE',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label className={styles.spanValueTable}>{this.dateFormat(row.invoice_date)}</label>
          </div>
        ),
        selector: (row: any, i: any) => row.invoice_date,
        sortable: true,

      },
      {
        name: 'CONTRACTOR',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label className={styles.spanValueTable}>{row.contractor}</label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.contractor
      },
      {
        name: 'INVOICE NO.',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label onClick={() => {
              this.selectInvoice({
                id: row.id,
                folio: row.folio,
                status: row.status,
                contractor: row.contractor,
                monthy_fee: row.monthy_fee,
                hourly_rate: row.hourly_rate,
                AM: row.AM,
                payment: row.payment,
                payment_details: row.payment_details,
                proyect: row.proyect,
                billable_hours: row.billable_hours,
                total: row.total,
                periodStart: row.periodStart,
                periodEnd: row.periodEnd,
                period: row.period,
                note: row.note,
                created: row.created,
                amount: row.amount,
                invoice_date: row.invoice_date
              })

            }} className={styles.spanValueTableFolio}>{row.folio}</label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.folio
      },
      {
        name: 'AM ASSIGNED',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label className={styles.spanValueTable}>{row.AM}</label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.AM
      },
      {
        name: 'AMOUNT',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label className={styles.spanValueTable}>
              {row.amount}
            </label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.amount
      },
      {
        name: 'PERIOD',
        center: true,
        cell: (row: any) => (
          <div className={styles.__divCellTable}>
            <label className={styles.spanValueTable}>
              {row.period}
            </label>
          </div>
        ),
        sortable: true,
        selector: (row: any, i: any) => row.period
      },
      {
        name: 'STATUS',
        center: true,
        cell: (row: InvoiceInfo) => (
          <div className={styles.__divCellTable}>
            <label className={[styles.spanValueTable, styles.spanValueTableStatus,
            row.status == "Submitted" ? styles.__spanSubmitted :
              row.status == "Approved - AM" ? styles.__spanApprovedAM :
                row.status == "Approved - FIN" ? styles.__spanApprovedFinance :
                  row.status == "Paid" ? styles.__spanPaid :
                    row.status == "Rejected - AM" ? styles.__spanRejectedAM :
                      row.status == "Rejected - FIN" ? styles.__spanRejectedFinance :
                        row.status == "Archived" ? styles.__spanArchived
                          : ""
            ].join(' ')}>
              {row.status}
            </label>
          </div>
        ),
        selector: (row: any, i: any) => row.status,
        sortable: true,
      }
    ];
    const headers = [
      { label: "Invoice date", key: "invoice_date" },
      { label: "Contractor", key: "contractor" },
      { label: "Invoice no.", key: "folio" },
      { label: "AM ASSIGNED", key: "AM" },
      { label: "period", key: "period" },
      { label: "Amount", key: "amount" },
      { label: "Status", key: "status" }
    ];
    return (
      this.state.showInvoiceDetails ?
        <InternalDetailsInvoice
          myUser={this.props.myUser}
          id={idSelected}
          folio={folioSelected}
          status={statusSelected}
          contractor={contractorSelected}
          monthly_fee={monthly_feeSelected}
          hourly_rate={hourly_rateSelected}
          AM={AMSelected}
          back={() => { this.setState({ showInvoiceDetails: false }) }}
          payment_method={payment_methodSelected}
          payment_details={payment_detailsSelected}
          proyect={proyectSelected}
          billable_hours={billable_hoursSelected}
          total={totalSelected}
          period={periodSelected}
          note={noteSelected}
          created={createdSelected}
          invoice_date={invoice_dateSelected}
        /> :
        <Container className={styles.__MainContent}>
          <Row className={titleStyle.__MainRowTitle}>
            <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
              <label className={titleStyle.__titlePart1}></label>
              <label className={titleStyle.__titlePart2}></label>
              <label className={titleStyle.__titlePart3}>INVOICES</label>
              <label className={titleStyle.__titlePart4}></label>
              <label className={titleStyle.__titlePart5}></label>
            </Col>
          </Row>
          <ReduxLoader />
          <Row className="">
            <Col lg="12" md="12" sm="12"><h4 className={styles.__MainTitle}></h4></Col>
            <Row className={styles.__rowHeadTable}>
              <Col lg="3" md="6" sm="12" className={styles.__buttonHeadTable}>
                <DropDown defaultValue={{ label: "Account Manager", value: "0" }}
                  options={valuesDropdownManager} onChange={(value: any) => { this.filterInvoices(value, null, true) }} />
              </Col>
              <Col lg="3" md="6" sm="12" className={styles.__buttonHeadTable}>
                <DropDown defaultValue={{ label: "Invoice status", value: "10" }}
                  options={valuesDropdownStatus} onChange={(value: any) => {
                    if (value.label == "Paid") {
                      const paidValue = activeInvoicePaid ? activeInvoicePaid : !activeInvoicePaid
                      this.props.invoiceFetch(paidValue, activeInvoiceArchiveBefore);
                      this.setState({ activeInvoicePaid: paidValue, activeInvoiceArchive: activeInvoiceArchiveBefore })
                      this.filterInvoices(null, value, true)
                    } else if (value.label == "Archived") {
                      const archivedValue = activeInvoiceArchive ? activeInvoiceArchive : !activeInvoiceArchive
                      this.props.invoiceFetch(activeInvoicePaidBefore, archivedValue);
                      this.setState({ activeInvoiceArchive: archivedValue, activeInvoicePaid: activeInvoicePaidBefore })
                      this.filterInvoices(null, value, true)
                    } else {
                      if (activeInvoiceArchive != activeInvoiceArchiveBefore || activeInvoicePaid != activeInvoicePaidBefore) {
                        this.props.invoiceFetch(activeInvoicePaidBefore, activeInvoiceArchiveBefore);
                        this.setState({ activeInvoiceArchive: activeInvoiceArchiveBefore, activeInvoicePaid: activeInvoicePaidBefore })
                      }
                      this.filterInvoices(null, value, true)
                    }

                  }} />
              </Col>
              <Col lg="6" md="6" sm="12" className={styles.__buttonHeadTableButtons}>
                <Tooltip id={"exportButton"} />
                <CSVLink data-tooltip-id="exportButton" data-tooltip-content="Export invoices"
                  className={styles.__buttonExport}
                  filename={"Invoices (" + new Date().toISOString().replace(/-/g, '_').substring(0, 10) + " - " + new Date().toISOString().substring(11, 16).replace(/:/g, ' ') + ")"}
                  data={invoicesTable} headers={headers}>
                  <TbFileExport />
                </CSVLink>
                <Tooltip id={"Reload"} />
                <ButtonForm id={"Reload"}
                  toltipText={"Reload"}
                  extraClass={styles.__buttonReload} colorFill
                  onClick={async () => {
                    this.props.invoiceFetch(activeInvoicePaid, activeInvoiceArchive)
                  }}
                  icon={<RxReload />}
                />
                <Col lg="12" md="12" sm="12" className={styles.__SwitchHeadTableButtons}>
                  <div>
                    <span className={styles.__filterSpan}>Archived</span> <Switch
                      onChange={() => {
                        this.props.invoiceFetch(activeInvoicePaid, !activeInvoiceArchive);
                        this.setState({ activeInvoiceArchive: !activeInvoiceArchive, activeInvoiceArchiveBefore: !activeInvoiceArchive })
                      }}
                      checked={activeInvoiceArchive} />
                  </div>
                  <div>
                    <span className={styles.__filterSpan}>Paid</span> <Switch
                      onChange={() => {
                        this.props.invoiceFetch(!activeInvoicePaid, activeInvoiceArchive);
                        this.setState({ activeInvoicePaid: !activeInvoicePaid, activeInvoicePaidBefore: !activeInvoicePaid })
                      }}
                      checked={activeInvoicePaid} />
                  </div>
                </Col>
              </Col>
            </Row>
            <TableInfo paginationPerPage={10} columns={columns} data={invoicesTable} />
          </Row>
        </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  invoices: state.invoiceReducer.paymentsFetch,
  usersManagers: state.usersReducer.usersManagers,
  invoiceLinkR: state.invoiceReducer.invoiceLinkR,
  create_invoice: state.invoiceReducer.create_invoice
});

const mapDispatchToProps = (dispatch: any) => ({
  invoiceFetch: (activePaid: boolean, activeArchive: boolean) => dispatch(invoiceFetch(activePaid, activeArchive)),
  usersManagerFetch: () => dispatch(usersManagerFetch()),
  invoiceLink: (id: string) => dispatch(invoiceLink(id)),
  archiveInvoice: (id: string) => dispatch(archiveInvoice(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTableHC);