// Redux
import { Dispatch } from 'redux';
import { paymentMethodsActionTypes } from "../constants/paymentMethod-constants";
import { API } from '../api/paymentsMethod-api';
import * as types from '../types/paymentMethods-types';

// Models
import { PaymentMethodModel } from "../models/payments/paymentsMethod-model";


// -------------------------------------FETCH PAYMENT METHODS ----------------------------------------

const paymentMethodsBegin = (): types.IPaymentMethodsBegin => ({
  type: paymentMethodsActionTypes.FETCH_PAYMENT_METHODS_BEGIN,
});

const paymentMethodsError = (e: any, errorType: any): types.IPaymentMethodsError => ({
  type: paymentMethodsActionTypes.FETCH_PAYMENT_METHODS_ERROR,
  error: e,
  errorType: errorType
});

const paymentMethodsSuccess = (data: Array<PaymentMethodModel>): types.IPaymentMethodsSuccess => ({
  type: paymentMethodsActionTypes.FETCH_PAYMENT_METHODS_SUCCESS,
  data,
});

export const fetchPaymentMethods = () => (dispatch: Dispatch) => {
  dispatch(paymentMethodsBegin());
  API.paymentMethods().then((response: any) => {
    dispatch(paymentMethodsSuccess(response.data));
  }).catch((error: any) => {
    dispatch(paymentMethodsError(error.response.data.message, error.response.request.status));
  });
};