// Redux
import { Reducer } from 'redux';
import { filesBucketAction } from '../types/filesBucket-types';
import { filesBucketActionTypes } from "../constants/filesBucket-contants";

//models
import { FilesInvoices } from "../models/invoice/filesInvoice_model"
import { FilesUser } from "../models/user/filesUser_model"

interface fileBucketState {
    loadingUpload: boolean,
    messageError: string,
    filesInvoice: Array<FilesInvoices>,
    filesUser: Array<FilesUser>,
    loadingGetSuccess: boolean
}

const initialState: fileBucketState = {
    loadingUpload: false,
    messageError: "",
    filesInvoice: [],
    filesUser: [],
    loadingGetSuccess: false
};

export const filesBucketReducer: Reducer<fileBucketState, filesBucketAction> = (state = initialState, action: filesBucketAction) => {
    switch (action.type) {
        // ------------------ FETCH INVOICE FILES-----------------------------
        case filesBucketActionTypes.GET_FILES_INVOICE_BEGIN:
            return {
                ...state,
                messageError: '',
                loadingUpload: true,
                loadingGetSuccess: true
            };

        case filesBucketActionTypes.GET_FILES_INVOICE_ERROR:
            return {
                ...state,
                loadingUpload: false,
                messageError: action.error,
                filesInvoices: {} as FilesInvoices,
                loadingGetSuccess: false
            };

        case filesBucketActionTypes.GET_FILES_INVOICE_SUCCESS:
            return {
                ...state,
                messageError: '',
                loadingUpload: false,
                filesInvoice: {
                    ...action.filesInvoices,
                },
                loadingGetSuccess: false
            };
        // ------------------ UPLOAD INVOICE FILES-----------------------------
        case filesBucketActionTypes.UPLOAD_INVOICE_BEGIN:
            return {
                ...state,
                messageError: '',
                loadingUpload: true,
            };

        case filesBucketActionTypes.UPLOAD_INVOICE_ERROR:
            return {
                ...state,
                loadingUpload: false,
                messageError: action.error
            };

        case filesBucketActionTypes.UPLOAD_INVOICE_SUCCESS:
            return {
                ...state,
                messageError: '',
                loadingUpload: false,
            };

        // ------------------ FETCH USER FILES-----------------------------
        case filesBucketActionTypes.GET_FILES_USER_BEGIN:
            return {
                ...state,
                messageError: '',
                loadingUpload: true,
                loadingGetSuccess: true
            };

        case filesBucketActionTypes.GET_FILES_USER_ERROR:
            return {
                ...state,
                loadingUpload: false,
                messageError: action.error,
                filesInvoices: {} as FilesUser,
                loadingGetSuccess: false
            };

        case filesBucketActionTypes.GET_FILES_USER_SUCCESS:
            return {
                ...state,
                messageError: '',
                loadingUpload: false,
                filesUser: {
                    ...action.filesUser,
                },
                loadingGetSuccess: false
            };
        // ------------------ UPLOAD USER FILES-----------------------------
        case filesBucketActionTypes.UPLOAD_USER_BEGIN:
            return {
                ...state,
                messageError: '',
                loadingUpload: true,
            };

        case filesBucketActionTypes.UPLOAD_USER_ERROR:
            return {
                ...state,
                loadingUpload: false,
                messageError: action.error
            };

        case filesBucketActionTypes.UPLOAD_USER_SUCCESS:
            return {
                ...state,
                messageError: '',
                loadingUpload: false,
            };

        default:
            return state;
    }
};
