import React, { Component } from 'react';

//React Icons
import ViewIcon from '../../assets/svg/view.svg';
import CancelIcon from '../../assets/svg/cancel.svg';
import DownloadIcon from '../../assets/svg/download.svg';

//External Components
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

//Internal Components
import UploadFile from '../../components/basicComponents/UploadFile';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import TextField from '../../components/basicComponents/TextField';
import TextFieldArea from '../../components/basicComponents/TextFieldArea';
import ToastAlert from '../../components/basicComponents/ToastAlert';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';
import DropDown from '../../components/basicComponents/DropDown';

//Styles
import styles from '../../styles/screens/myProfile.module.css';
import tableStyle from '../../styles/basicComponents/table.module.css';
import radioStyles from '../../styles/basicComponents/radioBtn.module.css';
import titleStyle from '../../styles/titles.module.css';

//Models
import { NewUser as NewUserCreate, usersManagersModel } from '../../models/user/user_model';

//redux
import { newUser } from '../../actions/newUser-actions';
import { connect } from 'react-redux';
import { usersManagerFetch } from '../../actions/users-action';
import { filesBucketUserUpload } from '../../actions/filesBucket-actions';





interface Props {
    //Redux and Reducers
    newUser: (data: NewUserCreate) => void,
    usersManagers: Array<usersManagersModel>,
    usersManagerFetch: () => void,
    messageError: string,
    filesBucketUserUpload: (files: any) => void,
    loadingUpload: boolean
}

interface State {
    dataCreate: NewUserCreate,
    textArea: string
    hourRate: any
    month: any
    monthFee: any
    userValue: any
    roleValue: any
    placeholderInputUpload: string
    fileInputUpload: any
    filesNames: any[],
    files: any[]
    managers: any[]
    activeForceLoader: boolean

    emailRepeat: boolean

    valueTitle: string

    //Toast
    saveSuccess: boolean
    saveError: boolean

    //errors
    nameError: boolean
    emailError: boolean
    checkError: boolean
    monthError: boolean
    selectUserError: boolean
    selectRoleError: boolean
    errorAMSelect: boolean
    errorFile: boolean
}
class NewUser extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            dataCreate: {
                name: '',
                user: '',
                phone: '',
                email: '',
                address: '',
                role: 'd8ae5776067290c4712fa454006c8ec6',
                hourly_rate: '',
                monthly_fee: '',
                manager: '0'
            },
            saveSuccess: false,
            saveError: false,
            textArea: '',
            userValue: '',
            month: '',
            roleValue: '',
            amValue: '',
            monthFee: '',
            hourRate: '',
            activeForceLoader: false,
            placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)',
            fileInputUpload: '',
            filesNames: [],
            files: [],

            managers: [],

            emailRepeat: false,

            valueTitle: "",

            //errors 
            nameError: false,
            emailError: false,
            monthError: false,
            checkError: false,
            selectUserError: false,
            selectRoleError: false,
            errorAMSelect: false,
            errorFile: false
        };
        this.state = initialState;
    }


    /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
    componentDidMount = () => {
        this.props.usersManagerFetch()
    }

    componentDidUpdate = (prevProps: Props) => {
        const { usersManagers, messageError } = this.props
        if (usersManagers != prevProps.usersManagers) {
            const managers = Object.values(usersManagers);
            if (Array.isArray(managers)) {
                let managersValues: any[] = []
                managers.map((managerFilter: usersManagersModel) => {
                    managersValues.push({ value: managerFilter.id, label: managerFilter.name })
                })
                this.setState({ managers: managersValues })
            }
        }
        if (messageError != prevProps.messageError && prevProps.messageError == "" &&
            messageError == "email repeat") {
            this.setState({ emailRepeat: true, saveSuccess: false })
            document.getElementById('emailFocusOnError')!.focus()
        }
        if (messageError != prevProps.messageError && prevProps.messageError == "" &&
            messageError.split("||")[0] == "no Error") {
            const { files } = this.state
            this.setState({ activeForceLoader: true })
            for (var i = 0; i < files.length; i++) {
                const formData = new FormData();
                formData.append('file', files[i])
                formData.append('user', messageError.split("||")[1])
                this.props.filesBucketUserUpload(formData)
            }
            const windowScroll = document.getElementById("windowScrollAnimation");
            windowScroll!.scrollTop = 0;
            this.setState({ saveSuccess: true, activeForceLoader: false })
            setTimeout(() => { window.location.href = "/userView" }, 1700)
        }

    }







    //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////
    onCancel = () => {
        window.location.href = "/userView"
    }

    onclickUpload = (fileV: any, fileNameV: any) => {
        if (fileV != "") {
            const file: any[] = this.state.files
            const filesName: any[] = this.state.filesNames
            file.unshift(fileV)
            filesName.unshift(fileNameV)
            this.setState({
                files: file, filesNames: filesName,
                fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)'
            })
            const inputElement = document.getElementById('fileDropdownGeneral') as HTMLInputElement
            inputElement.value = ""
        }
    }

    deleteFile = (value: any) => {
        const files = this.state.files.slice();
        const filesNames = this.state.filesNames.slice();

        if (value !== -1) {
            files.splice(value, 1);
            filesNames.splice(value, 1);
            this.setState({ files, filesNames });
        }
    }

    downLoadFile = (value: any, name: any) => {
        const { files } = this.state
        var urlArchivo = URL.createObjectURL(files[value]);

        var linkDescarga = document.createElement('a');
        linkDescarga.href = urlArchivo;
        linkDescarga.download = name;
        linkDescarga.click();
        URL.revokeObjectURL(urlArchivo);
    }

    previewFile = (value: any, name: any) => {
        const { files } = this.state
        var urlArchivo = URL.createObjectURL(files[value]);
        window.open(urlArchivo, '_blank');
        URL.revokeObjectURL(urlArchivo);
    }

    onSubmit = () => {
        let emptyName = false, emptyEmail = false, emptyMonth = false, noSelectUser = false, noCheck = false, errorAM = false
        const { dataCreate } = this.state;

        const email_regex = new RegExp(/^[A-Z0-9._%+-]+\@[A-Z0-9.]+$/i);

        if (this.state.month === '' && this.state.dataCreate.role == 'd8ae5776067290c4712fa454006c8ec6') {
            emptyMonth = true;
            this.setState({ monthError: true });
            document.getElementById('monthlyFeeFocusOnError')?.focus();
        }
        if (this.state.roleValue.trim() === '' && this.state.userValue == "Internal") {
            noCheck = true
            this.setState({ checkError: true })
        }
        if (dataCreate.email.trim() === '' || !email_regex.test(dataCreate.email)) {
            emptyEmail = true;
            this.setState({ emailError: true });
            document.getElementById('emailFocusOnError')!.focus()
        }
        if (dataCreate.name.trim() === '') {
            emptyName = true;
            this.setState({ nameError: true });
            document.getElementById('nameFocusOnError')!.focus()
        }
        if (this.state.userValue === '') {
            noSelectUser = true
            this.setState({ selectUserError: true })
        }
        if (dataCreate.role === '') {
            noSelectUser = true
            this.setState({ selectRoleError: true })
        }
        if (this.state.userValue == "Contractor" && dataCreate.manager == "0") {
            errorAM = true
            this.setState({ errorAMSelect: true })
        }
        if (emptyName || emptyEmail || emptyMonth || noSelectUser || noCheck || errorAM) {
            this.setState({ saveError: true })
            return
        } else {
            this.setState({ saveSuccess: true })
            this.props.newUser(this.state.dataCreate);
        }
    }








    //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
    onChangeUpload = (file: any) => {
        if (file.target.files[0] != undefined) {
            if (file.target.files[0].size < 10485750 &&
                file.target.files[0].name.split(".").length == 2
            ) {
                const extencion = file.target.files[0].name.split(".")
                if (extencion[1] == "pdf" || extencion[1] == "doc" || extencion[1] == "docx" || extencion[1] == "xlsx" || extencion[1] == "csv") {
                    this.onclickUpload(file.target.files[0], file.target.files[0].name)
                }
                else {
                    this.setState({ fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)', errorFile: true })
                }
            } else {
                this.setState({ fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)', errorFile: true })
            }

        }
    }

    onchangeName = (value: any) => {
        const { dataCreate } = this.state;
        dataCreate.name = value;
        this.setState({ dataCreate, nameError: false });
    };
    onchangeEmail = (value: any) => {
        const emailValue = value.replace(/\s/g, "")
        const { dataCreate } = this.state;
        dataCreate.email = emailValue;
        this.setState({ dataCreate, emailError: false, emailRepeat: false });
        dataCreate.user = dataCreate.email;
        this.setState({ dataCreate });
    };
    onchangePhone = (value: any) => {
        const { dataCreate } = this.state;
        dataCreate.phone = value;
        this.setState({ dataCreate });
    };
    onchangeText = (value: any) => {
        const { dataCreate } = this.state;
        dataCreate.address = value;
        this.setState({ dataCreate });
    };
    userChange = (value: any) => {
        this.setState({ userValue: value.label });
        const { dataCreate } = this.state;
        if (value.label === 'Contractor') {
            dataCreate.role = 'd8ae5776067290c4712fa454006c8ec6';
        }
        this.setState({ dataCreate, selectUserError: false })
    };
    roleChange = (value: any) => {
        this.setState({ roleValue: value.target.value, checkError: false });
        const { dataCreate } = this.state;
        if (value.target.value === 'finance') {
            dataCreate.role = 'd10e57760671893j712fa454777c8c10';
        } else if (value.target.value === 'accountManager') {
            dataCreate.role = 'd9ae5776067190c4712fa454986c8ec9';
        } else if (value.target.value === 'HC') {
            dataCreate.role = 'd12e57760671893j712fa454777c8c12';
        }
    }
    amChange = (value: any) => {
        const { dataCreate } = this.state;
        dataCreate.manager = value.value;
        this.setState({ dataCreate, errorAMSelect: false });
    };
    onChangeMonth(value: any) {
        const { dataCreate } = this.state
        let formattedValue = value.replace('$', '').replace(' ', '').replace(/,/g, "")
        let money = Number(formattedValue) / 160;
        let showMoney = money.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        let aMonth = Number(formattedValue);
        let totalMonth = aMonth.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        dataCreate.hourly_rate = showMoney
        dataCreate.monthly_fee = '$ ' + formattedValue
        this.setState({ dataCreate });
        this.setState({ month: totalMonth });
        if (value == '') { this.setState({ monthError: true }) }
        else { this.setState({ monthError: false }) }
    }
    onBlurMonth = () => {
        const { dataCreate } = this.state
        dataCreate.monthly_fee = this.state.month
        this.setState({ dataCreate })
    }







    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const options = [{ value: 'Contractor', label: 'Contractor' }, { value: 'Internal', label: 'Internal' }];
        const { saveSuccess, saveError, activeForceLoader } = this.state
        return (

            <Container className={styles.__MainContent}>
                <Row className={titleStyle.__MainRowTitle}>
                    <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
                        <label onClick={() => { window.location.href = "/userView" }} className={titleStyle.__titlePart1}>USERS</label>
                        <label className={titleStyle.__titlePart2}>{">"}</label>
                        <label className={titleStyle.__titlePart3}>NEW USER</label>
                        <label className={titleStyle.__titlePart4}>{this.state.valueTitle != "" && '>'}</label>
                        <label className={titleStyle.__titlePart5}>{this.state.valueTitle}</label>
                    </Col>
                </Row>
                {/* 👇======================Loader===========================👇 */}
                <ReduxLoader activeForce={activeForceLoader} />
                <ToastAlert
                    showToast={saveSuccess}
                    timeActive={3000}
                    disableFunction={() => { this.setState({ saveSuccess: false }) }}
                />
                <ToastAlert
                    error
                    label="Required data is missing"
                    showToast={saveError}
                    timeActive={3000}
                    disableFunction={() => { this.setState({ saveError: false }) }}
                />
                <Row>
                    <Col lg="12" md="12" sm="12"><h5 className={styles.__subTitle}>GENERAL INFORMATION</h5></Col>
                </Row>
                <Row onClick={() => { this.setState({ valueTitle: "General information" }) }}>
                    <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Name*</label>
                        <TextField maxLenght={50} value={this.state.dataCreate.name} id="nameFocusOnError" error={this.state.nameError} placeholder="Full name" onChange={(value: any) => { this.onchangeName(value) }} />
                        {this.state.nameError &&
                            <span className={styles.err} >This field is required</span>
                        }
                    </Col>
                    <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Email address*</label>
                        <TextField maxLenght={50} value={this.state.dataCreate.email} id="emailFocusOnError" error={this.state.emailError || this.state.emailRepeat} placeholder="Email address" onChange={(value: any) => { this.onchangeEmail(value) }} />
                        {this.state.emailError &&
                            <span className={styles.err} >This field is required</span>
                        }
                        {this.state.emailRepeat &&
                            <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This email is already registered.</span>
                        }
                    </Col>
                </Row>
                <Row className="justify-content-md-left" onClick={() => { this.setState({ valueTitle: "General information" }) }}>
                    <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Phone number</label>
                        <TextField maxLenght={17} value={this.state.dataCreate.phone} placeholder="Phone number" onChange={(value: any) => { this.onchangePhone(value) }} />
                    </Col>
                </Row>
                <Row onClick={() => { this.setState({ valueTitle: "General information" }) }}>
                    <Col lg="12" md="12" sm="12" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Full address</label>
                        <TextFieldArea maxLenght={100} value={this.state.dataCreate.address} placeholder="Write your full address, incluiding Zip Code and Country" onChange={(value: any) => { this.onchangeText(value) }} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12"><h5 className={styles.__subTitle}>CONFIGURATION</h5></Col>
                </Row>
                <Row onClick={() => { this.setState({ valueTitle: "Type of user" }) }}>
                    <Col lg="12" md="12" sm="12">
                        <label className={styles.__boldText}>Type of user*</label>
                        <DropDown error={this.state.selectUserError} defaultValue={{ value: "0", label: "Choose the type of this new user" }} options={options} onChange={(value: any) => { this.userChange(value) }} />
                        {this.state.selectUserError &&
                            <span className={styles.err} >Please select the type of user</span>
                        }
                    </Col>
                </Row>
                {this.state.userValue === "Contractor" &&
                    <>
                        <Row onClick={() => { this.setState({ valueTitle: "Configuration" }) }}>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>AM Assigned*</label>
                                <DropDown error={this.state.errorAMSelect} defaultValue={{ value: "0", label: "Select the assigned AM" }} options={this.state.managers} onChange={(value: any) => { this.amChange(value) }} />
                                {this.state.errorAMSelect &&
                                    <span id="requiredMonthField" className={styles.err} >This field is required</span>
                                }
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Monthly fee*</label>
                                <TextField maxLenght={50} onlyNumbers onBlur={() => { this.onBlurMonth() }} id='monthlyFeeErrorFocus' error={this.state.monthError} placeholder='$4,000.00'
                                    onChange={(value: any) => { this.onChangeMonth(value) }} disabled={false} value={this.state.dataCreate.monthly_fee} />
                                {this.state.monthError &&
                                    <span id="requiredMonthField" className={styles.err} >This field is required</span>
                                }
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Hourly rate</label>
                                <TextField maxLenght={50} placeholder='$25.00' onChange={() => { }} value={this.state.dataCreate.hourly_rate} disabled />
                            </Col>
                        </Row>
                        <Row onClick={() => { this.setState({ valueTitle: "Configuration" }) }}>
                            <Col lg="12" md="12" sm="12">
                                <label className={styles.__boldText}>Attachments</label>
                                <UploadFile onClick={() => { this.setState({ errorFile: false }) }} error={this.state.errorFile} placeholder={this.state.placeholderInputUpload} onChange={(value: any) => { this.onChangeUpload(value) }} />
                            </Col>
                        </Row>
                        <Col>
                            <Table responsive>
                                <tbody>
                                    {this.state.filesNames.length > 0 && this.state.filesNames.map((value: any, index: number) => (
                                        <>
                                            <tr>
                                                <td className={tableStyle.tableItem}>
                                                    <span id={index.toString()}>{value}</span>
                                                </td>
                                                {value.split(".")[1] == "pdf" ?
                                                    <>
                                                        <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                        <td><img onClick={() => { this.previewFile(index, value) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td>
                                                        <td><img onClick={() => { this.deleteFile(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                                                    </>
                                                    :
                                                    <>
                                                        <td></td>
                                                        <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                                        <td><img onClick={() => { this.deleteFile(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                                                    </>
                                                }

                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </>
                }
                {this.state.userValue === "Internal" &&
                    <>
                        <Row className={styles.__RoleText}>
                            <label className={this.state.checkError ? styles.errLabel : styles.__boldText}>Role*</label>
                        </Row>
                        <Row onClick={() => { this.setState({ valueTitle: "Role" }) }}>
                            <Col lg="3" md="3" sm="3" >
                                <div className={radioStyles.radioItem}>
                                    <input id='man' type="radio" value="accountManager" checked={this.state.roleValue === "accountManager"} onChange={(value: any) => { this.roleChange(value) }} />
                                    <label htmlFor='man'>Account Manager</label>
                                </div>
                            </Col>
                            <Col lg="3" md="3" sm="3">
                                <div className={radioStyles.radioItem}>
                                    <input id='fin' type="radio" value="finance" checked={this.state.roleValue === "finance"} onChange={(value: any) => { this.roleChange(value) }} />
                                    <label htmlFor='fin'>Finance</label>
                                </div>
                            </Col>
                            <Col lg="3" md="3" sm="3">
                                <div className={radioStyles.radioItem}>
                                    <input id='hc' type="radio" value="HC" checked={this.state.roleValue === "HC"} onChange={(value: any) => { this.roleChange(value) }} />
                                    <label htmlFor='hc'>Human Capital</label>
                                </div>
                            </Col>
                        </Row>
                        {this.state.checkError &&
                            <span className={styles.errCheck}>This field is required</span>
                        }
                    </>
                }
                <Col lg="12" md="12" sm="12">
                    <div className={styles.__buttonsSaveCancel}>
                        <ButtonForm onClick={() => { this.onCancel() }} extraClass={styles.__marginButtonsCancel} label="Cancel" />
                        <ButtonForm onClick={() => { this.onSubmit() }} extraClass={styles.__marginButtonsSave} colorFill label="Save" />
                    </div>
                </Col>
            </Container>

        )
    }
}

const mapStateToProps = (state: any) => ({
    usersManagers: state.usersReducer.usersManagers,
    messageError: state.newUserReducer.messageError,
    loadingUpload: state.filesBucketReducer.loadingUpload,
});
const mapDispatchToProps = (dispatch: any) => ({
    newUser: (data: NewUserCreate) => dispatch(newUser(data)),
    usersManagerFetch: () => dispatch(usersManagerFetch()),
    filesBucketUserUpload: (files: any) => dispatch(filesBucketUserUpload(files))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
