
import { combineReducers } from 'redux';
import { authReducer } from './auth-reducer';
import { newUserReducer } from './newUser-reducer';
import { paymentMethodsReducer } from './paymentMethods-reducer';
import { usersReducer } from './users-reducer';
import { activityLogReducer } from './activityLog-reducer';
import { invoiceReducer } from './invoices-reducer';
import { filesBucketReducer } from './filesBucket-reducer';
import { reportReducer } from './report-reducers';

const appReducer = combineReducers({
  authReducer: authReducer,
  newUserReducer: newUserReducer,
  paymentMethodsReducer: paymentMethodsReducer,
  usersReducer: usersReducer,
  activityLogReducer: activityLogReducer,
  invoiceReducer: invoiceReducer,
  filesBucketReducer: filesBucketReducer,
  reportReducer: reportReducer
});

const rootReducer = (state: any, action: any) => {
  if (action != undefined) {
    if (action.errorType == "401") {
      if (localStorage.getItem('tokenInvoicing') != undefined &&
        localStorage.getItem('tokenInvoicing') != null &&
        localStorage.getItem('tokenInvoicing') != "" && window.location.pathname != "/emailVerified") {
        alert("session expired")
        window.location.href = "/"
        localStorage.clear();
      }

    }
  }
  return appReducer(state, action);
};

export default rootReducer;
