// Axios
import axios from 'axios';

const base_api_url = process.env.REACT_APP_BASE_URL;

const createUser = (data : any) => {
    const headers = {
        headers:{
            Authorization : "Bearer "+ localStorage.getItem('tokenInvoicing')
        }
      };
    return axios.post(`${base_api_url}users/createuser`, data, headers);
}

export const API_CREATE_USER = {
    createUser: createUser
}