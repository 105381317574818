export enum authActionTypes {
    LOGIN_BEGIN = "LOGIN_BEGIN",
    LOGIN_ERROR = "LOGIN_ERROR",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",

    LOGOUT_BEGIN = "LOGOUT_BEGIN",
    LOGOUT_ERROR = "LOGOUT_ERROR",
    LOGOUT_SUCCESS = "LOGOUT_SUCCESS",

    USER_AUTH_BEGIN = "USER_AUTH_BEGIN",
    USER_AUTH_ERROR = "USER_AUTH_ERROR",
    USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS",

    USER_AUTH_UPDATE_BEGIN = "USER_AUTH_UPDATE_BEGIN",
    USER_AUTH_UPDATE_ERROR = "USER_AUTH_UPDATE_ERROR",
    USER_AUTH_UPDATE_SUCCESS = "USER_AUTH_UPDATE_SUCCESS",

    CLEAN_DATA_LOGIN = "CLEAN_DATA_LOGIN",

    USER_AUTH_UPDATE_PASSWORD_BEGIN = "USER_AUTH_UPDATE_PASSWORD_BEGIN",
    USER_AUTH_UPDATE_PASSWORD_ERROR = "USER_AUTH_UPDATE_PASSWORD_ERROR",
    USER_AUTH_UPDATE_PASSWORD_SUCCESS = "USER_AUTH_UPDATE_PASSWORD_SUCCESS",

    USER_AUTH_REQUEST_PASSWORD_EMAIL_BEGIN = "USER_AUTH_REQUEST_PASSWORD_EMAIL_BEGIN",
    USER_AUTH_REQUEST_PASSWORD_EMAIL_ERROR = "USER_AUTH_REQUEST_PASSWORD_EMAIL_ERROR",
    USER_AUTH_REQUEST_PASSWORD_EMAIL_SUCCESS = "USER_AUTH_REQUEST_PASSWORD_EMAIL_SUCCESS",

    USER_AUTH_REQUEST_FORGOT_PASSWORD_BEGIN = "USER_AUTH_REQUEST_FORGOT_PASSWORD_BEGIN",
    USER_AUTH_REQUEST_FORGOT_PASSWORD_ERROR = "USER_AUTH_REQUEST_FORGOT_PASSWORD_ERROR",
    USER_AUTH_REQUEST_FORGOT_PASSWORD_SUCCESS = "USER_AUTH_REQUEST_FORGOT_PASSWORD_SUCCESS",

    GET_INITIAL_DATA_UPDATE_PASSWORD_BEGIN = "GET_INITIAL_DATA_UPDATE_PASSWORD_BEGIN",
    GET_INITIAL_DATA_UPDATE_PASSWORD_ERROR = "GET_INITIAL_DATA_UPDATE_PASSWORD_ERROR",
    GET_INITIAL_DATA_UPDATE_PASSWORD_SUCCESS = "GET_INITIAL_DATA_UPDATE_PASSWORD_SUCCESS",

    UPDATE_EMAIL_USER_BEGIN = "UPDATE_EMAIL_USER_BEGIN",
    UPDATE_EMAIL_USER_ERROR = "UPDATE_EMAIL_USER_ERROR",
    UPDATE_EMAIL_USER_SUCCESS = "UPDATE_EMAIL_USER_SUCCESS",

    CANCEL_UPDATE_EMAIL_USER_BEGIN = "CANCEL_UPDATE_EMAIL_USER_BEGIN",
    CANCEL_UPDATE_EMAIL_USER_ERROR = "CANCEL_UPDATE_EMAIL_USER_ERROR",
    CANCEL_UPDATE_EMAIL_USER_SUCCESS = "CANCEL_UPDATE_EMAIL_USER_SUCCESS",
}
