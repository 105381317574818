// Redux
import { Reducer } from 'redux';
import { ActivityLogAction } from '../types/activityLog-types';
import { activityLogActionTypes } from '../constants/activityLog-constants';
// Models
import { ActivityLogModel } from '../models/activity/activityLogModel';

interface ActivityLogState {
  loading: boolean,
  log: Array<ActivityLogModel>,
  messageError: string,
  countRecords: any
}

const initialState: ActivityLogState = {
  loading: false,
  log: [],
  messageError: "",
  countRecords: ""
};

export const activityLogReducer: Reducer<ActivityLogState, ActivityLogAction> = (state = initialState, action: ActivityLogAction) => {
  switch (action.type) {
    // ------------------ ActivityLog FETCH -----------------------------
    case activityLogActionTypes.LOG_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
      };

    case activityLogActionTypes.LOG_ERROR:
      return {
        ...state,
        loginError: true,
        loading: false,
        messageError: action.error
      };

    case activityLogActionTypes.LOG_SUCCESS:
      return {
        ...state,
        messageError: '',
        loading: false,
        log: {
          ...action.data,
        },
      };

    // ------------------ ActivityLog COUNT -----------------------------
    case activityLogActionTypes.COUNT_RECORDS_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
        countRecords: ""
      };

    case activityLogActionTypes.COUNT_RECORDS_ERROR:
      return {
        ...state,
        loginError: true,
        loading: false,
        messageError: action.error,
        countRecords: ""
      };

    case activityLogActionTypes.COUNT_RECORDS_SUCCESS:
      return {
        ...state,
        messageError: '',
        loading: false,
        countRecords: {
          ...action.data,
        },
      };

    default:
      return state;
  }
};
