import React, { Component } from 'react';

//Styles
import styles from '../../styles/screens/updatePassword.module.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//Components
import TextField from '../../components/basicComponents/TextField';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import PopUp from '../../components/basicComponents/PopUp';

//Models
import { getInitialUserByToken, sendInitialUserByToken } from '../../models/auth/newUserAuthPassword-model';

//Logo
import newLogo from '../../assets/img/Logo-Invoiscio-gris.png';

//Icons
import { RiLockPasswordLine } from 'react-icons/ri';
import { BsX, BsCheck } from 'react-icons/bs';

//Redux
import { connect } from 'react-redux';
import { updateUserPassoword, getInitialDataUpdatePassword } from '../../actions/auth-actions';

interface Props {
  //Redux and Reducers
  myUserConfirm: getInitialUserByToken,
  updateUserPassoword: (password: string) => void,
  getInitialDataUpdatePassword: (data: sendInitialUserByToken) => void
}

interface State {
  password: string
  confirmPassword: string
  email: string
  role: string
  name: string

  //URL Values
  token: string

  //Eye Icon
  showPassword: boolean
  showConfirmPassword: boolean

  //Pop Up
  popUpTrigger: boolean
  //errors
  ErrorEmptyPassoword: boolean
  ErrorEmptyConfirmPassoword: boolean
  ErrorNoSpaces: boolean
  ErrorNotSecure: boolean
  ErrorNoMatch: boolean
  ErrorMinLength: boolean
  ErrorMaxLength: boolean
  ErrorLowerCase: boolean
  ErrorUpperCase: boolean
  ErrorNumber: boolean
  ErrorSymbol: boolean
  ErrorSymbolNotAllowed: boolean
}

class UpdatePassword extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const initialState = {
      password: '',
      confirmPassword: '',
      email: '',
      role: '',
      name: '',

      //URL Values
      token: window.location.hash.slice(1),

      //Eye Icons
      showPassword: false,
      showConfirmPassword: false,

      //Pop Up
      popUpTrigger: false,

      //Errors
      ErrorEmptyPassoword: false,
      ErrorEmptyConfirmPassoword: false,
      ErrorNoSpaces: false,
      ErrorNoMatch: false,
      ErrorNotSecure: false,
      ErrorMinLength: false,
      ErrorMaxLength: false,
      ErrorLowerCase: false,
      ErrorUpperCase: false,
      ErrorNumber: false,
      ErrorSymbol: false,
      ErrorSymbolNotAllowed: false,
    };
    this.state = initialState;
  }
  //////////////////BASIC FUNCTIONS////////////////////////////////////////////
  componentDidMount() {
    this.props.getInitialDataUpdatePassword({ token: this.state.token })
  }

  componentDidUpdate(prevProps: Props) {
    try {
      if (this.props.myUserConfirm != prevProps.myUserConfirm) {
        this.setState({ email: this.props.myUserConfirm.email, role: this.props.myUserConfirm.role, name: this.props.myUserConfirm.name })
      }
    } catch (e: any) {
    }
  }







  //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
  onChangePassword = (value: any) => {
    const lowerCase_regex = new RegExp(/[a-z]/);
    const upperCase_regex = new RegExp(/[A-Z]/);
    const number_regex = new RegExp(/[0-9]/);
    const symbols_regex = new RegExp(/[- ! @ # % ^ &  * _  + = , .]/);
    const symbolsNotAllowed_regex = new RegExp(/[\\/(){}'"\]|\[]/);
    const spaces_regex = new RegExp(/\s/g);
    let lowCase = true, upCase = true, errorNum = true, errorSymbol = true, errorLength = true;


    this.setState({
      password: value,
      ErrorEmptyPassoword: false,
      ErrorNoMatch: false
    })
    if (lowerCase_regex.test(value)) {
      this.setState({ ErrorLowerCase: true })

    } else { this.setState({ ErrorLowerCase: false }); lowCase = false }

    if (upperCase_regex.test(value)) {
      this.setState({ ErrorUpperCase: true })
    } else { this.setState({ ErrorUpperCase: false }); upCase = false }

    if (number_regex.test(value)) {
      this.setState({ ErrorNumber: true })
    } else { this.setState({ ErrorNumber: false }); errorNum = false }

    if (symbols_regex.test(value)) {
      this.setState({ ErrorSymbol: true })
    } else { this.setState({ ErrorSymbol: false }); errorSymbol = false }

    if (symbolsNotAllowed_regex.test(value)) {
      this.setState({ ErrorSymbolNotAllowed: true })
    } else { this.setState({ ErrorSymbolNotAllowed: false }) }

    if (value.length >= 12) {
      this.setState({ ErrorMinLength: true })
    } else { this.setState({ ErrorMinLength: false }); errorLength = false }

    if (value.length > 50) {
      this.setState({ ErrorMaxLength: true })
    } else { this.setState({ ErrorMaxLength: false }) }

    if (spaces_regex.test(value)) {
      this.setState({ ErrorNoSpaces: true })
    } else { this.setState({ ErrorNoSpaces: false }) }

    if (!lowCase || !upCase || !errorNum || !errorSymbol || !errorLength) {
      this.setState({ ErrorNotSecure: true })
    } else { this.setState({ ErrorNotSecure: false }) }

    if (value == '') {
      this.setState({
        ErrorEmptyPassoword: false,
        ErrorEmptyConfirmPassoword: false,
        ErrorNoSpaces: false,
        ErrorNoMatch: false,
        ErrorNotSecure: false,
        ErrorMinLength: false,
        ErrorMaxLength: false,
        ErrorLowerCase: false,
        ErrorUpperCase: false,
        ErrorNumber: false,
        ErrorSymbol: false,
        ErrorSymbolNotAllowed: false,
      })
    }
  }

  onConfirmPassword = (value: any) => {
    this.setState({
      confirmPassword: value,
      ErrorEmptyConfirmPassoword: false,
      ErrorNoMatch: false
    })
  }






  //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////
  onSubmit = () => {
    const allRequirements = this.state.ErrorMinLength && this.state.ErrorLowerCase
      && this.state.ErrorUpperCase && this.state.ErrorNumber
      && this.state.ErrorSymbol && !this.state.ErrorNoSpaces && !this.state.ErrorMaxLength && !this.state.ErrorSymbolNotAllowed;
    const matchingPasswords = this.state.password == this.state.confirmPassword;

    if (this.state.password == '') {
      this.setState({ ErrorEmptyPassoword: true })
      return
    }
    if (!allRequirements) {
      return
    }
    if (!matchingPasswords) {
      this.setState({ ErrorNoMatch: true, ErrorNotSecure: false });
      return
    }

    this.props.updateUserPassoword(this.state.password)

    this.setState({ popUpTrigger: true, password: '', confirmPassword: '' })
    localStorage.clear()
  }

  onPopUp = () => {
    localStorage.clear();
    window.location.href = "./";
  }






  /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
  render() {
    const { ErrorMinLength, ErrorNotSecure, ErrorNoMatch, ErrorEmptyPassoword, ErrorEmptyConfirmPassoword,
      ErrorLowerCase, ErrorUpperCase, ErrorNumber, ErrorSymbol, ErrorNoSpaces, ErrorMaxLength,
      showPassword, showConfirmPassword, ErrorSymbolNotAllowed, popUpTrigger, role, email, name } = this.state
    return (
      <>
        <div className={styles.__bgWhite}>
          <div className={styles.__centerAll}>
            <img className={styles.__invocieLogo} src={newLogo} alt="Scio logo" />
            <div>
              <h5 className={styles.__welcomeTitle}> <RiLockPasswordLine className={styles.__welcomeIcon} />Hi {name},<br /> Welcome to the password setting page</h5>
            </div>
            <PopUp passwordTrigger={popUpTrigger} title="Congratulations!" subTitle=" Your password has been successfully set."
              text="By clicking the button below you will be redirected to the login page." buttonTxt="GO TO LOGIN"
              onClick={() => { this.onPopUp() }} />
            <div className="d-flex justify-content-around mt-5">
              <div className={styles.__container}>
                <div className={styles.__userInfo}>
                  User: {email} | {role == "Manager" ? "Account Manager" : role}
                </div>
                <div className={styles.__leftContainerText}>
                  <p>Please keep in mind these recommendations to maintain the security of your account:</p>
                  <ul>
                    <li>We recommend choosing a strong and unique password.</li>
                    <li>Avoid using personal information such as your name, birthdate, or address as part of your password.</li>
                    <li>It's also important to avoid using the same password for multiple accounts.</li>
                    <li>Finally, your password should be at least <strong>12 characters long</strong> and include a combination of <strong>uppercase</strong> and <strong>lowercase</strong> letters, <strong>numbers</strong>, and <strong>symbols</strong>.</li>
                  </ul>
                </div>
              </div>
              <div className={styles.__container}>
                <div className="mt-4">
                  <label htmlFor='password' className='form-label fw-bold' id='password-label'>Password</label>
                  <TextField maxLenght={50} type={showPassword ? "text" : "password"} error={ErrorEmptyPassoword || ErrorNoMatch || ErrorNoSpaces || ErrorNotSecure || ErrorMaxLength} value={this.state.password} placeholder='Password' onChange={(value: any) => { this.onChangePassword(value) }} />
                  <div onClick={() => { this.setState({ showPassword: !showPassword }) }} className={['input-group-append', styles.eyeDivBase].join(' ')}>
                    <span className={['btn ', ErrorEmptyPassoword || ErrorNoMatch || ErrorNoSpaces || ErrorNotSecure || ErrorMaxLength ? styles.eyeError : styles.eye].join(' ')} id="basic-addon2">
                      {showPassword && <i className={['bi bi-eye', styles.eye_icon].join(' ')} id='eye-icon' ></i>}
                      {!showPassword && <i className={['bi bi-eye-slash', styles.eye_icon].join(' ')} id='eye-icon' ></i>}
                    </span>
                  </div>
                </div>
                <div className={styles.__passwordRequirements}>
                  <div>
                    {ErrorEmptyPassoword && <span className={styles.__errorText}>This field is required.</span>}
                    {ErrorMaxLength && <span className={styles.__errorText}>You've reached max password length.</span>}
                    {ErrorNoSpaces && <span className={styles.__errorText}>Can't contain spaces.</span>}
                    {ErrorNotSecure && <span className={styles.__errorText}>Password is not secure.</span>}
                    {ErrorNoMatch && <span className={styles.__errorText}>Password <strong>DOESN’T</strong> match.</span>}
                    {ErrorSymbolNotAllowed && <span className={styles.__errorText}>Symbol not allowed.</span>}


                  </div>
                  <div className="mt-2">
                    {ErrorMinLength ? <span className={styles.__correctIcon}> <BsCheck /> Minimum length<br /></span> : <span className={styles.__alertIcon}><BsX /> Minimum length<br /></span>}
                    {ErrorLowerCase ? <span className={styles.__correctIcon}> <BsCheck /> Lowercase<br /></span> : <span className={styles.__alertIcon}><BsX /> Lowercase<br /></span>}
                    {ErrorUpperCase ? <span className={styles.__correctIcon}> <BsCheck /> Uppercase<br /></span> : <span className={styles.__alertIcon}><BsX /> Uppercase<br /></span>}
                    {ErrorNumber ? <span className={styles.__correctIcon}> <BsCheck /> Number<br /></span> : <span className={styles.__alertIcon}><BsX /> Number<br /></span>}
                    {ErrorSymbol ? <span className={styles.__correctIcon}> <BsCheck /> Symbol (! @ # % ^ & * _ - + = , .)<br /></span> : <span className={styles.__alertIcon}><BsX /> Symbol (! @ # % ^ & * _ - + = , .)<br /></span>}
                  </div>
                </div>
                <div className="mt-3 mb-3">
                  <label htmlFor='password' className='form-label fw-bold' id='confirmPassword-label'>Please confirm your password</label>
                  <TextField maxLenght={50} type={showConfirmPassword ? "text" : "password"} error={ErrorEmptyConfirmPassoword || ErrorNoMatch} value={this.state.confirmPassword} placeholder='Password' onChange={(value: any) => { this.onConfirmPassword(value) }} />
                  <div onClick={() => { this.setState({ showConfirmPassword: !showConfirmPassword }) }} className={['input-group-append', styles.eyeDivBase].join(' ')}>
                    <span className={['btn ', ErrorEmptyConfirmPassoword || ErrorNoMatch ? styles.eyeError : styles.eye].join(' ')} id="basic-addon2">
                      {showConfirmPassword && <i className={['bi bi-eye', styles.eye_icon].join(' ')} id='eye-icon' ></i>}
                      {!showConfirmPassword && <i className={['bi bi-eye-slash', styles.eye_icon].join(' ')} id='eye-icon' ></i>}
                    </span>
                  </div>
                  <div className={styles.__passwordRequirements}>
                    {ErrorEmptyConfirmPassoword && <span className={styles.__errorText}>This field is required.</span>}
                    {ErrorNoMatch && <span className={styles.__errorText}>Password <strong>DOESN’T</strong> match.</span>}
                  </div>
                </div>
                <div className={styles.__buttonDiv}>
                  <ButtonForm onClick={() => { this.onSubmit() }} extraClass={styles.__buttonSize} colorFill label="Set password" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  myUserConfirm: state.authReducer.userInitialUpdatePassword,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateUserPassoword: (password: string) => dispatch(updateUserPassoword(password)),
  getInitialDataUpdatePassword: (data: sendInitialUserByToken) => dispatch(getInitialDataUpdatePassword(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);