import React, { Component } from 'react';
import axios from 'axios';

//Icons
import ViewIcon from '../../assets/svg/view.svg';
import CancelIcon from '../../assets/svg/cancel.svg';
import DownloadIcon from '../../assets/svg/download.svg';

//Styles
import styles from '../../styles/screens/editUser.module.css';
import titleStyle from '../../styles/titles.module.css';

//Internal components
import TextField from '../../components/basicComponents/TextField';
import TextFieldArea from '../../components/basicComponents/TextFieldArea';
import DropDown from '../../components/basicComponents/DropDown';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import UploadFile from '../../components/basicComponents/UploadFile';
import ToastAlert from '../../components/basicComponents/ToastAlert';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';

//External components
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//models 
import { usersManagersModel } from '../../models/user/user_model';

//Redux
import { connect } from 'react-redux';
import { userAuth } from '../../actions/auth-actions';
import { usersManagerFetch, UpdateUserDetailsModal } from '../../actions/users-action';
import { filesBucketUserUpload } from '../../actions/filesBucket-actions';


interface Props {
  //inherited
  onClick?: () => void,
  data?: any,
  filesNamesUploaded?: any[],
  filesUploaded?: any[],
  filesUser?: any[],

  //Redux and Reducers
  filesBucketUserUpload: (files: any) => void,
  UpdateUserDetailsModal?: (data: any) => void,
  userAuth?: () => void,
  usersManagers: Array<usersManagersModel>,
  usersManagerFetch: () => void,
  users: Array<any>,
  messageError: string
}

interface State {
  filesNames: any[]
  filesNamesUploaded?: any[],
  filesUploaded?: any[]
  files: any[],
  placeholderInputUpload: string
  fileInputUpload: any

  id: any
  name: string
  email: string
  phone: string
  address: string

  hourRate: any

  monthFee: any
  month: any
  valueTitle: string
  emailRepeat: boolean

  AM: any
  AMValue: any
  amOptions: Array<any>
  editedInput: boolean
  saveButtonActive: boolean

  //Errors
  nameError: boolean
  emailError: boolean
  emailRegexError: boolean
  monthlyFeeError: boolean
  errorFile: boolean

  //Toast
  saveSuccess: boolean;
  saveError: boolean;
  activeForceLoader: boolean
}

class EditUser extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const initialState = {
      filesNames: [],
      filesNamesUploaded: this.props.filesNamesUploaded,
      filesUploaded: this.props.filesUploaded,
      files: [],
      placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)',
      fileInputUpload: [],

      id: this.props.data.id,
      name: this.props.data.name,
      email: this.props.data.email,
      phone: this.props.data.phone,
      address: this.props.data.address,

      hourRate: this.props.data.hourly_rate.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      monthFee: this.props.data.monthly_fee.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      month: this.props.data.monthly_fee.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      saveButtonActive: false,

      AM: this.props.data.AM,
      AMValue: "",
      amOptions: [],
      editedInput: true,
      valueTitle: "",
      emailRepeat: false,
      //Errors
      nameError: false,
      emailError: false,
      emailRegexError: false,
      monthlyFeeError: false,
      errorFile: false,

      //Toast
      saveSuccess: false,
      saveError: false,
      activeForceLoader: false
    }

    this.state = initialState;
  }


  /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
  componentDidMount = async () => {
    const windowScroll = document.getElementById("windowScrollAnimation");
    windowScroll!.scrollTop = 0;

    this.props.usersManagerFetch()
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.data != prevProps.data) {
      this.setState({
        name: this.props.data.name,
        email: this.props.data.email,
        phone: this.props.data.phone,
        address: this.props.data.address,

        hourRate: this.props.data.hourly_rate,
        monthFee: this.props.data.monthly_fee,

        AM: this.props.data.AM,
        filesNamesUploaded: this.props.filesNamesUploaded,
        filesUploaded: this.props.filesUploaded
      })
    }
    if (this.props.usersManagers != prevProps.usersManagers) {
      const managers = Object.values(this.props.usersManagers);
      if (Array.isArray(managers)) {
        let managersValues: any[] = []
        managers.map((managerFilter: usersManagersModel) => {
          managersValues.push({ value: managerFilter.id, label: managerFilter.name })
          if (managerFilter.name == this.props.data.AM) {
            this.setState({ AMValue: managerFilter.id })
          }
        })
        this.setState({ amOptions: managersValues })
      }
    }
    if (this.props.messageError != prevProps.messageError && prevProps.messageError == "" &&
      this.props.messageError == "email repeat") {
      this.setState({ emailRepeat: true, saveSuccess: false })
      document.getElementById('emailErrorFocus')!.focus()
    }
    if (this.props.messageError != prevProps.messageError && prevProps.messageError == "" &&
      this.props.messageError == "no Error") {
      this.setState({ activeForceLoader: true })
      await this.uploadNewFiles()
      await this.updateFiles()
      const windowScroll = document.getElementById("windowScrollAnimation");
      windowScroll!.scrollTop = 0;
      this.setState({ saveSuccess: true, activeForceLoader: false })
      setTimeout(() => { window.location.href = "/userView" }, 1700)
    }
  }







  //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
  uploadNewFiles = async () => {
    const { files } = this.state
    for (var i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file', files[i])
      formData.append('user', this.props.data.id)
      this.props.filesBucketUserUpload(formData)
    }
  }

  updateFiles = async () => {
    if (this.props.filesUser != undefined && this.props.filesUser != null) {
      const filesInvoicesV = Object.values(this.props.filesUser);
      if (filesInvoicesV.length > 0) {
        filesInvoicesV.map((file: any) => {
          if (!(this.state.filesUploaded!.includes(file.data))) {
            const headers = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
              }
            };
            const base_api_url = process.env.REACT_APP_BASE_URL;
            axios.delete(`${base_api_url}uploadFiles/deleteFileUser/` + file.id, headers);
          }
        })
      }
    }
  }

  activeDisableSaveButton = () => {
    const { name, email, phone, address, AM, monthFee } = this.state;
    const { data } = this.props;
    const formattedValue = data.monthly_fee.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    if (name == data.name && email == data.email && phone == data.phone && address == data.address &&
      monthFee == formattedValue && AM == data.AM) {
      this.setState({ saveButtonActive: false })
    } else {
      this.setState({ saveButtonActive: true })
    }
  }








  //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////
  onclickUpload = (fileV: any, fileNameV: any) => {
    if (fileV != "") {
      const file: any[] = this.state.files
      const filesName: any[] = this.state.filesNames
      file.unshift(fileV)
      filesName.unshift(fileNameV)
      this.setState({ files: file, filesNames: filesName, fileInputUpload: '', saveButtonActive: true, placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)' })
      const inputElement = document.getElementById('fileDropdownGeneral') as HTMLInputElement
      inputElement.value = ""
    }
  }

  onSubmit = async () => {
    const { nameError, emailError, emailRegexError,
      monthlyFeeError, name, email, phone, address, hourRate,
      monthFee, AM, id, AMValue } = this.state;

    const allRequirements = nameError || emailError || emailRegexError || monthlyFeeError;


    if (monthlyFeeError) { document.getElementById('monthlyFeeErrorFocus')?.focus(); }

    if (emailError || emailRegexError) { document.getElementById('emailErrorFocus')?.focus(); }

    if (nameError) { document.getElementById('nameErrorFocus')?.focus(); }

    if (allRequirements) {
      const windowScroll = document.getElementById("windowScrollAnimation");
      windowScroll!.scrollTop = 0;
      this.setState({ saveError: true })
      return
    }

    this.setState({ saveSuccess: true })
    await this.props.UpdateUserDetailsModal!({
      id: id, name: name, email: email, phone: phone, address: address,
      hourly_rate: hourRate.toString().replace('$', '').replace(/ /g, '').replace(/,/g, ""), monthly_fee: monthFee.toString().replace('$', '').replace(/ /g, '').replace(/,/g, ""),
      manager: AMValue
    });
  }

  handleView = (fileName: any) => {
    const base_api_url = process.env.REACT_APP_BASE_URL;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    };

    axios.get(`${base_api_url}uploadFiles/viewUser/${fileName}`, {
      headers,
      responseType: 'arraybuffer'
    })
      .then(response => {
        const contentType = response.headers['content-type'];
        const fileBlob = new Blob([response.data], { type: contentType });
        const fileUrl = URL.createObjectURL(fileBlob);
        window.open(fileUrl);
      })
      .catch((error: any) => {
      });
  };

  handleDownload = (fileName: any) => {
    const base_api_url = process.env.REACT_APP_BASE_URL;
    const headers = {
      Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
    };

    axios.get(`${base_api_url}uploadFiles/viewUser/${fileName}`, {
      headers,
      responseType: 'arraybuffer'
    })
      .then(response => {
        const contentType = response.headers['content-type'];
        const fileBlob = new Blob([response.data], { type: contentType });
        const fileUrl = URL.createObjectURL(fileBlob);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(fileUrl);
      })
      .catch((error: any) => {
      });
  };

  handleDelete = (value: any) => {
    const files = this.state.filesUploaded!.slice();
    const filesNames = this.state.filesNamesUploaded!.slice();

    if (value !== -1) {
      files.splice(value, 1);
      filesNames.splice(value, 1);
      this.setState({ filesUploaded: files, filesNamesUploaded: filesNames, saveButtonActive: true });
    }
  };

  deleteFile = (value: any) => {
    const files = this.state.files.slice();
    const filesNames = this.state.filesNames.slice();

    if (value !== -1) {
      files.splice(value, 1);
      filesNames.splice(value, 1);
      this.setState({ files, filesNames, saveButtonActive: true });
    }
  }

  downLoadFile = (value: any, name: any) => {
    const { files } = this.state
    var urlArchivo = URL.createObjectURL(files[value]);

    var linkDescarga = document.createElement('a');
    linkDescarga.href = urlArchivo;
    linkDescarga.download = name;
    linkDescarga.click();
    URL.revokeObjectURL(urlArchivo);
  }

  previewFile = (value: any, name: any) => {
    const { files } = this.state
    var urlArchivo = URL.createObjectURL(files[value]);
    window.open(urlArchivo, '_blank');
    URL.revokeObjectURL(urlArchivo);
  }








  //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
  onChangeUpload = (file: any) => {
    if (file.target.files[0] != undefined) {
      if (file.target.files[0].size < 10485750 &&
        file.target.files[0].name.split(".").length == 2
      ) {
        const extencion = file.target.files[0].name.split(".")
        if (extencion[1] == "pdf" || extencion[1] == "doc" || extencion[1] == "docx" || extencion[1] == "xlsx" || extencion[1] == "csv") {
          this.onclickUpload(file.target.files[0], file.target.files[0].name)
        }
        else {
          this.setState({ fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)', errorFile: true })
        }
      } else {
        this.setState({ fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)', errorFile: true })
      }

    }
  }
  onChangeName(value: any) {
    const noSpace_regex = new RegExp(/^(\s)[\w\s-]*$/);
    this.setState({ name: value });
    setTimeout(() => this.activeDisableSaveButton(), 40)
    if (value == '' || noSpace_regex.test(value)) {
      this.setState({ nameError: true })
    }
    else { this.setState({ nameError: false }) }
  }

  onChangeEmail(value: any) {
    const emailValue = value.replace(/\s/g, "")
    const email_regex = new RegExp(/^[A-Z0-9._%+-]+\@[A-Z0-9.]+$/i);
    setTimeout(() => this.activeDisableSaveButton(), 40)
    this.setState({ email: emailValue });
    if (!email_regex.test(emailValue)) { this.setState({ emailRegexError: true }) }
    else { this.setState({ emailRegexError: false }) }
    if (emailValue == '') { this.setState({ emailRegexError: false, emailError: true }) }
    else { this.setState({ emailError: false }) }
    this.setState({ emailRepeat: false })
  }

  onChangePhone(value: any) {
    this.setState({ phone: value });
    setTimeout(() => this.activeDisableSaveButton(), 40)
  }

  onChangeAddress(value: any) {
    this.setState({ address: value });
    setTimeout(() => this.activeDisableSaveButton(), 40)
  }
  amChange(value: any) {
    this.setState({
      AM: value.label,
      AMValue: value.value
    })
    setTimeout(() => this.activeDisableSaveButton(), 40)
  }
  onChangeMonth(value: any) {
    let formattedValue = value.replace('$', '').replace(' ', '').replace(/,/g, "")
    let money = Number(formattedValue) / 160;
    let showMoney = money.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    let aMonth = Number(formattedValue);
    let totalMonth = aMonth.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    this.setState({ monthFee: '$ ' + formattedValue, hourRate: showMoney });
    this.setState({ month: totalMonth });
    setTimeout(() => this.activeDisableSaveButton(), 40)
    if (value == '') { this.setState({ monthlyFeeError: true }) }
    else { this.setState({ monthlyFeeError: false }) }
  }
  onBlurMonth = () => {
    this.setState({ monthFee: this.state.month })

  }






  /////////////////////////RENDER ON SCREEN////////////////////////////////////////////

  render() {
    const { filesNames, files, filesNamesUploaded, filesUploaded, name, email, phone, address, hourRate, monthFee, month, AM, amOptions, nameError,
      emailError, emailRegexError, monthlyFeeError, saveSuccess, saveError, emailRepeat,
      editedInput, AMValue, saveButtonActive } = this.state
    return (
      <>
        <ToastAlert
          showToast={saveSuccess}
          timeActive={3000}
          disableFunction={() => {
            this.setState({ saveSuccess: false });
          }}
        />
        <ToastAlert
          error
          label="Required data is missing"
          showToast={saveError}
          timeActive={3000}
          disableFunction={() => {
            this.setState({ saveError: false });
          }}
        />
        <ReduxLoader activeForce={this.state.activeForceLoader} />
        <Row className={titleStyle.__MainRowTitle}>
          <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
            <label onClick={() => { window.location.href = "/userView" }} className={titleStyle.__titlePart1}>USERS</label>
            <label className={titleStyle.__titlePart2}>{">"}</label>
            <label className={titleStyle.__titlePart3}>EDIT USER</label>
            <label className={titleStyle.__titlePart4}>{this.state.valueTitle != "" && '>'}</label>
            <label className={titleStyle.__titlePart5}>{this.state.valueTitle}</label>
          </Col>
        </Row>
        <div>
          <h5 className={styles.__generalInfoTitle}>GENERAL INFORMATION</h5>
          <Row onClick={() => { this.setState({ valueTitle: "GENERAL INFORMATION" }) }}>
            <Col lg="6" md="6" sm="6">
              <label className='form-label fw-bold'>Name</label>
              <TextField maxLenght={50} id='nameErrorFocus' value={name} error={nameError} onChange={(value: any) => { this.onChangeName(value); }} />
              {nameError && <span className={styles.__errorText} >This field is required</span>}
            </Col>

            <Col lg="6" md="6" sm="6">
              <label className='form-label fw-bold'>Email address</label>
              <TextField maxLenght={50} id='emailErrorFocus' value={email} error={emailError || emailRegexError || emailRepeat} onChange={(value: any) => { this.onChangeEmail(value); }} />
              {emailError && <span className={styles.__errorText} >This field is required</span>}
              {emailRegexError && <span className={styles.__errorText} >Invalid email</span>}
              {emailRepeat &&
                <span className={['text-danger', styles.alert].join(' ')} id='alert-user'>This email is already registered.</span>
              }
            </Col>
          </Row>

          <Row onClick={() => { this.setState({ valueTitle: "GENERAL INFORMATION" }) }}>
            <Col lg="6" md="6" sm="6">
              <label htmlFor='phonenumber' className='form-label fw-bold'>Phone number</label>
              <TextField maxLenght={17} id='phoneErrorFocus' value={phone} onChange={(value: any) => { this.onChangePhone(value); }} />
            </Col>
          </Row>
          <Row onClick={() => { this.setState({ valueTitle: "GENERAL INFORMATION" }) }}>
            <Col>
              <label htmlFor='address' className='form-label fw-bold'>Full address</label>
              <TextFieldArea maxLenght={100} id='addressErrorFocus' value={address} onChange={(value: any) => { this.onChangeAddress(value); }} />
            </Col>
          </Row>
          {
            AM &&
            <>
              <div className='mt-4'>
                <Row onClick={() => { this.setState({ valueTitle: "CONFIGURATION" }) }}>
                  <Col lg="4" md="4" sm="4">
                    <label className='form-label fw-bold' >AM Assigned*</label>
                    <DropDown error={false} defaultValue={{ value: AMValue, label: AM }} options={amOptions}
                      onChange={(value: any) => { this.amChange(value) }} />
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className='form-label fw-bold' >Monthly fee*</label>
                    <TextField maxLenght={50} onlyNumbers onBlur={() => { this.onBlurMonth() }} id='monthlyFeeErrorFocus' error={monthlyFeeError} placeholder='$4,000.00'
                      onChange={(value: any) => { this.onChangeMonth(value) }} disabled={false} value={monthFee} />
                    {monthlyFeeError && <span id="requiredMonthField" className={styles.__errorText} >This field is required</span>}
                  </Col>
                  <Col lg="4" md="4" sm="4">
                    <label className='form-label fw-bold' >Hourly rate</label>
                    <TextField maxLenght={50} placeholder='$25.00' onChange={() => { }} value={hourRate} disabled />
                  </Col>

                </Row>
                <Row className='mt-3'>
                  <Col lg="12" md="12" sm="12">
                    <label className='form-label fw-bold' >Attachments</label>
                    <UploadFile onClick={() => { this.setState({ errorFile: false }) }} error={this.state.errorFile}
                      placeholder={this.state.placeholderInputUpload} onChange={(value: any) => { this.onChangeUpload(value); }} />
                  </Col>
                </Row>
                <Table responsive>
                  <tbody>
                    {filesNames.length > 0 && filesNames.map((value: any, index: number) => (
                      <tr key={"filesNew" + index}>
                        <td className={styles.tableItem}>
                          <span id={index.toString()}>{value}</span>
                        </td>
                        {value.split(".")[1] == "pdf" ?
                          <>
                            <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                            <td><img onClick={() => { this.previewFile(index, value) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td>
                            <td><img onClick={() => { this.deleteFile(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                          </>
                          :
                          <>
                            <td></td>
                            <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                            <td><img onClick={() => { this.deleteFile(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                          </>
                        }
                      </tr>
                    ))}
                    {filesNamesUploaded != undefined &&
                      filesNamesUploaded.length > 0 &&
                      filesNamesUploaded.map((value: any, index: number) => (
                        <tr key={"filesUploaded" + index}>
                          <td className={styles.tableItem}>
                            <span id={index.toString()}>{value}</span>
                          </td>
                          {value.split(".")[1] == "pdf" ?
                            <>
                              <td><img onClick={() => { this.handleDownload(this.state.filesUploaded![index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                              <td><img onClick={() => { this.handleView(this.state.filesUploaded![index]) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td>
                              <td><img onClick={() => { this.handleDelete(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                            </>
                            :
                            <>
                              <td></td>
                              <td><img onClick={() => { this.handleDownload(this.state.filesUploaded![index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                              <td><img onClick={() => { this.handleDelete(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                            </>
                          }

                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </>
          }
          <Row>
            <Col lg="12" md="12" sm="12">
              <div className={styles.__buttonsSaveCancel}>
                <ButtonForm onClick={() => { this.props.onClick!(); }} extraClass={styles.__marginButtonsCancel} label="Cancel" />
                {editedInput && <ButtonForm disabled={!saveButtonActive} onClick={() => { this.onSubmit(); }} extraClass={styles.__marginButtonsSave} colorFill label="Save" />}
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}


const mapStateToProps = (state: any) => ({
  users: state.usersReducer.users,
  usersManagers: state.usersReducer.usersManagers,
  messageError: state.usersReducer.messageError
});

const mapDispatchToProps = (dispatch: any) => ({
  UpdateUserDetailsModal: (data: any) => dispatch(UpdateUserDetailsModal(data)),
  usersFetch: () => dispatch(userAuth()),
  usersManagerFetch: () => dispatch(usersManagerFetch()),
  filesBucketUserUpload: (files: any) => dispatch(filesBucketUserUpload(files))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
