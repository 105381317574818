import React, { Component } from 'react';
import { getInfoCards, getInfoTable } from '../../actions/report-actions';

//Icons
import { HiChevronUp, HiChevronDown } from 'react-icons/hi';

//Styles
import titleStyle from '../../styles/titles.module.css';
import style from '../../styles/screens/reports.module.css';

//Internal components

//External components
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';

//Redux
import { connect } from 'react-redux';


interface Props {
    //inherited

    //Redux and Reducers
    getInfoCards: (year: string) => void;
    getInfoTable: (year: string) => void;
    dataCards: any;
    dataTable: Array<any>;
    loadCardsReport: boolean;
}

interface State {
    total_hours: string;
    total_amount: string;
    invoices_received: string;
    invoices_paid: string;
    invoices_archived: string;
    invoices_progress: string;
    orderBy: number;
    yearSelected: number;
    dataTable: Array<any>;
    showDataTable: Array<any>;
}

class ViewReport extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            total_hours: "0",
            total_amount: "0",
            invoices_received: "0",
            invoices_paid: "0",
            invoices_archived: "0",
            invoices_progress: "0",
            orderBy: 0,
            dataTable: [],
            yearSelected: new Date().getFullYear(),
            showDataTable: []
        }

        this.state = initialState;
    }


    /////////////////////////BASIC FUNCTIONS/////////////////////////////////////////////
    componentDidMount = async () => {
        this.props.getInfoCards("2023")
        this.props.getInfoTable("2023")
    }

    async componentDidUpdate(prevProps: Props) {
        const { loadCardsReport, dataCards, dataTable } = this.props
        if (loadCardsReport !== prevProps.loadCardsReport &&
            loadCardsReport && !prevProps.loadCardsReport
        ) {
            this.setState({
                total_hours: dataCards.totals_paid[0].total_hours == null ? 0 : dataCards.totals_paid[0].total_hours,
                total_amount: dataCards.totals_paid[0].total_amount == null ? "0.00" : dataCards.totals_paid[0].total_amount,
                invoices_received: dataCards.invoices_received[0].invoices_received,
                invoices_paid: dataCards.invoices_paid[0].invoices_paid,
                invoices_archived: dataCards.invoices_archived[0].invoices_archived,
                invoices_progress: dataCards.invoices_process[0].invoices_process
            })
        }
        if (dataTable !== prevProps.dataTable) {
            this.setState({ dataTable: dataTable }, () => { this.arrayFormat() })
            if (document.querySelector("#mainTable") !== undefined && new Date().getMonth() > 3) {
                document.querySelector("#mainTable")!.scrollLeft = 3500;
            }
        }
    }

    arrayFormat = () => {
        const { dataTable } = this.state
        const users = Object.values(dataTable);
        if (Array.isArray(users)) {
            let usersValues: any[] = []
            users.map((userAllData: any) => (
                usersValues.push({
                    name: userAllData.name,
                    last_project: userAllData.last_project,
                    0: userAllData[0],
                    1: userAllData[1],
                    2: userAllData[2],
                    3: userAllData[3],
                    4: userAllData[4],
                    5: userAllData[5],
                    6: userAllData[6],
                    7: userAllData[7],
                    8: userAllData[8],
                    9: userAllData[9],
                    10: userAllData[10],
                    11: userAllData[11],
                })
            ))
            this.setState({ showDataTable: usersValues })
        }
    }

    currencyFormatter = (currency: any, value: any) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            minimumFractionDigits: 2,
            currency
        })
        return formatter.format(value)
    }

    TotalHours = (TotalOrHours: boolean, data: any, month: number) => {
        let total = 0, hours = 0;

        for (let i = 0; i < data.length; i++) {
            total = total + data[i][month].total
            hours = hours + data[i][month].total_hours
        }
        if (TotalOrHours) {
            return this.currencyFormatter("USD", total)
        } else {
            return hours.toFixed(2)
        }

    }

    getYearsSelected = () => {
        const year = new Date().getFullYear()
        let options = []
        for (let i = 2023; i <= year; i++) {
            options.push({ value: i, label: i.toString() })
        }
        return options
    }








    //////////////////////////////////////ONCLICK FUCTIONS//////////////////////////////////////

    orderByFunction = (indexTable: number, asc: boolean, month: number) => {
        if (!asc) {
            this.setState({ orderBy: 0 })
        } else {
            this.setState({ orderBy: indexTable + (month * 3) })
        }
        const data = this.state.showDataTable
        if (indexTable === 1 && asc) {
            const newOrder = data.sort((a: any, b: any) => a.name.localeCompare(b.name));
            this.setState({ showDataTable: newOrder })
        }
        if (indexTable === 1 && !asc) {
            const newOrder = data.sort((a: any, b: any) => b.name.localeCompare(a.name));
            this.setState({ showDataTable: newOrder })
        }
        if (indexTable === 2 && asc) {
            const newOrder = data.sort((a: any, b: any) => a.last_project.localeCompare(b.last_project));
            this.setState({ showDataTable: newOrder })
        }
        if (indexTable === 2 && !asc) {
            const newOrder = data.sort((a: any, b: any) => b.last_project.localeCompare(a.last_project));
            this.setState({ showDataTable: newOrder })
        }
        if (indexTable === 3 && asc) {
            const newOrder = [...data].sort((a: any, b: any) => {
                const totalA = a[month]?.hourly_rate || 0;
                const totalB = b[month]?.hourly_rate || 0;
                return totalA - totalB;
            });
            this.setState({ showDataTable: newOrder })
        }
        if (indexTable === 3 && !asc) {
            const newOrder = [...data].sort((a: any, b: any) => {
                const totalA = a[month]?.hourly_rate || 0;
                const totalB = b[month]?.hourly_rate || 0;
                return totalB - totalA;
            });
            this.setState({ showDataTable: newOrder })
        }

        if (indexTable === 4 && asc) {
            const newOrder = [...data].sort((a: any, b: any) => {
                const totalA = a[month]?.total_hours || 0;
                const totalB = b[month]?.total_hours || 0;
                return totalA - totalB;
            });
            this.setState({ showDataTable: newOrder })
        }
        if (indexTable === 4 && !asc) {
            const newOrder = [...data].sort((a: any, b: any) => {
                const totalA = a[month]?.total_hours || 0;
                const totalB = b[month]?.total_hours || 0;
                return totalB - totalA;
            });
            this.setState({ showDataTable: newOrder })
        }

        if (indexTable === 5 && asc) {
            const newOrder = [...data].sort((a: any, b: any) => {
                const totalA = a[month]?.total || 0;
                const totalB = b[month]?.total || 0;
                return totalA - totalB;
            });
            this.setState({ showDataTable: newOrder })
        }
        if (indexTable === 5 && !asc) {
            const newOrder = [...data].sort((a: any, b: any) => {
                const totalA = a[month]?.total || 0;
                const totalB = b[month]?.total || 0;
                return totalB - totalA;
            });
            this.setState({ showDataTable: newOrder })
        }
    }


    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////

    render() {
        const months = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"]
        const { total_hours, total_amount, invoices_archived, invoices_paid,
            invoices_progress, invoices_received, orderBy, showDataTable, yearSelected } = this.state
        const currentMonth = yearSelected < new Date().getFullYear() ? 11 : new Date().getMonth()
        return (
            <>
                <Row className={titleStyle.__MainRowTitle}>
                    <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
                        <label className={titleStyle.__titlePart1}></label>
                        <label className={titleStyle.__titlePart2}></label>
                        <label className={titleStyle.__titlePart3}>YEARLY SUMMARY</label>
                        <label className={titleStyle.__titlePart4}></label>
                        <label className={titleStyle.__titlePart5}></label>
                    </Col>
                </Row>
                <Row className={style.__selectYearRow}>
                    <Select
                        maxMenuHeight={250}
                        placeholder={"YEAR"}
                        onChange={(value: any) => { this.setState({ yearSelected: value.value }); this.props.getInfoCards(value.value); this.props.getInfoTable(value.value); }}
                        className={[style.__selectYear].join(' ')}
                        isSearchable={false}
                        defaultValue={{ value: yearSelected, label: yearSelected.toString() }}
                        options={this.getYearsSelected()}
                        value={{ value: yearSelected, label: yearSelected }}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 8,
                            colors: {
                                ...theme.colors,
                                primary25: '#f1f4fc',
                                primary: '#005979'
                            },
                        })}
                    />
                </Row>
                <div className={style.__mainTableScrolls} id="mainTable">
                    <div style={{ width: (currentMonth * 360) + "px" }} className={style.__mainTableScrollsMainMonths}>
                        <div className={style.__mainTableScrollsMonths2}>
                            ----
                        </div>
                        {months.map((value: any, index: number) => (
                            <>
                                {currentMonth >= index &&
                                    <>
                                        {index % 2 === 0 ?
                                            <div key={"month" + index} style={{ backgroundColor: "#E9ECEF" }} className={[style.__mainTableScrollsMonths, style.__boldText].join(' ')}>
                                                {value}
                                            </div>
                                            :
                                            <div key={"month" + index} className={[style.__mainTableScrollsMonths, style.__boldText].join(' ')}>
                                                {value}
                                            </div>
                                        }
                                    </>

                                }</>
                        ))}
                    </div>

                    <div style={{ width: (currentMonth * 360) + "px" }} className={style.__mainTableScrollsHeaders}>
                        <div className={[style.__mainTableScrollsHeaderContractor, style.__boldText].join(' ')}>
                            CONTRACTOR {orderBy === 1 ? <HiChevronUp onClick={() => { this.orderByFunction(1, false, 0) }} /> : <HiChevronDown onClick={() => { this.orderByFunction(1, true, 0) }} />}
                        </div>
                        <div className={[style.__mainTableScrollsHeaderContractor2, style.__boldText].join(' ')}>
                            PROJECT {orderBy === 2 ? <HiChevronUp onClick={() => { this.orderByFunction(2, false, 0) }} /> : <HiChevronDown onClick={() => { this.orderByFunction(2, true, 0) }} />}
                        </div>
                        {months.map((value: any, index: number) => (
                            <>

                                {currentMonth >= index &&
                                    <>
                                        {index % 2 === 0 ?
                                            <>
                                                <div key={"Rate" + index} style={{ backgroundColor: "#E9ECEF" }} className={[style.__mainTableScrollsHeaderData, style.__boldText].join(' ')}>
                                                    RATE {orderBy === ((index * 3) + 3) ? <HiChevronUp onClick={() => { this.orderByFunction(3, false, index) }} /> : <HiChevronDown onClick={() => { this.orderByFunction(3, true, index) }} />}
                                                </div>
                                                <div key={"Hours" + index} style={{ backgroundColor: "#E9ECEF" }} className={[style.__mainTableScrollsHeaderData, style.__boldText].join(' ')}>
                                                    HOURS {orderBy === ((index * 3) + 4) ? <HiChevronUp onClick={() => { this.orderByFunction(4, false, index) }} /> : <HiChevronDown onClick={() => { this.orderByFunction(4, true, index) }} />}
                                                </div>
                                                <div key={"Amount" + index} style={{ backgroundColor: "#E9ECEF" }} className={[style.__mainTableScrollsHeaderData, style.__boldText].join(' ')}>
                                                    AMOUNT {orderBy === ((index * 3) + 5) ? <HiChevronUp onClick={() => { this.orderByFunction(5, false, index) }} /> : <HiChevronDown onClick={() => { this.orderByFunction(5, true, index) }} />}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div key={"Rate" + index} className={[style.__mainTableScrollsHeaderData, style.__boldText].join(' ')}>
                                                    RATE {orderBy === ((index * 3) + 3) ? <HiChevronUp onClick={() => { this.orderByFunction(3, false, index) }} /> : <HiChevronDown onClick={() => { this.orderByFunction(3, true, index) }} />}
                                                </div>
                                                <div key={"Hours" + index} className={[style.__mainTableScrollsHeaderData, style.__boldText].join(' ')}>
                                                    HOURS  {orderBy === ((index * 3) + 4) ? <HiChevronUp onClick={() => { this.orderByFunction(4, false, index) }} /> : <HiChevronDown onClick={() => { this.orderByFunction(4, true, index) }} />}
                                                </div>
                                                <div key={"Amount" + index} className={[style.__mainTableScrollsHeaderData, style.__boldText].join(' ')}>
                                                    AMOUNT {orderBy === ((index * 3) + 5) ? <HiChevronUp onClick={() => { this.orderByFunction(5, false, index) }} /> : <HiChevronDown onClick={() => { this.orderByFunction(5, true, index) }} />}
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </>
                        ))}
                    </div>
                    {showDataTable !== undefined && showDataTable !== null &&
                        showDataTable.length > 0 &&
                        showDataTable!.map((value: any, index: number) => (
                            <>
                                <div key={"MonthV" + index} style={{ width: (currentMonth * 360) + "px" }} className={style.__mainTableScrollsData}>
                                    <div className={style.__mainTableScrollsDataContractor}>
                                        {value.name}
                                    </div>
                                    <div className={style.__mainTableScrollsDataContractor2}>
                                        {value.last_project}
                                    </div>
                                    {months.map((valueMonth: any, month: number) => (
                                        <>
                                            {currentMonth >= month &&
                                                <>
                                                    {month % 2 === 0 ?
                                                        <>
                                                            <div key={"RateV" + index} style={{ backgroundColor: "#E9ECEF" }} className={style.__mainTableScrollsDataData}>
                                                                {this.currencyFormatter("USD", value[month].hourly_rate)}
                                                            </div>
                                                            <div key={"HoursV" + index} style={{ backgroundColor: "#E9ECEF" }} className={style.__mainTableScrollsDataData}>
                                                                {value[month].total_hours.toFixed(2)}
                                                            </div>
                                                            <div key={"AmountV" + index} style={{ backgroundColor: "#E9ECEF" }} className={style.__mainTableScrollsDataData}>
                                                                {this.currencyFormatter("USD", value[month].total)}
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div key={"RateV" + index} className={style.__mainTableScrollsDataData}>
                                                                {this.currencyFormatter("USD", value[month].hourly_rate)}
                                                            </div>
                                                            <div key={"HoursV" + index} className={style.__mainTableScrollsDataData}>
                                                                {value[month].total_hours.toFixed(2)}
                                                            </div>
                                                            <div key={"AmountV" + index} className={style.__mainTableScrollsDataData}>
                                                                {this.currencyFormatter("USD", value[month].total)}
                                                            </div>
                                                        </>
                                                    }
                                                </>

                                            }</>
                                    ))}
                                </div>
                            </>
                        ))
                    }

                    <div style={{ width: (currentMonth * 360) + "px" }} className={style.__mainTableScrollsData}>
                        <div className={[style.__mainTableScrollsDataContractor, style.__noText].join(' ')}>
                            ---
                        </div>
                        <div className={[style.__mainTableScrollsDataContractor2, style.__noText].join(' ')}>
                            ----
                        </div>
                        {months.map((valueMonth: any, month: number) => (
                            <>
                                {currentMonth >= month &&
                                    <>
                                        {month % 2 === 0 ?
                                            <>
                                                <div key={"Total" + month} style={{ backgroundColor: "#E9ECEF" }} className={[style.__mainTableScrollsDataData, style.__boldText].join(' ')}>
                                                    Total
                                                </div>
                                                <div key={"HoursT" + month} style={{ backgroundColor: "#E9ECEF" }} className={[style.__mainTableScrollsDataData, style.__boldText].join(' ')}>
                                                    {this.TotalHours(false, showDataTable, month)}
                                                </div>
                                                <div key={"AmountT" + month} style={{ backgroundColor: "#E9ECEF" }} className={[style.__mainTableScrollsDataData, style.__boldText].join(' ')}>
                                                    {this.TotalHours(true, showDataTable, month)}
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div key={"Total" + month} className={[style.__mainTableScrollsDataData, style.__boldText].join(' ')}>
                                                    Total
                                                </div>
                                                <div key={"HoursT" + month} className={[style.__mainTableScrollsDataData, style.__boldText].join(' ')}>
                                                    {this.TotalHours(false, showDataTable, month)}
                                                </div>
                                                <div key={"AmountT" + month} className={[style.__mainTableScrollsDataData, style.__boldText].join(' ')}>
                                                    {this.TotalHours(true, showDataTable, month)}
                                                </div>
                                            </>
                                        }
                                    </>

                                }</>
                        ))}
                    </div>
                </div>

                <Row>
                    <Col lg="12" md="12" sm="12">
                        <h3 className={style.__secondTitle}>YEAR STATISTICS</h3>
                    </Col>
                    <Col lg="12" md="12" sm="12">
                        <div className={style.__CardMain}>
                            <label className={style.__CardTitle}>TOTAL HOURS</label>
                            <label className={style.__CardData}>{total_hours}</label>
                        </div>

                        <div className={style.__CardMain}>
                            <label className={style.__CardTitle}>TOTAL AMOUNT</label>
                            <label className={style.__CardData2}>${total_amount}</label>
                        </div>

                        <div className={style.__CardMain}>
                            <label className={style.__CardTitle}>INVOICES RECEIVED</label>
                            <label className={style.__CardData}>{invoices_received}</label>
                        </div>

                        <div className={style.__CardMain}>
                            <label className={style.__CardTitle}>INVOICES PAID</label>
                            <label className={style.__CardData}>{invoices_paid}</label>
                        </div>

                        <div className={style.__CardMain}>
                            <label className={style.__CardTitle}>INVOICES ARCHIVED</label>
                            <label className={style.__CardData}>{invoices_archived}</label>
                        </div>

                        <div className={style.__CardMain}>
                            <label className={style.__CardTitle}>INVOICES IN PROCESS</label>
                            <label className={style.__CardData}>{invoices_progress}</label>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}


const mapStateToProps = (state: any) => ({
    dataCards: state.reportReducer.dataCards,
    dataTable: state.reportReducer.dataTable,
    loadCardsReport: state.reportReducer.loadCardsReport
});

const mapDispatchToProps = (dispatch: any) => ({
    getInfoCards: (year: string) => dispatch(getInfoCards(year)),
    getInfoTable: (year: string) => dispatch(getInfoTable(year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewReport);
