// Redux
import { Dispatch } from 'redux';
import { usersActionTypes } from "../constants/users-constants";
import { API } from '../api/user-api';
import * as types from '../types/users-types';

// Models
import { usersModel, usersManagersModel, MyUserModel } from "../models/user/user_model";
import { AnyIfEmpty } from 'react-redux';


// -------------------------------------USERS FETCH----------------------------------------
const userFetchBegin = (): types.IUserFetchBegin => ({
  type: usersActionTypes.USERS_FETCH_BEGIN,
});

const userFetchError = (e: any, errorType: any): types.IUserFetchError => ({
  type: usersActionTypes.USERS_FETCH_ERROR,
  error: e,
  errorType: errorType
});

const userFetchSuccess = (data: Array<usersModel>): types.IUserFetchSuccess => ({
  type: usersActionTypes.USERS_FETCH_SUCCESS,
  data,
});

export const usersFetch = () => (dispatch: Dispatch) => {
  dispatch(userFetchBegin());
  API.usersFetch().then((response: any) => {
    dispatch(userFetchSuccess(response.data));
  }).catch((error: any) => {
    dispatch(userFetchError(error.response.data.message, error.response.request.status));
  });
};

// -------------------------------------USERS MANAGERS FETCH----------------------------------------
const userManagerFetchBegin = (): types.IUsersManagersFetchBegin => ({
  type: usersActionTypes.USERS_MANAGERS_FETCH_BEGIN,
});

const userManagerFetchError = (e: any, errorType: any): types.IUsersManagersFetchError => ({
  type: usersActionTypes.USERS_MANAGERS_FETCH_ERROR,
  error: e,
  errorType: errorType
});

const userManagerFetchSuccess = (dataManager: Array<usersManagersModel>): types.IUsersManagersFetchSuccess => ({
  type: usersActionTypes.USERS_MANAGERS_FETCH_SUCCESS,
  dataManager,
});

export const usersManagerFetch = () => (dispatch: Dispatch) => {
  dispatch(userManagerFetchBegin());
  API.usersManagersFetch().then((response: any) => {
    dispatch(userManagerFetchSuccess(response.data));
  }).catch((error: any) => {
    dispatch(userManagerFetchError(error.response.data.message, error.response.request.status));
  });
};

// -------------------------------------USER STATUS UPDATE----------------------------------------
const userStatusUpdateBegin = (): types.IUserStatusBegin => ({
  type: usersActionTypes.USER_STATUS_BEGIN,
});

const userStatusUpdateError = (e: any, errorType: any): types.IUserStatusError => ({
  type: usersActionTypes.USER_STATUS_ERROR,
  error: e,
  errorType: errorType
});

const userStatusUpdateSuccess = (): types.IUserStatusSuccess => ({
  type: usersActionTypes.USER_STATUS_SUCCESS,
});

export const userStatusUpdate = (data: any) => (dispatch: Dispatch) => {
  dispatch(userStatusUpdateBegin());
  API.updateUserStatus(data).then((response: any) => {
    dispatch(userStatusUpdateSuccess());
  }).catch((error: any) => {
    dispatch(userStatusUpdateError(error, error.response.request.status));
  });
};

// -------------------------------------USERS DETAILS MODAL----------------------------------------
const userDetailsBegin = (): types.IUserDetailsStatusBegin => ({
  type: usersActionTypes.USER_DETAILS_STATUS_BEGIN
});

const userDetailsError = (e: any, errorType: any): types.IUserDetailsStatusError => ({
  type: usersActionTypes.USER_DETAILS_STATUS_ERROR,
  error: e,
  errorType: errorType
});

const userDetailsSuccess = (userDetails: Array<MyUserModel>): types.IUserDetailsStatusSuccess => ({
  type: usersActionTypes.USER_DETAILS_STATUS_SUCCESS,
  userDetails,
});

export const userDetailsModal = (userDetails: any) => (dispatch: Dispatch) => {
  dispatch(userDetailsBegin());
  API.userDetailsModal(userDetails).then((response: any) => {
    dispatch(userDetailsSuccess(response.data));
  }).catch((error: any) => {
    dispatch(userDetailsError(error.response.data.message, error.response.request.status));
  });
};


// -------------------------------------UPDATE USER DETAILS MODAL----------------------------------------
const UpdateUserDetailsBegin = (data: any): types.IUserDetailsStatusUpdateBegin => ({
  type: usersActionTypes.UPDATE_USER_DETAILS_STATUS_BEGIN,
  data
});

const UpdateUserDetailsError = (e: any, errorType: any): types.IUserDetailsStatusUpdateError => ({
  type: usersActionTypes.UPDATE_USER_DETAILS_STATUS_ERROR,
  error: e,
  errorType: errorType
});

const UpdateUserDetailsSuccess = (data: any): types.IUserDetailsStatusUpdateSuccess => ({
  type: usersActionTypes.UPDATE_USER_DETAILS_STATUS_SUCCESS,
  data,
});

export const UpdateUserDetailsModal = (data: any) => (dispatch: Dispatch) => {
  dispatch(UpdateUserDetailsBegin(data));
  API.updateUserDetailsModal(data).then((response: any) => {
    dispatch(UpdateUserDetailsSuccess(response.data));
  }).catch((error: any) => {
    if (error.response.data.message == "email repeat") {
      return dispatch(UpdateUserDetailsError(`email repeat`, error.response.request.status));
    }
    dispatch(UpdateUserDetailsError(error.response.data.message, error.response.request.status));
  });
};

