import React, { Component } from 'react';

//Styles
import style from '../../styles/basicComponents/ButtonForm.module.css';

interface Props {
  label?: string
  extraClass?: any
  onClick: () => void;
  disabled?: boolean
  colorFill?: boolean
  linkButton?: boolean
  icon?: any
  id?: string
  toltipText?: string
  statusBtn?: boolean
}

interface State { }

export default class ButtonForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {};
  }

  functionDisable = () => {

  }

  render() {
    return (
      <>
        <button
          onClick={() => { this.props.disabled ? this.functionDisable() : this.props.onClick() }}
          data-tooltip-id={this.props.id ? this.props.id : "buttonNoId"}
          data-tooltip-content={this.props.toltipText ? this.props.toltipText : ""}
          className={[this.props.statusBtn ? style.__baseStructureStatusBtn : style.__baseStructure,
          !this.props.linkButton ? style.__baseStructure : style.__baseStructureLink,
          this.props.colorFill ? style.__baseStructureBlack : style.__baseStructureWhite,
          this.props.disabled ? style.__baseStructureDisable : "",
          this.props.extraClass ? this.props.extraClass : "",
          ].join(' ')}
        >
          {this.props.icon} {this.props.label}
        </button>
      </>
    );
  }
}
