import React, { Component } from 'react';
import axios from 'axios';

//components internal
import TextField from '../../components/basicComponents/TextField';
import TextFieldArea from '../../components/basicComponents/TextFieldArea';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import PopUp from '../../components/basicComponents/PopUp';

//components external
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

//Icons
import ViewIcon from '../../assets/svg/view.svg';
import DownloadIcon from '../../assets/svg/download.svg';

//styles
import styles from '../../styles/screens/myProfile.module.css';
import titleStyle from '../../styles/titles.module.css';
import stylesPop from '../../styles/screens/updatePassword.module.css';

//models
import { MyUserModel } from '../../models/user/user_model';
import { ActivityInvoice } from '../../models/invoice/activityInvoice_model';
import { FilesInvoices } from '../../models/invoice/filesInvoice_model';

//redux
import { connect } from 'react-redux';
import { statusApprovedAM, statusApprovedFinance, statusPaidFinance, statusRejectedAM, statusRejectedFinance, invoiceActivityFetch } from '../../actions/invoices-actions';
import { fetchBucketInvoiceFiles } from '../../actions/filesBucket-actions';


interface Props {
    //inherited
    id: string,
    folio: string,
    status: string,
    contractor: string,
    monthly_fee: string,
    hourly_rate: string,
    AM: string,
    payment_method: string,
    payment_details: string,
    proyect: any,
    billable_hours: any,
    total: any,
    period: any,
    invoice_date: any,
    note: string,
    created: any,
    myUser: MyUserModel,
    back: () => void,

    //Redux and Reducers
    activityInvoice: Array<ActivityInvoice>,
    invoiceActivityFetch: (id: string) => void,
    statusApprovedAM: (data: any) => void,
    statusApprovedFinance: (data: any) => void,
    statusPaidFinance: (data: any) => void,
    statusRejectedAM: (data: any) => void,
    statusRejectedFinance: (data: any) => void,
    filesInvoice: Array<FilesInvoices>,
    updateInvoicesStatus: boolean,
    loadingGetSuccess: boolean,
    updateStatusLoad: boolean,
    fetchBucketInvoiceFiles: (id: string) => void

}
interface State {
    rejection_note: string
    noteError: boolean
    filesNamesUploaded: any[],
    filesUploaded: any[],
    modalReload: boolean,
    statusChange: number
}

class InternalDetailsInvoice extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            rejection_note: "",
            noteError: false,
            filesNamesUploaded: [],
            filesUploaded: [],
            modalReload: false,
            statusChange: 0
        };
        this.state = initialState;
    }

    //////////////////BASIC FUNCTIONS////////////////////////////////////////////
    componentDidMount = async () => {
        this.props.invoiceActivityFetch(this.props.id)
        this.props.fetchBucketInvoiceFiles(this.props.id)
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.id != this.props.id) {
            this.props.invoiceActivityFetch(this.props.id)
        }
        if (this.props.loadingGetSuccess != prevProps.loadingGetSuccess &&
            prevProps.loadingGetSuccess && !this.props.loadingGetSuccess
        ) {
            this.updateFilesUploaded()
        }
        if (this.props.updateStatusLoad != prevProps.updateStatusLoad &&
            prevProps.updateStatusLoad &&
            !this.props.updateStatusLoad
        ) {
            if (!this.props.updateInvoicesStatus) {
                window.location.reload()
            } else {
                this.setState({ modalReload: true })
            }
        }
    }








    ///////////////////FORMATS FUNCTIONS//////////////////////////////////////
    periodFormat = (startDate: any, endDate: any) => {
        const startFormat = startDate.substring(5, 7) + "/" + startDate.substring(8, 10) + "/" + startDate.substring(0, 4);
        const endFormat = endDate.substring(5, 7) + "/" + endDate.substring(8, 10) + "/" + endDate.substring(0, 4);
        return startFormat + " - " + endFormat
    }

    dateFormat = (date: any) => {
        const dateValue = date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4);
        return dateValue
    }

    renderStatusName = (valueStatus: any) => {
        if (valueStatus == 0) {
            return "Submitted"
        }
        if (valueStatus == 1) {
            return "Approved - AM"
        }
        if (valueStatus == 2) {
            return "Approved - FIN"
        }
        if (valueStatus == 3) {
            return "Paid"
        }
        if (valueStatus == 4) {
            return "Rejected - AM"
        }
        if (valueStatus == 5) {
            return "Rejected - FIN"
        }
    }
    currencyFormatter = (currency: any, value: any) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            minimumFractionDigits: 2,
            currency
        })
        return formatter.format(value)
    }







    //////////////////////////////////ACTIVE FUNCIONS///////////////////////////////////
    updateFilesUploaded = () => {
        let filesInvoicesV: Array<any> = [];
        if (this.props.filesInvoice != null && this.props.filesInvoice != undefined) {
            filesInvoicesV = Object.values(this.props.filesInvoice);
            let filesValuesData: any[] = []
            let filesValuesName: any[] = []
            if (Array.isArray(filesInvoicesV)) {
                filesInvoicesV.map((file: any) => {
                    filesValuesName.push(file.name)
                    filesValuesData.push(file.data)
                })

                this.setState({ filesNamesUploaded: filesValuesName, filesUploaded: filesValuesData })
            }
        }
    }








    /////////////////////////////////ONCLICK FUNCTIONS//////////////////////////////////////////
    onApproved = () => {
        if (this.props.myUser.role.name == "Manager") {
            this.props.statusApprovedAM({ id: this.props.id, note: this.state.rejection_note })

        } else if (this.props.myUser.role.name == "Finance") {
            this.props.statusApprovedFinance({ id: this.props.id, note: this.state.rejection_note })
        } else {
            alert('you cant do that')
        }
    }

    onRejected = () => {
        if (this.props.myUser.role.name == "Manager") {
            if (this.state.rejection_note == "") {
                this.setState({ noteError: true })
                return
            } else {
                this.props.statusRejectedAM({ id: this.props.id, rejectedNote: this.state.rejection_note })
            }

        } else if (this.props.myUser.role.name == "Finance") {
            if (this.state.rejection_note == "") {
                this.setState({ noteError: true })
                return
            } else {
                this.props.statusRejectedFinance({ id: this.props.id, rejectedNote: this.state.rejection_note })
            }

        } else {
            alert('you cant do that')
        }

    }

    onPaid = () => {
        if (this.props.myUser.role.name == "Finance" && this.props.status == "Approved - FIN") {
            this.props.statusPaidFinance({ id: this.props.id, note: this.state.rejection_note })
        } else {
            alert("You cannot change the status")
        }
    }
    onCancel = () => {
        if (!this.props.updateStatusLoad) {
            this.props.back()
        }
    }
    onUpdate = () => {
        alert("this must redirect to update invoice")
    }
    handleView = (fileName: any) => {
        const base_api_url = process.env.REACT_APP_BASE_URL;
        const headers = {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        };

        axios.get(`${base_api_url}uploadFiles/viewInvoice/${fileName}`, {
            headers,
            responseType: 'arraybuffer'
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const fileBlob = new Blob([response.data], { type: contentType });
                const fileUrl = URL.createObjectURL(fileBlob);
                window.open(fileUrl);
            })
            .catch(error => {
            });
    };

    handleDownload = (fileName: any) => {
        const base_api_url = process.env.REACT_APP_BASE_URL;
        const headers = {
            Authorization: "Bearer " + localStorage.getItem('tokenInvoicing')
        };

        axios.get(`${base_api_url}uploadFiles/viewInvoice/${fileName}`, {
            headers,
            responseType: 'arraybuffer'
        })
            .then(response => {
                const contentType = response.headers['content-type'];
                const fileBlob = new Blob([response.data], { type: contentType });
                const fileUrl = URL.createObjectURL(fileBlob);

                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = fileName;
                link.click();

                URL.revokeObjectURL(fileUrl);
            })
            .catch(error => {
            });
    };







    //////////////////////////ONCHANGE FUNCTIONS/////////////////////////////
    onChangeNote = (value: any) => {
        this.setState({ rejection_note: value, noteError: false })
    }







    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const { filesNamesUploaded, filesUploaded, rejection_note, noteError, modalReload, statusChange } = this.state

        const { activityInvoice, status, folio, contractor, monthly_fee, hourly_rate,
            AM, payment_details, payment_method, myUser, proyect, billable_hours, total,
            period, invoice_date, filesInvoice } = this.props

        const valuesTable = Object.values(activityInvoice);
        let invoiceActivityValues: any[] = []
        if (Array.isArray(valuesTable)) {
            valuesTable.map((activity: ActivityInvoice) => {
                invoiceActivityValues.push({
                    user: activity.user,
                    note: activity.note,
                    created_at: activity.created_at
                })
            })
        }
        const tableActivity = invoiceActivityValues
        return (
            <Container className={styles.__MainContent}>
                <div className={stylesPop.__centerAll}>
                    <PopUp passwordTrigger={modalReload} title=""
                        subTitle="STATUS ERROR"
                        text={`The status of this invoice has changed, your ` + (statusChange == 0 ? "approve" : statusChange == 1 ? "reject" : "pay") + ` can't be applied`}
                        buttonTxt="Accept"
                        onClick={() => {
                            window.location.href = "./invoices?invoice=" + this.props.id
                        }} />
                </div>

                <Row className={titleStyle.__MainRowTitle}>
                    <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
                        <label onClick={() => { window.location.href = "/invoices" }} className={titleStyle.__titlePart1}>INVOICES</label>
                        <label className={titleStyle.__titlePart2}>{">"}</label>
                        <label className={titleStyle.__titlePart3}>REVIEW INVOICE</label>
                        <label className={titleStyle.__titlePart4}>{">"}</label>
                        <label className={titleStyle.__titlePart5}>{status}</label>
                    </Col>
                </Row>
                <Row>
                    <Col lg="10" md="10" sm="10"><h4 className={styles.__MainTitle}>Invoice no. {folio} </h4></Col>

                    <Col lg="2" md="2" sm="2"><div className={[
                        status == "Submitted" ? styles.__subbmitedTag :
                            status == "Approved - AM" ? styles.__approvedAMTag :
                                status == "Approved - FIN" ? styles.__approvedFinanceTag :
                                    status == "Paid" ? styles.__paidTag :
                                        status == "Rejected - AM" ? styles.__rejectedTag :
                                            status == "Rejected - FIN" ? styles.__rejectedTag :
                                                status == "Archived" ? styles.__archivedTag
                                                    : ""
                    ].join('')}>{status} </div></Col>
                </Row>
                <Row>
                    <Row>
                        <Col lg="12" md="12" sm="12"><h5 className={styles.__subTitle}>Invoice details</h5></Col>
                    </Row>
                    <Row className={styles.__contractorInfo}>
                        <Row>
                            <Col lg="4" md="4" sm="4" >
                                <label className={styles.__boldText}>Contractor: </label> {contractor}
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Monthly fee: </label> {monthly_fee}
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Hourly rate: </label> {hourly_rate}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" md="4" sm="4" >
                                <label className={styles.__boldText}>Assigned Account Manager: </label> {AM}
                            </Col>
                            <Col lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Payment method: </label> {payment_method}
                            </Col>
                            {this.props.myUser.role.name == "Finance" && this.props.status == "Paid" &&
                                <Col lg="4" md="4" sm="4">
                                    <label className={styles.__boldText}>Payment details: </label> {payment_details}
                                </Col>
                            }
                        </Row>
                    </Row>
                </Row>
                <Row>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Invoice date</label>
                        <TextField maxLenght={100} disabled value={this.dateFormat(invoice_date)} onChange={(value: any) => { }} />
                    </Col>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Period</label>
                        <TextField maxLenght={100} disabled value={period} onChange={(value: any) => { }} />
                    </Col>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Assigned project</label>
                        <TextField maxLenght={100} disabled value={(proyect)} onChange={(value: any) => { }} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Billable hours</label>
                        <TextField maxLenght={50} disabled value={(billable_hours)} onChange={(value: any) => { }} />
                    </Col>
                    <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Total amount</label>
                        <TextField maxLenght={50} disabled value={(total)} onChange={(value: any) => { }} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12" className={styles.__AttachmentsRowsDivMain}>
                        <label className={styles.__boldText}>Attachments</label>
                    </Col>
                </Row>
                <Row className={[styles.__AttachmentsRowsDivMain, styles.__borderMainDivAttachments].join(' ')}>

                    {(filesInvoice != undefined && filesInvoice != null) &&
                        filesNamesUploaded.length > 0 ?
                        filesNamesUploaded.map((value: any, index: number) => (

                            <Col key={"filesTableCol" + index} lg="12" md="12" sm="12" className={styles.__AttachmentsRows}>
                                {value}
                                <div className={styles.__rightIcons}>
                                    {value.split(".")[1] == "pdf" ?
                                        <><img onClick={() => { this.handleDownload(filesUploaded[index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} />
                                            <img onClick={() => { this.handleView(filesUploaded[index]) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></>
                                        :
                                        <><img onClick={() => { this.handleDownload(filesUploaded[index]) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></>
                                    }

                                </div>
                            </Col>
                        ))
                        :
                        <>
                            <Col lg="12" md="12" sm="12" className={styles.__AttachmentsRows}>
                                <h3 className={styles.__noFilesText}>No files</h3>
                            </Col>
                        </>
                    }
                </Row>
                {
                    (((myUser.role.name == "Manager") && (status == "Submitted")) ||
                        ((myUser.role.name == "Finance") && (status == "Approved - AM")))
                    &&
                    <Row>
                        <Col lg="12" md="12" sm="12" className={styles.colMyProfileInput}>
                            <label className={styles.__boldText}>Notes</label>
                            <TextFieldArea
                                maxLenght={300}
                                error={noteError} value={rejection_note} placeholder="Send us a note" onChange={(value: any) => { this.onChangeNote(value) }} />
                            {noteError &&
                                <span className={styles.err}>Submit a rejection reason.</span>
                            }
                        </Col>
                    </Row>
                }

                <Row >
                    <Col lg="12" md="12" sm="12" className={styles.colAcitivyInvoice}>
                        <label className={styles.__boldText}>ACTIVITY</label>
                        <Table responsive className={styles.__tableBase}>
                            <thead>
                                <tr key="thead">
                                    <td className={[styles.__theadTable, styles.__nameDateStatusColumns, styles.__nameColumn].join(' ')}><span>USER</span></td>
                                    <td className={[styles.__theadTable, styles.__nameDateStatusColumns, styles.__dateColumn].join(' ')}><span>DATE</span></td>
                                    <td className={[styles.__theadTable, styles.__nameDateStatusColumns, styles.__statusColumn].join(' ')}><span>STATUS</span></td>
                                    <td className={[styles.__theadTable, styles.__notesColumn].join(' ')}><span>NOTES</span></td>
                                </tr>
                            </thead>
                            <tbody>
                                {tableActivity != undefined && tableActivity.length > 0 &&
                                    tableActivity.map((value: ActivityInvoice, index: number) => (
                                        <tr className={styles.__trClass} key={index}>
                                            <td className={[styles.__tbodyTable, styles.__nameColumn].join(' ')}><p className={styles.overflowEllipsis}>{value.user}</p></td>
                                            <td className={[styles.__tbodyTable, styles.__dateColumn].join(' ')}><p className={styles.overflowEllipsis}>{value.created_at.substring(0, 10).replace(/-/g, '/')} - ({value.created_at.substring(11, 16)})</p></td>
                                            <td className={[styles.__tbodyTable, styles.__statusColumn].join(' ')}><p className={styles.overflowEllipsis}>{value.note.split('||')[0]}</p></td>
                                            <td className={[styles.__tbodyTablenoCenter, styles.__notesColumn].join(' ')}>
                                                <p className={styles.overflowEllipsis} lang="es">{value.note.split('||')[1]}</p>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <div className={styles.__buttonsSaveCancel}>

                            {(myUser.role.name == "Manager") && (status == "Rejected - AM" || status == "Rejected - FIN" || status == "Paid" || status == "Approved - AM" || status == "Approved - FIN" || status == "Archived") &&
                                <ButtonForm onClick={() => { this.onCancel() }} statusBtn extraClass={styles.__spanCancel} label="Back" />
                            }
                            {(myUser.role.name == "Human Capital") &&
                                <ButtonForm onClick={() => { this.onCancel() }} statusBtn extraClass={styles.__spanCancel} label="Back" />
                            }
                            {(myUser.role.name == "Finance") && (status == "Rejected - FIN" || status == "Rejected - AM" || status == "Paid" || status == "Archived") &&
                                <ButtonForm onClick={() => { this.onCancel() }} statusBtn extraClass={styles.__spanCancel} label="Back" />
                            }

                            {(myUser.role.name == "Manager") && (status == "Submitted") &&
                                <>
                                    <ButtonForm onClick={() => { this.onCancel() }} statusBtn extraClass={styles.__spanCancel} label="Cancel" />
                                    <ButtonForm onClick={() => { this.setState({ statusChange: 1 }); this.onRejected(); }} statusBtn extraClass={styles.__spanRejected} label="Reject" />
                                    <ButtonForm onClick={() => { this.setState({ statusChange: 0 }); this.onApproved() }} statusBtn extraClass={styles.__spanAproved} label="Approve" />
                                </>
                            }
                            {(myUser.role.name == "Finance") && (status == "Approved - AM") &&
                                <>
                                    <ButtonForm onClick={() => { this.onCancel() }} statusBtn extraClass={styles.__spanCancel} label="Cancel" />
                                    <ButtonForm onClick={() => { this.setState({ statusChange: 1 }); this.onRejected(); }} statusBtn extraClass={styles.__spanRejected} label="Reject" />
                                    <ButtonForm onClick={() => { this.setState({ statusChange: 0 }); this.onApproved() }} statusBtn extraClass={styles.__spanAproved} label="Approve" />
                                </>
                            }

                            {(myUser.role.name == "Finance") && (status == "Approved - FIN") &&
                                <>
                                    <ButtonForm onClick={() => { this.onCancel() }} statusBtn extraClass={styles.__spanCancel} label="Cancel" />
                                    <ButtonForm onClick={() => { this.setState({ statusChange: 2 }); this.onPaid(); }} statusBtn extraClass={styles.__spanAproved} label="Mark as paid" />
                                </>
                            }
                        </div>
                    </Col>
                </Row>

            </Container >
        );
    }
}

const mapStateToProps = (state: any) => ({
    activityInvoice: state.invoiceReducer.activityInvoice,
    filesInvoice: state.filesBucketReducer.filesInvoice,
    loadingGetSuccess: state.filesBucketReducer.loadingGetSuccess,
    updateStatusLoad: state.invoiceReducer.updateStatusLoad,
    updateInvoicesStatus: state.invoiceReducer.updateInvoicesStatus
});
const mapDispatchToProps = (dispatch: any) => ({
    invoiceActivityFetch: (id: string) => dispatch(invoiceActivityFetch(id)),
    statusApprovedAM: (data: any) => dispatch(statusApprovedAM(data)),
    statusApprovedFinance: (data: any) => dispatch(statusApprovedFinance(data)),
    statusPaidFinance: (data: any) => dispatch(statusPaidFinance(data)),
    statusRejectedAM: (data: any) => dispatch(statusRejectedAM(data)),
    statusRejectedFinance: (data: any) => dispatch(statusRejectedFinance(data)),
    fetchBucketInvoiceFiles: (id: string) => dispatch(fetchBucketInvoiceFiles(id)),
});
export default connect(
    mapStateToProps, mapDispatchToProps)(InternalDetailsInvoice);