// Redux
import { Dispatch } from "redux";
import { authActionTypes } from "../constants/auth-constants";
import { API } from "../api/auth-api";
import * as types from "../types/auth-types";

// Models
import { LoginModel } from "../models/auth/login-model";
import { MyUserModel } from "../models/user/user_model";
import { sendInitialUserByToken, getInitialUserByToken } from "../models/auth/newUserAuthPassword-model";

// -------------------------------------LOGIN ----------------------------------------

const loginBegin = (data: LoginModel): types.ILoginBegin => ({
  type: authActionTypes.LOGIN_BEGIN,
  data,
});

const loginError = (e: any, errorType: any): types.ILoginError => ({
  type: authActionTypes.LOGIN_ERROR,
  error: e,
  errorType: errorType
});

const loginSuccess = (data: any): types.ILoginSuccess => ({
  type: authActionTypes.LOGIN_SUCCESS,
  data,
});

export const login = (data: LoginModel) => (dispatch: Dispatch) => {
  dispatch(loginBegin(data));
  API.login(data)
    .then((response: any) => {
      localStorage.setItem("tokenInvoicing", response.data.token);
      dispatch(loginSuccess(response.data));
      userAuth();
    })
    .catch((error) => {
      dispatch(loginError(error.response.data.message, error.response.request.status));
    });
};
// -------------------------------------LOGOUT ----------------------------------------

const logoutBegin = (): types.ILogoutBegin => ({
  type: authActionTypes.LOGOUT_BEGIN,
});

const logoutError = (e: any, errorType: any): types.ILogoutError => ({
  type: authActionTypes.LOGOUT_ERROR,
  error: e,
  errorType: errorType
});

const logoutSuccess = (): types.ILogoutSuccess => ({
  type: authActionTypes.LOGOUT_SUCCESS,
});

export const logout = () => (dispatch: Dispatch) => {
  try {
    dispatch(logoutBegin());
    localStorage.clear();
    dispatch(logoutSuccess());
    window.location.href = "./";
  } catch (error: any) {
    dispatch(logoutError("error", error.response.request.status));
  }
};

// -------------------------------------UPDATE USER AUTH ----------------------------------------

const updateUserAuthBegin = (data: any): types.IUserUpdateAuthBegin => ({
  type: authActionTypes.USER_AUTH_UPDATE_BEGIN,
  data,
});

const updateUserAuthError = (e: any, errorType: any): types.IUserUpdateAuthError => ({
  type: authActionTypes.USER_AUTH_UPDATE_ERROR,
  error: e,
  errorType: errorType
});

const updateUserAuthSuccess = (data: any): types.IUserUpdateAuthSuccess => ({
  type: authActionTypes.USER_AUTH_UPDATE_SUCCESS,
  data,
});

export const updateUser = (data: any) => (dispatch: Dispatch) => {
  dispatch(updateUserAuthBegin(data));
  API.updateUserAuth(data)
    .then((response: any) => {
      dispatch(updateUserAuthSuccess("Update succesfull"));
    })
    .catch((error: any) => {
      if (error.response.data.message == "email repeat") {
        return dispatch(updateUserAuthError(`email repeat`, error.response.request.status));
      }
      dispatch(updateUserAuthError(`Update failed`, error.response.request.status));
    });
};


// -------------------------------------UPDATE USER PASSWORD ----------------------------------------

const updateUserPasswordBegin = (password: string): types.IUserAuthUpdatePasswordBegin => ({
  type: authActionTypes.USER_AUTH_UPDATE_PASSWORD_BEGIN,
  password,
});

const updateUserPasswordError = (e: any, errorType: any): types.IUserAuthUpdatePasswordError => ({
  type: authActionTypes.USER_AUTH_UPDATE_PASSWORD_ERROR,
  error: e,
  errorType: errorType
});

const updateUserPasswordSuccess = (): types.IUserAuthUpdatePasswordSuccess => ({
  type: authActionTypes.USER_AUTH_UPDATE_PASSWORD_SUCCESS,
});

export const updateUserPassoword = (password: string) => (dispatch: Dispatch) => {
  dispatch(updateUserPasswordBegin(password));
  API.updatePassword(password)
    .then((response: any) => {
      dispatch(updateUserPasswordSuccess());
    })
    .catch((error: any) => {
      dispatch(updateUserPasswordError(`Update failed`, error.response.request.status));
    });
};


// -------------------------------------USER AUTH ----------------------------------------

const userAuthBegin = (): types.IUserAuthBegin => ({
  type: authActionTypes.USER_AUTH_BEGIN,
});

const userAuthError = (e: any, errorType: any): types.IUserAuthError => ({
  type: authActionTypes.USER_AUTH_ERROR,
  error: e,
  errorType: errorType
});

const userAuthSuccess = (data: MyUserModel): types.IUserAuthSuccess => ({
  type: authActionTypes.USER_AUTH_SUCCESS,
  data,
});

export const userAuth = () => (dispatch: Dispatch) => {
  dispatch(userAuthBegin());
  API.userAuth()
    .then((response: any) => {
      dispatch(userAuthSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(userAuthError("Error", error.response.request.status));
      if (error.response != undefined) {
        if (error.response.data.error == "Expired token" && window.location.pathname != "/emailVerified") {
          dispatch(logoutBegin());
          localStorage.clear();
          dispatch(logoutSuccess());
          window.location.href = "./";
        }
      }
    });
};


// -------------------------------------GET INITIAL DATA (UPDATE USER PASSWORD) ----------------------------------------

const getInitialDataUpdatePasswordBegin = (data: sendInitialUserByToken): types.IGetInitialDataUpdatePasswordBegin => ({
  type: authActionTypes.GET_INITIAL_DATA_UPDATE_PASSWORD_BEGIN,
  data
});

const getInitialDataUpdatePasswordError = (e: any, errorType: any): types.IGetInitialDataUpdatePasswordError => ({
  type: authActionTypes.GET_INITIAL_DATA_UPDATE_PASSWORD_ERROR,
  error: e,
  errorType: errorType
});

const getInitialDataUpdatePasswordSuccess = (data: getInitialUserByToken): types.IGetInitialDataUpdatePasswordSuccess => ({
  type: authActionTypes.GET_INITIAL_DATA_UPDATE_PASSWORD_SUCCESS,
  data,
});

export const getInitialDataUpdatePassword = (data: sendInitialUserByToken) => (dispatch: Dispatch) => {
  dispatch(getInitialDataUpdatePasswordBegin(data));
  API.getUserNewPassword(data)
    .then((response: any) => {
      if (response.data.validator == "0") {
        window.location.href = "/"
      }
      dispatch(getInitialDataUpdatePasswordSuccess(response.data));
    })
    .catch((error: any) => {
      window.location.href = "/"
      dispatch(getInitialDataUpdatePasswordError("Error", error.response.request.status));
    });
};


// -------------------------------------REQUEST PASSWORD CHANGE EMAIL----------------------------------------

const PasswordEmailBegin = (id: any): types.IUserAuthRequestPasswordEmailBegin => ({
  type: authActionTypes.USER_AUTH_REQUEST_PASSWORD_EMAIL_BEGIN,
  id: id
});

const PasswordEmailError = (e: any, errorType: any): types.IUserAuthRequestPasswordEmailError => ({
  type: authActionTypes.USER_AUTH_REQUEST_PASSWORD_EMAIL_ERROR,
  error: e,
  errorType: errorType
});

const PasswordEmailSuccess = (): types.IUserAuthRequestPasswordEmailSuccess => ({
  type: authActionTypes.USER_AUTH_REQUEST_PASSWORD_EMAIL_SUCCESS,
});

export const requestPasswordChangeEmail = (id: any) => (dispatch: Dispatch) => {
  dispatch(PasswordEmailBegin(id));
  API.requestPasswordChangeEmail(id)
    .then((response: any) => {
      dispatch(PasswordEmailSuccess());
    })
    .catch((error: any) => {
      dispatch(PasswordEmailError("Error", error.response.request.status));
    });
};

// -------------------------------------REQUEST FORGOT PASSWORD----------------------------------------

const ForgotPasswordBegin = (email: string): types.IUserAuthRequestForgotPasswordBegin => ({
  type: authActionTypes.USER_AUTH_REQUEST_FORGOT_PASSWORD_BEGIN,
  email: email
});

const ForgotPasswordError = (e: any, errorType: any): types.IUserAuthRequestForgotPasswordError => ({
  type: authActionTypes.USER_AUTH_REQUEST_FORGOT_PASSWORD_ERROR,
  error: e,
  errorType: errorType
});

const ForgotPasswordSuccess = (message: any): types.IUserAuthRequestForgotPasswordSuccess => ({
  type: authActionTypes.USER_AUTH_REQUEST_FORGOT_PASSWORD_SUCCESS,
  message: message
});

export const requestForgotPassword = (email: string, option: number, userRequest: any) => (dispatch: Dispatch) => {
  dispatch(ForgotPasswordBegin(email));
  API.forgotPasswordRequest(email, option, userRequest)
    .then((response: any) => {
      dispatch(ForgotPasswordSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(ForgotPasswordError("Error", error.response.request.status));
    });
};

// -------------------------------------UPDATE EMAIL USER----------------------------------------

const emailUserVerifiedBegin = (token: string): types.IUpdateEmailUserBegin => ({
  type: authActionTypes.UPDATE_EMAIL_USER_BEGIN,
  token: token
});

const emailUserVerifiedError = (e: any, errorType: any): types.IUpdateEmailUserError => ({
  type: authActionTypes.UPDATE_EMAIL_USER_ERROR,
  error: e,
  errorType: errorType
});

const emailUserVerifiedSuccess = (message: any): types.IUpdateEmailUserSuccess => ({
  type: authActionTypes.UPDATE_EMAIL_USER_SUCCESS,
  message: message
});

export const emailUserVerified = (token: string) => (dispatch: Dispatch) => {
  dispatch(emailUserVerifiedBegin(token));
  API.emailUserVerified(token)
    .then((response: any) => {
      dispatch(emailUserVerifiedSuccess(response.data));
    })
    .catch((error: any) => {
      dispatch(emailUserVerifiedError("Error", error.response.request.status));
      window.location.href = "/"
    });
};

// -------------------------------------CANCEL UPDATE EMAIL USER----------------------------------------

const cancelEmailUserVerifiedBegin = (): types.ICancelUpdateEmailUserBegin => ({
  type: authActionTypes.CANCEL_UPDATE_EMAIL_USER_BEGIN,
});

const cancelEmailUserVerifiedError = (e: any, errorType: any): types.ICancelUpdateEmailUserError => ({
  type: authActionTypes.CANCEL_UPDATE_EMAIL_USER_ERROR,
  error: e,
  errorType: errorType
});

const cancelEmailUserVerifiedSuccess = (message: any): types.ICancelUpdateEmailUserSuccess => ({
  type: authActionTypes.CANCEL_UPDATE_EMAIL_USER_SUCCESS,
  message: message
});

export const cancelEmailUserVerified = () => (dispatch: Dispatch) => {
  dispatch(cancelEmailUserVerifiedBegin());
  API.cancelEmailUserVerified()
    .then((response: any) => {
      dispatch(cancelEmailUserVerifiedSuccess(response.data));
      window.location.reload()
    })
    .catch((error: any) => {
      dispatch(cancelEmailUserVerifiedError("Error", error.response.request.status));
    });
};


// -------------------------------------DATA CLEAN ----------------------------------------

const dataClean = (): types.ICleanDataLogin => ({
  type: authActionTypes.CLEAN_DATA_LOGIN,
});

export const clean = () => (dispatch: Dispatch) => {
  dispatch(dataClean());
};
