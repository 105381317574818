import React, { Component } from 'react';

//Styles
import style from '../../styles/basicComponents/TextFieldArea.module.css';

interface Props {
  value: string
  error?: boolean
  extraClass?: any
  placeholder?: string
  onChange: (value: any) => void;
  disabled?: boolean
  id?: string
  maxLenght: number
  noShowLenght?: boolean
}

interface State { }

export default class TextFieldArea extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialState = {};
  }

  handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const tecla = String.fromCharCode(keyCode);
    if (tecla == "|") {
      event.preventDefault();
    }
  }

  render() {
    return (
      <>
        <textarea
          maxLength={this.props.maxLenght}
          placeholder={this.props.placeholder}
          className={[style.__baseStructure, this.props.disabled ? style.__disabled : style.__baseStructureCorrect, this.props.error ? style.__baseStructureIncorrect : this.props.disabled ? style.__disabledInput : style.__baseStructureCorrect].join(' ')}
          onChange={(value: any) => {
            this.props.onChange(value.target.value.substring(0, this.props.maxLenght))
          }}
          value={this.props.value}
          id={this.props.id}
          onKeyPress={this.handleKeyPress}
          readOnly={this.props.disabled}
        >
        </textarea>
        {!this.props.noShowLenght && this.props.maxLenght &&
          <div className={style.__characterCounter}>{Number(this.props.maxLenght - this.props.value.length)}/{this.props.maxLenght}</div>
        }

      </>
    );
  }
}
