import React, { Component } from 'react';

//External components
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Container } from 'react-bootstrap';

//Icons
import ViewIcon from '../../assets/svg/view.svg';
import CancelIcon from '../../assets/svg/cancel.svg';
import DownloadIcon from '../../assets/svg/download.svg';

//Internal components
import UploadFile from '../../components/basicComponents/UploadFile';
import ButtonForm from '../../components/basicComponents/ButtonForm';
import TextField from '../../components/basicComponents/TextField';
import TextFieldArea from '../../components/basicComponents/TextFieldArea';
import ReduxLoader from '../../components/basicComponents/ReduxLoader';
import PopUp from '../../components/basicComponents/PopUp';
import ToastAlert from '../../components/basicComponents/ToastAlert';

//Styles
import styles from '../../styles/screens/myProfile.module.css';
import stylesPop from '../../styles/screens/updatePassword.module.css';
import tableStyle from '../../styles/basicComponents/table.module.css';
import titleStyle from '../../styles/titles.module.css';

//Models
import { MyUserModel } from '../../models/user/user_model';
import { NewInvoice as NewInvoiceCreate } from '../../models/invoice/invoice_model';

//redux
import { newInvoice, lastInvoice } from '../../actions/invoices-actions';
import { connect } from 'react-redux';
import { filesBucketInvoiceUpload } from '../../actions/filesBucket-actions';

interface Props {
    //inherited
    myUser: MyUserModel,

    //Redux and Reducers
    newInvoice: (data: NewInvoiceCreate) => void,
    filesBucketInvoiceUpload: (files: any) => void,
    loadingUpload: boolean,
    create_invoice: string,
    messageError: string,
    lastInvoice: () => void,
    lastInvoiceData: any
}
interface State {
    invoiceCreate: NewInvoiceCreate,
    total_hours_format: any,
    totalFormat: string,
    //Files
    placeholderInputUpload: string
    fileInputUpload: any
    filesNames: any[],
    files: any[]
    errorFile: boolean

    //Errors
    folioRepeat: boolean
    folioError: boolean
    start_dateError: boolean
    end_dateError: boolean
    total_hoursError: boolean
    totalError: boolean
    proyectError: boolean
    filesError: boolean
    datePickerError: boolean
    dateInvoiceError: boolean
    //Pop Up
    popUpTrigger: boolean

    //Toast
    saveSuccess: boolean
    saveError: boolean
    activeForceLoader: boolean
}
class NewInvoice extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const initialState = {
            invoiceCreate: {
                folio: '',
                start_date: '',
                end_date: '',
                total_hours: '',
                total: '',
                proyect: '',
                note: '',
                invoice_date: ''
            },
            total_hours_format: '',
            totalFormat: '',

            popUpTrigger: false,

            //Files
            placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)',
            fileInputUpload: [],
            filesNames: [],
            files: [],
            errorFile: false,

            //Errors
            folioError: false,
            start_dateError: false,
            end_dateError: false,
            total_hoursError: false,
            totalError: false,
            proyectError: false,
            filesError: false,
            datePickerError: false,
            folioRepeat: false,
            dateInvoiceError: false,

            //Toasts
            saveSuccess: false,
            saveError: false,
            activeForceLoader: false
        };
        this.state = initialState;
    }

    /////////////////////////////////////////BASE FUNCTIONS/////////////////////////////////
    componentDidUpdate(prevProps: Props) {
        const { create_invoice, loadingUpload } = this.props
        if (create_invoice != prevProps.create_invoice &&
            create_invoice.split("||")[0] == "invoice created successfully" &&
            prevProps.create_invoice == ""
        ) {
            const { files } = this.state
            this.setState({ activeForceLoader: true })
            for (var i = 0; i < files.length; i++) {
                const formData = new FormData();
                formData.append('file', files[i])
                formData.append('invoice', create_invoice.split("||")[1])
                this.props.filesBucketInvoiceUpload(formData)
            }
            this.setState({ saveSuccess: true, activeForceLoader: false })
            localStorage.setItem('idNew', create_invoice.split("||")[1])
            setTimeout(() => { window.location.href = "/contractorDetailsInvoices" }, 2000)
        }
        if (create_invoice != prevProps.create_invoice &&
            create_invoice == "folio repeat" &&
            prevProps.create_invoice == ""
        ) {
            this.setState({ folioRepeat: true })
        }
    }

    componentDidMount() {
        this.props.lastInvoice();
    }













    /////////////////////////////////////////FORMATS FUNCTIONS//////////////////////////////////
    currencyFormatter = (currency: any, value: any) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            minimumFractionDigits: 2,
            currency
        })
        return formatter.format(value)
    }
    periodFormat = (startDate: any, endDate: any) => {
        const startFormat = startDate.substring(5, 7) + "/" + startDate.substring(8, 10) + "/" + startDate.substring(0, 4);
        const endFormat = endDate.substring(5, 7) + "/" + endDate.substring(8, 10) + "/" + endDate.substring(0, 4);
        return startFormat + " - " + endFormat
    }

    dateFormat = (date: any) => {
        const dateValue = date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4);
        return dateValue
    }
    renderStatusName = (valueStatus: any) => {
        if (valueStatus == 0) {
            return "Submitted"
        }
        if (valueStatus == 1) {
            return "Approved - AM"
        }
        if (valueStatus == 2) {
            return "Approved - FIN"
        }
        if (valueStatus == 3) {
            return "Paid"
        }
        if (valueStatus == 4) {
            return "Rejected - AM"
        }
        if (valueStatus == 5) {
            return "Rejected - FIN"
        }
    }














    ////////////////////////////////////////ONCLICK FUCTIONS/////////////////////////////////
    onSubmit = () => {
        let wrongDate = false, emptyInvoice = false, emptystart_date = false, emptyend_date = false, emptyInvoice_date = false,
            emptyHour = false, emptyTotal = false, emptyProyect = false, errorLength = false, filesErrornoPDF = true;
        const { invoiceCreate, filesNames } = this.state;
        if (invoiceCreate.start_date == '') {
            emptystart_date = true
            this.setState({ start_dateError: true })
        }
        if (invoiceCreate.end_date == '') {
            emptyend_date = true
            this.setState({ end_dateError: true })
        }
        if (invoiceCreate.invoice_date == '') {
            emptyInvoice_date = true
            this.setState({ dateInvoiceError: true })
        }
        if (invoiceCreate.end_date < invoiceCreate.start_date) {
            wrongDate = true
            this.setState({ datePickerError: true })
        }
        if (invoiceCreate.proyect == '') {
            emptyProyect = true
            this.setState({ proyectError: true })
            document.getElementById('projectAssignedFocusOnError')!.focus()
        }
        if (invoiceCreate.total == '') {
            emptyTotal = true
            this.setState({ totalError: true })
            document.getElementById('totalAmountFocusOnError')!.focus()
        }
        if (invoiceCreate.total_hours == '') {
            emptyHour = true
            this.setState({ total_hoursError: true })
            document.getElementById('billableHourFocusOnError')!.focus()
        }
        if (invoiceCreate.folio.trim() === '') {
            emptyInvoice = true
            this.setState({ folioError: true })
            document.getElementById('invocieNumberFocusOnError')!.focus()
        }
        for (var i = 0; i < filesNames.length; i++) {
            const nameComplete = filesNames[i].split(".")
            const lastValue = nameComplete.length
            if (nameComplete[lastValue - 1] == "pdf") {
                filesErrornoPDF = false
            }
        }
        if (emptyInvoice || emptystart_date || emptyend_date ||
            emptyHour || emptyTotal || emptyProyect || wrongDate || errorLength || emptyInvoice_date || filesErrornoPDF) {
            this.setState({ saveError: true, filesError: filesErrornoPDF })
            return
        } else {
            this.setState({ popUpTrigger: true })

        }

    }

    onCancel = () => {
        window.location.href = "/invoices"
    }

    onclickUpload = (fileV: any, fileNameV: any) => {
        if (fileV != "") {
            const file: any[] = this.state.files
            const filesName: any[] = this.state.filesNames
            file.unshift(fileV)
            filesName.unshift(fileNameV)
            this.setState({ files: file, filesNames: filesName, fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)' })
            const inputElement = document.getElementById('fileDropdownGeneral') as HTMLInputElement
            inputElement.value = ""
        }
    }
    deleteFile = (value: any) => {
        const files = this.state.files.slice();
        const filesNames = this.state.filesNames.slice();

        if (value !== -1) {
            files.splice(value, 1);
            filesNames.splice(value, 1);
            this.setState({ files, filesNames });
        }
    }

    downLoadFile = (value: any, name: any) => {
        const { files } = this.state
        var urlArchivo = URL.createObjectURL(files[value]);

        var linkDescarga = document.createElement('a');
        linkDescarga.href = urlArchivo;
        linkDescarga.download = name;
        linkDescarga.click();
        URL.revokeObjectURL(urlArchivo);
    }

    previewFile = (value: any, name: any) => {
        const { files } = this.state
        var urlArchivo = URL.createObjectURL(files[value]);
        window.open(urlArchivo, '_blank');
        URL.revokeObjectURL(urlArchivo);
    }

















    //////////////////////////////////////////ONCHANGE FUNCTIONS/////////////////////////////////

    onChangeFolio = (value: any) => {
        const { invoiceCreate } = this.state;
        invoiceCreate.folio = value;
        this.setState({ invoiceCreate, folioError: false, folioRepeat: false })
    }
    onChangeStartDate = (value: any) => {
        const { invoiceCreate } = this.state;
        if (invoiceCreate.end_date > value || invoiceCreate.end_date == '') {
            invoiceCreate.start_date = value;
            this.setState({ invoiceCreate, start_dateError: false, datePickerError: false })
        }
    }
    onChangeEndDate = (value: any) => {
        const { invoiceCreate } = this.state;
        if (invoiceCreate.start_date < value) {
            invoiceCreate.end_date = value;
            this.setState({ invoiceCreate, end_dateError: false, datePickerError: false })
        }
    }
    onChangeInvoiceDate = (value: any) => {
        const { invoiceCreate } = this.state;
        invoiceCreate.invoice_date = value;
        this.setState({ invoiceCreate, dateInvoiceError: false })
    }
    onChangeHours = (value: any) => {
        const { invoiceCreate } = this.state;
        invoiceCreate.total_hours = value;
        this.setState({ invoiceCreate, total_hoursError: false, total_hours_format: Number.parseFloat(value).toFixed(2) })
    }
    onChangeTotal = (value: any) => {
        const formatValue = value.replace("$", "").replace(/,/g, "")
        const { invoiceCreate } = this.state;
        invoiceCreate.total = value;
        this.setState({ invoiceCreate, totalError: false, totalFormat: value == "" ? "" : this.currencyFormatter("USD", formatValue), })
    }
    onChangeProject = (value: any) => {
        const { invoiceCreate } = this.state;
        invoiceCreate.proyect = value;
        this.setState({ invoiceCreate, proyectError: false })
    }

    onchangeNote = (value: any) => {
        const { invoiceCreate } = this.state;
        invoiceCreate.note = value
        this.setState({ invoiceCreate })
    };

    onChangeUpload = (file: any) => {
        if (file.target.files[0] != undefined) {
            if (file.target.files[0].size < 10485750 &&
                file.target.files[0].name.split(".").length == 2
            ) {
                const extencion = file.target.files[0].name.split(".")
                if (extencion[1] == "pdf" || extencion[1] == "doc" || extencion[1] == "docx" || extencion[1] == "xlsx" || extencion[1] == "csv") {
                    this.onclickUpload(file.target.files[0], file.target.files[0].name)
                }
                else {
                    this.setState({ fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)', errorFile: true })
                }
            } else {
                this.setState({ fileInputUpload: '', placeholderInputUpload: 'Attach your files (.pdf, .doc, .docx, .xlsx, .csv)', errorFile: true })
            }

        }

    }






    /////////////////////////RENDER ON SCREEN////////////////////////////////////////////
    render() {
        const currentDate = new Date();
        const minDate = new Date(currentDate.getFullYear() - 2, 0, 1).toISOString().split('T')[0];
        const maxDate = new Date(currentDate.getFullYear() + 2, 11, 31).toISOString().split('T')[0];
        const { lastInvoiceData, myUser } = this.props
        const { saveSuccess, saveError, popUpTrigger, invoiceCreate, totalFormat, activeForceLoader,
            errorFile, filesError, folioError, totalError, proyectError, end_dateError, datePickerError,
            total_hoursError, dateInvoiceError, start_dateError, folioRepeat, filesNames,
            files, fileInputUpload, placeholderInputUpload, total_hours_format
        } = this.state
        return (
            <Container>
                <div className={stylesPop.__centerAll}>
                    <PopUp passwordTrigger={popUpTrigger} title="CONFIRMATION"
                        subTitle=""
                        text={`Once submitted, your invoice cannot be edited until reviewed and rejected. <br> Do you want to proceed?<br>
                        <div style="margin-left: .7em ;width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Invoice no.:</strong> `+ invoiceCreate.folio + `</div>
                        <div style="width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Invoice date:</strong> `+ invoiceCreate.invoice_date + `</div>
                        <div style="margin-left: .7em ;width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Period start:</strong> `+ invoiceCreate.start_date + `</div>
                        <div style="width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Period end:</strong> `+ invoiceCreate.end_date + `</div>
                        <div style="margin-left: .7em ;width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Billable hours:</strong> `+ invoiceCreate.total_hours + `</div>
                        <div style="width: calc(49% - .35em); display: inline-block; text-align: left;"><strong>Total amount:</strong> `+ invoiceCreate.total + `</div>
                        <div style="margin-left: .7em ;width: calc(99% - 0.7em); display: inline-block; text-align: left;"><strong>Assigned project:</strong> `+ invoiceCreate.proyect + `</div>
                        <div style="margin-left: .7em ;width: calc(99% - 0.7em); display: inline-block; text-align: left;"><strong>Notes:</strong> `+ invoiceCreate.note + `</div>
                        `}
                        buttonTxt="YES"
                        extraButton={<ButtonForm label="CANCEL" extraClass={styles.__buttonCancel} onClick={() => { this.setState({ popUpTrigger: false }) }} />}
                        onClick={() => {
                            this.setState({ popUpTrigger: false })
                            const { invoiceCreate } = this.state
                            invoiceCreate.total = invoiceCreate.total.replace("$", "").replace(/,/g, "")
                            this.props.newInvoice(invoiceCreate);
                            localStorage.setItem('contractor', myUser.name)
                            localStorage.setItem('folio', invoiceCreate.folio)
                            localStorage.setItem('AM', myUser.AM)
                            localStorage.setItem('start_date', invoiceCreate.start_date)
                            localStorage.setItem('end_date', invoiceCreate.end_date)
                            localStorage.setItem('status', "Submitted")
                            localStorage.setItem('created', new Date().toLocaleDateString())
                            localStorage.setItem('billable_hours', (invoiceCreate.total_hours))
                            localStorage.setItem('payment_method', this.props.myUser.payment_method.name)
                            localStorage.setItem('hourly_rate', this.currencyFormatter("USD", this.props.myUser.hourly_rate))
                            localStorage.setItem('monthly_fee', this.currencyFormatter("USD", this.props.myUser.monthly_fee))
                            localStorage.setItem('total_hours', invoiceCreate.total_hours)
                            localStorage.setItem('total', totalFormat)
                            localStorage.setItem('proyect', invoiceCreate.proyect)
                            localStorage.setItem('note', invoiceCreate.note)
                            localStorage.setItem('invoice_date', invoiceCreate.invoice_date)
                        }} />
                </div>
                <ReduxLoader activeForce={activeForceLoader} />
                <ToastAlert
                    showToast={saveSuccess}
                    timeActive={3000}
                    disableFunction={() => { this.setState({ saveSuccess: false }) }}
                />
                <ToastAlert
                    error
                    label="Required data is missing"
                    showToast={saveError}
                    timeActive={3000}
                    disableFunction={() => { this.setState({ saveError: false }) }}
                />
                <Row className={titleStyle.__MainRowTitle}>
                    <Col lg="12" md="12" sm="12" className={titleStyle.__MainTitle}>
                        <label onClick={() => { window.location.href = "/invoices" }} className={titleStyle.__titlePart1}>INVOICES</label>
                        <label className={titleStyle.__titlePart2}>{">"}</label>
                        <label className={titleStyle.__titlePart3}>NEW INVOICE</label>
                        <label className={titleStyle.__titlePart4}></label>
                        <label className={titleStyle.__titlePart5}></label>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" md="6" sm="6" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Invoice no.*</label>
                        <TextField maxLenght={99} id="invocieNumberFocusOnError" value={invoiceCreate.folio} error={folioError || folioRepeat}
                            placeholder={(lastInvoiceData != "" && lastInvoiceData != "No invoices") ? "Write the folio of your invoice (last invoice no. " + lastInvoiceData.folio + ")" : "Write the folio of your invoice"} onChange={(value: any) => { this.onChangeFolio(value) }} />
                        {folioError && <span className={styles.err} >This field is required</span>}
                        {folioRepeat && <span className={styles.err} >This folio is already registered</span>}
                    </Col>
                    <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Period start*</label>
                        <TextField maxLenght={100} minValue={minDate} maxValue={invoiceCreate.end_date == '' ? maxDate : invoiceCreate.end_date} type="date" value={invoiceCreate.start_date} error={start_dateError || datePickerError} onChange={(value: any) => { this.onChangeStartDate(value) }} />
                        {start_dateError &&
                            <span className={styles.err} >This field is required</span>
                        }
                    </Col>
                    <Col lg="3" md="3" sm="3" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Period end*</label>
                        <TextField maxLenght={100} minValue={invoiceCreate.start_date} maxValue={maxDate} disabled={invoiceCreate.start_date == ""} type="date" value={invoiceCreate.end_date} error={end_dateError || datePickerError} onChange={(value: any) => { this.onChangeEndDate(value) }} />
                        {end_dateError &&
                            <span className={styles.err} >This field is required</span>
                        }
                        {datePickerError &&
                            <span className={styles.err} >Invalid date</span>
                        }
                    </Col>
                </Row>
                <Row className="justify-content-md-left">
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Billable hours*</label>
                        <TextField maxLenght={50} onlyNumbers
                            onBlur={() => {
                                const { invoiceCreate } = this.state;
                                invoiceCreate.total_hours = total_hours_format;
                                this.setState({ invoiceCreate })
                            }}
                            id="billableHourFocusOnError" value={invoiceCreate.total_hours} error={total_hoursError} placeholder="How many hours your invoice covers" onChange={(value: any) => { this.onChangeHours(value) }} />
                        {total_hoursError &&
                            <span className={styles.err}>This field is required</span>
                        }
                    </Col>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Total amount*</label>
                        <TextField maxLenght={50} onlyNumbers onBlur={() => {
                            const { invoiceCreate } = this.state
                            invoiceCreate.total = totalFormat
                            this.setState({ invoiceCreate })
                        }}
                            id="totalAmountFocusOnError" value={invoiceCreate.total} error={totalError} placeholder="Write the total amount" onChange={(value: any) => { this.onChangeTotal(value) }} />
                        {totalError &&
                            <span className={styles.err}>This field is required</span>
                        }
                    </Col>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Invoice date*</label>
                        <TextField maxValue={maxDate} minValue={minDate} maxLenght={100} type="date" value={invoiceCreate.invoice_date}
                            error={dateInvoiceError} onChange={(value: any) => { this.onChangeInvoiceDate(value) }} />
                        {dateInvoiceError &&
                            <span className={styles.err} >This field is required</span>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg="8" md="8" sm="8" className={styles.__contractorInfoInvoice}>
                        <Row>
                            <Col className={styles.__hourly_rate_suggest} lg="4" md="4" sm="4">
                                <label className={styles.__boldText}>Your hourly rate:</label><br /> {this.currencyFormatter("USD", this.props.myUser.hourly_rate)}
                            </Col>
                            <Col lg="8" md="8" sm="8">
                                <label className={styles.__boldText}>Suggested total based on your information:</label> {this.currencyFormatter("USD", invoiceCreate.total_hours != "" ? (myUser.hourly_rate * invoiceCreate.total_hours) : "0")}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="4" md="4" sm="4" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Assigned project*</label>
                        <TextField maxLenght={35} value={invoiceCreate.proyect} id="projectAssignedFocusOnError" error={proyectError} placeholder="Write the name of the project" onChange={(value: any) => { this.onChangeProject(value) }} />
                        {proyectError &&
                            <span className={styles.err} >This field is required</span>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <label className={styles.__boldText}>Attachments*</label>
                        <UploadFile needPDF onClick={() => { this.setState({ errorFile: false, filesError: false }) }} error={errorFile || filesError}
                            placeholder={placeholderInputUpload} onChange={(value: any) => { this.onChangeUpload(value) }} />
                    </Col>
                </Row>
                <Col>
                    <Table responsive>
                        <tbody>
                            {filesNames.length > 0 && filesNames.map((value: any, index: number) => (
                                <tr key={"newFiles" + index}>
                                    <td className={tableStyle.tableItem}>
                                        <span key={"newFiles" + index} id={index.toString()}>{value}</span>
                                    </td>
                                    {value.split(".")[1] == "pdf" ?
                                        <>
                                            <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                            <td><img onClick={() => { this.previewFile(index, value) }} className={[styles.__rightIconsDownload, styles.__eyeIcon].join(' ')} src={ViewIcon} /></td>
                                            <td><img onClick={() => { this.deleteFile(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                                        </>
                                        :
                                        <>
                                            <td></td>
                                            <td><img onClick={() => { this.downLoadFile(index, value) }} className={styles.__rightIconsDownload} src={DownloadIcon} /></td>
                                            <td><img onClick={() => { this.deleteFile(index) }} className={styles.__rightIconsDownload} src={CancelIcon} /></td>
                                        </>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Row>
                    <Col lg="12" md="12" sm="12" className={styles.colMyProfileInput}>
                        <label className={styles.__boldText}>Notes</label>
                        <TextFieldArea maxLenght={300} value={invoiceCreate.note} placeholder="Send us a note" onChange={(value: any) => { this.onchangeNote(value) }} />
                    </Col>
                </Row>
                <Col lg="12" md="12" sm="12">
                    <div className={styles.__buttonsSaveCancel}>
                        <ButtonForm onClick={() => { this.onCancel() }} extraClass={styles.__marginButtonsCancel} label="Cancel" />
                        <ButtonForm onClick={() => { this.onSubmit() }} extraClass={styles.__marginButtonsSave} colorFill label="Save" />
                    </div>
                </Col>
            </Container>
        )
    }
}

const mapStateToProps = (state: any) => ({
    loadingUpload: state.filesBucketReducer.loadingUpload,
    create_invoice: state.invoiceReducer.create_invoice,
    messageError: state.filesBucketReducer.messageError,
    lastInvoiceData: state.invoiceReducer.lastInvoiceData
});

const mapDispatchToProps = (dispatch: any) => ({
    newInvoice: (data: NewInvoiceCreate) => dispatch(newInvoice(data)),
    filesBucketInvoiceUpload: (files: Array<any>) => dispatch(filesBucketInvoiceUpload(files)),
    lastInvoice: () => dispatch(lastInvoice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoice);
