// Redux
import { Reducer } from 'redux';
import { UserAction } from '../types/users-types';
import { usersActionTypes } from '../constants/users-constants';

// Models
import { usersModel, usersManagersModel, MyUserModel } from '../models/user/user_model';

interface UserState {
  loading: boolean,
  users: Array<usersModel>,
  usersManagers: Array<usersManagersModel>,
  messageError: string,
  getUserStatus: boolean,
  userDetails: Array<MyUserModel>
  statusUpdate: boolean
}

const initialState: UserState = {
  loading: false,
  users: [],
  usersManagers: [],
  messageError: "",
  userDetails: [],
  getUserStatus: false,
  statusUpdate: false
};

export const usersReducer: Reducer<UserState, UserAction> = (state = initialState, action: UserAction) => {
  switch (action.type) {
    // ------------------ USERS FETCH_ -----------------------------
    case usersActionTypes.USERS_FETCH_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
      };

    case usersActionTypes.USERS_FETCH_ERROR:
      return {
        ...state,
        loginError: true,
        loading: false,
        messageError: action.error
      };

    case usersActionTypes.USERS_FETCH_SUCCESS:
      return {
        ...state,
        messageError: '',
        loading: false,
        users: {
          ...action.data,
        },
      };

    // ------------------ USERS MANAGERS FETCH_ -----------------------------
    case usersActionTypes.USERS_MANAGERS_FETCH_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
      };

    case usersActionTypes.USERS_MANAGERS_FETCH_ERROR:
      return {
        ...state,
        loginError: true,
        loading: false,
        messageError: action.error
      };

    case usersActionTypes.USERS_MANAGERS_FETCH_SUCCESS:
      return {
        ...state,
        messageError: '',
        loading: false,
        usersManagers: {
          ...action.dataManager,
        },
      };


    // ------------------ USER STATUS UPDATE -----------------------------
    case usersActionTypes.USER_STATUS_BEGIN:
      return {
        ...state,
        statusUpdate: true
      };

    case usersActionTypes.USER_STATUS_ERROR:
      return {
        ...state,
        statusUpdate: false
      };

    case usersActionTypes.USER_STATUS_SUCCESS:
      return {
        ...state,
        statusUpdate: false
      };

    // ------------------ USERS DETAILS MODAL -----------------------------
    case usersActionTypes.USER_DETAILS_STATUS_BEGIN:
      return {
        ...state,
        messageError: '',
        getUserStatus: false,
      };

    case usersActionTypes.USER_DETAILS_STATUS_ERROR:
      return {
        ...state,
        loginError: true,
        getUserStatus: true,
        messageError: action.error
      };

    case usersActionTypes.USER_DETAILS_STATUS_SUCCESS:
      return {
        ...state,
        messageError: 'Updated successfully',
        getUserStatus: false,
        userDetails: {
          ...action.userDetails,
        },
      };


    // ------------------UPDATE USERS DETAILS MODAL -----------------------------
    case usersActionTypes.UPDATE_USER_DETAILS_STATUS_BEGIN:
      return {
        ...state,
        messageError: '',
        loading: true,
      };

    case usersActionTypes.UPDATE_USER_DETAILS_STATUS_ERROR:
      return {
        ...state,
        loginError: true,
        loading: false,
        messageError: action.error
      };

    case usersActionTypes.UPDATE_USER_DETAILS_STATUS_SUCCESS:
      return {
        ...state,
        messageError: 'no Error',
        loading: false,
        userModalUpdate: {
          ...action.data,
        },
      };

    default:
      return state;
  }
};



